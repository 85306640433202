import { BUILD_ALL, BUILD_COVE } from "@lib/utils/routes";
import { Button, Fade, FormControlLabel, FormLabel, Radio, RadioGroup, Typography } from "@material-ui/core";
import { Family, familyKey } from "@lib/utils/familyKey";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router";

import Facets from "./Facets";
import { observer } from "mobx-react";
import { size } from "lodash";
import { useKitActions } from "@lib/hooks/useKitActions";
import { useStore } from "@lib/hooks/useStore";
import { useStyles } from "styles";
import { useTranslation } from "react-i18next";

const FilterContainer: React.FC = observer(() => {
  const styles = useStyles();
  const { queryKits } = useKitActions();
  const store = useStore();
  const { t } = useTranslation();
  const [activeRadioKey, setActiveRadioKey] = useState(BUILD_ALL);
  const history = useHistory();
  const location = useLocation();
  const showFacets = size(store.categories.superCategory) + size(store.categories.category) >= 1;
  const { brand } = useParams<any>();

  const activeRadioMap: any = {
    all: "All Brands",
    cove: "Cove",
    wolf: "Wolf",
    subzero: "Sub Zero",
    best: "Best",
  };

  const activeRouteMap: any = {
    "All Brands": "/build/all",
    Cove: "/build/cove",
    Wolf: "/build/wolf",
    "Sub Zero": "/build/subzero",
    Best: "/build/best",
  };

  useEffect(() => {
    if (location?.pathname) {
      setActiveRadioKey(brand);
      openFacets(activeRadioMap[brand]);

      if (activeRadioMap[brand] != undefined) {
        store.clearSeachValue();
        setActiveRadioKey(brand);
        openFacets(activeRadioMap[brand]);
        store.setCurrentFamily(activeRadioMap[brand]);
      }
    }
  }, [brand, location?.pathname]);

  const openFacets = (family: any) => {
    const sameFamily = store.currentFamily === family;
    let searchObj = {};

    store.setLoading("facets", true);
    store.handleResetCategories();
    store.resetAggregations();

    if (family != "All Brands") {
      searchObj = {
        family: sameFamily
          ? activeRadioMap[brand] != null
            ? [activeRadioMap[brand]]
            : []
          : family != null
          ? [family]
          : [],
      };
    }

    store.setSearchTerms(searchObj, true);

    queryKits();
  };

  const handleResetFiltersClick = (): void => {
    window.location.reload();
  };

  const handleOnRadioClick = (family: any): void => {
    history.push(activeRouteMap[family]);
  };

  return (
    <div className={styles.facetContainer}>
      <FormLabel
        component="legend"
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "30px",
        }}
      >
        <Typography variant="subtitle2" className={styles.facetCategoryHeader}>
          {t("Filter by Brand")}
        </Typography>
        <Button
          className={styles.buttonFilterReset}
          size="small"
          variant="outlined"
          color="secondary"
          onClick={handleResetFiltersClick}
        >
          Reset Filters
        </Button>
      </FormLabel>
      <RadioGroup
        aria-label="brand"
        name="brand-radios"
        onChange={(e): void => handleOnRadioClick(e.currentTarget.value)}
        value={
          activeRadioMap[activeRadioKey] == undefined || activeRadioMap[activeRadioKey] == null
            ? "All Brands"
            : activeRadioMap[activeRadioKey]
        }
      >
        {Object.keys(familyKey).map((key: Family | any, index: number) => (
          <FormControlLabel
            key={index}
            value={key}
            classes={{ label: styles.flex }}
            control={<Radio color="secondary" />}
            label={familyKey[key]?.logo || key}
          />
        ))}
      </RadioGroup>
      <Fade in={showFacets}>
        <div className={styles.stickSidebar}>{showFacets && <Facets />}</div>
      </Fade>
    </div>
  );
});

export default FilterContainer;
