import React from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { Menu, MenuItem, Button, useTheme } from "@material-ui/core";
import { useQuery } from "models";
import { useStyles } from "styles";
import LinkNoStyle from "@lib/components/LinkNoStyle";
import { useHistory, useLocation } from "react-router";
import LoginOutlineIcon from "@lib/logos/LoginOutlineIcon";
import AccountIcon from "@lib/logos/AccountIcon";
import { BUILD_ALL, LOGIN, ROOT, USER_PROFILE } from "@lib/utils/routes";

const UserMenu: React.FC = observer(() => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const { t } = useTranslation();
  const { store } = useQuery();
  const styles = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const location = useLocation();
  const open = Boolean(anchorEl);

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = (): void => {
    setAnchorEl(null);
  };

  const handleLogout = (): void => {
    handleCloseMenu();
    store.logoutUser();
    history.push(ROOT);
  };

  const handleProfileClick = (): void => {
    store.currentUser && history.push(`..${USER_PROFILE}`);
  };

  const handleMyCollectionClick = (): void => {
    handleCloseMenu();
    history.push(BUILD_ALL);
  };

  return (
    <div className={styles.flex}>
      {store.currentUser ? (
        <div>
          <Button
            color="secondary"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleOpenMenu}
            className={styles.accountButton}
            endIcon={<AccountIcon />}
          >
            {t("My Account")}
          </Button>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={open}
            onClose={handleCloseMenu}
          >
            <MenuItem selected={location.pathname == BUILD_ALL} onClick={handleMyCollectionClick}>
              {t("My Collection")}
            </MenuItem>
            <MenuItem selected={location.pathname == USER_PROFILE} onClick={handleProfileClick}>
              {t("Profile")}
            </MenuItem>
            <MenuItem onClick={handleLogout}>{t("Logout")}</MenuItem>
          </Menu>
        </div>
      ) : (
        <LinkNoStyle to={`..${LOGIN}`}>
          <Button color="secondary" className={styles.loginButton}>
            {t("Login")}
            <LoginOutlineIcon className={styles.loginIcon} outlineColor={theme.palette.secondary.main} />
          </Button>
        </LinkNoStyle>
      )}
    </div>
  );
});

export default UserMenu;
