import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { FormikValues } from "formik";
import { Typography, Divider, Button } from "@material-ui/core";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import LoginForm from "./LoginForm";
import UserPageContainer from "components/User/UserPageContainer";
import FormikTextField from "@lib/components/FormikTextField";
import LinkNoStyle from "@lib/components/LinkNoStyle";
import { useStyles } from "styles";
import ConfirmButtonSpinner from "@lib/components/ConfirmButtonSpinner";
import { CREATE_ACCOUNT, SEND_RESET_PASSWORD_LINK, BUILD_ALL } from "@lib/utils/routes";
import { Redirect, useHistory, useLocation } from "react-router";
import { Slide } from "@material-ui/core";
import { useStore } from "@lib/hooks/useStore";
import { Alert } from "@material-ui/lab";
import { useNotification } from "@lib/components/NotificationManagerContext";

interface InputsProps {
  handleEnterPress: (event: React.KeyboardEvent) => void;
}

const Inputs: React.FC<InputsProps> = ({ handleEnterPress }) => {
  const { t } = useTranslation();

  return (
    <form>
      <FormikTextField
        variant="outlined"
        name="email"
        label={t("Email")}
        type="email"
        labelWidth={40}
        onKeyDown={handleEnterPress}
      />
      <FormikTextField
        variant="outlined"
        name="password"
        label={t("Password")}
        type="password"
        labelWidth={72}
        onKeyDown={handleEnterPress}
      />
    </form>
  );
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const LoginPage: React.FC = () => {
  const { t } = useTranslation();
  const styles = useStyles();
  const history = useHistory();
  const store = useStore();
  const location = useLocation();
  const token = new URLSearchParams(location.search).get("token");
  const passreset = new URLSearchParams(location.search).get("passreset");
  const { message, clearNotification } = useNotification();

  useEffect(() => {
    clearNotification();
  }, []);

  return (
    <>
      {!store.currentUser || token ? (
        <UserPageContainer>
          <LoginForm>
            {({ isSubmitting, handleSubmit }: FormikValues): React.ReactNode => (
              <>
                <div className={styles.loginPageHeader}>
                  <Typography variant="h2" color="primary">
                    {t("Login")}
                  </Typography>
                  <Divider className={styles.dividerModal} />

                  <div className={styles.loginPageCreateAccount}>
                    <LinkNoStyle to={CREATE_ACCOUNT}>
                      <Typography color="primary" className={styles.fontSize13}>
                        {t("Don't have an account?")} <strong>{t("Create one")}</strong>
                      </Typography>
                    </LinkNoStyle>
                  </div>
                </div>

                {message && <Alert severity="warning">{message}!</Alert>}

                <Inputs
                  handleEnterPress={(event: React.KeyboardEvent): void => {
                    if (event.keyCode === 13) {
                      handleSubmit();
                    }
                  }}
                />
                <Divider className={styles.dividerModal} />

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Button
                    onClick={(): void => handleSubmit()}
                    className={styles.buttonWithRoundedCorners}
                    disabled={isSubmitting}
                    variant="contained"
                    color="secondary"
                    endIcon={<ArrowForwardIcon color={isSubmitting ? "disabled" : "inherit"} />}
                  >
                    {t("Confirm")}
                    {isSubmitting && <ConfirmButtonSpinner />}
                  </Button>

                  <LinkNoStyle to={SEND_RESET_PASSWORD_LINK} className={styles.formForgotPassButton}>
                    <strong>{t("Forgot password?")}</strong>
                  </LinkNoStyle>
                </div>
              </>
            )}
          </LoginForm>
        </UserPageContainer>
      ) : passreset == "success" ? (
        history.push(BUILD_ALL)
      ) : (
        history.goBack()
      )}
    </>
  );
};

export default LoginPage;
