import { Instance, flow, destroy, types } from "mobx-state-tree";
import { CanvasModelBase } from "./CanvasModel.base";
import { Query } from "mst-gql";
import { CanvasKitModel } from "./CanvasKitModel";
import { produce } from "immer";

interface CanvasAttributes {
  id: string;
  name: string;
}

/* The TypeScript type of an instance of CanvasModel */
export interface CanvasModelType
  extends Instance<typeof CanvasModel.Type> { }

/* A graphql query fragment builders for CanvasModel */
export {
  selectFromCanvas,
  canvasModelPrimitives,
  CanvasModelSelector,
} from "./CanvasModel.base";


/**
 * CanvasModel
 */

export const CanvasModel = CanvasModelBase.props({
  selectedCanvasKit: types.maybe(types.reference(CanvasKitModel)),
}).actions((self) => {

  const queryCanvass = flow(function* (collectionId = "", clientMutationId = ""): Generator {
    try {
      const response: any = yield self.store.queryCanvass({ collectionId });
      const canvass = response.canvass;
      const canvas = canvass[0];

      self.store.currentUser.canvass = canvass;
      self.store.setCurrentCanvas(canvas);

      return response;

    } catch (error) {
      console.error("Canvas Model, QueryCanvass: ", error);
      throw error;
    }
  });

  const createCanvas = flow(function* (name = ""): Generator {
    try {
      const input = {
        name,
        collectionId: self.store.currentCollection.id,
        clientMutationId: "createCanvas",
      };

      const response: any = yield self.store.mutateCreateCanvas(
        { input }, (res) => res.result.canvas((col) => col.name.createdAt.updatedAt)
      );

      const canvas = response.createCanvas.canvas;

      self.store.setCurrentCanvas(canvas);
      self.store.addCurrentCanvasToUser();

      return response;
    } catch (error) {
      console.error("Canvas Model, createCanvas: ", error);
      throw error;
    }
  });

  const updateCanvas = ({ id, name }: CanvasAttributes): Query => {
    try {
      const isAssociatedWithUser = !!self.store.currentUser?.canvass?.some(
        (col: any) => col.id === id
      );

      const input = { id, name, clientMutationId: "UpdateCanvas" };

      const response = self.store.mutateUpdateCanvas(

        { input }, (res) => res.canvas((col: any) => col.name.updatedAt),
        () => {
          const canvas = self.store.canvass.get(id);
          self.store.currentUser && self.store.addCurrentCanvasToUser();

          if (canvas) {
            canvas.name = name;
          }
        }
      );
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }

  };

  const setSelectedCanvasKit = (id: string | undefined): void => {
    const previousKit = self.selectedCanvasKit;
    const kit = self.store.canvaskits.get(id || "");

    self.selectedCanvasKit = kit;

    // remove temp canvas kits after use
    if (previousKit && !previousKit?.canvas) {
      self.store.removeCanvasKitFromStore(previousKit.id);
    }
  };

  // RAGE COMMENT:
  // THESE CANVAS KITS FUNCTIONS SHOULD BE IN THERE OWN
  // MODAL BUT FOR SOME DUMB REASON IT WILL REGESTER THEM.
  // THEY ARE IN HERE FOR THE TIME BEINING
  const createCanvasKit = flow(function* createCanvasKit(
    canvasId: string,
    collectionKitId: string,
    kitId: string,
    x: string,
    y: string,
    scale: string,
  ): Generator {
    try {

      const input = {
        canvasId,
        collectionKitId,
        kitId,
        x,
        y,
        scale
      };

      const response: any = yield self.store.mutateCreateCanvasKit(
        { input },
        (res: any) => res.result.canvasKit((canKit: any) => canKit.id.collectionKitId.kitId.canvasId.x.y.scale)
      );

      const newCanvasKit = response.createCanvasKit.canvasKit;
      const canvasKitId = newCanvasKit.id;
      const collectionKitIdalt = newCanvasKit.collectionKitId;

      self.store.currentCanvas.canvasKits.push(newCanvasKit);

      const updatedItems = produce(self.store.previewWallContents, (draft: any) => {
        draft[collectionKitIdalt].canvasKitId = canvasKitId;
      });

      self.store.updatePreviewWallContents(updatedItems);

      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  });

  const updateCanvasKit = flow(function* updateCanvasKit(
    id: string,
    x: string,
    y: string,
    scale: string,
  ): Generator {
    try {

      const input = {
        id,
        x,
        y,
        scale,
      };

      const response: any = yield self.store.mutateUpdateCanvasKit(
        { input },
        (res: any) => res.result
      );
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  });

  const destroyCanvasKit = flow(function* destroyCanvasKit(
    id: string,
  ): Generator {
    try {
      const input = {
        id,
      };

      const response = yield self.store.mutateDestroyCanvasKit(
        { input },
        (res: any) => res.result,
        () => {
          self.store.currentCanvas.canvasKits = self.store.currentCanvas?.canvasKits.filter(
            (colKit: any) => {
              return colKit.id !== id;
            }
          );
          self.store.canvaskits.delete(id);
        }
      );

      return response;

    } catch (error) {
      console.error(error);
      throw error;
    }
  });


  return {
    queryCanvass,
    updateCanvas,
    createCanvas,
    setSelectedCanvasKit,
    createCanvasKit,
    updateCanvasKit,
    destroyCanvasKit
  };
});
