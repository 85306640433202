/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from "mobx-state-tree"
import { MSTGQLRef, QueryBuilder, withTypedRefs } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { CanvasModel, CanvasModelType } from "./CanvasModel"
import { CanvasModelSelector } from "./CanvasModel.base"
import { RootStoreType } from "./index"


/* The TypeScript type that explicits the refs to other models in order to prevent a circular refs issue */
type Refs = {
  canvas: CanvasModelType;
}

/**
 * UpdateCanvasPayloadBase
 * auto generated base class for the model UpdateCanvasPayloadModel.
 *
 * Autogenerated return type of UpdateCanvas
 */
export const UpdateCanvasPayloadModelBase = withTypedRefs<Refs>()(ModelBase
  .named('UpdateCanvasPayload')
  .props({
    __typename: types.optional(types.literal("UpdateCanvasPayload"), "UpdateCanvasPayload"),
    canvas: types.union(types.undefined, types.null, MSTGQLRef(types.late((): any => CanvasModel))),
    /** A unique identifier for the client performing the mutation. */
    clientMutationId: types.union(types.undefined, types.null, types.string),
    result: types.union(types.undefined, types.null, types.boolean),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  })))

export class UpdateCanvasPayloadModelSelector extends QueryBuilder {
  get clientMutationId() { return this.__attr(`clientMutationId`) }
  get result() { return this.__attr(`result`) }
  canvas(builder?: string | CanvasModelSelector | ((selector: CanvasModelSelector) => CanvasModelSelector)) { return this.__child(`canvas`, CanvasModelSelector, builder) }
}
export function selectFromUpdateCanvasPayload() {
  return new UpdateCanvasPayloadModelSelector()
}

export const updateCanvasPayloadModelPrimitives = selectFromUpdateCanvasPayload().clientMutationId.result
