import React from "react";

import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

const CoveLogo: React.FC<SvgIconProps> = props => {
  return (
    <SvgIcon viewBox="0 0 38 10" titleAccess="Cove Logo" {...props}>
      <path d="M13.7383 9.6243C12.762 9.7021 11.7645 9.43537 10.9262 8.64631C10.1728 7.92393 9.75893 7.03485 9.7271 5.96795C9.70587 5.50118 9.71649 5.04552 9.7271 4.57875C9.74832 2.60054 11.1809 0.955732 13.0804 0.833483C13.8126 0.789028 14.5766 0.777914 15.2876 0.933504C16.9112 1.30025 17.9936 2.7339 18.0785 4.46762C18.1103 5.05663 18.1209 5.65677 18.036 6.23467C17.7601 8.29068 16.3169 9.60208 14.3432 9.6243C14.184 9.6243 14.0248 9.6243 13.7383 9.6243ZM16.6353 5.22334C16.6353 5.03441 16.6353 4.85659 16.6353 4.66766C16.6035 3.27846 15.659 2.31159 14.3326 2.30047C14.0142 2.30047 13.6959 2.30047 13.3775 2.30047C12.6984 2.28936 12.1784 2.62276 11.6903 3.08953C10.9368 3.8008 10.9581 6.65698 11.7115 7.36825C12.3376 7.96838 13.0379 8.10175 13.8444 8.15731C15.4892 8.26845 16.8051 7.26823 16.6353 5.22334Z" />
      <path d="M37.8375 3.58967C37.5616 3.58967 37.3706 3.57855 37.1795 3.58967C36.6702 3.64524 36.2563 3.56744 35.9167 3.0451C35.5666 2.51165 34.9723 2.28938 34.3356 2.28938C33.9642 2.28938 33.5928 2.27827 33.2214 2.28938C32.1284 2.31161 31.2794 3.23404 31.2688 4.46764C32.2133 4.46764 33.1577 4.46764 34.1234 4.46764C34.1234 4.97886 34.1234 5.43452 34.1234 5.93463C33.1683 5.93463 32.2345 5.93463 31.3113 5.93463C31.1945 6.80149 31.757 7.73502 32.6059 7.99064C33.4336 8.23513 34.2932 8.26847 35.0997 7.93507C35.4604 7.79059 35.7894 7.49053 36.0547 7.17935C36.2669 6.93485 36.4367 6.80149 36.7551 6.82371C37.084 6.85705 37.4236 6.83483 37.8162 6.83483C37.3918 8.22402 36.5641 9.12422 35.2694 9.46874C34.2825 9.73547 33.2638 9.73547 32.2769 9.46874C31.0248 9.11311 30.1864 8.22402 29.8575 6.91262C29.5709 5.79015 29.5709 4.63434 29.8575 3.51188C30.2395 1.95598 31.4598 0.900189 32.9985 0.822394C33.7838 0.789054 34.6009 0.77794 35.3649 0.977983C36.6171 1.30028 37.413 2.2227 37.8375 3.58967Z" />
      <path d="M8.26266 6.51236C8.15654 7.33476 7.79575 7.95712 7.297 8.49057C6.63908 9.19072 5.83259 9.5797 4.88815 9.61304C4.2939 9.62415 3.67843 9.66861 3.09478 9.56858C1.31203 9.27963 0.0704613 7.79042 0.00679139 5.83443C-0.0144319 5.08982 0.00679204 4.30076 0.176579 3.57838C0.558598 1.96691 1.86383 0.888896 3.43436 0.811101C4.18778 0.777761 4.96244 0.788876 5.69464 0.955579C7.01049 1.26676 8.03981 2.47814 8.25205 3.85622C7.81697 3.85622 7.38189 3.86733 6.95743 3.8451C6.87253 3.8451 6.76642 3.70063 6.71336 3.6006C6.23583 2.67818 5.49302 2.25587 4.49552 2.28921C4.20901 2.30032 3.9331 2.28921 3.64659 2.28921C2.42625 2.31143 1.4712 3.30054 1.44998 4.5786C1.43937 4.94535 1.44998 5.32321 1.44998 5.68995C1.46059 6.99024 2.21402 7.93489 3.42374 8.09048C3.92249 8.15716 4.45308 8.14605 4.95182 8.09048C5.7477 8.01268 6.33134 7.55703 6.69213 6.81242C6.80886 6.57904 6.92559 6.47902 7.18027 6.50124C7.51985 6.53458 7.87003 6.51236 8.26266 6.51236Z" />
      <path d="M18.5878 0.900248C19.1184 0.900248 19.5959 0.900248 20.1052 0.900248C20.4979 1.96715 20.8905 3.05627 21.2831 4.1454C21.6545 5.16785 22.0153 6.1903 22.3867 7.20163C22.6733 7.99069 22.8855 8.13517 23.692 8.13517C24.7744 8.13517 24.8911 8.05737 25.2837 6.99047C25.9735 5.11228 26.6526 3.22298 27.3212 1.33368C27.4485 0.978042 27.6077 0.833564 27.9685 0.866905C28.2762 0.900246 28.5946 0.878019 28.9766 0.878019C28.9341 1.06695 28.9023 1.20031 28.8599 1.33368C28.0958 3.46748 27.3318 5.59016 26.5677 7.71285C26.1008 9.01314 25.4005 9.52436 24.0846 9.52436C23.8724 9.52436 23.6601 9.52436 23.4479 9.52436C22.2594 9.52436 21.5166 9.01314 21.0921 7.84622C20.4024 5.96803 19.7338 4.07872 19.0547 2.18942C18.8955 1.77822 18.7576 1.36702 18.5878 0.900248Z" />
      <path d="M23.7876 0C24.2652 0.611245 24.7321 1.20026 25.1884 1.81151C25.6022 2.3783 25.6977 3.01177 25.4961 3.6897C25.199 4.63435 24.2864 5.12335 23.2783 4.87885C22.4612 4.67881 21.8563 3.74527 21.9943 2.85618C22.0473 2.53389 22.1959 2.23382 22.3126 1.92264C22.3551 1.81151 22.4506 1.71149 22.5355 1.61147C22.9493 1.07801 23.3738 0.544564 23.7876 0Z" />
    </SvgIcon>
  );
};

export default CoveLogo;
