import React from "react";

import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

const BackArrowIcon: React.FC<SvgIconProps> = props => {
  return (
    <SvgIcon viewBox="0 0 38 36" titleAccess="Icon" {...props}>
      <svg width="38" height="36" viewBox="0 0 38 36" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M22.9126 26.5175L14.0001 17.6049L22.9126 9.30764" stroke="#F26A21" strokeWidth="2"/>
        <rect x="1" y="1" width="36" height="34" rx="17" stroke="#F26A21" strokeWidth="2"/>
      </svg>
    </SvgIcon>
  );
};

export default BackArrowIcon;
