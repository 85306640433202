import React from "react";
import { useTranslation } from "react-i18next";
import LinkNoStyle from "@lib/components/LinkNoStyle";
import ButtonWhiteWithRoundedCorners from "@lib/components/ButtonWhiteWithRoundedCorners";
import { BUILD_ALL } from "@lib/utils/routes";
import { useStyles } from "styles";
import { useStore } from "@lib/hooks/useStore";
import { size } from "lodash";

const AboutSection: React.FC = () => {
  const styles = useStyles();
  const { t } = useTranslation();
  const store = useStore();

  return (
    <div className={styles.aboutSectionContainer}>
      <div className={styles.aboutButtonContainer}>
        <LinkNoStyle to={BUILD_ALL}>
          <ButtonWhiteWithRoundedCorners>
            {size(store?.currentUser?.collections) > 0 ? "Back to collection" : t("Get Started")}
          </ButtonWhiteWithRoundedCorners>
        </LinkNoStyle>
      </div>
    </div>
  );
};

export default AboutSection;
