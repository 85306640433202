import React, { createContext, useContext } from "react";
import { useTranslation } from "react-i18next";

import SimpleSnackbar from "./SimpleSnackbar";

interface NotificationContextValues {
  openNotification: (message?: string, hasSimpleSnackbar?: boolean) => void;
  clearNotification: () => void;
  message: any;
}

const NotificationContext = createContext<NotificationContextValues | undefined>(undefined);

export const NotificationProvider: React.FC = ({ children }) => {
  const [message, setMessage] = React.useState<string | null>(null);
  const [hasSimpleSnackbar, setHasSimpleSnackbar] = React.useState<boolean | null>(true);
  const { t } = useTranslation();

  const closeNotification = (): void => {
    setMessage(null);
  };

  const openNotification = (newMessage = null, hasSimpleSnackbar = true): void => {
    if (newMessage) {
      setHasSimpleSnackbar(hasSimpleSnackbar);
      setMessage(newMessage);
    }
  };

  const clearNotification = (): void => {
    setMessage(null);
  };

  return (
    <NotificationContext.Provider value={{ clearNotification, openNotification, message }}>
      {children}
      {message && hasSimpleSnackbar && <SimpleSnackbar message={message} onClose={closeNotification} />}
    </NotificationContext.Provider>
  );
};

export const useNotification = (): NotificationContextValues => {
  const context = useContext(NotificationContext);

  if (!context) {
    throw new Error("useNotification must be used within a NotificationProvider");
  }

  return {
    clearNotification: context.clearNotification,
    openNotification: context.openNotification,
    message: context.message,
  };
};
