/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { IObservableArray } from "mobx"
import { types } from "mobx-state-tree"
import { MSTGQLRef, QueryBuilder, withTypedRefs } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { CanvasKitModel, CanvasKitModelType } from "./CanvasKitModel"
import { CanvasKitModelSelector } from "./CanvasKitModel.base"
import { RootStoreType } from "./index"


/* The TypeScript type that explicits the refs to other models in order to prevent a circular refs issue */
type Refs = {
  canvasKits: IObservableArray<CanvasKitModelType>;
}

/**
 * CreateCanvasKitsPayloadBase
 * auto generated base class for the model CreateCanvasKitsPayloadModel.
 *
 * Autogenerated return type of CreateCanvasKits
 */
export const CreateCanvasKitsPayloadModelBase = withTypedRefs<Refs>()(ModelBase
  .named('CreateCanvasKitsPayload')
  .props({
    __typename: types.optional(types.literal("CreateCanvasKitsPayload"), "CreateCanvasKitsPayload"),
    canvasKits: types.union(types.undefined, types.array(MSTGQLRef(types.late((): any => CanvasKitModel)))),
    /** A unique identifier for the client performing the mutation. */
    clientMutationId: types.union(types.undefined, types.null, types.string),
    result: types.union(types.undefined, types.boolean),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  })))

export class CreateCanvasKitsPayloadModelSelector extends QueryBuilder {
  get clientMutationId() { return this.__attr(`clientMutationId`) }
  get result() { return this.__attr(`result`) }
  canvasKits(builder?: string | CanvasKitModelSelector | ((selector: CanvasKitModelSelector) => CanvasKitModelSelector)) { return this.__child(`canvasKits`, CanvasKitModelSelector, builder) }
}
export function selectFromCreateCanvasKitsPayload() {
  return new CreateCanvasKitsPayloadModelSelector()
}

export const createCanvasKitsPayloadModelPrimitives = selectFromCreateCanvasKitsPayload().clientMutationId.result
