import React from "react";
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonNext,
  Dot,
  ButtonBack
} from "pure-react-carousel";

import NextIcon from "@material-ui/icons/NavigateNextOutlined";

import KitCard from "./KitCard";
import { useStyles } from "styles";
import { KitModelType } from "models";

interface CardCarouselProps {
  carouselArray: KitModelType[];
}

const CardCarousel: React.FC<CardCarouselProps> = ({ carouselArray }) => {
  const styles = useStyles();

  const disabled = carouselArray.length <= 2;

  return (
    <CarouselProvider
      visibleSlides={2}
      totalSlides={carouselArray.length}
      step={2}
      naturalSlideWidth={320}
      naturalSlideHeight={550}
      infinite
    >
      <div className={styles.positionRelative}>
        <ButtonBack className={styles.carouselBackButton} disabled={disabled}>
          <NextIcon fontSize="large" color={disabled ? "inherit" : "secondary"} />
        </ButtonBack>
        <Slider className={styles.carouselSlidesContainer}>
          {carouselArray.map((kit, index) => (
            <Slide index={index} key={index}>
              <div className={styles.carouselSlides}>
                <KitCard {...kit} />
              </div>
            </Slide>
          ))}
        </Slider>
        <ButtonNext className={styles.carouselNextButton} disabled={disabled}>
          <NextIcon fontSize="large" color={disabled ? "inherit" : "secondary"} />
        </ButtonNext>
      </div>
      <div className={styles.carouselDotsContainer}>
        {carouselArray.map((kit, index) => (
          <Dot slide={index} key={index} className={styles.carouselDots}>
            {""}
          </Dot>
        ))}
      </div>
    </CarouselProvider>
  );
};

export default CardCarousel;
