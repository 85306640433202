import React from "react";

import WolfLogo from "@lib/logos/WolfLogo";
import CoveLogo from "@lib/logos/CoveLogo";
import SubZeroLogo from "@lib/logos/SubZeroLogo";
import WolfMainLogo from "@lib/logos/WolfMainLogo";
import CoveMainLogo from "@lib/logos/CoveMainLogo";
import SubZeroMainLogo from "@lib/logos/SubZeroMainLogo";
import BestLogo from "@lib/logos/BestLogo";
import WolfIcon from "@lib/logos/WolfIcon";
import SubZeroIcon from "@lib/logos/SubZeroIcon";
import CoveIcon from "@lib/logos/CoveIcon";
import BestIcon from "@lib/logos/BestIcon";
import { WOLF, SUB_ZERO, COVE, BEST, WHITE } from "styles/styles";

export type Family = "All Brands" | "Cove" | "Wolf" | "Sub Zero" | "Best";

export const familyKey = {
  ["All Brands"]: {
    logo: null,
    logoSmall: null,
    detailsViewLogo: null,
    icon: null,
    jsName: "allBrands",
  },
  ["Sub Zero"]: {
    logo: <SubZeroMainLogo style={{ width: 103 }} />,
    logoSmall: <SubZeroLogo style={{ width: 65 }} />,
    detailsViewLogo: <SubZeroLogo style={{ width: 90, color: SUB_ZERO }} />,
    icon: <SubZeroIcon style={{ color: WHITE }} />,
    jsName: "subZero",
  },
  ["Wolf"]: {
    logo: <WolfMainLogo style={{ width: 85 }} />,
    logoSmall: <WolfLogo style={{ width: 44 }} />,
    detailsViewLogo: <WolfLogo style={{ width: 65, color: WOLF }} />,
    icon: <WolfIcon style={{ color: WHITE }} />,
    jsName: "wolf",
  },
  ["Cove"]: {
    logo: <CoveMainLogo style={{ width: 89 }} />,
    logoSmall: <CoveLogo style={{ width: 40 }} />,
    detailsViewLogo: <CoveLogo style={{ width: 60, color: COVE }} />,
    icon: <CoveIcon style={{ color: WHITE }} />,
    jsName: "cove",
  },
  ["Best"]: {
    logo: <BestLogo style={{ width: 48 }} />,
    logoSmall: <BestLogo style={{ width: 36 }} />,
    detailsViewLogo: <BestLogo style={{ width: 48, color: BEST }} />,
    icon: <BestIcon style={{ color: WHITE }} />,
    jsName: "best",
  },
};
