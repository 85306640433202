/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from "mobx-state-tree"
import { MSTGQLRef, QueryBuilder, withTypedRefs } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { CollectionModel, CollectionModelType } from "./CollectionModel"
import { CollectionModelSelector } from "./CollectionModel.base"
import { RootStoreType } from "./index"


/* The TypeScript type that explicits the refs to other models in order to prevent a circular refs issue */
type Refs = {
  collection: CollectionModelType;
}

/**
 * CreateCollectionPayloadBase
 * auto generated base class for the model CreateCollectionPayloadModel.
 *
 * Autogenerated return type of CreateCollection
 */
export const CreateCollectionPayloadModelBase = withTypedRefs<Refs>()(ModelBase
  .named('CreateCollectionPayload')
  .props({
    __typename: types.optional(types.literal("CreateCollectionPayload"), "CreateCollectionPayload"),
    /** A unique identifier for the client performing the mutation. */
    clientMutationId: types.union(types.undefined, types.null, types.string),
    collection: types.union(types.undefined, MSTGQLRef(types.late((): any => CollectionModel))),
    result: types.union(types.undefined, types.null, types.boolean),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  })))

export class CreateCollectionPayloadModelSelector extends QueryBuilder {
  get clientMutationId() { return this.__attr(`clientMutationId`) }
  get result() { return this.__attr(`result`) }
  collection(builder?: string | CollectionModelSelector | ((selector: CollectionModelSelector) => CollectionModelSelector)) { return this.__child(`collection`, CollectionModelSelector, builder) }
}
export function selectFromCreateCollectionPayload() {
  return new CreateCollectionPayloadModelSelector()
}

export const createCollectionPayloadModelPrimitives = selectFromCreateCollectionPayload().clientMutationId.result
