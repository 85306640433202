import React from "react";
import { Fab, Paper } from "@material-ui/core";
import { useStyles } from "styles";
import { Slide } from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import { useHistory, useLocation } from "react-router-dom";
import { useNotification } from "@lib/components/NotificationManagerContext";

const UserPageContainer: React.FC = ({ children, onClose }) => {
  const styles = useStyles();
  const history = useHistory();
  const location = useLocation();
  const { clearNotification } = useNotification();

  const handleOnClose = (): void => {
    clearNotification();
    history.goBack();
  };

  return (
    <div className={styles.userPageContainer}>
      <Slide direction={"up"} in>
        <Paper className={styles.userPagePaper}>
          <div className={styles.dialogInnerContainer}>
            <Fab size="small" color="secondary" onClick={onClose || handleOnClose} className={styles.dialogCancelBtn}>
              <ClearIcon />
            </Fab>
            {children}
          </div>
        </Paper>
      </Slide>
    </div>
  );
};

export default UserPageContainer;
