import React from "react";
import { FormikValues } from "formik";
import { useTranslation } from "react-i18next";

import { PopperPlacementType } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";

import DialogModal from "@lib/components/DialogModal";
import { useDialogManager } from "@lib/components/DialogManagerContext";
import ButtonFullWidthDialog from "@lib/components/ButtonFullWidthDialog";
import { useStore } from "@lib/hooks/useStore";
import { useCollectionKitActions } from "@lib/hooks/useCollectionKitActions";
import { CollectionModelType } from "models";
import NewCollectionDialog from "@lib/components/NewCollectionDialog";
import { useCollectionActions } from "@lib/hooks/useCollectionActions";
import DeletePopper from "./DeletePopper";
import MyCollectionsDialogLists from "./MyCollectionsDialogLists";
import { useCanvasActions } from "@lib/hooks/useCanvasActions";
import { matchPath, useHistory, useLocation, useParams } from "react-router";
import { BUILD_ALL } from "@lib/utils/routes";

interface MyCollectionsDialogProps {
  kitId?: string;
  listView?: boolean;
  quoteView?: boolean;
}

const MyCollectionsDialog: React.FC<MyCollectionsDialogProps> = ({
  kitId = "",
  listView = false,
  quoteView = false,
}) => {
  const store = useStore();
  const { closeDialog } = useDialogManager();
  const { t } = useTranslation();
  const { createCollectionKit, queryCollectionKits } = useCollectionKitActions();
  const { deleteCollection } = useCollectionActions();
  const [showNewCollection, setShowNewCollection] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [quoteSuccessOpen, setQuoteSuccessOpen] = React.useState(false);
  const [quoteErrorOpen, setQuoteErrorOpen] = React.useState(false);
  const [edit, setEdit] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const [placement, setPlacement] = React.useState<PopperPlacementType>("left");
  const [selectedCollection, setSelectedCollection] = React.useState<CollectionModelType | null>(null);
  const { product_id = null, instance_id = null, brand } = useParams<any>();
  const history = useHistory();
  const { pathname } = useLocation();
  const match: any = matchPath(pathname, { path: "/build/:brand/details/:product_id" });

  const handleDeleteClick =
    (newPlacement: PopperPlacementType, collection: CollectionModelType) =>
    (event: React.MouseEvent<HTMLButtonElement>): void => {
      setAnchorEl(event.currentTarget);
      setOpen((prev) => placement !== newPlacement || !prev);
      setPlacement(newPlacement);
      setSelectedCollection(collection);
    };

  const handleEditClick = (collection: CollectionModelType, listType: string): void => {
    setSelectedCollection(collection ? { ...collection, listType } : null);
    setEdit(!edit);
  };

  const { queryCanvass } = useCanvasActions();

  const handleSendQuoteClick = async (collection: CollectionModelType): Promise<any> => {
    store.setCurrentCollection(collection);
    listView || quoteView ? await queryCollectionKits() : await createCollectionKit(kitId);
  };

  const handleCreateCollectionAddKit = async ({ name }: FormikValues): Promise<any> => {
    await createCollectionKit(kitId, name);

    if (match?.params?.brand && match?.params?.product_id && match?.url) {
      history.push({
        pathname: `${BUILD_ALL}`,
      });
    }

    closeDialog();
  };

  const handleSelectClick = async (collection: CollectionModelType): Promise<any> => {
    if (confirm("By selecting a new collection any canvas kits not saved will be lost. continue?")) {
      if (listView) {
        store.setCurrentCollection(collection);
        await store.handleQueryCollectionKits(collection.id);
      }

      if (quoteView) {
        await createCollectionKit(kitId);
      }

      if (store.currentCollection?.id) {
        queryCanvass(store.currentCollection.id);
      }

      if (match?.params?.brand && match?.params?.product_id && match?.url) {
        history.push({
          pathname: `${BUILD_ALL}`,
        });
      }
    }

    closeDialog();
  };

  const getTitle = (): string => {
    if (open) return t("Delete this collection");
    if (edit) return t("Edit this collection name");
    return listView
      ? "Select a <strong>collection</strong> to view"
      : "Choose the <strong>collection</strong> for this item";
  };

  const handleDeleteConfirm = async (): Promise<any> => {
    await setOpen(false);

    if (selectedCollection) {
      if (match?.params?.brand && match?.params?.product_id && match?.url) {
        history.push({
          pathname: `${match.url}`,
          search: "?action=delete",
        });
      }

      store.collectionStore?.setSelectedCollectionKit(undefined);

      let response = await deleteCollection(selectedCollection);

      if (response?.destroyCollection?.result) {
        let collection = store.currentUser.collections[0] || undefined;
        store.setCurrentCollection(collection);
        store.setCurrentCanvas(undefined);
        store.collectionStore?.setSelectedCollectionKit(undefined);

        if (collection == undefined) {
          if (match?.params?.brand && match?.params?.product_id && match?.url) {
            history.push({
              pathname: `${match?.url}`,
            });
          } else {
            history.push({
              pathname: "/build/all",
            });
          }
        }
      }
    }
  };

  return (
    <React.Fragment>
      {!showNewCollection ? (
        <React.Fragment>
          <DialogModal
            title={getTitle()}
            open={true}
            inputComponent={
              <MyCollectionsDialogLists
                listView={listView}
                quoteView={quoteView}
                open={open}
                edit={edit}
                selectedCollection={selectedCollection}
                onSelectClick={handleSelectClick}
                handleDeleteClick={handleDeleteClick}
                handleEditClick={handleEditClick}
                handleSendQuoteClick={handleSendQuoteClick}
              />
            }
            onClose={closeDialog}
            maxWidth="md"
            fullWidthBottomButton={true}
            dialogActionButtons={
              !listView && (
                <ButtonFullWidthDialog
                  style={{ fontSize: 20 }}
                  onClick={(): void => setShowNewCollection(true)}
                  startIcon={<AddIcon style={{ position: "relative", bottom: 1 }} />}
                >
                  {t("Create new collection")}
                </ButtonFullWidthDialog>
              )
            }
          />
          <DialogModal
            open={quoteSuccessOpen}
            title={t("Thank You")}
            onClose={closeDialog}
            contentText={t("Your collection has been sent to your email")}
          />
          <DialogModal open={quoteErrorOpen} title={t("Error")} onClose={closeDialog} />
          <DeletePopper
            open={open}
            anchorEl={anchorEl}
            placement={placement}
            setOpen={setOpen}
            selectedCollection={selectedCollection}
            handleDeleteConfirm={handleDeleteConfirm}
          />
        </React.Fragment>
      ) : (
        <NewCollectionDialog onConfirm={handleCreateCollectionAddKit} />
      )}
    </React.Fragment>
  );
};

export default MyCollectionsDialog;
