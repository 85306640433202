import React from "react";
import { useTranslation } from "react-i18next";
import { FormikValues } from "formik";

import { Typography, IconButton, Tooltip } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
// import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import SaveIcon from "@material-ui/icons/Save";

import { useStyles } from "styles";
import { useCollectionActions } from "@lib/hooks/useCollectionActions";
import FormikTextField from "@lib/components/FormikTextField";
import CollectionForm from "@lib/components/CollectionForm";
import EditIconButton from "@lib/components/EditIconButton";
import ExportIcon from "@lib/logos/ExportIcon";
import LoginAndSendDialog from "components/User/LoginAndSendDialog";
import { useStore } from "@lib/hooks/useStore";
import { useDialogManager } from "./DialogManagerContext";
import NewCollectionDialog from "./NewCollectionDialog";
import ExportDialog from "./ExportDialog";
import ButtonOpenCollectionsList from "./ButtonOpenCollectionsList";
import { size } from "lodash";
import { useCanvasActions } from "@lib/hooks/useCanvasActions";
import { CollectionModelType } from "models";

interface CollectionNameContainerProps {
  name: string | undefined;
  id: string | undefined;
  darkBackground?: boolean;
}

const CollectionNameContainer: React.FC<CollectionNameContainerProps> = ({ name, id, darkBackground = true }) => {
  const store = useStore();
  const styles = useStyles();
  const { t } = useTranslation();
  const { updateCollection, createCollection, sendCollection, loading } = useCollectionActions();
  const { queryCanvass } = useCanvasActions();
  const { openDialog, closeDialog } = useDialogManager();

  const handleGetQuote: any = async (collection: CollectionModelType): Promise<any> => {
    if (store.currentUser && store.currentCollection?.id && store.collectionHasItems) {
      await sendCollection(store.currentCollection.id);
    }
  };

  const [edit, setEdit] = React.useState(false);
  const toggleEdit = (): void => {
    if (store.currentUser) {
      setEdit(!edit);
    } else {
      openDialog(<LoginAndSendDialog send={false} />);
    }
  };

  const handleConfirm = (values: FormikValues): void => {
    store.currentCollection ? updateCollection(values) : createCollection(values.name);
    setEdit(false);
  };

  const handleCreate = async ({ name }: FormikValues): Promise<any> => {
    if (confirm("By selecting a new collection any canvas kits not saved will be lost. continue?")) {
      await createCollection(name);
      await queryCanvass(store.currentCollection.id);
    }
    closeDialog();
  };

  return (
    <div className={styles.collectionNameContainer}>
      <div className={styles.collectionNameForm}>
        <CollectionForm
          onConfirm={handleConfirm}
          initialValues={{
            name: name || "",
            id: id || "",
          }}
        >
          {({ setFieldTouched, handleSubmit }): FormikValues => {
            const handleBlur = (): void => {
              setFieldTouched("name");
              handleSubmit();
            };

            const handleEnterPress = (event: React.KeyboardEvent): void => {
              if (event.keyCode === 13) {
                handleBlur();
              }
            };

            return (
              <React.Fragment>
                {store.currentUser && !!size(store.currentUser.collections) && <ButtonOpenCollectionsList />}
                {edit ? (
                  <React.Fragment>
                    <FormikTextField
                      onBlur={handleBlur}
                      onKeyDown={handleEnterPress}
                      name="name"
                      type="text"
                      autoFocus
                      classes={{ root: styles.collectionInlineEditNameInput }}
                    />
                    <Tooltip title={t("Save Name")} placement="top" classes={{ tooltip: styles.tooltipBackground }}>
                      <IconButton aria-label="save" size="small">
                        <SaveIcon fontSize="inherit" />
                      </IconButton>
                    </Tooltip>
                  </React.Fragment>
                ) : (
                  <Typography
                    variant={darkBackground ? "h2" : "h3"}
                    color={darkBackground ? undefined : "primary"}
                    style={
                      !size(store.currentUser.collections)
                        ? { fontSize: "16px", marginLeft: "16px" }
                        : { fontSize: "16px" }
                    }
                    noWrap
                  >
                    {name || <span> {t("Unnamed collection")} </span>}
                  </Typography>
                )}
              </React.Fragment>
            );
          }}
        </CollectionForm>
      </div>
      {!edit && (
        <React.Fragment>
          <Tooltip title={t("Create collection")} placement="top" classes={{ tooltip: styles.tooltipBackground }}>
            <div
              className={styles.editIconButtonContainer}
              style={darkBackground ? { backgroundColor: "white" } : undefined}
            >
              <IconButton onClick={(): void => openDialog(<NewCollectionDialog onConfirm={handleCreate} />)}>
                <AddIcon fontSize="small" style={{ color: darkBackground ? "inherit" : "white" }} />
              </IconButton>
            </div>
          </Tooltip>
          <Tooltip title={t("Edit collection")} placement="top" classes={{ tooltip: styles.tooltipBackground }}>
            <div>
              <EditIconButton onClick={toggleEdit} darkBackground={darkBackground} />
            </div>
          </Tooltip>
          <Tooltip title={t("Send My Quote")} placement="top" classes={{ tooltip: styles.tooltipBackground }}>
            <div
              className={styles.editIconButtonContainer}
              style={darkBackground ? { backgroundColor: "white" } : undefined}
            >
              <IconButton disabled={!id || loading || !store.collectionHasItems} onClick={handleGetQuote}>
                <ExportIcon fontSize="small" stroke={darkBackground ? "#5D6068" : "white"} />
              </IconButton>
            </div>
          </Tooltip>
        </React.Fragment>
      )}
    </div>
  );
};

export default CollectionNameContainer;
