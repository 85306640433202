import React from "react";
import { Formik, FormikValues } from "formik";
import { observer } from "mobx-react";

import { useDialogManager } from "@lib/components/DialogManagerContext";
import { useYupSchema } from "@lib/hooks/useYupSchema";
import { useUserActions } from "@lib/hooks/useUserActions";
import { useStore } from "@lib/hooks/useStore";

interface LoginFormProps {
  children: (formikProps: FormikValues) => React.ReactNode;
  includeCollectionName?: boolean;
  send?: any;
}

const LoginForm: React.FC<LoginFormProps> = observer(({ children, includeCollectionName = false, send }) => {
  const { closeDialog } = useDialogManager();
  const { loginSchema, loginWithCollectionNameSchema } = useYupSchema();
  const { handleLogin, handleLoginAndSend } = useUserActions();
  const store = useStore();

  return (
    <Formik
      initialValues={{
        email: "",
        password: "",
        name: store.currentCollection?.name || "",
      }}
      validationSchema={includeCollectionName ? loginWithCollectionNameSchema : loginSchema}
      onSubmit={async ({ email, password, name }, { setSubmitting }): Promise<any> => {
        includeCollectionName
          ? await handleLoginAndSend(email, password, name, send)
          : await handleLogin(email, password);
        setSubmitting(false);
        closeDialog();
      }}
    >
      {(formikProps): React.ReactNode => <React.Fragment>{children(formikProps)}</React.Fragment>}
    </Formik>
  );
});

export default LoginForm;
