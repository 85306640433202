import React from "react";

import Header from "./Header";
import Routing from "./Routing";
import { useStyles } from "../styles";
import Help from "components/SpashPage/Help";
import Footer from "components/SpashPage/Footer";

const Home: React.FC = () => {
  const styles = useStyles();
  return (
    <React.Fragment>
      <Header />
      <div className={styles.homeContainer}>
        <div className={styles.mainContent}>
          <Routing />
        </div>
        <div className={styles.helpFooterContainer}>
          <Help />
          <Footer />
        </div>
      </div>
    </React.Fragment>
  );
};

export default Home;
