import { types } from "mobx-state-tree";

export const SearchTermsModel = types.model({
  family: types.array(
    types.union(types.literal("Cove"), types.literal("Wolf"), types.literal("Sub Zero"), types.literal("Best"))
  ),
  height: types.array(types.number),
  width: types.array(types.number),
  depth: types.array(types.number),
  category: types.array(types.string),
  superCategory: types.array(types.string),
  featured: types.array(types.boolean),
});
