/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from "mobx-state-tree"
import { MSTGQLRef, QueryBuilder, withTypedRefs } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { KitModel, KitModelType } from "./KitModel"
import { KitModelSelector } from "./KitModel.base"
import { RootStoreType } from "./index"


/* The TypeScript type that explicits the refs to other models in order to prevent a circular refs issue */
type Refs = {
  node: KitModelType;
}

/**
 * KitEdgeBase
 * auto generated base class for the model KitEdgeModel.
 */
export const KitEdgeModelBase = withTypedRefs<Refs>()(ModelBase
  .named('KitEdge')
  .props({
    __typename: types.optional(types.literal("KitEdge"), "KitEdge"),
    node: types.union(types.undefined, types.null, MSTGQLRef(types.late((): any => KitModel))),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  })))

export class KitEdgeModelSelector extends QueryBuilder {
  node(builder?: string | KitModelSelector | ((selector: KitModelSelector) => KitModelSelector)) { return this.__child(`node`, KitModelSelector, builder) }
}
export function selectFromKitEdge() {
  return new KitEdgeModelSelector()
}

export const kitEdgeModelPrimitives = selectFromKitEdge()
