import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { CardMedia, Typography, Divider, Grid } from "@material-ui/core";
import { useStyles } from "styles";
import AccessoriesMenuContainer from "./AccessoriesMenuContainer";
import { useStore } from "@lib/hooks/useStore";
import { familyKey } from "@lib/utils/familyKey";
import ButtonAddToCollection from "@lib/components/ButtonAddToCollection";
import { LIGHT_GRAY_BACKGROUND } from "styles/styles";
import { useParams } from "react-router";

const KitDetails: React.FC = () => {
  const styles = useStyles();
  const store = useStore();
  const { t } = useTranslation();
  const { product_id = null, instance_id = null } = useParams<any>();
  const details = store.viewKitDetails(product_id);
  const { imageUrl, family, name, description, width, height, depth, pdfUrl, id } = details;

  return (
    details && (
      <Grid container spacing={6}>
        <Grid item xs={12} md={7} className={styles.collectionKitDisplayContainer}>
          {imageUrl && (
            <CardMedia
              src={imageUrl}
              title={name}
              component="img"
              classes={{ root: styles.cardImg }}
              style={{ backgroundColor: LIGHT_GRAY_BACKGROUND }}
            />
          )}
        </Grid>
        <Grid item xs={12} md={5}>
          {familyKey[family].detailsViewLogo}
          <div className={styles.margin24TopBottom}>
            <Typography component="h5" variant="button" color="primary">
              {description}
            </Typography>
            <Typography variant="subtitle1" color="textSecondary">
              {name}
            </Typography>
          </div>
          <div>
            <div className={styles.spaceBetween}>
              {(width || height || depth) && (
                <Typography variant="subtitle1" className={styles.bold} color="textSecondary">
                  {t("Dimensions")}
                </Typography>
              )}
            </div>
            {width && (
              <Typography variant="subtitle2" color="textSecondary">
                {t("Width")}
                {" = "}
                {`${width}"`}
              </Typography>
            )}
            {height && (
              <Typography variant="subtitle2" color="textSecondary">
                {t("Height")}
                {" = "}
                {`${height}"`}
              </Typography>
            )}
            {depth && (
              <Typography variant="subtitle2" color="textSecondary">
                {t("Depth")}
                {" = "}
                {`${depth}"`}
              </Typography>
            )}
          </div>
          <Divider className={styles.margin24TopBottom} />

          {<ButtonAddToCollection disabled={instance_id} style={{ width: "100%", marginBottom: "10px" }} id={id} />}
          {pdfUrl && (
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={pdfUrl}
              className={styles.detailsPdfBtn}
              style={{ width: "100%" }}
            >
              View specification & accessories
            </a>
          )}
        </Grid>
      </Grid>
    )
  );
};

export default KitDetails;
