import React from "react";
import { Link, LinkProps } from "react-router-dom";

import { useStyles } from "styles";

interface LinkNoStyleProps extends LinkProps {
  disabled?: boolean;
}

const LinkNoStyle: React.FC<LinkNoStyleProps> = ({
  children,
  disabled = false,
  to,
  ...props
}) => {
  const styles = useStyles();
  return (
    <Link
      className={styles.linkNoStyle}
      {...props}
      to={disabled ? "#" : to}
      style={{ pointerEvents: disabled ? "none" : "initial" }}
    >
      {children}
    </Link>
  );
};

export default LinkNoStyle;
