import React from "react";
import { useTranslation } from "react-i18next";
import { FormikValues } from "formik";

import { Typography, Divider, Button } from "@material-ui/core";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";

import ResetUserPasswordForm from "./ResetUserPasswordForm";
import UserPageContainer from "components/User/UserPageContainer";
import ButtonWithRoundedCorners from "@lib/components/ButtonWithRoundedCorners";
import FormikTextField from "@lib/components/FormikTextField";
import LinkNoStyle from "@lib/components/LinkNoStyle";
import { useStyles } from "styles";
import ConfirmButtonSpinner from "@lib/components/ConfirmButtonSpinner";

const ResetUserPasswordPage: React.FC = () => {
  const { t } = useTranslation();
  const styles = useStyles();

  return (
    <UserPageContainer>
      <ResetUserPasswordForm>
        {({ isSubmitting, handleSubmit }: FormikValues): React.ReactNode => (
          <React.Fragment>
            <div className={styles.loginPageHeader}>
              <Typography variant="h2" color="primary">
                {t("Reset Password")}
              </Typography>
              <Divider className={styles.dividerModal} />
            </div>

            <FormikTextField name="password" label={t("Password")} type="password" variant="outlined" labelWidth={72} />

            <FormikTextField
              name="passwordConfirmation"
              label={t("Confirm Password")}
              type="password"
              variant="outlined"
              labelWidth={128}
              onKeyDown={(event: React.KeyboardEvent): void => {
                if (event.keyCode === 13) {
                  handleSubmit();
                }
              }}
            />
            <Divider className={styles.dividerModal} />

            <Button
              onClick={(): void => handleSubmit()}
              className={styles.buttonWithRoundedCorners}
              disabled={isSubmitting}
              variant="contained"
              color="secondary"
              endIcon={<ArrowForwardIcon color={isSubmitting ? "disabled" : "inherit"} />}
            >
              {t("Update Password")}
              {isSubmitting && <ConfirmButtonSpinner />}
            </Button>
          </React.Fragment>
        )}
      </ResetUserPasswordForm>
    </UserPageContainer>
  );
};

export default ResetUserPasswordPage;
