import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { FormikValues } from "formik";
import { Typography, Divider, Button } from "@material-ui/core";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import SendResetPasswordLinkForm from "./SendResetPasswordLinkForm";
import UserPageContainer from "components/User/UserPageContainer";
import FormikTextField from "@lib/components/FormikTextField";
import { useStyles } from "styles";
import ConfirmButtonSpinner from "@lib/components/ConfirmButtonSpinner";
import { useNotification } from "@lib/components/NotificationManagerContext";
import { Alert } from "@material-ui/lab";

interface InputsProps {
  handleEnterPress: (event: React.KeyboardEvent) => void;
}

const Inputs: React.FC<InputsProps> = ({ handleEnterPress }) => {
  const { t } = useTranslation();

  return (
    <form>
      <FormikTextField
        variant="outlined"
        name="email"
        label={t("Email")}
        type="email"
        labelWidth={40}
        onKeyDown={handleEnterPress}
      />
    </form>
  );
};

const ForgotPasswordPage: React.FC = () => {
  const { t } = useTranslation();
  const { message, clearNotification } = useNotification();
  const styles = useStyles();

  useEffect(() => {
    clearNotification();
  }, []);

  return (
    <UserPageContainer>
      <SendResetPasswordLinkForm>
        {({ isSubmitting, handleSubmit }: FormikValues): React.ReactNode => (
          <React.Fragment>
            <div className={styles.loginPageHeader}>
              <Typography variant="h2" color="primary">
                {t("Forgot Password")}
              </Typography>
              <Divider className={styles.dividerModal} />
            </div>
            {message && <Alert severity="warning">{message}!</Alert>}
            <Inputs
              handleEnterPress={(event: React.KeyboardEvent): void => {
                if (event.keyCode === 13) {
                  handleSubmit();
                }
              }}
            />
            <Divider className={styles.dividerModal} />

            <Button
              onClick={(): void => handleSubmit()}
              className={styles.buttonWithRoundedCorners}
              disabled={isSubmitting}
              variant="contained"
              color="secondary"
              endIcon={<ArrowForwardIcon color={isSubmitting ? "disabled" : "inherit"} />}
            >
              {t("Reset Password")}
              {isSubmitting && <ConfirmButtonSpinner />}
            </Button>
          </React.Fragment>
        )}
      </SendResetPasswordLinkForm>
    </UserPageContainer>
  );
};

export default ForgotPasswordPage;
