import React from "react";
import { Formik, FormikValues } from "formik";
import { observer } from "mobx-react";

import { useDialogManager } from "@lib/components/DialogManagerContext";
import { useYupSchema } from "@lib/hooks/useYupSchema";
import { useUserActions } from "@lib/hooks/useUserActions";
import { useStore } from "@lib/hooks/useStore";
import { Link, useLocation, BrowserRouter as Router } from "react-router-dom";

interface ResetUserPasswordFormProps {
  children: (formikProps: FormikValues) => React.ReactNode;
}

const ResetUserPasswordForm: React.FC<ResetUserPasswordFormProps> = observer(({ children }) => {
  const { closeDialog } = useDialogManager();
  const { resetUserPasswordSchema } = useYupSchema();
  const { handleResetUserPassword } = useUserActions();
  const store = useStore();
  let query = new URLSearchParams(useLocation().search);
  let token = query ? query.get("token") : "";

  return (
    <Formik
      initialValues={{
        password: "",
        passwordConfirmation: "",
        resetPasswordToken: token,
      }}
      validationSchema={resetUserPasswordSchema}
      onSubmit={async ({ password, passwordConfirmation, resetPasswordToken }, { setSubmitting }): Promise<any> => {
        handleResetUserPassword(password, passwordConfirmation, resetPasswordToken);
        setSubmitting(false);
        closeDialog();
      }}
    >
      {(formikProps): React.ReactNode => <React.Fragment>{children(formikProps)}</React.Fragment>}
    </Formik>
  );
});

export default ResetUserPasswordForm;
