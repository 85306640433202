import React from "react";

import { CircularProgress } from "@material-ui/core";

import { useStyles } from "styles";
import { CircularProgressProps } from "@material-ui/core/CircularProgress";

const ConfirmButtonSpinner: React.FC<CircularProgressProps> = props => {
  const styles = useStyles();
  return (
    <CircularProgress
      size={24}
      className={`${styles.confirmButtonSpinner} ${props.className}`}
      {...props}
    />
  );
};

export default ConfirmButtonSpinner;
