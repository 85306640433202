import React, { useEffect } from "react";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Fab,
  Typography,
  DialogProps,
} from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import { useNotification } from "@lib/components/NotificationManagerContext";
import { useStyles } from "../../styles";
import { Slide } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

interface DialogModalProps extends DialogProps {
  dialogActionButtons?: React.ReactNode;
  title: string;
  titleVariant: string;
  contentText?: string | React.ReactNode;
  inputComponent?: React.ReactNode;
  onClose?: () => void;
  fullWidthBottomButton?: boolean;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DialogModal: React.FC<DialogModalProps> = ({
  title,
  titleVariant,
  dialogActionButtons,
  contentText,
  inputComponent,
  onClose,
  fullWidthBottomButton = false,
  open = true,
  ...restProps
}) => {
  const styles = useStyles();
  const { message, clearNotification } = useNotification();

  useEffect(() => {
    clearNotification();
  }, []);

  return (
    <Dialog
      TransitionComponent={Transition}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
      classes={{ paper: styles.dialog, container: styles.dialogContainer }}
      open={open}
      {...restProps}
    >
      <div className={styles.dialogInnerContainer}>
        <Fab size="small" color="secondary" onClick={onClose} className={styles.dialogCancelBtn}>
          <ClearIcon />
        </Fab>
        <DialogTitle id="form-dialog-title" disableTypography>
          <Typography variant={titleVariant || "h2"} color="primary">
            <div dangerouslySetInnerHTML={{ __html: title }} />
          </Typography>
        </DialogTitle>
        <DialogContent>
          {message && <Alert severity="warning">{message}!</Alert>}
          {contentText && <DialogContentText>{contentText}</DialogContentText>}
          {inputComponent}
        </DialogContent>
        {!fullWidthBottomButton && (
          <DialogActions className={styles.dialogActionsContainer}>{dialogActionButtons}</DialogActions>
        )}
      </div>
      {fullWidthBottomButton && (
        <DialogActions className={styles.dialogActionsContainerBottom}>{dialogActionButtons}</DialogActions>
      )}
    </Dialog>
  );
};

export default DialogModal;
