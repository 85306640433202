import DetailedStep from "./DetailedStep";
import { Grid } from "@material-ui/core";
import React from "react";
import SectionHeaderWithDivider from "./SectionHeaderWithDivider";
import Step1 from "@lib/logos/step-1.png";
import Step2 from "@lib/video/step-2.mp4";
import Step3 from "@lib/logos/step-3.jpg";
import { useStyles } from "styles";
import { useTranslation } from "react-i18next";

export interface Step {
  title: string;
  image: JSX.Element;
}

interface UseSteps {
  steps: Step[];
}

const useSteps = (): UseSteps => {
  const { t } = useTranslation();
  const styles = useStyles();
  const searchStep = {
    title: t("Step 1 title"),
    image: <img className={styles.detailedStepImage} style={{ objectFit: "unset" }} src={Step1} />,
  };

  const buildStep = {
    title: t("Step 2 title"),
    image: (
      <video className={styles.detailedStepImage} width="100%" height="auto" autoPlay muted loop>
        <source src={Step2} type="video/mp4" />
      </video>
    ),
  };

  const exportStep = {
    title: t("Step 3 title"),
    image: <img className={styles.detailedStepImage} src={Step3} />,
  };

  const steps = [searchStep, buildStep, exportStep];

  return { steps };
};

const StepsSection: React.FC = () => {
  const styles = useStyles();
  const { t } = useTranslation();
  const { steps } = useSteps();

  return (
    <React.Fragment>
      <div id="toSeps" className={styles.detailedStepSectionContainer}>
        <SectionHeaderWithDivider primaryText={t("Three easy steps")} secondaryText={t("How it works")} />
        <Grid container className={styles.detailedStep}>
          {steps.map((step, index) => (
            <DetailedStep key={step.title} number={index + 1} {...step} />
          ))}
        </Grid>
      </div>
    </React.Fragment>
  );
};

export default StepsSection;
