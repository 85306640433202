import React from "react";

import {
  Typography
} from "@material-ui/core";

import UserPageContainer from "./UserPageContainer";

interface SendMyQuotePageProps { }

const SendMyQuotePage: React.FC<SendMyQuotePageProps> = () => {
  return (
    <UserPageContainer>
      <Typography variant="h2" color="primary" style={{
        paddingTop: '50px',
        fontWeight: 'bolder'
      }}>
        Thank you,
      </Typography>
      <Typography variant="h3" color="primary" style={{
        paddingTop: '25px',
        paddingBottom: '50px',
        lineHeight: '1.4'
      }}>
        A confirmation has been sent to your email, and you will receive a quote soon after.
      </Typography>
    </UserPageContainer>
  );
};

export default SendMyQuotePage;
