import { useState } from "react";
import { useTranslation } from "react-i18next";
import { FormikValues } from "formik";

import { useStore } from "@lib/hooks/useStore";
import { useNotification } from "@lib/components/NotificationManagerContext";
import { CollectionModelType } from "models";
import { useHistory } from "react-router";
import { SEND_MY_QUOTE, DISTRIBUTOR_OUTSIDE_AREA, DISTRIBUTOR_IN_AREA, ERROR } from "@lib/utils/routes";
import { useCanvasActions } from "./useCanvasActions";
import { useCanvasKitActions } from "./useCanvasKitActions";

interface UseCollectionActions {
  createCollection: (name?: string, userId?: string, clientMutationId?: string) => Promise<any>;
  updateCollection: ({ id, name }: FormikValues) => Promise<any>;
  deleteCollection: (collection: CollectionModelType) => Promise<any>;
  sendCollection: (collection: any) => Promise<any>;
  handleAssociateAndSendCollection: () => Promise<any>;
  loading: boolean;
  success: boolean;
}

export const useCollectionActions = (): UseCollectionActions => {
  const store = useStore();
  const { t } = useTranslation();
  const { openNotification } = useNotification();

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const { queryCanvass } = useCanvasActions();
  const { addCanvasKitsToPreviewWall } = useCanvasKitActions();
  const history = useHistory();

  const createCollection = async (name = ""): Promise<any> => {
    try {
      await store.collectionStore?.createCollection(name);
    } catch (error) {
      openNotification(t("Collection failed to create"));
      console.error(error);
    }
  };

  const updateCollection = async ({ id, name }: FormikValues): Promise<any> => {
    try {
      await store.collectionStore?.updateCollection({ id, name });

      openNotification(t("Collection Updated"));
    } catch (error) {
      openNotification(t("Collection failed to update"));
      console.error(error);
    }
  };

  const deleteCollection = async (collection: CollectionModelType): Promise<any> => {
    try {
      let response = await store.handleDestroyCollection(collection);

      if (store.currentCollection?.id) {
        queryCanvass(store.currentCollection?.id);
      } else {
        // store.resetCollections();
        //store.resetCanvass();
        //addCanvasKitsToPreviewWall();
      }

      openNotification(t("Collection successfully removed"));
      return response;
    } catch (error) {
      openNotification(t("Collection failed to delete"));
      console.error(error);
    }
  };

  const sendCollection = async (collectionId: any): Promise<any> => {
    try {
      setLoading(true);
      const response = await store.collectionStore?.sendCollection({
        id: collectionId,
        clientMutationId: "SendCollection",
      });

      if (!response.sendCollection.distributorMatch) {
        history.push(DISTRIBUTOR_OUTSIDE_AREA);
      }

      if (response.sendCollection.result) {
        history.push(SEND_MY_QUOTE);
      } else if (!response.sendCollection.result) {
        history.push(ERROR);
      }

      setSuccess(true);
    } catch (error) {
      openNotification(t("Collection failed to send"));
    } finally {
      setLoading(false);
    }
  };

  const handleAssociateAndSendCollection = async (): Promise<any> => {
    try {
      setLoading(true);

      if (store.currentUser && store.currentCollection?.id && store.collectionHasItems) {
        await sendCollection(store.currentCollection.id);
      }
    } catch (error) {
      console.error("AssociateAndSendCollection", error);
    } finally {
      setLoading(false);
    }
  };

  return {
    createCollection,
    updateCollection,
    deleteCollection,
    sendCollection,
    handleAssociateAndSendCollection,
    loading,
    success,
  };
};
