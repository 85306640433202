import React from "react";

import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

const ExportIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon viewBox="0 0 30 30" titleAccess="Export icon" {...props}>
      <path
        d="M20 7L6 7L6 23L20 23L20 19.2667"
        strokeWidth="2.10567"
        fill={props.fill || "white"}
      />
      <path
        d="M14 19.5C14 19.5 13.9997 16.1432 17 14C20.0003 11.8568 25.0001 13.1587 25.0001 13.1587"
        strokeWidth="2.10567"
      />
      <path
        d="M21.4218 16.8964L25.3699 12.9482L21.4218 9.00011"
        strokeWidth="2.10567"
      />
    </SvgIcon>
  );
};

export default ExportIcon;
