/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from "mobx-state-tree"
import { QueryBuilder } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { RootStoreType } from "./index"


/**
 * BucketBase
 * auto generated base class for the model BucketModel.
 */
export const BucketModelBase = ModelBase
  .named('Bucket')
  .props({
    __typename: types.optional(types.literal("Bucket"), "Bucket"),
    docCount: types.union(types.undefined, types.null, types.integer),
    key: types.union(types.undefined, types.null, types.string),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  }))

export class BucketModelSelector extends QueryBuilder {
  get docCount() { return this.__attr(`docCount`) }
  get key() { return this.__attr(`key`) }
}
export function selectFromBucket() {
  return new BucketModelSelector()
}

export const bucketModelPrimitives = selectFromBucket().docCount.key
