import { get } from "lodash";

export const sortBySize = (array: any[], ascendingOrder = true): any[] => {
  return array.sort((a, b) => {
    const itemA = get(a, "key", "");
    const itemB = get(b, "key", "");

    if (itemA > itemB) return ascendingOrder ? 1 : -1;
    if (itemA < itemB) return ascendingOrder ? -1 : 1;
    return 0;
  });
};

export const sortByUpdatedAt = (list: any[], ascendingOrder = true): any[] => {
  const sortedList = list.sort((a, b) => {
    const itemA = get(a, "updatedAt", "");
    const itemB = get(b, "updatedAt", "");

    if (itemA > itemB) return ascendingOrder ? 1 : -1;
    if (itemA < itemB) return ascendingOrder ? -1 : 1;
    return 0;
  });

  return sortedList;
};
