import React from "react";

import { IconButton, useTheme } from "@material-ui/core";
import { IconButtonProps } from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";

import { useStyles } from "styles";

interface EditIconButtonProps extends IconButtonProps {
  darkBackground?: boolean;
}

const EditIconButton: React.FC<EditIconButtonProps> = ({
  darkBackground = true,
  ...buttonProps
}) => {
  const styles = useStyles();
  const { palette } = useTheme();

  return (
    <div
      className={styles.editIconButtonContainer}
      style={
        darkBackground ? { backgroundColor: palette.common.white } : undefined
      }
    >
      <IconButton aria-label="edit" {...buttonProps}>
        <EditIcon
          fontSize="small"
          style={{ color: darkBackground ? "inherit" : palette.common.white }}
        />
      </IconButton>
    </div>
  );
};

export default EditIconButton;
