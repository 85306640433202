import React from "react";
import { FormikValues } from "formik";
import { useTranslation } from "react-i18next";
import DialogModal from "@lib/components/DialogModal";
import { useDialogManager } from "@lib/components/DialogManagerContext";
import FormikTextField from "@lib/components/FormikTextField";
import CollectionForm from "./CollectionForm";
import { useStyles } from "styles";
import Button from "@material-ui/core/Button";
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

export interface NewCollectionAttributes {
  id: string;
  name: string;
}

interface NewCollectionDialogProps {
  onConfirm: (param?: any) => void;
  title?: string;
  view?: string;
  initialValues?: NewCollectionAttributes;
  onClose?: () => void;
}

const NewCollectionDialog: React.FC<NewCollectionDialogProps> = ({
  onConfirm,
  initialValues,
  title,
  onClose,
  view,
}) => {
  const { closeDialog } = useDialogManager();
  const { t } = useTranslation();
  const styles = useStyles();

  const getTitle = (): string => {
    if (view == 'unnamed') {
      return "Save Unnamed Collection?";
    } else {
      return "Create a new collection";
    }
  };

  const getButton = (): string => {
    if (view == 'unnamed') {
      return t("Update");
    } else {
      return t("Create collections");
    }
  };

  return (
    <CollectionForm onConfirm={onConfirm} initialValues={initialValues}>
      {({ isSubmitting, handleSubmit }: FormikValues): React.ReactNode => (
        <DialogModal
          title={getTitle()}
          inputComponent={
            <FormikTextField
              name="name"
              label={t("Name")}
              type="text"
              variant="outlined"
            />
          }
          onClose={onClose || closeDialog}
          fullWidthBottomButton={false}
          dialogActionButtons={
            <Button
              className={styles.buttonWithRoundedCorners}
              variant="contained"
              color="secondary"
              disabled={isSubmitting}
              onClick={(): void => handleSubmit()}
              endIcon={
                <ArrowForwardIcon />
              }
            >
              {getButton()}
            </Button>
          }
        />
      )}
    </CollectionForm>
  );
};

export default NewCollectionDialog;
