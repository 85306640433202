import React from "react";
import { FormikValues } from "formik";
import { useTranslation } from "react-i18next";

import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { useDialogManager } from "@lib/components/DialogManagerContext";
import { Button } from "@material-ui/core";
import { useStyles } from "styles";
import FormikTextField from "@lib/components/FormikTextField";
import DialogModal from "@lib/components/DialogModal";
import CanvasForm from "./CanvasForm";
export interface NewCanvasAttributes {
  id: string;
  name: string;
}

interface NewCanvasDialogProps {
  onConfirm: (param?: any) => void;
  title?: string;
  view?: string;
  initialValues?: NewCanvasAttributes;
  onClose?: () => void;
}

const NewCanvasDialog: React.FC<NewCanvasDialogProps> = ({
  onConfirm,
  initialValues,
  title,
  onClose,
  view,
}) => {
  const { closeDialog } = useDialogManager();
  const { t } = useTranslation();
  const styles = useStyles();

  const getTitle = (): string => {
    if (view == 'unnamed') {
      return "Save unnamed canvas?";
    } else {
      return "Save & name your <strong>canvas</strong>";
    }
  };

  const getButton = (): string => {
    if (view == 'unnamed') {
      return t("Update");
    } else {
      return t("Create Canvas");
    }
  };

  return (
    <CanvasForm onConfirm={onConfirm} initialValues={initialValues}>
      {({ isSubmitting, handleSubmit }: FormikValues): React.ReactNode => (
        <DialogModal
          title={getTitle()}
          inputComponent={
            <FormikTextField
              name="name"
              label={t("Name")}
              type="text"
              variant="outlined"
            />
          }
          onClose={onClose || closeDialog}
          fullWidthBottomButton={false}
          dialogActionButtons={
            <Button
              className={styles.buttonWithRoundedCorners}
              variant="contained"
              color="secondary"
              disabled={isSubmitting}
              onClick={(): void => handleSubmit()}
              endIcon={
                <ArrowForwardIcon />
              }
            >
              {getButton()}
            </Button>
          }
        />
      )}
    </CanvasForm>
  );
};

export default NewCanvasDialog;
