import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { size } from "lodash";

import { Divider } from "@material-ui/core";

import { useStyles } from "styles";
import { useKitActions } from "@lib/hooks/useKitActions";
import OptionsRelatedDisplay from "./OptionsRelatedDisplay";
import { useStore } from "@lib/hooks/useStore";

const OptionsRelatedContainer: React.FC = observer(() => {
  const store = useStore();
  const styles = useStyles();
  const { t } = useTranslation();
  const { queryKitsForOptionsRelated } = useKitActions();
  const loading = store.loading.optionsRelatedKits;
  const showOptions = !!size(store.optionsAndAccessoriesKits) || loading;
  const showRelated = !!size(store.relatedKits) || loading;

  useEffect(() => {
    queryKitsForOptionsRelated();
  }, [store.selectedCollectionKit?.id]);

  return (
    <React.Fragment>
      {showOptions && (
        <OptionsRelatedDisplay text={t("Options")} kits={store.optionsAndAccessoriesKits} loading={loading} />
      )}
      {showOptions && showRelated && (
        <Divider className={styles.dividerThick} style={{ marginTop: 32, marginBottom: 32 }} />
      )}
      {showRelated && <OptionsRelatedDisplay text={t("Related")} kits={store.relatedKits} loading={loading} />}
    </React.Fragment>
  );
});

export default OptionsRelatedContainer;
