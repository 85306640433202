import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import {
  Typography,
  Divider,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  FormHelperText,
  Button,
} from "@material-ui/core";

import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import UserPageContainer from "./UserPageContainer";
import NewUserForm from "./UserForm";
import FormikTextField from "@lib/components/FormikTextField";
import { UserModelType } from "models";
import ConfirmButtonSpinner from "@lib/components/ConfirmButtonSpinner";
import { useStyles } from "styles";
import FormikRadioField from "@lib/components/FormikRadioField";
import { ErrorMessage } from "formik";
import { useStore } from "@lib/hooks/useStore";
import { useHistory } from "react-router";
import { FormikValues } from "formik";
import { useNotification } from "@lib/components/NotificationManagerContext";
import { Alert } from "@material-ui/lab";

interface CreateAccountPageProps {
  user: UserModelType;
}

const CreateAccountPage: React.FC<CreateAccountPageProps> = ({ user }) => {
  const { t } = useTranslation();
  const [projectType, setProjectType] = React.useState(null);
  const styles = useStyles();
  const store = useStore();
  const history = useHistory();
  const { message, clearNotification } = useNotification();

  useEffect(() => {
    clearNotification();
  }, []);

  return (
    <>
      {!store.currentUser ? (
        <UserPageContainer>
          <NewUserForm user={user}>
            {({ handleSubmit, isSubmitting }: FormikValues): React.ReactNode => (
              <form>
                <Typography variant="h2" color="primary">
                  {t("Create Account")}
                </Typography>
                <Divider className={styles.dividerModal} />
                {message && <Alert severity="warning">{message}!</Alert>}
                <FormikTextField
                  name="firstName"
                  label={t("First Name")}
                  type="text"
                  variant="outlined"
                  labelWidth={78}
                />
                <FormikTextField
                  name="lastName"
                  label={t("Last Name")}
                  type="text"
                  variant="outlined"
                  labelWidth={78}
                />
                <FormikTextField name="email" label={t("Email")} type="text" variant="outlined" labelWidth={40} />
                <FormikTextField
                  name="password"
                  label={t("Password")}
                  type="password"
                  variant="outlined"
                  labelWidth={72}
                />
                <FormikTextField
                  name="passwordConfirmation"
                  label={t("Confirm Password")}
                  type="password"
                  variant="outlined"
                  labelWidth={128}
                  onKeyDown={(event: React.KeyboardEvent): void => {
                    if (event.keyCode === 13) {
                      handleSubmit();
                    }
                  }}
                />
                <Divider className={styles.dividerModal} />
                <p>To better serve you, please provide your ZIP code</p>
                <FormikTextField name="zipCode" label={t("Zip Code")} type="text" variant="outlined" labelWidth={128} />
                <Divider className={styles.dividerModal} />
                <FormControl component="fieldset">
                  <FormLabel component="legend">Account Created for a:</FormLabel>
                  <RadioGroup
                    row
                    aria-label="Account Created for a:"
                    value={projectType}
                    name="projectType"
                    onChange={(e): void => setProjectType(e.currentTarget.value)}
                  >
                    <FormikRadioField
                      value="personal"
                      name="projectType"
                      label="Personal Project"
                      labelPlacement="end"
                      control={<Radio />}
                    />
                    <FormikRadioField
                      value="professional"
                      name="projectType"
                      label="Professional Project"
                      labelPlacement="end"
                      control={<Radio />}
                    />
                  </RadioGroup>
                  <ErrorMessage name="projectType">
                    {(errorMessage): React.ReactNode => <FormHelperText error>{errorMessage}</FormHelperText>}
                  </ErrorMessage>
                </FormControl>

                {projectType == "professional" && (
                  <FormikTextField
                    name="affiliatedTradeFirm"
                    label="Trade Firm I am affiliated with"
                    type="text"
                    variant="outlined"
                    labelWidth={78}
                  />
                )}
                <Divider className={styles.dividerModal} />
                <Button
                  onClick={(): void => handleSubmit()}
                  className={styles.buttonWithRoundedCorners}
                  disabled={isSubmitting}
                  variant="contained"
                  color="secondary"
                  endIcon={<ArrowForwardIcon color={isSubmitting ? "disabled" : "inherit"} />}
                >
                  {t("Create Account")}
                  {isSubmitting && <ConfirmButtonSpinner />}
                </Button>
              </form>
            )}
          </NewUserForm>
        </UserPageContainer>
      ) : (
        history.goBack()
      )}
    </>
  );
};

export default CreateAccountPage;
