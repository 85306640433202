import React, { createContext, useContext } from "react";
import { NotificationProvider } from "@lib/components/NotificationManagerContext";

interface DialogContextValues {
  openDialog: (component: React.ReactNode) => void;
  closeDialog: () => void;
  isDialogOpen: boolean;
}

const DialogContext = createContext<DialogContextValues | undefined>(undefined);

export const DialogProvider: React.FC = ({ children }) => {
  const [dialogComponent, setDialogComponent] = React.useState<React.ReactNode | null>(null);
  const [showDialog, setShowDialog] = React.useState(false);

  const closeDialog = (): void => {
    setShowDialog(false);
    setDialogComponent(null);
  };

  const openDialog = (component: React.ReactNode): void => {
    setDialogComponent(component);
    setShowDialog(true);
  };

  return (
    <DialogContext.Provider value={{ openDialog, closeDialog, isDialogOpen: showDialog }}>
      <NotificationProvider>
        {children}
        {showDialog && dialogComponent}
      </NotificationProvider>
    </DialogContext.Provider>
  );
};

export const useDialogManager = (): DialogContextValues => {
  const context = useContext(DialogContext);

  if (!context) {
    throw new Error("useDialogManager must be used within a DialogProvider");
  }

  return {
    openDialog: context.openDialog,
    closeDialog: context.closeDialog,
    isDialogOpen: context.isDialogOpen,
  };
};
