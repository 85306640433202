/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from "mobx-state-tree"
import { QueryBuilder } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { RootStoreType } from "./index"


/**
 * PageInfoBase
 * auto generated base class for the model PageInfoModel.
 */
export const PageInfoModelBase = ModelBase
  .named('PageInfo')
  .props({
    __typename: types.optional(types.literal("PageInfo"), "PageInfo"),
    id: types.identifier,
    items: types.union(types.undefined, types.null, types.integer),
    next: types.union(types.undefined, types.null, types.integer),
    page: types.union(types.undefined, types.null, types.integer),
    search: types.union(types.undefined, types.null, types.string),
    totalCount: types.union(types.undefined, types.null, types.integer),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  }))

export class PageInfoModelSelector extends QueryBuilder {
  get id() { return this.__attr(`id`) }
  get items() { return this.__attr(`items`) }
  get next() { return this.__attr(`next`) }
  get page() { return this.__attr(`page`) }
  get search() { return this.__attr(`search`) }
  get totalCount() { return this.__attr(`totalCount`) }
}
export function selectFromPageInfo() {
  return new PageInfoModelSelector()
}

export const pageInfoModelPrimitives = selectFromPageInfo().items.next.page.search.totalCount
