import { Button, ButtonProps, Fade } from "@material-ui/core";

import AddIcon from "@material-ui/icons/AddCircleOutlined";
import CheckIcon from "@material-ui/icons/CheckCircle";
import { FormikValues } from "formik";
import MyCollectionsDialog from "components/MyCollections/MyCollectionsDialog";
import NewCollectionDialog from "./NewCollectionDialog";
import React from "react";
import { size } from "lodash";
import { useCollectionKitActions } from "@lib/hooks/useCollectionKitActions";
import { useDialogManager } from "./DialogManagerContext";
import { matchPath, useHistory, useLocation, useParams } from "react-router";
import { useStore } from "@lib/hooks/useStore";
import { useStyles } from "styles";
import { useTranslation } from "react-i18next";

interface ButtonAddToCollectionProps extends ButtonProps {
  id: string;
}

const ButtonAddToCollection: React.FC<ButtonAddToCollectionProps> = ({ id, ...props }) => {
  const store = useStore();
  const { brand = null } = useParams<any>();
  const { t } = useTranslation();
  const styles = useStyles();
  const { createCollectionKit, addCollectionKitToCollection, loading, success } = useCollectionKitActions();
  const { openDialog, closeDialog } = useDialogManager();
  const history = useHistory();
  const { pathname } = useLocation();
  const matchBrand: any = matchPath(pathname, { path: "/build/:brand" });
  const matchProduct: any = matchPath(pathname, { path: "/build/:brand/details/:product_id" });
  const matchInstance: any = matchPath(pathname, { path: "/build/:brand/details/:product_id/:instance_id" });

  const handleCreateCollectionAddKit = async ({ name }: FormikValues): Promise<any> => {
    const response = await createCollectionKit(id, name);
    const instance_id = response?.createCollectionKit?.collectionKit?.id;
    const product_id = response?.createCollectionKit?.collectionKit?.kit?.id;

    if (!store.currentUser && response?.createCollectionKit?.collectionKit?.collection) {
      localStorage.setItem(
        "dssTempCollection",
        JSON.stringify(response?.createCollectionKit?.collectionKit?.collection)
      );
    }

    if (response) {
      if (product_id && instance_id) {
        history.push(`/build/${brand}/details/${product_id}/${instance_id}`);
      } else if (product_id) {
        history.push(`/build/${brand}/details/${product_id}`);
      } else {
        history.push(`/build/${brand}`);
      }

      closeDialog();
    }
  };

  const handleAddItemClick = async () => {
    if (!store.currentUser && !store.currentCollection) {
      openDialog(<NewCollectionDialog onConfirm={handleCreateCollectionAddKit} />);
    } else if (store.currentUser && !store.currentCollection) {
      size(store.currentUser.collections)
        ? openDialog(<MyCollectionsDialog kitId={id} />)
        : openDialog(<NewCollectionDialog onConfirm={handleCreateCollectionAddKit} />);
    } else {
      const response = await addCollectionKitToCollection(id);
      const product_id = response?.createCollectionKit?.collectionKit?.kit?.id;
      const instance_id = response?.createCollectionKit?.collectionKit?.id;

      if (matchBrand?.isExact) {
        history.push(`/build/${brand}`);
      } else if (matchProduct?.isExact && instance_id) {
        history.push(`/build/${brand}/details/${product_id}/${instance_id}`);
      } else {
        history.push(`/build/${brand}`);
      }
    }
  };

  return (
    <Button
      variant="text"
      color="primary"
      size="small"
      classes={{ textPrimary: styles.kitCardBtnOverrides }}
      className={styles.addKitToCollectionBtn}
      onClick={handleAddItemClick}
      disabled={loading}
      {...props}
    >
      <div style={{ marginRight: 8, display: "flex" }}>
        {success ? (
          <Fade in={success} timeout={600}>
            <CheckIcon color="primary" fontSize="large" />
          </Fade>
        ) : (
          <AddIcon color="secondary" fontSize="large" />
        )}
      </div>
      {success ? t("Collected") : t("Add to Collection")}
    </Button>
  );
};

export default ButtonAddToCollection;
