import React from "react";
import { FormikValues } from "formik";
import { useTranslation } from "react-i18next";

import { PopperPlacementType } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";

import DialogModal from "@lib/components/DialogModal";
import { useDialogManager } from "@lib/components/DialogManagerContext";
import ButtonFullWidthDialog from "@lib/components/ButtonFullWidthDialog";
import { useStore } from "@lib/hooks/useStore";
import { useCanvasKitActions } from "@lib/hooks/useCanvasKitActions";
import { CanvasModelType } from "models";
import NewCanvasDialog from "@lib/components/NewCanvasDialog";
import { useCanvasActions } from "@lib/hooks/useCanvasActions";
import DeletePopper from "./DeletePopper";
import MyCanvasDialogLists from "./MyCanvasDialogLists";

interface MyCanvasDialogProps {
  kitId?: string;
  listView?: boolean;
  quoteView?: boolean;
}

const MyCanvasDialog: React.FC<MyCanvasDialogProps> = ({ kitId = "", listView = false }) => {
  const store = useStore();
  const { closeDialog } = useDialogManager();
  const { t } = useTranslation();
  const { createCanvasKit, queryCanvasKits } = useCanvasKitActions();

  const { destroyCanvas } = useCanvasActions();
  const [showNewCanvas, setShowNewCanvas] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [edit, setEdit] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const [placement, setPlacement] = React.useState<PopperPlacementType>("left");

  const [selectedCanvas, setSelectedCanvas] = React.useState<CanvasModelType | null>(null);

  const handleDeleteClick =
    (newPlacement: PopperPlacementType, canvas: CanvasModelType) =>
    (event: React.MouseEvent<HTMLButtonElement>): void => {
      setAnchorEl(event.currentTarget);
      setOpen((prev) => placement !== newPlacement || !prev);
      setPlacement(newPlacement);
      setSelectedCanvas(canvas);
    };

  const handleEditClick = (canvas: CanvasModelType, listType: string): void => {
    setSelectedCanvas(canvas ? { ...canvas, listType } : null);
    setEdit(!edit);
  };

  const handleSendQuoteClick = async (canvas: CanvasModelType): Promise<any> => {};

  const handleCreateCanvasAddKit = async ({ name }: FormikValues): Promise<any> => {
    await createCanvasKit(kitId, name);
    closeDialog();
  };

  const handleSelectClick = async (canvas: CanvasModelType): Promise<any> => {
    if (confirm("By changing canvas any canvas not saved will be lost. continue?")) {
      store.setCurrentCanvas(canvas);

      if (listView) {
        await queryCanvasKits();
      } else {
        await createCanvasKit(kitId);
      }
    }

    closeDialog();
  };

  const getTitle = (): string => {
    if (open) return t("Delete this canvas");
    if (edit) return t("Edit this canvas name");
    return listView ? "Select a <strong>canvas</strong>" : "Choose the <strong>canvas</strong> for this item";
  };

  const handleDeleteConfirm = async (): Promise<any> => {
    await setOpen(false);

    if (selectedCanvas) {
      destroyCanvas(selectedCanvas);

      if (store.currentCanvas?.canvass == undefined) {
        store.clearWallContent();
      }
    }

    // store.setCurrentCanvas(undefined);
  };

  return (
    <React.Fragment>
      {!showNewCanvas ? (
        <React.Fragment>
          <DialogModal
            title={getTitle()}
            contentText={"Get creative. Build up to " + store?.userCanvasList.length + " of 5 canvases."}
            open={true}
            inputComponent={
              <MyCanvasDialogLists
                listView={listView}
                open={open}
                edit={edit}
                selectedCanvas={selectedCanvas}
                onSelectClick={handleSelectClick}
                handleDeleteClick={handleDeleteClick}
                handleEditClick={handleEditClick}
                handleSendQuoteClick={handleSendQuoteClick}
              />
            }
            onClose={closeDialog}
            maxWidth="md"
            fullWidthBottomButton={true}
            dialogActionButtons={
              !listView && (
                <ButtonFullWidthDialog
                  style={{ fontSize: 20 }}
                  onClick={(): void => setShowNewCanvas(true)}
                  startIcon={<AddIcon style={{ position: "relative", bottom: 1 }} />}
                >
                  {t("Create new canvas")}
                </ButtonFullWidthDialog>
              )
            }
          />
          <DeletePopper
            open={open}
            anchorEl={anchorEl}
            placement={placement}
            setOpen={setOpen}
            selectedCanvas={selectedCanvas}
            handleDeleteConfirm={handleDeleteConfirm}
          />
        </React.Fragment>
      ) : (
        <NewCanvasDialog onConfirm={handleCreateCanvasAddKit} />
      )}
    </React.Fragment>
  );
};

export default MyCanvasDialog;
