/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { IObservableArray } from "mobx"
import { types } from "mobx-state-tree"
import { MSTGQLRef, QueryBuilder, withTypedRefs } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { CollectionModel, CollectionModelType } from "./CollectionModel"
import { CollectionModelSelector } from "./CollectionModel.base"
import { GroupModel, GroupModelType } from "./GroupModel"
import { GroupModelSelector } from "./GroupModel.base"
import { KitModel, KitModelType } from "./KitModel"
import { KitModelSelector } from "./KitModel.base"
import { ProductModel, ProductModelType } from "./ProductModel"
import { ProductModelSelector } from "./ProductModel.base"
import { RootStoreType } from "./index"


/* The TypeScript type that explicits the refs to other models in order to prevent a circular refs issue */
type Refs = {
  accessoryProducts: IObservableArray<ProductModelType>;
  collection: CollectionModelType;
  group: GroupModelType;
  kit: KitModelType;
}

/**
 * CollectionKitBase
 * auto generated base class for the model CollectionKitModel.
 */
export const CollectionKitModelBase = withTypedRefs<Refs>()(ModelBase
  .named('CollectionKit')
  .props({
    __typename: types.optional(types.literal("CollectionKit"), "CollectionKit"),
    accessoryProducts: types.union(types.undefined, types.null, types.array(MSTGQLRef(types.late((): any => ProductModel)))),
    collection: types.union(types.undefined, MSTGQLRef(types.late((): any => CollectionModel))),
    group: types.union(types.undefined, MSTGQLRef(types.late((): any => GroupModel))),
    id: types.identifier,
    kit: types.union(types.undefined, MSTGQLRef(types.late((): any => KitModel))),
    optional: types.union(types.undefined, types.boolean),
    updatedAt: types.union(types.undefined, types.string),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  })))

export class CollectionKitModelSelector extends QueryBuilder {
  get id() { return this.__attr(`id`) }
  get optional() { return this.__attr(`optional`) }
  get updatedAt() { return this.__attr(`updatedAt`) }
  accessoryProducts(builder?: string | ProductModelSelector | ((selector: ProductModelSelector) => ProductModelSelector)) { return this.__child(`accessoryProducts`, ProductModelSelector, builder) }
  collection(builder?: string | CollectionModelSelector | ((selector: CollectionModelSelector) => CollectionModelSelector)) { return this.__child(`collection`, CollectionModelSelector, builder) }
  group(builder?: string | GroupModelSelector | ((selector: GroupModelSelector) => GroupModelSelector)) { return this.__child(`group`, GroupModelSelector, builder) }
  kit(builder?: string | KitModelSelector | ((selector: KitModelSelector) => KitModelSelector)) { return this.__child(`kit`, KitModelSelector, builder) }
}
export function selectFromCollectionKit() {
  return new CollectionKitModelSelector()
}

export const collectionKitModelPrimitives = selectFromCollectionKit().optional.updatedAt
