import React from "react";

import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

const ListIcon: React.FC<SvgIconProps> = props => {
  return (
    <SvgIcon viewBox="0 0 19 17" titleAccess="List Icon" {...props}>
      <rect width="13" height="17" />
      <rect x="15" width="3.62173" height="3.62173" />
      <rect x="15" y="6.63965" width="3.62173" height="3.62173" />
      <rect x="15" y="13.2793" width="3.62173" height="3.62173" />
    </SvgIcon>
  );
};

export default ListIcon;
