/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from "mobx-state-tree"
import { QueryBuilder } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { FilterModel, FilterModelType } from "./FilterModel"
import { FilterModelSelector } from "./FilterModel.base"
import { RootStoreType } from "./index"


/**
 * AggregationBase
 * auto generated base class for the model AggregationModel.
 */
export const AggregationModelBase = ModelBase
  .named('Aggregation')
  .props({
    __typename: types.optional(types.literal("Aggregation"), "Aggregation"),
    category: types.union(types.undefined, types.null, types.late((): any => FilterModel)),
    depth: types.union(types.undefined, types.null, types.late((): any => FilterModel)),
    family: types.union(types.undefined, types.null, types.late((): any => FilterModel)),
    height: types.union(types.undefined, types.null, types.late((): any => FilterModel)),
    id: types.identifier,
    superCategory: types.union(types.undefined, types.null, types.late((): any => FilterModel)),
    width: types.union(types.undefined, types.null, types.late((): any => FilterModel)),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  }))

export class AggregationModelSelector extends QueryBuilder {
  get id() { return this.__attr(`id`) }
  category(builder?: string | FilterModelSelector | ((selector: FilterModelSelector) => FilterModelSelector)) { return this.__child(`category`, FilterModelSelector, builder) }
  depth(builder?: string | FilterModelSelector | ((selector: FilterModelSelector) => FilterModelSelector)) { return this.__child(`depth`, FilterModelSelector, builder) }
  family(builder?: string | FilterModelSelector | ((selector: FilterModelSelector) => FilterModelSelector)) { return this.__child(`family`, FilterModelSelector, builder) }
  height(builder?: string | FilterModelSelector | ((selector: FilterModelSelector) => FilterModelSelector)) { return this.__child(`height`, FilterModelSelector, builder) }
  superCategory(builder?: string | FilterModelSelector | ((selector: FilterModelSelector) => FilterModelSelector)) { return this.__child(`superCategory`, FilterModelSelector, builder) }
  width(builder?: string | FilterModelSelector | ((selector: FilterModelSelector) => FilterModelSelector)) { return this.__child(`width`, FilterModelSelector, builder) }
}
export function selectFromAggregation() {
  return new AggregationModelSelector()
}

export const aggregationModelPrimitives = selectFromAggregation()
