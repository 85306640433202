/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from "mobx-state-tree"
import { QueryBuilder } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { RootStoreType } from "./index"


/**
 * SendCollectionPayloadBase
 * auto generated base class for the model SendCollectionPayloadModel.
 *
 * Autogenerated return type of SendCollection
 */
export const SendCollectionPayloadModelBase = ModelBase
  .named('SendCollectionPayload')
  .props({
    __typename: types.optional(types.literal("SendCollectionPayload"), "SendCollectionPayload"),
    /** A unique identifier for the client performing the mutation. */
    clientMutationId: types.union(types.undefined, types.null, types.string),
    distributorMatch: types.union(types.undefined, types.boolean),
    result: types.union(types.undefined, types.boolean),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  }))

export class SendCollectionPayloadModelSelector extends QueryBuilder {
  get clientMutationId() { return this.__attr(`clientMutationId`) }
  get distributorMatch() { return this.__attr(`distributorMatch`) }
  get result() { return this.__attr(`result`) }
}
export function selectFromSendCollectionPayload() {
  return new SendCollectionPayloadModelSelector()
}

export const sendCollectionPayloadModelPrimitives = selectFromSendCollectionPayload().clientMutationId.distributorMatch.result
