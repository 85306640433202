/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from "mobx-state-tree"
import { QueryBuilder } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { RootStoreType } from "./index"


/**
 * LogoutPayloadBase
 * auto generated base class for the model LogoutPayloadModel.
 *
 * Autogenerated return type of Logout
 */
export const LogoutPayloadModelBase = ModelBase
  .named('LogoutPayload')
  .props({
    __typename: types.optional(types.literal("LogoutPayload"), "LogoutPayload"),
    /** A unique identifier for the client performing the mutation. */
    clientMutationId: types.union(types.undefined, types.null, types.string),
    result: types.union(types.undefined, types.null, types.boolean),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  }))

export class LogoutPayloadModelSelector extends QueryBuilder {
  get clientMutationId() { return this.__attr(`clientMutationId`) }
  get result() { return this.__attr(`result`) }
}
export function selectFromLogoutPayload() {
  return new LogoutPayloadModelSelector()
}

export const logoutPayloadModelPrimitives = selectFromLogoutPayload().clientMutationId.result
