import React from "react";

import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

const WolfLogo: React.FC<SvgIconProps> = props => {
  return (
    <SvgIcon viewBox="0 0 44 10" titleAccess="Wolf Logo" {...props}>
      <path d="M19.5591 9.51706C18.1715 9.51706 17.4715 9.40131 16.4645 9.24698C15.212 9.04121 14.7085 8.46247 14.6348 7.15066C14.5734 5.91602 14.5734 4.66851 14.6348 3.43387C14.6962 2.1735 15.1751 1.5819 16.354 1.37613C18.4785 1.01603 20.6275 1.02889 22.7519 1.38899C23.6852 1.54332 24.2992 2.13492 24.3483 3.13807C24.4097 4.59135 24.3975 6.04462 24.3483 7.48504C24.3115 8.50105 23.6729 9.09265 22.7519 9.22126C21.6836 9.37559 20.9959 9.51706 19.5591 9.51706ZM17.0785 5.27297C17.0785 5.82599 17.0417 6.39187 17.0908 6.94488C17.1522 7.7294 17.4224 8.03806 18.1715 8.07664C19.0802 8.12808 20.0012 8.12808 20.9222 8.06378C21.6099 8.0252 21.9046 7.7294 21.9414 7.02205C21.9906 5.86457 21.9906 4.70709 21.9414 3.56248C21.9046 2.86799 21.659 2.59791 20.9713 2.54647C20.038 2.4693 19.0925 2.4693 18.1715 2.53361C17.3855 2.59791 17.1522 2.90657 17.1031 3.72967C17.0417 4.2441 17.0785 4.75854 17.0785 5.27297Z" />
      <path d="M2.58803 7.39539C5.08088 6.50799 7.43866 6.44369 9.87011 7.39539C9.89467 7.04815 9.91923 6.84238 9.91923 6.64946C9.91923 5.64632 9.89467 4.63031 9.93151 3.62716C9.96835 2.30249 10.7297 1.45368 11.9577 1.31221C12.4121 1.26076 12.5103 1.32507 12.5103 1.81378C12.5226 3.8458 12.5226 5.87781 12.5103 7.90983C12.498 8.87439 11.8472 9.37597 10.9385 9.14447C10.1034 8.9387 9.26839 8.74579 8.44562 8.48857C6.97201 8.03844 5.51068 8.0513 4.03707 8.50143C3.23887 8.74579 2.41611 8.92584 1.60562 9.13161C0.672334 9.37597 0.0214901 8.87439 0.00921006 7.85839C-0.00307002 5.85209 -0.00307002 3.83294 0.00921006 1.82664C0.00921006 1.29935 0.0951702 1.23504 0.586373 1.29935C1.77754 1.45368 2.51435 2.25105 2.57575 3.51141C2.62487 4.51456 2.60031 5.51771 2.60031 6.53372C2.58803 6.79093 2.58803 7.02243 2.58803 7.39539Z" />
      <path d="M43.8737 1.33789C44.0579 2.59825 43.2842 3.56282 42.0685 3.58854C40.7791 3.62712 39.4897 3.58854 38.2003 3.61426C37.9792 3.61426 37.6108 3.73001 37.5863 3.83289C37.5249 4.21872 37.5617 4.63027 37.6108 5.02895C37.6231 5.09326 37.9301 5.1447 38.102 5.1447C39.2932 5.15756 40.4721 5.1447 41.6632 5.15756C41.8474 5.15756 42.0316 5.15756 42.2036 5.19615C42.572 5.29903 42.7807 5.63342 42.7193 6.0321C42.6579 6.41793 42.4492 6.66228 42.0685 6.67515C41.4545 6.68801 40.8405 6.67515 40.2265 6.67515C39.4897 6.67515 38.7529 6.68801 38.0161 6.67515C37.6477 6.66228 37.5003 6.81662 37.5126 7.20244C37.5249 7.53682 37.5249 7.89693 37.4757 8.23131C37.3652 8.87435 36.8863 9.2859 36.2723 9.29876C35.6706 9.29876 35.1794 8.90008 35.0443 8.25703C35.0074 8.07698 35.0074 7.87121 35.0074 7.67829C35.0074 6.09641 35.0074 4.51452 35.0074 2.91977C35.0074 1.78802 35.4741 1.31216 36.5302 1.31216C38.7774 1.31216 41.0247 1.31216 43.2842 1.31216C43.493 1.31216 43.6895 1.32503 43.8737 1.33789Z" />
      <path d="M26.5464 4.86142C26.5464 3.85827 26.5464 2.85512 26.5464 1.83911C26.5464 1.32468 26.6446 1.24751 27.1358 1.32468C28.3761 1.50473 29.0883 2.32783 29.1129 3.67822C29.1375 4.80997 29.1129 5.94173 29.1252 7.08635C29.1252 7.71653 29.1375 7.71653 29.7515 7.72939C30.8567 7.72939 31.9619 7.72939 33.0671 7.72939C33.2513 7.72939 33.4355 7.72939 33.6197 7.76797C34.0004 7.858 34.246 8.19238 34.2337 8.53963C34.2214 8.92545 33.9267 9.24697 33.5092 9.25984C32.9566 9.28556 32.404 9.25984 31.8514 9.25984C30.5619 9.25984 29.2725 9.25984 27.9831 9.25984C26.9393 9.25984 26.5464 8.84829 26.5464 7.74225C26.5464 6.79055 26.5464 5.82598 26.5464 4.86142Z" />
      <path d="M6.50523 0C6.72627 0.8874 7.25432 1.50472 7.80692 2.10918C8.07708 2.41784 8.31041 2.77795 8.48233 3.16377C8.76477 3.79395 8.61741 4.32125 8.10164 4.83568C7.91744 4.44986 7.73324 4.07689 7.56132 3.70393C7.49992 3.71679 7.43852 3.71679 7.37712 3.72965C7.34028 3.96115 7.29116 4.19264 7.2666 4.42414C7.15608 5.44015 6.56663 5.92886 5.59651 5.81311C4.34394 5.67164 3.55801 4.35983 3.97553 3.06089C4.13517 2.57217 4.3685 2.1349 4.88426 1.7748C4.95794 2.08346 4.99478 2.34068 5.06846 2.58503C5.15442 2.88083 5.2895 3.17663 5.65791 3.09947C5.98947 3.03516 6.11227 2.7265 6.09999 2.41784C6.07543 2.05774 6.01403 1.69763 5.92807 1.35039C5.79299 0.758791 6.05087 0.347243 6.50523 0Z" />
    </SvgIcon>
  );
};

export default WolfLogo;
