import { Button, ButtonBase, Fade, List, Typography, useTheme } from "@material-ui/core";
import { CANVAS, LOGIN } from "@lib/utils/routes";
import React, { useEffect, useRef, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router";
import AddIcon from "@material-ui/icons/Add";
import ButtonOpenCollectionsList from "./ButtonOpenCollectionsList";
import ButtonWithRoundedCorners from "./ButtonWithRoundedCorners";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import CloseIcon from "@material-ui/icons/Close";
import CollectionKitListItem from "components/Details/CollectionKitListItem";
import { CollectionModelType } from "models";
import CollectionNameContainer from "@lib/components/CollectionNameContainer";
import LinkNoStyle from "./LinkNoStyle";
import SideDrawer from "./SideDrawer";
import SideDrawerListIcon from "@lib/components/SideDrawerListIcon";
import clsx from "clsx";
import { observer } from "mobx-react";
import { size } from "lodash";
import { useCanvasActions } from "@lib/hooks/useCanvasActions";
import { useCollectionActions } from "@lib/hooks/useCollectionActions";
import { useStore } from "@lib/hooks/useStore";
import { useStyles } from "styles";
import { useTranslation } from "react-i18next";
import NewCollectionDialog from "./NewCollectionDialog";
import { useDialogManager, isDialogOpen } from "./DialogManagerContext";

interface SideDrawerWrapperProps {
  props: { children?: React.ReactElement<any, any> };
  ref: React.Ref<unknown>;
}

const SideDrawerWrapper: React.FC<SideDrawerWrapperProps> = observer(({ props, ref }) => {
  const { product_id = null, brand = "all", instance_id = null } = useParams<any>();
  const store = useStore();
  const drawerContentList = useRef<any>(null);
  const [drawerContentListOffset, setDrawerContentListOffset] = useState(false);
  const location = useLocation();
  const history = useHistory();
  const styles = useStyles();
  const { t } = useTranslation();
  const { updateCollection, sendCollection, loading } = useCollectionActions();
  const { queryCanvass } = useCanvasActions();
  const [isSearchView, setIsSearchView] = useState(false);
  const [isCanvasView, setIsCanvasView] = useState(false);
  const [isBrandView, setIsIsBrandView] = useState(false);
  const { openDialog, closeDialog, isDialogOpen } = useDialogManager();

  useEffect(() => {
    setIsCanvasView(brand == "canvas");
    setIsSearchView(brand && !product_id);
    setIsIsBrandView(brand != "canvas");
  }, [location, product_id, brand, location.pathname]);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    handleResize();
  }, [store.currentCollectionKits.length]);

  useEffect(() => {
    if (store.currentUser) {
      if (store.currentCollection?.id) {
        queryCanvass(store.currentCollection.id);
      }

      if (store.currentUser && store.currentCollection?.id && !store.currentCollection?.name) {
        let id = store.currentCollection?.id;
        let name = "Unnamed Collection";

        updateCollection({ id, name });
      }
    } else if (!store.currentUser && store.currentCollection?.id && !store.currentCollection?.name) {
      const collection = JSON.parse(localStorage.getItem("dssTempCollection") || "");

      if (collection.id == store.currentCollection?.id) {
        store.currentCollection.name = collection.name;
      }
    }
  }, [store.currentCollection, store.currentUser]);

  const handleResize = () => {
    let clientHeight = drawerContentList?.current?.clientHeight;
    let scrollHeight = drawerContentList?.current?.scrollHeight;

    if (scrollHeight > clientHeight) {
      setDrawerContentListOffset(true);
    } else {
      setDrawerContentListOffset(false);
    }
  };

  const handleGetQuote: any = async (collection: CollectionModelType): Promise<any> => {
    if (store.currentUser && store.currentCollection?.id && store.collectionHasItems) {
      await sendCollection(store.currentCollection.id);
    }
  };

  return (
    <SideDrawer open={store.isDrawerOpen} isNotABuildView={false}>
      <React.Fragment>
        {store.isDrawerOpen ? (
          <div className={styles.drawerHeader}>
            {store.currentUser && (
              <div className={styles.sideDrawerCollectionName}>
                <CollectionNameContainer name={store.currentCollection?.name} id={store.currentCollection?.id} />
              </div>
            )}

            <React.Fragment>
              {isCanvasView && (
                <Button
                  onClick={(): void => history.goBack()}
                  className={styles.canvasButtonPrimary}
                  variant="contained"
                  style={{ backgroundColor: "background: #383B46" }}
                  fullWidth
                >
                  <CloseIcon className={styles.actionIconPrimary} />
                  {t("Exit canvas")}
                </Button>
              )}
              {isBrandView && (
                <LinkNoStyle
                  to={{
                    pathname: CANVAS,
                    hash: "#top",
                  }}
                >
                  {store.currentCollection?.snapshotUrl ? (
                    <Button
                      className={styles.canvasButtonPrimary}
                      variant="contained"
                      style={{ backgroundColor: "background: #383B46" }}
                      fullWidth
                    >
                      <AddIcon className={styles.actionIconPrimary} />
                      {t("View canvas")}
                    </Button>
                  ) : (
                    <Button
                      className={styles.canvasButtonPrimary}
                      variant="contained"
                      style={{ backgroundColor: "background: #383B46" }}
                      fullWidth
                    >
                      <AddIcon className={styles.actionIconPrimary} />
                      {size(store.currentCanvas) ? "Edit canvas" : "Create canvas"}
                    </Button>
                  )}
                </LinkNoStyle>
              )}
            </React.Fragment>
          </div>
        ) : (
          <React.Fragment>
            <ButtonBase
              disabled={!store.currentCollectionKits.length}
              onClick={(): void => store.collectionStore?.setSelectedCollectionKit(store.currentCollectionKits[0].id)}
              className={styles.drawerListIconBadgeContainer}
              classes={{ disabled: styles.drawerListIconButtonDisabled }}
            >
              <SideDrawerListIcon
                badgeContent={store.currentCollectionKits.length}
                disabled={!store.currentCollectionKits.length}
              />
            </ButtonBase>
            {size(store.currentCollectionKits) ? (
              <div className={styles.drawerWrap}>
                {store.currentUser && !!size(store.currentUser.collections) && (
                  <ButtonOpenCollectionsList
                    style={{
                      height: 24,
                      width: 24,
                      border: "1px solid #7d8086",
                    }}
                  />
                )}
                <Typography
                  style={{
                    whiteSpace: "pre-wrap",
                    paddingTop: 6,
                    fontSize: 10,
                    fontWeight: "bold",
                    lineHeight: "12px",
                    display: "-webkit-box",
                    WebkitBoxOrient: "vertical",
                    WebkitLineClamp: 4,
                    overflow: "hidden",
                    marginBottom: 8,
                    marginLeft: 8,
                    marginRight: 8,
                    textAlign: "center",
                    textTransform: "capitalize",
                  }}
                >
                  {store.currentCollection?.name || t("Unnamed collection")}
                </Typography>
              </div>
            ) : (
              <Typography
                style={{
                  width: "100%",
                  whiteSpace: "pre-wrap",
                  padding: 8,
                  fontSize: 10,
                  fontWeight: "bold",
                  lineHeight: "12px",
                  textAlign: "center",
                  textTransform: "capitalize",
                }}
              >
                {t("Collect items to begin")}
              </Typography>
            )}
          </React.Fragment>
        )}
      </React.Fragment>
      <List
        ref={drawerContentList}
        className={`${drawerContentListOffset ? styles.drawerContentListMarginBottom : styles.drawerContentList}`}
      >
        {store.currentCollectionKits.map(({ id, optional, kit, accessoryProducts }: any) => {
          return (
            <CollectionKitListItem
              key={id}
              collectionKitId={id}
              optional={optional}
              showButton={store.isDrawerOpen}
              numberOfAccessories={size(accessoryProducts)}
              {...kit}
            />
          );
        })}
      </List>
      <React.Fragment>
        {store.collectionHasItems && store.isDrawerOpen && isSearchView}
        {/* {!isSearchView && <AddNewItemListButton />} */}
        {store.isDrawerOpen && (
          <div className={styles.drawerContact}>
            <React.Fragment>
              {store.currentUser ? (
                <ButtonWithRoundedCorners
                  className={styles.drawerContactButton}
                  onClick={handleGetQuote}
                  disabled={!store.collectionHasItems}
                >
                  {t("Send My Quote")}
                  <ChevronRightIcon />
                </ButtonWithRoundedCorners>
              ) : (
                <ButtonWithRoundedCorners
                  onClick={(): void => history.push(LOGIN)}
                  className={styles.drawerContactButton}
                >
                  {t("Sign in / Create Account")}
                  <ChevronRightIcon />
                </ButtonWithRoundedCorners>
              )}

              <a
                href="mailto:info@Inspiringdesign.com"
                target="_blank"
                rel="noopener noreferrer"
                className={styles.textDecorationNone}
              >
                <ButtonWithRoundedCorners
                  color="secondary"
                  className={clsx(styles.contactButton, styles.contactButtonOverrides)}
                >
                  {t("Email Us for Support")}
                  <ChevronRightIcon />
                </ButtonWithRoundedCorners>
              </a>
            </React.Fragment>
          </div>
        )}
      </React.Fragment>
    </SideDrawer>
  );
});

export default SideDrawerWrapper;
function openDialog(arg0: JSX.Element) {
  throw new Error("Function not implemented.");
}
