import React from "react";
import { DialogProvider } from "./DialogManagerContext";
import { NotificationProvider } from "./NotificationManagerContext";

const ContextProviders: React.FC = ({ children }) => {
  return (
    <React.Fragment>
      <DialogProvider>
        <NotificationProvider>{children}</NotificationProvider>
      </DialogProvider>
    </React.Fragment>
  );
};

export default ContextProviders;
