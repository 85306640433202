import { Grid, Typography } from "@material-ui/core";

import AboutSection from "./AboutSection";
import LogoNavSection from "./LogoNavSection";
import PerfectKitchenSection from "./PerfectKitchenSection";
import React from "react";
import ScrollToMore from "./ScrollToMore";
import StepsSection from "./StepsSection";
import TryNowSection from "./TryNowSection";
import useScrollTrigger from "@material-ui/core/useScrollTrigger";
import { useStyles } from "styles";
import { useTranslation } from "react-i18next";

const SpashPage: React.FC = () => {
  const { t } = useTranslation();
  const styles = useStyles();
  const triggerAn = useScrollTrigger({
    disableHysteresis: true,
    threshold: 100,
  });

  const handleOnClick = (): void => {
    var toSeps = document.getElementById("toSeps");
    toSeps?.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "center",
    });
  };

  return (
    <div>
      <Grid container direction="row" justify="center">
        <Grid item className={styles.contentGroupContainer}>
          <PerfectKitchenSection />
          <AboutSection />
        </Grid>

        <Grid container item direction="row" justify="center">
          <Grid container direction="row" justify="center">
            <Typography variant="h2" className={styles.aboutSectionPrimaryText}>
              {t("about-header")}
            </Typography>
          </Grid>
          <Grid container direction="row" justify="center">
            <LogoNavSection />
          </Grid>
          <Grid container direction="row" justify="center">
            <Typography className={styles.aboutSectionSecondaryText}>{t("about-copy")}</Typography>
          </Grid>
        </Grid>

        <Grid
          container
          justify="center"
          style={{
            position: "sticky",
            bottom: "0",
            background: "#fafafa",
          }}
        >
          <Grid item style={{ textAlign: "center", padding: "20px" }}>
            <ScrollToMore onClick={handleOnClick} />
          </Grid>
        </Grid>
      </Grid>

      <StepsSection />
      <TryNowSection />
    </div>
  );
};

export default SpashPage;
