/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from "mobx-state-tree"
import { MSTGQLRef, QueryBuilder, withTypedRefs } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { CollectionModel, CollectionModelType } from "./CollectionModel"
import { CollectionModelSelector } from "./CollectionModel.base"
import { RootStoreType } from "./index"


/* The TypeScript type that explicits the refs to other models in order to prevent a circular refs issue */
type Refs = {
  collection: CollectionModelType;
}

/**
 * AttachSnapshotCollectionPayloadBase
 * auto generated base class for the model AttachSnapshotCollectionPayloadModel.
 *
 * Autogenerated return type of AttachSnapshotCollection
 */
export const AttachSnapshotCollectionPayloadModelBase = withTypedRefs<Refs>()(ModelBase
  .named('AttachSnapshotCollectionPayload')
  .props({
    __typename: types.optional(types.literal("AttachSnapshotCollectionPayload"), "AttachSnapshotCollectionPayload"),
    collection: types.union(types.undefined, MSTGQLRef(types.late((): any => CollectionModel))),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  })))

export class AttachSnapshotCollectionPayloadModelSelector extends QueryBuilder {
  collection(builder?: string | CollectionModelSelector | ((selector: CollectionModelSelector) => CollectionModelSelector)) { return this.__child(`collection`, CollectionModelSelector, builder) }
}
export function selectFromAttachSnapshotCollectionPayload() {
  return new AttachSnapshotCollectionPayloadModelSelector()
}

export const attachSnapshotCollectionPayloadModelPrimitives = selectFromAttachSnapshotCollectionPayload()
