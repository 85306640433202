import React from "react";
import { useField } from "formik";

import { FormHelperText, FormControlLabel } from "@material-ui/core";
import { FormControlLabelProps } from "@material-ui/core/FormControlLabel";

import { useStyles } from "styles";

interface FormikRadioFieldProps extends FormControlLabelProps {
  value: string;
  label: string;
  name: string;
}

const FormikRadioField: React.FC<FormikRadioFieldProps> = ({
  name,
  ...props
}) => {
  const styles = useStyles();
  const [field] = useField(name);

  return (
    <div>

      <FormControlLabel
        {...field}
        {...props}
      />

    </div>
  );
};

export default FormikRadioField;