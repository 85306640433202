import { useStore } from "@lib/hooks/useStore";
import { useNotification } from "@lib/components/NotificationManagerContext";

interface UseKitActions {
  queryKits: (next?: number | null | undefined) => Promise<any>;
  queryKitsForOptionsRelated: () => Promise<any>;
}

export const useKitActions = (): UseKitActions => {
  const store = useStore();
  const { openNotification } = useNotification();

  const queryKits = async (next: number | null | undefined): Promise<any> => {
    try {
      await store.handleQueryKits(next);
    } catch (error: any) {
      if (error?.response?.error?.message == "Couldn't find Kit with 'id'=") {
        openNotification("That item is no longer in your collection!");
      } else {
        openNotification(error?.response?.error?.message);
      }
      // console.error(error);
    }
  };

  const queryKitsForOptionsRelated = async (): Promise<any> => {
    try {
      await store.handleQueryKitsForOptionsRelated();
    } catch (error: any) {
      if (error?.response?.error?.message == "Couldn't find Kit with 'id'=") {
        openNotification("That item is no longer in your collection!");
      } else {
        openNotification(error?.response?.error?.message);
      }
      // console.error(error);
    }
  };

  return { queryKits, queryKitsForOptionsRelated };
};
