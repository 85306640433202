/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from "mobx-state-tree"
import { MSTGQLRef, QueryBuilder, withTypedRefs } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { CanvasKitModel, CanvasKitModelType } from "./CanvasKitModel"
import { CanvasKitModelSelector } from "./CanvasKitModel.base"
import { RootStoreType } from "./index"


/* The TypeScript type that explicits the refs to other models in order to prevent a circular refs issue */
type Refs = {
  canvasKit: CanvasKitModelType;
}

/**
 * DestroyCanvasKitPayloadBase
 * auto generated base class for the model DestroyCanvasKitPayloadModel.
 *
 * Autogenerated return type of DestroyCanvasKit
 */
export const DestroyCanvasKitPayloadModelBase = withTypedRefs<Refs>()(ModelBase
  .named('DestroyCanvasKitPayload')
  .props({
    __typename: types.optional(types.literal("DestroyCanvasKitPayload"), "DestroyCanvasKitPayload"),
    canvasKit: types.union(types.undefined, MSTGQLRef(types.late((): any => CanvasKitModel))),
    /** A unique identifier for the client performing the mutation. */
    clientMutationId: types.union(types.undefined, types.null, types.string),
    result: types.union(types.undefined, types.null, types.boolean),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  })))

export class DestroyCanvasKitPayloadModelSelector extends QueryBuilder {
  get clientMutationId() { return this.__attr(`clientMutationId`) }
  get result() { return this.__attr(`result`) }
  canvasKit(builder?: string | CanvasKitModelSelector | ((selector: CanvasKitModelSelector) => CanvasKitModelSelector)) { return this.__child(`canvasKit`, CanvasKitModelSelector, builder) }
}
export function selectFromDestroyCanvasKitPayload() {
  return new DestroyCanvasKitPayloadModelSelector()
}

export const destroyCanvasKitPayloadModelPrimitives = selectFromDestroyCanvasKitPayload().clientMutationId.result
