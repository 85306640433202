import React from "react";
import {
  Typography
} from "@material-ui/core";

import UserPageContainer from "./UserPageContainer";


interface DistributorThankYouPageProps { }

const DistributorOutSideAreaPage: React.FC<DistributorThankYouPageProps> = () => {

  return (
    <UserPageContainer>
      <Typography variant="h2" color="primary" style={{
        paddingTop: '50px',
        fontWeight: 'bolder'
      }}>
        Hi,
      </Typography>
      <Typography variant="h3" color="primary" style={{
        paddingTop: '25px',
        paddingBottom: '50px',
        lineHeight: '1.4'
      }}>
        Your project is outside the Roth Living territory. On your behalf, Roth Living has sent your collection to the local Sub-Zero, Wolf, and Cove distributor. You will be contacted via email for further assistance.
      </Typography>
    </UserPageContainer>
  );
};

export default DistributorOutSideAreaPage;
