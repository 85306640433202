/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from "mobx-state-tree"
import { MSTGQLRef, QueryBuilder, withTypedRefs } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { AggregationModel, AggregationModelType } from "./AggregationModel"
import { AggregationModelSelector } from "./AggregationModel.base"
import { KitEdgeModel, KitEdgeModelType } from "./KitEdgeModel"
import { KitEdgeModelSelector } from "./KitEdgeModel.base"
import { PageInfoModel, PageInfoModelType } from "./PageInfoModel"
import { PageInfoModelSelector } from "./PageInfoModel.base"
import { RootStoreType } from "./index"


/* The TypeScript type that explicits the refs to other models in order to prevent a circular refs issue */
type Refs = {
  aggregations: AggregationModelType;
  pageInfo: PageInfoModelType;
}

/**
 * KitSearchBase
 * auto generated base class for the model KitSearchModel.
 */
export const KitSearchModelBase = withTypedRefs<Refs>()(ModelBase
  .named('KitSearch')
  .props({
    __typename: types.optional(types.literal("KitSearch"), "KitSearch"),
    aggregations: types.union(types.undefined, types.null, MSTGQLRef(types.late((): any => AggregationModel))),
    categoryHash: types.union(types.undefined, types.string),
    edges: types.union(types.undefined, types.null, types.array(types.late((): any => KitEdgeModel))),
    pageInfo: types.union(types.undefined, types.null, MSTGQLRef(types.late((): any => PageInfoModel))),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  })))

export class KitSearchModelSelector extends QueryBuilder {
  get categoryHash() { return this.__attr(`categoryHash`) }
  aggregations(builder?: string | AggregationModelSelector | ((selector: AggregationModelSelector) => AggregationModelSelector)) { return this.__child(`aggregations`, AggregationModelSelector, builder) }
  edges(builder?: string | KitEdgeModelSelector | ((selector: KitEdgeModelSelector) => KitEdgeModelSelector)) { return this.__child(`edges`, KitEdgeModelSelector, builder) }
  pageInfo(builder?: string | PageInfoModelSelector | ((selector: PageInfoModelSelector) => PageInfoModelSelector)) { return this.__child(`pageInfo`, PageInfoModelSelector, builder) }
}
export function selectFromKitSearch() {
  return new KitSearchModelSelector()
}

export const kitSearchModelPrimitives = selectFromKitSearch().categoryHash
