import React from "react";
import { useTranslation } from "react-i18next";
import { useDialogManager } from "@lib/components/DialogManagerContext";
import { useStyles } from "styles";
import DialogModal from "@lib/components/DialogModal";
import ButtonWithRoundedCorners from "@lib/components/ButtonWithRoundedCorners";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";

export interface ConfirmAttributes {
  id: string;
}

interface ConfirmDialogProps {
  onConfirm: (param?: any) => void;
  title?: string;
  titleVariant?: string;
  view?: string;
  initialValues?: ConfirmAttributes;
  onClose?: () => void;
}

const ConfirmDialog: React.FC<ConfirmDialogProps> = ({ onConfirm, title, titleVariant, onClose, view }) => {
  const { closeDialog } = useDialogManager();
  const { t } = useTranslation();
  const styles = useStyles();

  return (
    <React.Fragment>
      <DialogModal
        title={title}
        titleVariant={titleVariant}
        onClose={onClose || closeDialog}
        fullWidthBottomButton={false}
        dialogActionButtons={
          <React.Fragment>
            <ButtonWithRoundedCorners
              variant="contained"
              color="secondary"
              onClick={onConfirm}
              endIcon={<CheckIcon />}
              style={{
                padding: "6px 10px",
                minWidth: "100px",
                display: "inline-flex",
                justifyContent: "space-around",
                alignItems: "center",
              }}
            >
              {t("Yes")}
            </ButtonWithRoundedCorners>
            <ButtonWithRoundedCorners
              variant="outlined"
              color="secondary"
              onClick={(): void => onClose()}
              endIcon={<ClearIcon color="secondary" />}
              className={styles.basicButton}
              style={{
                padding: "6px 10px",
                minWidth: "100px",
                display: "inline-flex",
                justifyContent: "space-around",
                alignItems: "center",
              }}
            >
              {t("No")}
            </ButtonWithRoundedCorners>
          </React.Fragment>
        }
      />
    </React.Fragment>
  );
};

export default ConfirmDialog;
