import React from "react";
import { useTranslation, Trans } from "react-i18next";

import {
  Typography,
  Fade,
  Paper,
  Popper,
  PopperPlacementType,
} from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";

import { useStyles } from "styles";
import { CollectionModelType } from "models";
import ButtonWithRoundedCorners from "@lib/components/ButtonWithRoundedCorners";

interface DeletePopperProps {
  open: boolean;
  anchorEl: HTMLButtonElement | null;
  placement: PopperPlacementType;
  selectedCollection: CollectionModelType | null;
  setOpen: (bool: boolean) => void;
  handleDeleteConfirm: () => Promise<any>;
}

const DeletePopper: React.FC<DeletePopperProps> = ({
  open,
  anchorEl,
  placement,
  setOpen,
  selectedCollection,
  handleDeleteConfirm,
}) => {
  const { t } = useTranslation();

  const styles = useStyles();

  return (
    <Popper
      open={open}
      anchorEl={anchorEl}
      placement={placement}
      transition
      style={{ zIndex: 1400 }}
    >
      {({ TransitionProps }): React.ReactNode => (
        <Fade {...TransitionProps} timeout={350}>
          <Paper className={styles.deletePopper}>
            <Typography variant="h3" className={styles.deletePopperText}>
              <Trans i18nKey="deleteCollectionConfirm">
                Are you sure you would like to delete &nbsp;
                <strong>
                  {{
                    collectionName:
                      selectedCollection?.name || t("Unnamed collection"),
                  }}
                </strong>
                ?
              </Trans>
            </Typography>
            <ButtonWithRoundedCorners
              variant="contained"
              color="secondary"
              onClick={handleDeleteConfirm}
              endIcon={<CheckIcon />}
              style={{
                padding: '10px 10px',
                minWidth: '100px',
                display: 'inline-flex',
                justifyContent: 'space-around',
                alignItems: 'center',
              }}
            >
              {t("Yes")}
            </ButtonWithRoundedCorners>
            <ButtonWithRoundedCorners
              variant="outlined"
              color="secondary"
              onClick={(): void => setOpen(false)}
              endIcon={<ClearIcon color="secondary" />}
              className={styles.basicButton}
              classes={{ label: styles.colorWhite }}
              style={{
                padding: '10px 10px',
                minWidth: '100px',
                display: 'inline-flex',
                justifyContent: 'space-around',
                alignItems: 'center',
              }}
            >
              {t("No")}
            </ButtonWithRoundedCorners>
          </Paper>
        </Fade>
      )}
    </Popper>
  );
};

export default DeletePopper;
