export const ROOT = "/";
export const CANVAS = "/build/canvas";

export const BUILD_ALL = "/build/all";
export const BUILD_SUBZERO = "/build/subzero";
export const BUILD_WOLF = "/build/wolf";
export const BUILD_COVE = "/build/cove";
export const BUILD_BEST = "/build/best";

export const DYNAMIC_BUILD_BRAND = "/build/:brand";
export const DYNAMIC_BUILD_BRAND_DETAILS = "/build/:brand/details/:product_id?/:instance_id?";

export const LOGIN = "/login";
export const SEND_RESET_PASSWORD_LINK = "/reset-password";
export const SEND_RESET_THANK_YOU = "/reset-password-thank-you";
export const RESET_PASSWORD = "/edit-password";
export const CREATE_ACCOUNT = "/create-account";

export const SEND_MY_QUOTE = "/send-my-quote";
export const DISTRIBUTOR_IN_AREA = "/distributor-in-area";
export const DISTRIBUTOR_OUTSIDE_AREA = "/distributor-outside-area";
export const ERROR = "/error";
export const USER_PROFILE = "/profile";
export const TERMS = "/terms-conditions";
export const PRIVACY = "/privacy-policy";
