import React from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { Button, FormControlLabel, Switch, Typography } from "@material-ui/core";
import { useStyles } from "styles";
import { Icon } from "@material-ui/core";
import FullscreenIcon from "@material-ui/icons/Fullscreen";
import downloadFolderIconSvg from "@lib/icons/download-folder-icon.svg";
import duplicateIconSvg from "@lib/icons/duplicate-icon.svg";
import NewCanvasDialog from "@lib/components/NewCanvasDialog";
import { useDialogManager } from "@lib/components/DialogManagerContext";
import { useNotification } from "@lib/components/NotificationManagerContext";
import { useCanvasActions } from "@lib/hooks/useCanvasActions";
import { useStore } from "@lib/hooks/useStore";
import { useCanvasKitActions } from "@lib/hooks/useCanvasKitActions";
import { useHistory } from "react-router";
import { LOGIN } from "@lib/utils/routes";
import { FormikValues } from "formik/dist/types";
import { values } from "lodash";

interface PreviewWallNavHeader {
  setIsFull: (isFull: boolean) => void;
  setShowGrid: (showGrid: boolean) => void;
  showGrid: boolean;
  disableScreenshot: boolean;
}

const PreviewWallNavHeader: React.FC<PreviewWallNavHeader> = observer(
  ({ setIsFull, setShowGrid, showGrid, disableScreenshot }) => {
    const styles = useStyles();
    const store = useStore();
    const { createCanvas, loading } = useCanvasActions();

    const { createCanvasKit, updateCanvasKit, destroyCanvasKit, queryCanvasKits, destroyCanvasKits } =
      useCanvasKitActions();
    const { t } = useTranslation();
    const { openDialog, closeDialog } = useDialogManager();
    const { openNotification } = useNotification();

    const history = useHistory();

    const DownloadFolderIcon = () => (
      <Icon className={styles.actionIconSecondary}>
        <img style={{ maxWidth: "100%" }} src={downloadFolderIconSvg} />
      </Icon>
    );

    const DuplicateIcon = () => (
      <Icon className={styles.actionIconSecondary}>
        <img style={{ maxWidth: "100%" }} src={duplicateIconSvg} />
      </Icon>
    );

    const handleCreateCanvasForm = async ({ name }: FormikValues): Promise<any> => {
      if (confirm("By creating a new canvas any canvas kits not saved will be lost. continue?")) {
        await createCanvas(name);
        await queryCanvasKits();
        store.clearWallContent();
      }

      closeDialog();
    };

    const handleCreateCanvas = () => {
      if (store.currentUser) {
        if (store?.userCanvasList.length != 5) {
          openDialog(<NewCanvasDialog onConfirm={handleCreateCanvasForm} />);
        } else {
          alert("You Have reached your canvas build limit");
        }
      } else {
        history.push(LOGIN);
      }
    };

    const handleUpdateCreateCanvass = async () => {
      let currentWallItems = Object.values(store.previewWallContents);

      if (store.currentUser) {
        currentWallItems.forEach(async (value: any) => {
          let collectionKitId = value.collectionKitId;
          let canvasId = store.currentCanvas.id;
          let canvasKitId = value.canvasKitId;
          let kitId = value.id;
          let x = String(value.left);
          let y = String(value.top);

          if (value?.canvasKitId && value.delete != true) {
            await updateCanvasKit(canvasKitId, x, y, String(store.previewWallScale));
          } else if (value.delete != true) {
            await createCanvasKit(canvasId, collectionKitId, kitId, x, y, String(store.previewWallScale));
          } else if (value.delete == true) {
            await destroyCanvasKit(value?.canvasKitId);
            store.destroyPreviewWallContent(collectionKitId);
          }
        });
      } else {
        history.push(LOGIN);
      }
    };

    return (
      <div
        className={styles.navButtonContainer}
        style={{ margin: "5px auto", display: "flex", justifyContent: "space-between" }}
      >
        <span style={{ display: "flex", alignItems: "center" }}>
          <FormControlLabel
            className={styles.showGridSwitch}
            control={
              <Switch
                checked={showGrid}
                onChange={(): void => setShowGrid(!showGrid)}
                value="showGrid"
                color="primary"
              />
            }
            label={
              <Typography variant="button" color="primary" className={styles.showGridSwitchText}>
                {showGrid ? t("Hide grid") : t("Show grid")}
              </Typography>
            }
          />
          <Typography variant="button" className={styles.gridIncrements} style={{ marginRight: 25 }}>
            {t("In 1' increments")}
          </Typography>

          <Button onClick={(): void => setIsFull(true)}>
            <FullscreenIcon color="secondary" fontSize="large" style={{ marginRight: 8, marginLeft: -8 }} />
            {t("View full screen")}
          </Button>
        </span>
        <span>
          <Button
            disabled={!store.currentUser || !store.currentCollection || !store.currentCanvas}
            style={{ height: "auto" }}
            onClick={handleUpdateCreateCanvass}
          >
            <span className={styles.stackedBtn}>
              <DownloadFolderIcon />
              <span>Save Canvas</span>
            </span>
          </Button>
          <Button
            style={{ height: "auto" }}
            disabled={!store.currentUser || !store.currentCollection}
            onClick={handleCreateCanvas}
          >
            <span className={styles.stackedBtn}>
              <DuplicateIcon />
              <span>Create Canvas</span>
            </span>
          </Button>
        </span>
      </div>
    );
  }
);

export default PreviewWallNavHeader;
