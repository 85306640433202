import React from "react";
import { BUILD_ALL } from "@lib/utils/routes";
import { Typography } from "@material-ui/core";

import UserPageContainer from "./UserPageContainer";
import { UserModelType } from "models";
import { useStyles } from "styles";
import { useHistory } from "react-router";

interface ResetUserPasswordThankYouPageProps {
  user: UserModelType;
}

const ResetUserPasswordThankYouPage: React.FC<ResetUserPasswordThankYouPageProps> = ({ user }) => {
  const styles = useStyles();
  const history = useHistory();

  const modalCose = (): void => {
    history.push(BUILD_ALL);
  };

  return (
    <UserPageContainer onClose={modalCose}>
      <Typography
        variant="h3"
        color="primary"
        style={{
          paddingTop: "50px",
          paddingBottom: "50px",
        }}
      >
        Thank you,
        <br />
        Password reset link has been sent to your email.
      </Typography>
    </UserPageContainer>
  );
};

export default ResetUserPasswordThankYouPage;
