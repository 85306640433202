import {
  BASE_WALL_HEIGHT,
  BASE_WALL_WIDTH,
  calculateWallScale,
  updateWallItemsLocations,
} from "@lib/utils/wallHelpers";
import {
  COLLECTION_CANVAS_KIT_FRAGMENT,
  COLLECTION_COLLECTION_KIT_FRAGMENT,
  KITS_OPTIONS_RELATED_FRAGMENT,
  KIT_SEARCH_FRAGMENT,
} from "@lib/utils/queryFragments";
import {
  CanvasKitModel,
  CanvasKitModelType,
  CanvasModel,
  CanvasModelType,
  CollectionKitModel,
  CollectionModel,
  CollectionModelType,
  KitEdgeModelType,
  KitModel,
  UserModel,
  UserModelType,
} from "./index";
import { Instance, applySnapshot, destroy, flow, getEnv, getSnapshot, types } from "mobx-state-tree";
import { get as _get, values as _values, omit, size } from "lodash";
import { keys, values } from "mobx";
import { sortBySize, sortByUpdatedAt } from "@lib/utils/sortHelpers";

import { LoadingModel } from "./LoadingModel";
import { PrevWallPopoverSettings } from "./PrevWallPopoverSettings";
import { RootStoreBase } from "./RootStore.base";
import { SearchTermsModel } from "./SearchTermsModel";
import produce from "immer";
import { CollectionAttributes } from "@lib/components/CollectionForm";
import { Query } from "mst-gql";
import { useCollectionActions } from "@lib/hooks/useCollectionActions";

//Default Items should be divisable by 2 and 3 to account for our row widths
// 12 / 18 / 24
export const ITEMS_PER_QUERY = 12;

const valueToNumberCategories = ["height", "width", "depth"];

export interface RootStoreType extends Instance<typeof RootStore.Type> {}

export const RootStore: any = RootStoreBase.props({
  loading: types.optional(LoadingModel, {}),
  userStore: types.safeReference(UserModel),
  searchResults: types.map(types.safeReference(KitModel)),
  searchTerms: types.optional(SearchTermsModel, {}),
  searchValue: types.optional(types.string, ""),
  currentFamilyValue: types.optional(types.string, ""),
  categories: types.optional(SearchTermsModel, {}),
  prevWallPopoverSettings: types.optional(PrevWallPopoverSettings, {
    text: "",
    open: true,
    direction: "up",
    time: 1000,
  }),
  currentUser: types.maybe(types.reference(UserModel)),
  currentCollection: types.safeReference(CollectionModel),
  collectionStore: types.safeReference(CollectionModel),
  collectionKitStore: types.safeReference(CollectionKitModel),
  currentCanvas: types.safeReference(CanvasModel),
  canvasStore: types.safeReference(CanvasModel),
  canvasKitStore: types.safeReference(CanvasKitModel),
})
  .volatile((self) => ({
    initialStoreSnapshot: getSnapshot(self),
    isDrawerOpen: false,
    previewWallContents: {},
    previewWallScale: 5,
    isFullScreen: false,
    resetCategories: false,
    changeCategories: false,
    isCanvasContentSaved: false,
  }))
  .views((self) => ({
    get currentCategories() {
      return values(self.categories.superCategory);
    },
    get categoryFacets() {
      return values(self.categories);
    },
    get currentFamily() {
      return values(self.searchTerms.family)[0];
    },
    get searchFacets() {
      if (!size(self.searchTerms.family)) return {};

      const aggregations: any = self.aggregations.get("1") || {};

      const facets = Object.keys(aggregations).reduce((acc, cat) => {
        if (cat === "id" || cat === "__typename" || cat === "family" || cat === "depth") {
          return acc;
        }

        const isNumber = valueToNumberCategories.some((val) => val === cat);

        const values =
          aggregations[cat] &&
          aggregations[cat].buckets.toJS().map((val: any) => ({
            key: isNumber ? parseInt(val.key) : val.key,
            count: val.docCount,
          }));

        return { ...acc, [cat]: values };
      }, {});

      if (facets.width) {
        facets.width = sortBySize(facets.width, false);
      }

      if (facets.height) {
        facets.height = sortBySize(facets.height, false);
      }

      return facets;
    },
    get searchTermValues() {
      const terms = keys(self.searchTerms).reduce((acc: any, item: any) => {
        const term = self.searchTerms[item].toJS();
        if (size(term)) {
          acc[item] = term;
        }
        return acc;
      }, {});

      return terms;
    },
    get selectedSearchTerms(): { facet: string; value: string }[] {
      return Object.keys(self.searchTermValues).reduce((acc: any, key: any) => {
        const termValues = self.searchTermValues[key].map((value: any) => ({
          facet: key,
          value,
        }));

        return [...acc, ...termValues];
      }, []);
    },
    get previewWallHeight() {
      return BASE_WALL_HEIGHT * self.previewWallScale;
    },
    get previewWallWidth() {
      return BASE_WALL_WIDTH * self.previewWallScale;
    },
    get collectionHasItems() {
      return !!_get(self.currentCollection, "collectionKits.length", 0);
    },
    get selectedCollectionKit() {
      return self.collectionStore?.selectedCollectionKit;
    },
    get currentCollectionKits() {
      if (!self.currentCollection?.collectionKits) return [];
      const collectionKits = values(self.currentCollection?.collectionKits);
      return sortByUpdatedAt([...collectionKits]);
    },
    get userCollectionList() {
      const collectionList = values(self.currentUser?.collections);
      return [...collectionList].sort((a, b) => {
        if (!a.name) return 1;
        if (!b.name) return -1;
        if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
        if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
        return 0;
      });
    },
    get userCanvasList() {
      const canvasList = values(self.currentUser?.canvass);
      return [...canvasList].sort((a, b) => {
        if (!a.name) return 1;
        if (!b.name) return -1;
        if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
        if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
        return 0;
      });
    },
    get recentUserCollectionList() {
      const collectionList = values(self.currentUser?.collections);
      return sortByUpdatedAt([...collectionList], false).slice(0, 3);
    },
    get filteredKits() {
      return values(self.searchResults);
    },
    get optionsAndAccessoriesKits() {
      const kit = self.collectionStore?.selectedCollectionKit?.kit;
      if (kit?.hasLoaded("optionKits") && kit?.hasLoaded("accessoryKits")) {
        const options = values(kit.optionKits).map((option) => ({
          ...option,
          isOption: true,
        }));
        const acessories = values(kit.accessoryKits);
        return [...acessories, ...options];
      }
      return [];
    },
    get relatedKits() {
      const kit = self.collectionStore?.selectedCollectionKit?.kit;
      if (kit?.hasLoaded("relatedKits")) {
        return values(kit.relatedKits);
      }
      return [];
    },
    get canvasHasItems() {
      return !!_get(self.currentCanvas, "canvaseKits.length", 0);
    },
    get selectedCanvaseKit() {
      return self.canvasStore?.selectedCanvasKit;
    },
    get currentCanvasKits() {
      if (!self.currentCanvas?.canvasKits) return [];
      const canvaseKits = values(self.currentCanvas?.canvasKits);
      return sortByUpdatedAt([...canvaseKits]);
    },
    get recentUserCanvasList() {
      const canvasList = values(self.currentUser?.canvass);
      return sortByUpdatedAt([...canvasList], false).slice(0, 3);
    },
    get currentFamilyFiltersList() {
      let family = self?.currentFamily?.replace(/\s+/g, "_").toLowerCase();
      let familyObj = self?.categoriesFilters ? self?.categoriesFilters[family] : null;
      let familyObjArr: any = [];

      if (familyObj) {
        familyObjArr = Object.keys(familyObj).map((key, index) => {
          let o = familyObj[key];

          let items: any = Object.keys(o).map((k, i) => {
            let w = _.uniq(o[k]);

            var wNull = w.filter(function (el) {
              return el != null;
            });

            let width = wNull
              .map((kw: any, iw: any) => {
                return {
                  id: iw,
                  label: kw,
                  type: "width",
                  seleted: false,
                  parent: k,
                };
              })
              .sort((a: any, b: any) => parseFloat(a.label) - parseFloat(b.label));

            return {
              id: i,
              label: k,
              width: width,
              type: "category",
              seleted: false,
            };
          });

          const pChildren = items.filter((item: any) => {
            return item.label != "width";
          });

          const pWidth = items
            .filter((item: any) => {
              return item.label == "width";
            })
            .flatMap(({ width }: any) => {
              return width;
            });

          const pWidthRename = pWidth
            .map((item: any, index: any) => {
              item.parent = key;
              return item;
            })
            .sort((a: any, b: any) => parseFloat(a.label) - parseFloat(b.label));

          const obj: any = {
            id: index,
            label: key,
            items: pChildren,
            type: "superCategory",
            seleted: false,
            width: pWidthRename,
            indeterminate: false,
          };

          return obj;
        });
      } else {
        familyObjArr = [];
      }

      const familyObjArrFiltered = familyObjArr.filter((item: any) => {
        return item.label != "";
      });

      return familyObjArrFiltered;
    },
  }))
  .actions((self: any) => {
    const findCanvasKit = (id: number) => {
      self.currentCanvas?.canvasKits.forEach((value: any) => {
        if (value.id == id) {
          return true;
        }
      });

      return false;
    };

    const openPrevWallPopover = (obj: any) => {
      self.prevWallPopoverSettings = {
        text: self.currentCanvas.name + " " + obj.text,
        open: true,
        direction: "up",
        time: obj.time,
      };

      setTimeout(() => {
        self.updatePrevWallPopoverSettings({
          text: ".............",
          open: false,
          direction: "down",
          time: 0,
        });
      }, self.prevWallPopoverSettings.time);
    };

    const updatePrevWallPopoverSettings = (obj: any) => {
      self.prevWallPopoverSettings = obj;
    };

    const queryCategories = () => {
      return self.query<{}>(`query {categories}`, null, undefined);
    };

    const setFilterCategories = (val: any) => {
      self.categoriesFilters = val;
    };

    const setLoading = (key: string, value: boolean) => {
      self.loading[key] = value;
    };

    const setUserLocalStorage = () => {
      const user = { ...self.currentUser, collections: [], canvass: [] };
      localStorage.setItem("dssUser", JSON.stringify(user));
    };

    const setUserToken = () => {
      const token = self.currentUser?.token;

      getEnv(self).gqlHttpClient.setHeaders({
        authorization: token ? `Bearer ${token}` : undefined,
      });
    };

    const setCollectionForLocalStorage = async (name: any = "Unnamed Collection") => {
      if (localStorage.getItem("dssTempCollection") === null) {
        try {
          const response = await self.collectionStore.createCollection(name);
          self.setCurrentCollection(response.createCollection.collection);
          return response;
        } catch (e) {
          console.log(e);
        }
      }
    };

    const queryCollectionLocalStorage = async (id: any) => {
      try {
        const response = await self.handleQueryCollectionKits(id);

        self.setCurrentCollection(response.collection);
        return response;
      } catch (e) {
        console.log(e);
      }
    };

    const updateCollectionLocalStorage = async (id: any, name: any = "Unnamed Collection") => {
      try {
        const response = await self.collectionStore.updateCollection({ id, name });
        self.setCurrentCollection(response?.createCollection?.collection);
        return response;
      } catch (e) {
        console.log(e);
      }
    };

    const setCurrentFamily = (val: string) => {
      self.currentFamilyValue = val;
    };

    const setSearchValue = (val: string) => {
      self.searchValue = val;
    };

    const clearSeachValue = () => {
      self.searchResults.clear();
    };

    const getSearchValue = () => {
      return self.searchValue;
    };

    const setSearchTerms = (val: { [key: string]: any }, reset = false) => {
      if (reset) {
        self.searchTerms = {};
      }

      self.searchTerms = { ...self.searchTerms, ...val };
    };

    const isFiltersChecked = () => {
      let pList = self.currentFamilyFiltersList;
      for (let p = 0; p < pList.length; p++) {
        const parentCb = pList[p];

        if (parentCb.seleted == true && parentCb.items.length == 0) {
          return true;
        }

        if (parentCb.seleted == true && parentCb.items.length > 0) {
          let cList = parentCb.items;
          for (let c = 0; c < cList.length; c++) {
            const childCb = cList[c];
            if (childCb.seleted == true) {
              return true;
            }
          }
        }
      }
    };

    const updateSearchTerms = (cat?: string, value?: string): void => {
      self.searchResults.clear();

      let superCategory: string[] = [];
      let category: string[] = [];
      let width: number[] = [];

      self?.currentFamilyFiltersList?.forEach((parent: any) => {
        if (parent.seleted == true && parent.type == "superCategory") {
          superCategory.push(parent.label);

          if (parent?.items?.length > 0) {
            parent.items.forEach((item: any) => {
              if (item.seleted == true && item.type == "category") category.push(item.label);

              item?.width.forEach((item: any) => {
                if (item.seleted == true && item.type == "width") width.push(item.label);
              });
            });
          }

          if (parent?.width?.length > 0) {
            parent.width.forEach((item: any) => {
              if (item.seleted == true && item.type == "width") width.push(item.label);
            });
          }
        }
      });

      setSearchTerms({
        superCategory: _.uniq(superCategory),
        category: _.uniq(category),
        width: _.uniq(width),
      });
    };

    const setIsFullScreen = (isFull: boolean) => {
      self.isFullScreen = isFull;
    };

    const updatePreviewWallContents = (content: { [key: string]: any }) => {
      self.previewWallContents = content;
    };

    const addPreviewWallContent = (key: any, wallItem: { [key: string]: any }) => {
      const valuesArr = _values(self.previewWallContents);
      const exists = valuesArr.some((item: any) => key === item.collectionKitId);
      self.previewWallContents = { ...self.previewWallContents, ...wallItem };
    };

    const removePreviewWallContent = (key: string) => {
      if (self.previewWallContents[key]?.canvasKitId == null) {
        self.previewWallContents = omit(self.previewWallContents, key);
      } else {
        const updatedItems = produce(self.previewWallContents, (draft: any) => {
          draft[key].delete = true;
        });
        self.updatePreviewWallContents(updatedItems);
      }
      //console.log('removePreviewWallContent', self.previewWallContents[key]);
    };

    const destroyPreviewWallContent = (key: string) => {
      self.previewWallContents = omit(self.previewWallContents, key);
    };

    const clearWallContent = () => {
      self.previewWallContents = [];
    };

    const updatePreviewWallItemsLocations = (prevScale: number) => {
      self.previewWallContents = updateWallItemsLocations(prevScale, self.previewWallScale, self.previewWallContents);
    };

    const calculatePreviewWallScale = (width: number) => {
      const previousScale = self.previewWallScale;
      self.previewWallScale = calculateWallScale(width, self.isFullScreen);

      if (!size(Object.keys(self.previewWallContents))) return;

      updatePreviewWallItemsLocations(previousScale);
    };

    const setIsDrawerOpen = (bool: boolean) => {
      self.isDrawerOpen = bool;
    };

    const setCurrentUser = (user: UserModelType) => {
      self.currentUser = user;
    };

    const setCurrentCollection = (collection: CollectionModelType) => {
      self.currentCollection = collection;
      self.previewWallContents = [];
    };

    const setCurrentCanvas = (canvas: CanvasModelType) => {
      self.currentCanvas = canvas;
      self.previewWallContents = [];
    };

    const removeCollectionKitFromStore = (id: string) => {
      self.collectionkits.delete(id);
    };

    const removeCanvasKitFromStore = (id: string) => {
      self.collectionkits.delete(id);
    };

    const addCurrentCollectionToUser = () => {
      if (self.currentUser && self.currentCollection) {
        const alreadyAssociated = self.currentUser.collections.some((col: any) => col.id === self.currentCollection.id);

        !alreadyAssociated && self.currentUser.collections.push(self.currentCollection);
      }
    };

    const addCurrentCanvasToUser = () => {
      if (self.currentUser && self.currentCanvas) {
        if (!self.currentUser.canvass) {
          self.currentUser.canvass = [];
        }

        const alreadyAssociated = self.currentUser.canvass.some((col: any) => col.id === self.currentCanvas.id);

        !alreadyAssociated && self.currentUser.canvass.push(self.currentCanvas);
      }
    };

    const viewKitDetails = (kitId: string) => {
      const kit: any = self.kits.get(kitId);
      const key: string = Date.now().toString();
      self.collectionkits.set(key, CollectionKitModel.create({ id: key, kit }));
      self.collectionStore?.setSelectedCollectionKit(key);
      return kit;
    };

    const handleDestroyCollection = (collection: CollectionModelType) => {
      const input = {
        id: collection.id,
        clientMutationId: "destroyCollection",
      };

      return self.mutateDestroyCollection({ input }, undefined, () => {
        // optimistic update
        // remove collection kits that are in the collection
        values(self.collectionkits).forEach((collectionKit) => {
          if (collectionKit.collection?.id === collection.id) {
            destroy(collectionKit);
          }
        });

        // remove collection reference from user collections
        self.currentUser.collections = self.currentUser?.collections.filter((col: any) => col.id !== collection.id);

        // finally, destroy collection
        destroy(collection);
      });
    };

    const handleDestroyCollectionKit = (id: string) => {
      if (self.selectedCollectionKit && self.selectedCollectionKit.id === id) {
        const newSelectedKit = self.currentCollectionKits.find((kit: any) => kit.id !== id);
        self.collectionStore?.setSelectedCollectionKit(newSelectedKit?.id);
      }

      return self.mutateDestroyCollectionKit({ input: { id, clientMutationId: id } }, undefined, () => {
        self.currentCollection.collectionKits = self.currentCollection.collectionKits.filter(
          (colKit: any) => colKit.id !== id
        );
        self.collectionkits.delete(id);
        removePreviewWallContent(id);
      });
    };

    const handleDestroyCanvas = (canvas: CanvasModelType) => {
      const input = {
        id: canvas.id,
        clientMutationId: "destroyCanvas",
      };

      return self.mutateDestroyCanvas({ input }, undefined, () => {
        // optimistic update
        // remove canvas kits that are in the canvas
        values(self.canvaskits).forEach((canvasKit) => {
          if (canvasKit.canvas?.id === canvas.id) {
            destroy(canvasKit);
          }
        });

        //remove canvas reference from user canvass
        self.currentUser.canvass = self.currentUser?.canvass.filter((col: any) => col.id !== canvas.id);

        //finally, destroy canvas
        destroy(canvas);
      });
    };

    const logoutUser = flow(function* logoutUser() {
      const loadedKits = getSnapshot(self.kits);
      const searchResults = getSnapshot(self.searchResults);

      try {
        const input = {
          clientMutationId: "LogoutUser",
        };
        const response: any = yield self.mutateLogout({ input }, (res: any) => res.result.clientMutationId);

        self.currentUser = undefined;
        setUserToken();
        localStorage.removeItem("dssUser");
        localStorage.removeItem("dssTempCollection");

        applySnapshot(self, self.initialStoreSnapshot);
        applySnapshot(self.kits, loadedKits);
        applySnapshot(self.searchResults, searchResults);
        self.clearWallContent();

        return response;
      } catch (error) {
        console.error("Logout: ", error);
        throw error;
      }
    });

    const createCanvasKits = flow(function* createCanvasKits(input: any[]): Generator {
      try {
        const response = yield self.mutateCreateCanvasKits({ input }, (res: any) => res.result);

        return response;
      } catch (error) {
        console.error(error);
        throw error;
      }
    });

    const handleQueryCollectionKits = flow(function* handleQueryCollectionKits(id = null) {
      let collectionId = "";

      if (id != null) {
        collectionId = id;
      } else {
        collectionId = self.currentCollection?.id && "";
      }

      try {
        const response: any = yield self.queryCollection(
          {
            id: collectionId,
            clientMutationId: "queryCollection",
          },
          COLLECTION_COLLECTION_KIT_FRAGMENT,
          { fetchPolicy: "cache-first" }
        );

        return response;
      } catch (error) {
        console.error("Logout: ", error);
        throw error;
      }
    });

    const handleQueryGroups = () => {
      return self.queryGroups(undefined, (group: any) => group.sortOrder.title, {
        fetchPolicy: "cache-first",
      });
    };

    const resetAggregations = () => {
      self.aggregations.clear();
    };

    const resetCollections = () => {
      self.collections.clear();
    };

    const resetCanvass = () => {
      self.canvass.clear();
    };

    const handleResetCategories = (bool = true) => {
      self.resetCategories = bool;
    };

    const handleChangeCategories = (bool = true) => {
      self.changeCategories = bool;
    };

    const updateCategories = (response: any) => {
      const categories = keys(response.kits.aggregations).reduce((acc: any, key: any) => {
        const isValid = typeof response.kits.aggregations[key] === "object";

        if (!acc[key] && isValid) {
          acc[key] = response.kits.aggregations[key].buckets.map(({ key }) => {
            if (parseInt(key)) return parseInt(key);

            return key;
          });
        }

        return acc;
      }, {});

      if (self.resetCategories) {
        self.categories = categories;
        self.resetCategories = false;
      } else if (self.changeCategories) {
        const updatedCategories = {
          ...categories,
          category: self.categories.category,
        };
        self.categories = updatedCategories;
        self.changeCategories = false;
      }
    };

    const handleQueryKits = flow(function* handleQueryKits(next?: number | null | undefined) {
      try {
        self.loading.kits = true;

        if (self.searchValue == null) {
          self.searchResults.clear();
        }

        const response = yield self.queryKits(
          {
            items: ITEMS_PER_QUERY,
            page: next || 1,
            search: self.searchValue.length >= 0 ? self.searchValue : "",
            terms: self.searchTermValues,
          },
          KIT_SEARCH_FRAGMENT,
          {
            fetchPolicy: self.resetCategories || self.changeCategories ? "network-only" : "cache-and-network",
          }
        );

        if (self.resetCategories || self.changeCategories) {
          self.searchResults.clear();
        }

        updateCategories(response);

        response.kits.edges.forEach(({ node }: KitEdgeModelType) => {
          self.searchResults.set(node.id, node);
        });

        return response;
      } catch (error) {
        throw error;
      } finally {
        self.loading.kits = false;
      }
    });

    const handleQueryKitsForOptionsRelated = flow(function* handleQueryKitsForOptionsRelated() {
      try {
        self.loading.optionsRelatedKits = true;
        const id = self.selectedCollectionKit?.kit.id || "";
        return yield self.queryKit({ id }, KITS_OPTIONS_RELATED_FRAGMENT, {
          fetchPolicy: "cache-first",
        });
      } catch (error) {
        throw error;
      } finally {
        self.loading.optionsRelatedKits = false;
      }
    });

    const queryCanvasKits = flow(function* queryCanvasKits() {
      try {
        const canvasId = self.currentCanvas?.id || null;
        const collectionId = self.currentCollection?.id || null;

        if (canvasId && collectionId) {
          const response = yield self.queryCanvas(
            {
              id: canvasId,
              collectionId: collectionId,
            },
            COLLECTION_CANVAS_KIT_FRAGMENT,
            { fetchPolicy: "cache-first" }
          );

          return response;
        } else {
          return [];
        }
      } catch (error) {
        throw error;
      } finally {
        // self.loading.kits = false;
      }
    });

    const afterCreate = flow(function* afterCreate() {
      try {
        if (localStorage.getItem("dssUser")) {
          const user = JSON.parse(localStorage.getItem("dssUser") || "");

          if (user) {
            self.users.set(user.id, user);
            const userRef = self.users.get(user.id);
            userRef && setCurrentUser(userRef);

            if (self.currentUser) {
              setUserToken();
              const response = yield self.queryMe(undefined, (res: any) =>
                res.collections((col: any) =>
                  col.id.name.snapshotUrl.updatedAt.collectionKits((colKit: any) =>
                    colKit.optional
                      .collection()
                      .group()
                      .accessoryProducts()
                      .kit((kit: any) =>
                        kit.name.description.imageUrl.secondaryImageUrl.stackable.width.height.depth.family.showOnWall.pdfUrl
                          .accessoryKits()
                          .relatedKits()
                          .optionKits()
                      )
                  )
                )
              );
              let collection = response.me.collections[response.me.collections.length - 1] || undefined;
              self.setCurrentCollection(collection);

              const collectionLS =
                localStorage.getItem("dssTempCollection") &&
                JSON.parse(localStorage.getItem("dssTempCollection") || "");

              if (collectionLS?.id) {
                queryCollectionLocalStorage(collectionLS?.id).then((result) => {
                  //localStorage.removeItem("dssTempCollection");
                });
              }
            }
          }
        } else if (!self.currentUser) {
          const collectionLS =
            localStorage.getItem("dssTempCollection") && JSON.parse(localStorage.getItem("dssTempCollection") || "");

          if (collectionLS?.id) {
            queryCollectionLocalStorage(collectionLS.id).then((result) => {
              console.log(result);
            });
          }
        } else {
          console.log("no dssTempCollection");
        }
      } catch (error) {
        throw error;
      } finally {
        // self.loading.kits = false;
      }
    });

    return {
      setFilterCategories,
      queryCategories,
      openPrevWallPopover,
      updatePrevWallPopoverSettings,
      findCanvasKit,
      afterCreate,
      setIsDrawerOpen,
      setCurrentUser,
      setCurrentCollection,
      setCurrentCanvas,
      removeCollectionKitFromStore,
      removeCanvasKitFromStore,
      addCurrentCollectionToUser,
      addCurrentCanvasToUser,
      viewKitDetails,
      logoutUser,
      handleDestroyCollection,
      handleDestroyCollectionKit,
      handleDestroyCanvas,
      handleQueryCollectionKits,
      createCanvasKits,
      queryCanvasKits,
      handleQueryGroups,
      handleQueryKits,
      handleQueryKitsForOptionsRelated,
      calculatePreviewWallScale,
      clearWallContent,
      addPreviewWallContent,
      removePreviewWallContent,
      destroyPreviewWallContent,
      updatePreviewWallContents,
      updatePreviewWallItemsLocations,
      updateSearchTerms,
      setIsFullScreen,
      setSearchValue,
      getSearchValue,
      clearSeachValue,
      setSearchTerms,
      setCurrentFamily,
      isFiltersChecked,
      setUserLocalStorage,
      setUserToken,
      setCollectionForLocalStorage,
      updateCollectionLocalStorage,
      queryCollectionLocalStorage,
      setLoading,
      resetCanvass,
      resetCollections,
      resetAggregations,
      handleResetCategories,
      handleChangeCategories,
    };
  });
