import React from "react";
import { useTranslation } from "react-i18next";

import {
  Typography,
  FormControlLabel,
  Switch,
  Divider,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  FormHelperText,
  Button,
} from "@material-ui/core";

import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import UserPageContainer from "./UserPageContainer";
import NewUserForm from "./UserForm";
import FormikTextField from "@lib/components/FormikTextField";
import { UserModelType } from "models";
import ConfirmButtonSpinner from "@lib/components/ConfirmButtonSpinner";
import { useStyles } from "styles";
import FormikRadioField from "@lib/components/FormikRadioField";
import { ErrorMessage } from "formik";
import { useHistory } from "react-router";

interface ProfilePageProps {
  user: UserModelType;
}

const ProfilePage: React.FC<ProfilePageProps> = ({ user }) => {
  const { t } = useTranslation();
  const styles = useStyles();
  const [changePassword, setChangePassword] = React.useState(false);
  const [projectType, setProjectType] = React.useState(user.projectType);
  const history = useHistory();

  return (
    <UserPageContainer onClose={(): void => history.goBack()}>
      <NewUserForm user={user} changePassword={changePassword}>
        {({ handleSubmit, isSubmitting }): React.ReactNode => (
          <React.Fragment>
            <form>
              <Typography variant="h2" color="primary">
                {t("User Profile")}
              </Typography>
              <Divider className={styles.dividerModal} />
              <FormikTextField
                name="firstName"
                label={t("First Name")}
                type="text"
                variant="outlined"
                labelWidth={78}
              />
              <FormikTextField name="lastName" label={t("Last Name")} type="text" variant="outlined" labelWidth={78} />
              <FormikTextField name="email" label={t("Email")} type="text" variant="outlined" labelWidth={40} />

              <Divider className={styles.dividerModal} />

              <p>To better serve you, please provide your ZIP code</p>
              <FormikTextField name="zipCode" label={t("Zip Code")} type="text" variant="outlined" labelWidth={128} />

              <FormControl component="fieldset">
                <FormLabel component="legend">Account Created for a:</FormLabel>
                <RadioGroup
                  row
                  aria-label="Account Created for a:"
                  value={projectType}
                  name="projectType"
                  onChange={(e): void => setProjectType(e.currentTarget.value)}
                >
                  <FormikRadioField
                    value="personal"
                    name="projectType"
                    label="Personal Project"
                    labelPlacement="end"
                    control={<Radio />}
                  />
                  <FormikRadioField
                    value="professional"
                    name="projectType"
                    label="Professional Project"
                    labelPlacement="end"
                    control={<Radio />}
                  />
                </RadioGroup>
                <ErrorMessage name="projectType">
                  {(errorMessage): React.ReactNode => <FormHelperText error>{errorMessage}</FormHelperText>}
                </ErrorMessage>
              </FormControl>

              {projectType == "professional" && (
                <FormikTextField
                  name="affiliatedTradeFirm"
                  label="Trade Firm I am affiliated with"
                  type="text"
                  variant="outlined"
                  labelWidth={78}
                />
              )}
              <Divider className={styles.dividerModal} />

              <div className={styles.formikTextField}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={changePassword}
                      onChange={(): void => setChangePassword(!changePassword)}
                      value="changePassword"
                      color="primary"
                    />
                  }
                  label={
                    <Typography variant="h5" color="primary">
                      {t("Update password?")}
                    </Typography>
                  }
                />
                {changePassword && (
                  <React.Fragment>
                    <FormikTextField
                      name="password"
                      label={t("New Password")}
                      type="password"
                      variant="outlined"
                      labelWidth={85}
                    />
                    <FormikTextField
                      name="passwordConfirmation"
                      label={t("Confirm New Password")}
                      type="password"
                      variant="outlined"
                      labelWidth={145}
                    />
                  </React.Fragment>
                )}
              </div>
              <Divider className={styles.dividerModal} />

              <Button
                onClick={(): void => handleSubmit()}
                className={styles.buttonWithRoundedCorners}
                disabled={isSubmitting}
                variant="contained"
                color="secondary"
                endIcon={<ArrowForwardIcon color={isSubmitting ? "disabled" : "inherit"} />}
              >
                {t("Update")}
                {isSubmitting && <ConfirmButtonSpinner />}
              </Button>
            </form>
          </React.Fragment>
        )}
      </NewUserForm>
    </UserPageContainer>
  );
};

export default ProfilePage;
