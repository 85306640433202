import React from "react";
import { useDrop, XYCoord } from "react-dnd";
import { size } from "lodash";
import { observer } from "mobx-react";
import { produce } from "immer";
import clsx from "clsx";
import { Paper, Typography, Fade, Slide } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useStyles } from "styles";
import { useStore } from "@lib/hooks/useStore";
import PreviewWallItem, { ItemProps } from "./PreviewWallItem";
import EyeIcon from "@lib/logos/EyeIcon";

export const ItemTypes = {
  ITEM: "item",
};

export interface DragItem extends ItemProps {
  type: string;
}

interface PreviewWallContainerProps {
  width: number;
  showGrid: boolean;
}

const PreviewWallContainer: React.FC = observer(({ width, showGrid }: any) => {
  const store = useStore();
  const styles = useStyles();
  const { t } = useTranslation();

  const [lastValidLocation, setLastValidLocation] = React.useState<{
    [key: string]: any;
  } | null>(null);

  const moveItem = (item: DragItem, left: number, top: number): void => {
    const updatedItems = produce(store.previewWallContents, (draft) => {
      draft[item.collectionKitId].left = left;
      draft[item.collectionKitId].top = top;
    });

    setLastValidLocation(null);
    store.updatePreviewWallContents(updatedItems);
  };

  const onExit = (): void => {
    store.updatePrevWallPopoverSettings({
      text: "",
      open: false,
      direction: "down",
      time: 0,
    });
  };

  const [, drop] = useDrop({
    accept: ItemTypes.ITEM,
    drop(item: DragItem) {
      moveItem(item, lastValidLocation.left, lastValidLocation.top);
      return undefined;
    },
    canDrop(item: DragItem, monitor) {
      if (!lastValidLocation) {
        setLastValidLocation({ left: item.left, top: item.top });
      }

      const delta = monitor.getDifferenceFromInitialOffset() as XYCoord;
      const xCord = Math.round(item.left + delta.x);
      const yCord = Math.round(item.top + delta.y);

      const itemMoving = {
        left: xCord,
        top: item.stackable ? yCord : store.previewWallHeight - item.itemHeight,
        right: xCord + item.itemWidth,
        bottom: item.stackable ? yCord + item.itemHeight : store.previewWallHeight,
      };

      const isInContainer = (): boolean => {
        return !(
          itemMoving.left < 0 ||
          itemMoving.top < 0 ||
          itemMoving.right > store.previewWallWidth ||
          itemMoving.bottom > store.previewWallHeight
        );
      };

      const isValidDrop = (): void => {
        const isValidLocation = !Object.keys(store.previewWallContents)
          .filter((key) => key !== item.collectionKitId)
          .some((key) => {
            const itemOnWall = store.previewWallContents[key];
            const top = itemOnWall.top;
            const bottom = top + itemOnWall.itemHeight;
            const left = itemOnWall.left;
            const right = left + itemOnWall.itemWidth;

            if (!isInContainer()) return true;

            if (
              left < itemMoving.right &&
              right > itemMoving.left &&
              top < itemMoving.bottom &&
              bottom > itemMoving.top
            ) {
              return true;
            }
            return false;
          });

        isValidLocation && setLastValidLocation(itemMoving);
      };

      if (size(store.previewWallContents) < 2) {
        isInContainer() && setLastValidLocation(itemMoving);
      }

      isValidDrop();
      return true;
    },
  });

  React.useEffect(() => {
    if (width) store.calculatePreviewWallScale(width);
  }, [width]);

  return (
    <Paper
      id="previewWall"
      ref={drop}
      square
      elevation={2}
      className={clsx(styles.previewWall, { [styles.wallGrid]: showGrid })}
      style={{
        width: store.previewWallWidth,
        height: store.previewWallHeight,
      }}
    >
      {Object.values(store.prevWallPopoverSettings.text).length > 0 && (
        <Slide
          in={store.prevWallPopoverSettings.open}
          timeout={350}
          direction={store.prevWallPopoverSettings.direction}
          onExited={onExit}
        >
          <Fade in timeout={400}>
            <div className={styles.canvasPreviewWallSaveMessageWrap}>
              <Typography variant="h5" align="center" className={styles.canvasPreviewWallSaveMessageText}>
                {" "}
                {store.prevWallPopoverSettings.text}
              </Typography>
            </div>
          </Fade>
        </Slide>
      )}
      {Object.keys(store.previewWallContents).length == 0 && (
        <Slide in timeout={350} direction="left">
          <Fade in timeout={400}>
            <div className={styles.canvasCtaMessageBubble}>
              <Typography variant="h3" align="center" className={styles.canvasCtaMessageText}>
                {" "}
                {t(
                  "To build your canvas click items in your collection. Create multiple canvases to explore more ideas."
                )}
              </Typography>
              <EyeIcon color="primary" className={styles.removeButtonIcon} />
            </div>
          </Fade>
        </Slide>
      )}
      {Object.keys(store.previewWallContents).map((key) => {
        return (
          !store.previewWallContents[key].delete && (
            <PreviewWallItem key={key} id={key} {...store.previewWallContents[key]} />
          )
        );
      })}
    </Paper>
  );
});

export default PreviewWallContainer;
