/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from "mobx-state-tree"
import { MSTGQLRef, QueryBuilder, withTypedRefs } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { UserModel, UserModelType } from "./UserModel"
import { UserModelSelector } from "./UserModel.base"
import { RootStoreType } from "./index"


/* The TypeScript type that explicits the refs to other models in order to prevent a circular refs issue */
type Refs = {
  user: UserModelType;
}

/**
 * ResetUserPasswordPayloadBase
 * auto generated base class for the model ResetUserPasswordPayloadModel.
 *
 * Autogenerated return type of ResetUserPassword
 */
export const ResetUserPasswordPayloadModelBase = withTypedRefs<Refs>()(ModelBase
  .named('ResetUserPasswordPayload')
  .props({
    __typename: types.optional(types.literal("ResetUserPasswordPayload"), "ResetUserPasswordPayload"),
    /** A unique identifier for the client performing the mutation. */
    clientMutationId: types.union(types.undefined, types.null, types.string),
    result: types.union(types.undefined, types.null, types.boolean),
    user: types.union(types.undefined, types.null, MSTGQLRef(types.late((): any => UserModel))),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  })))

export class ResetUserPasswordPayloadModelSelector extends QueryBuilder {
  get clientMutationId() { return this.__attr(`clientMutationId`) }
  get result() { return this.__attr(`result`) }
  user(builder?: string | UserModelSelector | ((selector: UserModelSelector) => UserModelSelector)) { return this.__child(`user`, UserModelSelector, builder) }
}
export function selectFromResetUserPasswordPayload() {
  return new ResetUserPasswordPayloadModelSelector()
}

export const resetUserPasswordPayloadModelPrimitives = selectFromResetUserPasswordPayload().clientMutationId.result
