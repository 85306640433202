import React from "react";
import { Formik, FormikValues } from "formik";

import { useYupSchema } from "@lib/hooks/useYupSchema";

export interface CollectionAttributes {
  id: string;
  name: string;
}

interface CollectionFormProps {
  onConfirm: (values: FormikValues) => void;
  children: (formikProps: FormikValues) => React.ReactNode;
  initialValues?: CollectionAttributes;
}

const CollectionForm: React.FC<CollectionFormProps> = ({
  onConfirm,
  children,
  initialValues = { name: "", id: "" },
}) => {
  const { collectionSchema } = useYupSchema();

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      validationSchema={collectionSchema}
      onSubmit={async (values, { setSubmitting }): Promise<any> => {
        await onConfirm(values);
        setSubmitting(false);
      }}
    >
      {(formikProps): React.ReactNode => <React.Fragment>{children(formikProps)}</React.Fragment>}
    </Formik>
  );
};

export default CollectionForm;
