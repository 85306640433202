import { PRIVACY, TERMS } from "@lib/utils/routes";

import LinkNoStyle from "@lib/components/LinkNoStyle";
import React from "react";
import RothLivingLogo from "@lib/logos/RothLivingLogo";
import { Typography } from "@material-ui/core";
import { useStyles } from "styles";
import { useTranslation } from "react-i18next";

const Footer: React.FC = () => {
  const styles = useStyles();
  const { t } = useTranslation();

  return (
    <div className={styles.footer}>
      <div className={styles.centeredVertically}>
        <a title="rothLiving.com" target="_blank" href="http://rothLiving.com">
          <RothLivingLogo style={{ width: 160, height: 40 }} />
        </a>
      </div>
      <div className={styles.flex}>
        <LinkNoStyle
          to={{
            pathname: TERMS,
            hash: "#top",
          }}
        >
          <Typography variant="button" className={styles.footerText} style={{ marginRight: 32 }}>
            {t("Terms + Conditions")}
          </Typography>
        </LinkNoStyle>
        <LinkNoStyle
          to={{
            pathname: PRIVACY,
            hash: "#top",
          }}
        >
          <Typography variant="button" className={styles.footerText}>
            {t("Privacy Policy")}
          </Typography>
        </LinkNoStyle>
      </div>
    </div>
  );
};

export default Footer;
