import {
  CREATE_ACCOUNT,
  DISTRIBUTOR_IN_AREA,
  DISTRIBUTOR_OUTSIDE_AREA,
  ERROR,
  LOGIN,
  PRIVACY,
  RESET_PASSWORD,
  ROOT,
  SEND_MY_QUOTE,
  SEND_RESET_PASSWORD_LINK,
  SEND_RESET_THANK_YOU,
  TERMS,
  USER_PROFILE,
} from "@lib/utils/routes";
import React, { useEffect } from "react";
import { Redirect, Route, Switch, useLocation, useParams } from "react-router";

import CreateAccountPage from "./User/CreateAccountPage";
import DistributorInAreaPage from "./User/DistributorInAreaPage";
import DistributorOutSideAreaPage from "./User/DistributorOutSideAreaPage";
import ErrorPage from "./User/ErrorPage";
import Home from "components/Home";
import LoginPage from "components/User/LoginPage";
import PrivacyPolicy from "./SpashPage/PrivacyPolicy";
import ProfilePage from "./User/ProfilePage";
import ResetUserPasswordPage from "./User/ResetUserPasswordPage";
import ResetUserPasswordThankYouPage from "./User/ResetUserPasswordThankYouPage";
import SendMyQuotePage from "./User/SendMyQuotePage";
import SendResetPasswordLinkPage from "./User/SendResetPasswordLinkPage";
import TermsConditions from "./SpashPage/TermsConditions";
import { observer } from "mobx-react";
import { useCategoriesActions } from "@lib/hooks/useCategoriesActions";
import { useStore } from "@lib/hooks/useStore";

const RootLevelRoutes: React.FC = observer(() => {
  const store = useStore();
  const location = useLocation();

  useEffect(() => {
    if (location.hash.includes("#top")) {
      var root = document.getElementById("body");
      root?.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "center",
      });
    }
  }, [location.pathname]);

  return (
    <Switch>
      <Route exact path={CREATE_ACCOUNT} component={CreateAccountPage} />
      <Route exact path={ERROR} component={ErrorPage} />
      <Route exact path={LOGIN} component={LoginPage} />
      <Route exact path={SEND_RESET_PASSWORD_LINK} component={SendResetPasswordLinkPage} />
      <Route exact path={SEND_RESET_THANK_YOU} component={ResetUserPasswordThankYouPage} />
      <Route exact path={RESET_PASSWORD} component={ResetUserPasswordPage} />
      <Route exact path={SEND_RESET_THANK_YOU} component={ResetUserPasswordThankYouPage} />
      <Route exact path={DISTRIBUTOR_OUTSIDE_AREA} component={DistributorOutSideAreaPage} />
      <Route exact path={DISTRIBUTOR_IN_AREA} component={DistributorInAreaPage} />

      <Route exact path={SEND_MY_QUOTE} component={SendMyQuotePage}>
        {store.currentUser ? <SendMyQuotePage user={store.currentUser} /> : <Redirect to={ROOT} />}
      </Route>

      <Route exact path={TERMS} component={TermsConditions} />
      <Route exact path={PRIVACY} component={PrivacyPolicy} />
      <Route exact path={USER_PROFILE}>
        {store.currentUser ? <ProfilePage user={store.currentUser} /> : <Redirect to={ROOT} />}
      </Route>
      <Route path={ROOT} component={Home} />
    </Switch>
  );
});

export default RootLevelRoutes;
