import React from "react";
import { useTranslation } from "react-i18next";
import { useDialogManager } from "@lib/components/DialogManagerContext";
import { useStyles } from "styles";
import DialogModal from "@lib/components/DialogModal";
import ButtonWithRoundedCorners from "@lib/components/ButtonWithRoundedCorners";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { Button } from "@material-ui/core";

export interface GeneralAttributes {
  id: string;
}

interface GeneralDialogProps {
  onConfirm: (param?: any) => void;
  title?: string;
  titleVariant?: string;
  contentText?: string;
  view?: string;
  initialValues?: GeneralAttributes;
  onClose?: () => void;
}

const GeneralDialog: React.FC<GeneralDialogProps> = ({
  onConfirm,
  title,
  titleVariant,
  contentText,
  onClose,
  view,
}) => {
  const { closeDialog } = useDialogManager();
  const { t } = useTranslation();
  const styles = useStyles();

  return (
    <React.Fragment>
      <DialogModal
        title={title}
        titleVariant={titleVariant}
        contentText={contentText}
        onClose={onClose || closeDialog}
        fullWidthBottomButton={false}
        dialogActionButtons={
          <React.Fragment>
            <Button
              onClick={onConfirm}
              className={styles.buttonWithRoundedCorners}
              variant="contained"
              color="secondary"
              endIcon={<ArrowForwardIcon />}
            >
              CONTINUE
            </Button>
          </React.Fragment>
        }
      />
    </React.Fragment>
  );
};

export default GeneralDialog;
