import React from "react";
import { useTranslation } from "react-i18next";

import { Typography, Divider } from "@material-ui/core";

import UserPageContainer from "components/User/UserPageContainer";
import { useStyles } from "styles";

const PrivacyPolicy: React.FC = () => {
  const { t } = useTranslation();
  const styles = useStyles();

  return (
    <UserPageContainer>
      <Typography variant="h3" gutterBottom>
        Roth Corporation Website Privacy Policy
      </Typography>

      <Typography variant="h4" gutterBottom>
        EFFECTIVE DATE: 4/27/2022
      </Typography>

      <Typography variant="body1" gutterBottom>
        At Roth Corporation (&ldquo;Roth&rdquo;), we value our community. We believe in transparency and are committed
        to being upfront about our privacy practices.
      </Typography>

      <Typography variant="body1" gutterBottom>
        In this Roth Corporation Website Privacy Policy (this &ldquo;Policy&rdquo;), we describe what information we
        gather about you, what we use that information for, and who we share that information with, when you visit or
        use our website located at www.inspiringdesign.com, our online services, and the software provided on our
        website or in connection with our services (collectively the &ldquo;Site&rdquo;). Among the reasons that Roth
        collects personal information from people who visit or use the Site is to provide better services to all users
        of the Site.
      </Typography>

      <Typography variant="body1" gutterBottom>
        This Policy is designed to assist you in understanding and making informed decisions when using the Site about
        how personal information about you may be obtained, used, shared, and disclosed to third parties by Roth. This
        Policy also describes how you can opt out of some uses of your personal information.
      </Typography>

      <Typography variant="body1" gutterBottom>
        This Policy, together with the Terms of Use Agreement posted on the Site, sets forth the general rules and
        policies governing your use of the Site. Depending on your activities when visiting the Site, you may be
        required to agree to additional terms and conditions.
      </Typography>

      <Typography variant="body1" gutterBottom>
        WHEN YOU ACCESS THE SITE, YOU AGREE TO THIS PRIVACY POLICY. IF YOU DO NOT AGREE TO THIS PRIVACY POLICY, OR TO
        ANY CHANGES THAT MAY BE SUBSEQUENTLY MADE TO THIS PRIVACY POLICY, YOU SHOULD IMMEDIATELY STOP ACCESSING THE
        SITE.
      </Typography>

      <Typography variant="h3" gutterBottom>
        1. CHANGES TO THIS PRIVACY POLICY
      </Typography>

      <Typography variant="body1" gutterBottom>
        This Policy is current as of the Effective Date set forth above. We may change this Policy from time to time in
        our sole discretion. To let you know when we make changes to this Policy, we will amend the Effective Date. The
        new modified or amended Policy will apply from that new Effective Date, so please be sure to check back
        periodically.
      </Typography>

      <Typography variant="body1" gutterBottom>
        Your continued use of the Site after any change to this Policy will constitute your acceptance of the changes.
        Roth will not use your personally identifiable information, in a manner that is materially different than what
        was stated at the time it was collected, without your consent.
      </Typography>

      <Typography variant="h3" gutterBottom>
        2. THE TYPE OF INFORMATION WE COLLECT AND HOW IT IS USED
      </Typography>
      <Typography variant="body1" gutterBottom>
        &ldquo;Personal information&rdquo; is information that may be used to specifically identify or contact you, such
        as your name, postal address, email address, and telephone number, among other things. When you visit the Site,
        personal information about you is not collected unless you voluntarily provide it. If you provide personal
        information to us, Roth might receive additional information about you from other sources and combine that
        additional information with the personal information that you have provided. If you provide personal information
        such as your phone number or email address on the Site, the content of your communications, and your e-mail
        address, along with any other personal information provided, may be retained. You acknowledge and agree that by
        using the Site, information provide on the Site or directly to Roth may be redirected to a different distributor
        that is responsible for the territory where your project may reside. Roth has certain geographic restrictions
        any may not provide certain services outside of its territory, in accordance with its contractual obligations
        with vendors included on the Site..
      </Typography>

      <Typography variant="body1" gutterBottom>
        In certain circumstances, Roth may request, or may allow or otherwise provide you an opportunity to submit, your
        personally identifiable information in connection with a feature, program, promotion or some other aspect of the
        Site. For example, you may provide your name, mailing/shipping address, email address, and phone number when
        using the Site or to pursue other services offered through the Site. Certain information may not be personally
        identifiable when standing alone, but may become so when combined with other information (e.g., email address
        and name). Whether or not you provide this information is your choice; however, in some instances this type of
        information may be required to participate in a particular activity, realize a benefit that the Site may offer,
        or gain access to certain information linked to the use of the Site.
      </Typography>

      <Typography variant="body1" gutterBottom>
        The personally identifiable information that you submit to Roth is generally used to carry out your requests,
        respond to your inquiries, or better serve you, or is used in other ways naturally associated with the
        circumstances in which you provided the information. Roth may use this information to contact you later for a
        variety of reasons, such as for customer service, to provide you with information or services, or to set up
        transactions that you have requested or agreed to receive. We may use this information to provide you with
        marketing or promotional information for products or services of Roth or of Roth&rsquo;s parent company,
        subsidiaries or other affiliated companies, including our vendors on the Site. We may use this information to
        communicate with you about content or other information that you have posted or shared with Roth via the Site,
        or with other users of the Site, or with service providers who use the Site. We may use this information with
        regard to your use of the Site and, in the discretion of Roth, with regard to changes to the Site or Site
        policies. We may also use this information for internal business purposes, or for purposes disclosed at the time
        you provide your information, or as otherwise set forth in this Policy. You may opt out from receiving future
        promotional information from Roth, or direct that Roth not share your information in the future with any
        affiliated companies or third parties for their direct marketing purposes. To opt out of receiving promotional
        offers simply unsubscribe via the email sent for that purpose.
      </Typography>

      <Typography variant="body1" gutterBottom>
        In certain instances, Roth may also share your personally identifiable information with its third-party vendors
        who are performing functions on behalf of Roth, or on behalf of affiliated companies of Roth (e.g., vendors that
        process credit card orders, deliver merchandise, administer promotions, provide marketing or promotional
        assistance, analyze data, assist with customer service, and the like). In addition, Roth may share your
        personally identifiable information with participating sponsors of a program or promotion, and with third
        parties who assist Roth in using the content or other information that you have posted or shared with Roth via
        the Site. Further, Roth may share your personally identifiable information with third parties such as
        promotional partners of Roth, and persons or companies with whom Roth has marketing or other relationships,
        among others.
      </Typography>

      <Typography variant="body1" gutterBottom>
        &ldquo;Non-personal information&rdquo; is information about you or your activities through which you cannot be
        personally identified. When you visit the Site, we may collect such information. Examples include the type of
        browser and operating system you are using, the type of device you are using, the domain name of your Internet
        service provider, the pages you visit on the Site and how long you spend on each page, and the unique number
        assigned to your computer or Internet connection. This type of information can include certain personally
        identifiable information that has been de-identified (i.e., information that has been rendered anonymous). Roth
        and its third- party service providers may obtain non-personal information about you from information that you
        provide, either separately or together with your personally identifiable information. This type of information
        may be used for a variety of purposes, including monitoring use of and improving the Site, as well as for
        internal analysis.
      </Typography>

      <Typography variant="body1" gutterBottom>
        The Site may utilize a standard technology called &ldquo;cookies,&rdquo; described in more detail below, to
        provide the Site&rsquo;s personalization feature, and web server logs to collect information about how the Site
        is used. Information gathered through cookies and web server logs may include the date and time of visits, the
        pages viewed, time spent at the Site, and other websites visited just before your visit to the Site. This
        information may be collected on an aggregate basis. None of this information may be associated with you as an
        individual.
      </Typography>

      <Typography variant="body1" gutterBottom>
        Non-personal information is used in a variety of ways, including to help analyze Site traffic, to understand
        customer needs and trends, to carry out targeted promotional activities, and to improve the services provided by
        Roth. Roth may use your non-personal information by itself, or we may aggregate it with information that Roth
        has obtained from others. Roth may share your non-personal information with its affiliated companies and third
        parties to achieve these objectives and others; however, this information is anonymous information that does not
        personally identify you. Roth may provide data analyses and certain non-personal information to third parties
        (who may in turn use this information to provide advertisements tailored to your interests), but this will not
        involve disclosing any of your personally identifiable information.
      </Typography>
      <Typography variant="h3" gutterBottom>
        3. INFORMATION FROM THIRD PARTIES ABOUT YOU
      </Typography>
      <Typography variant="body1" gutterBottom>
        Roth also may, from time to time, supplement the information we collect with outside records from third parties
        in order to enhance our ability to serve you, to tailor our content to you, and to offer you opportunities to
        purchase products or services that we believe may be of interest to you. Roth may combine the information we
        receive from those other sources with information we collect through the Site. In those cases, Roth will apply
        this Policy to any personally identifiable information received, unless otherwise specifically disclosed by Roth
        at the time you provide your personally identifiable information.
      </Typography>

      <Typography variant="body1" gutterBottom>
        Roth is affiliated with other businesses and works closely with them. These businesses may offer promotions, or
        may sell items or services to you through Roth&rsquo;s services. Roth may also provide services or sell products
        jointly with affiliated businesses. Due to the nature of our business model, when an affiliated business is
        associated with your transaction, we may share information that is related to such transaction with that
        affiliated business. Roth may share aggregated demographic information with business partners.
      </Typography>
      <Typography variant="h3" gutterBottom>
        4. INFORMATION ABOUT SITE USE
      </Typography>
      <Typography variant="body1" gutterBottom>
        In addition to any personally identifiable information or other information that you choose to provide to Roth
        via the Site, Roth and its third-party service providers may use a variety of technologies, now and hereafter
        devised, that automatically collect certain Site usage information whenever you visit or interact with the Site.
        This information may include your browser type, your operating system, the page served, the time, the source of
        a request, the preceding pages viewed by you, and other similar information. Roth may use this usage information
        for a variety of purposes, including to enhance or otherwise improve the Site. In addition, Roth may also
        collect your IP address or some other unique identifier for the particular device you use to access the
        Internet, as applicable (collectively, referred to herein as a &ldquo;Device Identifier&rdquo;). A Device
        Identifier is a number that is automatically assigned to your computer, and Roth may identify your device by its
        Device Identifier. When analyzed, usage information helps Roth determine how the Site is used, such as what
        types of visitors arrive at the Site, what type of content is most popular, what type of content you may find
        most relevant, and what types of visitors are interested in particular kinds of content and advertising. Roth
        may associate your Device Identifier or Site usage information with the personally identifiable information you
        provide, and may treat the combined information as personally identifiable information. Site usage information
        may be collected using various methods, such as web beacons, cookies, mobile device identifiers, and embedded
        scripts, among other methods.
      </Typography>
      <Typography variant="h3" gutterBottom>
        A. COOKIES&mdash;IN GENERAL
      </Typography>

      <Typography variant="body1" gutterBottom>
        We use cookies and other tracking mechanisms to track information about your use of our Site and our products
        and services. We may combine this information with other personal information we collect from you. Cookies are
        alphanumeric identifiers that we transfer to your computer&rsquo;s hard drive through your web browser for
        record-keeping purposes. Some cookies allow us to make it easier for you to navigate our Site and our products
        and services, while others are used to enable a faster log-in process or to allow us to track your activities
        with respect to our Site and our products and services. Some cookies are used to enable certain functions of the
        Site, and some are used to store your preferences. We may use cookies to remember information that changes the
        way the Site or our services behave or look.
      </Typography>

      <Typography variant="body1" gutterBottom>
        Some of the cookies we use are necessary to enable you to move around the Site and use its features, including
        accessing secure areas that may contain content for registered users.
      </Typography>

      <Typography variant="body1" gutterBottom>
        We may use accounts-related cookies to authenticate users and prevent fraudulent use of user accounts. We may
        use analytics cookies to track information about how the Site and our products and services are used so that we
        can make improvements. We may also use analytics cookies to test new advertisements, pages, or features, or new
        functionality of the Site and our services, to see how our users react to them.
      </Typography>

      <Typography variant="body1" gutterBottom>
        In addition, we may use web beacons or tracking pixels to count visitor numbers, and performance cookies to
        track how many individual users access the Site, and how often. This information is used for statistical
        purposes only and it is not our intention to use such information to personally identify any user. However, if
        you have registered and signed into the Site, we may combine this information with information from our web
        analytic services and cookies to analyze in more detail how you use the Site.
      </Typography>

      <Typography variant="body1" gutterBottom>
        In addition to our own cookies, we may also use various third-party cookies to report usage statistics for the
        Site and our products and services, to deliver advertisements on and through the Site, and so forth. Cookies may
        be used to create profiles of our users, based on the user information we collect, and cookies may enable
        users&#39; identification across multiple websites. You can find more detailed information about cookies and how
        they work at
        <a href="http://www.aboutcookies.org/&amp;sa=D&amp;source=editors&amp;ust=1651009191100863&amp;usg=AOvVaw2_SEVx8q5zZ8UBledakrO5">
          http://www.aboutcookies.org/
        </a>
        . Cookies may include session cookies and persistent cookies.
      </Typography>

      <Typography variant="h3" gutterBottom>
        B. SESSION COOKIES
      </Typography>

      <Typography variant="body1" gutterBottom>
        Session cookies exist only during an online session. They disappear from your computer when you close your
        browser or turn off your computer. We use session cookies to allow our systems to uniquely identify you during a
        session, or while you are logged into the Site. This allows us to process your online transactions and requests
        and to verify your identity, after you have logged in, as you move through our Site.
      </Typography>
      <Typography variant="h3" gutterBottom>
        C. PERSISTENT COOKIES
      </Typography>

      <Typography variant="body1" gutterBottom>
        Persistent cookies remain on your computer after you have closed your browser or turned off your computer. We
        use persistent cookies to track aggregate and statistical information about user activity.
      </Typography>
      <Typography variant="h3" gutterBottom>
        D. DISABLING COOKIES
      </Typography>

      <Typography variant="body1" gutterBottom>
        Most web browsers automatically accept cookies, but if you prefer, you can edit your browser options to block
        them in the future. The &ldquo;Help&rdquo; or &ldquo;Options&rdquo; portion of the toolbar on most browsers will
        tell you how to prevent your computer from accepting new cookies, how to have the browser notify you when you
        receive a new cookie, or how to disable cookies altogether. If you visit our Site and disable cookies, you will
        be able to browse certain areas of the Site, but some features may not function, you may not be able to store
        your preferences, and some of our pages may not display properly. Be aware that any preferences you have set
        will be lost if you delete all cookies, including your preference to opt out from cookies, as this itself
        requires an opt-out cookie to have been set.
      </Typography>
      <Typography variant="h3" gutterBottom>
        E. YOUR CONSENT TO THE USE OF COOKIES
      </Typography>

      <Typography variant="body1" gutterBottom>
        By using the Site or purchasing or using any of our products or services, and by consenting to the terms of this
        Policy, you consent to our use of cookies.
      </Typography>

      <Typography variant="h3" gutterBottom>
        5. INFORMATION SHARING AND DISCLOSURE
      </Typography>
      <Typography variant="body1" gutterBottom>
        Roth will share personal information with companies, organizations or individuals outside of Roth if we have a
        good-faith belief that access, use, preservation or disclosure of such personal information is reasonably
        necessary to: (i) meet any applicable law, regulation, legal process or enforceable governmental request; (ii)
        enforce applicable provisions of the Terms of Use Agreement posted on the Site, including investigation of
        potential violations; (iii) detect, prevent, or otherwise address fraud, security or technical issues; and (iv)
        protect against harm to the rights, property or safety of Roth, Site users, or the public as required or
        permitted by law.
      </Typography>

      <Typography variant="body1" gutterBottom>
        It is not our policy to sell or rent your personal information to unaffiliated third parties. Your personal
        information may be shared by us with unrelated third parties, including contractors that provide services to the
        Site.
      </Typography>
      <Typography variant="body1" gutterBottom>
        When we share your personal information with a third party with whom we have a contractual relationship, we use
        reasonable efforts to include clauses in our contract with such third party that require them to comply with the
        privacy laws and regulations relevant to that information, and to restrict the use to the purposes for which it
        was provided to them.
      </Typography>

      <Typography variant="h3" gutterBottom>
        6. OTHER USES
      </Typography>

      <Typography variant="body1" gutterBottom>
        For security reasons, Roth does not recommend that you send non-public personally identifiable information, such
        as bank account information, to us by email. Roth may communicate with you by email in the following
        circumstances: (a) when you request a particular service or sign up for a feature that involves email
        communications; (b) when our email to you relates to requests you have made from or through Roth (e.g.,
        product/services information, directing your requests to a product specialist or other distributor in your area,
        etc.); (c) when Roth is sending you information about products and services; (d) when you have consented to
        being contacted by email for a particular purpose; (e) when you send Roth an e-mail, post information on the
        Site, or otherwise submit information to Roth electronically, where Roth may email you to follow up or otherwise
        communicate with you with respect thereto; (f) when we provide you with legal notices or notices with respect to
        your use of the Site; or (g) when we are otherwise attempting to facilitate a transaction between you and Roth,
        or between you and someone else. In certain instances, Roth may provide you with tools on the Site that will
        allow you to set your preferences for receiving email communications from Roth or other persons, where such
        tools allow you to agree to some communications but not others. You may opt out of receiving future commercial
        email communications from Roth by sending an email to Roth opting out of future promotional email
        communications; provided, however, that Roth reserves the right to send you transactional emails, such as
        customer service communications and auto-renewal billing notices.
      </Typography>

      <Typography variant="h3" gutterBottom>
        7. DO-NOT-TRACK DISCLOSURE
      </Typography>

      <Typography variant="body1" gutterBottom>
        Currently, our systems do not recognize browser &ldquo;do-not-track&rdquo; requests.
      </Typography>
      <Typography variant="h3" gutterBottom>
        8. YOUR CALIFORNIA PRIVACY RIGHTS
      </Typography>
      <Typography variant="body1" gutterBottom>
        Residents of the State of California, under certain provisions of the California Civil Code, have the right to
        request from companies conducting business in California a list of all third parties to which the company has
        disclosed certain personally identifiable information, as defined under California law, during the preceding
        year for third-party direct marketing purposes. You are limited to one request per calendar year. In your
        request, please attest to the fact that you are a California resident, and provide a current California address
        for our response. You may request the information in writing at:
      </Typography>

      <Typography variant="body1" gutterBottom>
        Roth Corporation
        <br />
        742 S. Broadway
        <br />
        Denver, CO 80209
      </Typography>
      <Typography variant="h3" gutterBottom>
        9. EXCEPTIONS TO THIS PRIVACY POLICY
      </Typography>

      <Typography variant="body1" gutterBottom>
        This Policy applies to all of the services Roth offers. It excludes services that have separate privacy policies
        that do not incorporate this Policy. Furthermore, it does not apply to services offered by other companies or
        individuals, including products, services, or sites that may be displayed to you on the Site, or other websites
        linked from the Site. This Policy does not cover the information practices of other companies and organizations
        who advertise on and or provide services through the Site, and who may use cookies, pixel tags and other
        technologies to serve and offer relevant ads.
      </Typography>

      <Typography variant="body1" gutterBottom>
        Regarding the Site&rsquo;s links to other websites, neither Roth nor any third parties that help us operate the
        Site are responsible for the information collection or privacy practices of such other websites. You should
        consult the privacy policies of other websites before you visit those websites or provide any personal
        information to those websites. Roth or third parties that help us operate the Site may provide information at
        the Site about programs and events that are sponsored by or co-sponsored by other firms or organizations. If you
        register for any of these programs or events, then Roth and third parties that help us operate the Site may have
        no control over the use of your personal information by such other firms or organizations.
      </Typography>

      <Typography variant="body1" gutterBottom>
        Third-party applications may be available via the Site. The owners of these applications (&ldquo;Third-Party
        Owners&rdquo;) may collect personally identifiable information from you, and may have their own policies and
        practices. Roth is not responsible for how Third-Party Owners or their applications use your personally
        identifiable information. Such Third-Party Owners may have their own terms of service, privacy policies or other
        policies, and may ask you to agree to the same. Roth is not responsible for those policies or for the practices
        of Third-Party Owners. Be sure to review any available policies before submitting any personally identifiable
        information to a third-party application, or otherwise interacting with it.
      </Typography>

      <Typography variant="body1" gutterBottom>
        Roth and third parties that help us operate the Site reserve the right to disclose your personal information:
        (i) if it is believed that the Site is being used to commit unlawful acts; (ii) if disclosure of your personal
        information is required to comply with applicable laws or regulations or with a court or administrative order,
        or will help to enforce any applicable terms of use; and (iii) to protect your safety and security, including
        the safety and security of property that belongs to you, or to protect the safety and security of the Site or of
        third parties.
      </Typography>
      <Typography variant="h3" gutterBottom>
        10. SECURITY
      </Typography>
      <Typography variant="body1" gutterBottom>
        Although the Site employs security measures that are reasonably appropriate to protect your personal
        information, neither Roth nor any third party that helps us operate the Site guarantees that such security
        precautions will protect against the loss or misuse of your personal information. Similarly, neither Roth nor
        any third party that helps us operate the Site guarantees the privacy of personal information that you transmit
        over the Internet or that may be collected in transit by others, including contractors that provide services to
        Roth or to third parties that help us operate the Site.
      </Typography>

      <Typography variant="body1" gutterBottom>
        By using the Site, you agree that you understand the foregoing provisions of this section, and you consent to
        the transfer of your personal information across national borders, and to the storage and processing of your
        information in a country that may not provide the same level of privacy protection that your country provides.
      </Typography>
      <Typography variant="h3" gutterBottom>
        11. CHILDREN&#39;S PRIVACY
      </Typography>
      <Typography variant="body1" gutterBottom>
        Roth does not intend the Site or our products or services to be used by individuals under the age of 18. Roth
        relies upon parents or guardians 18 years of age or older to determine if the Site and our products and services
        are appropriate for viewing, access, or participation by individuals under the age of 18. If an individual under
        the age of 18 uses the Site or any of our products or services, such individual may only do so under the
        supervision of a parent or guardian who is 18 years of age or older.
      </Typography>

      <Typography variant="body1" gutterBottom>
        We do not seek or knowingly collect any personal information about children under 13 years of age. If we become
        aware that we have unknowingly collected personal information from a child under the age of 13, we will make
        commercially reasonable efforts to delete such information from our database.
      </Typography>
      <Typography variant="h3" gutterBottom>
        12. CONTACT US
      </Typography>

      <Typography variant="body1" gutterBottom>
        If you have any questions regarding this Policy you may send an email to{" "}
        <a href="mailTo:info@Inspiringdesign.com">info@Inspiringdesign.com</a> or write to:
      </Typography>

      <Typography variant="body1" gutterBottom>
        Roth Corporation
        <br />
        742 S. Broadway
        <br />
        Denver, CO 80209
      </Typography>
    </UserPageContainer>
  );
};

export default PrivacyPolicy;
