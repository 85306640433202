import React from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";

import {
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  ListProps,
  Typography,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import CheckCircle from "@material-ui/icons/CheckCircle";

import { CanvasModelType } from "models";
import { FormikValues } from "formik";
import { useCanvasActions } from "@lib/hooks/useCanvasActions";
import EditName from "./EditName";
import { useStyles } from "styles";
import { MyCanvasDialogListsProps } from "./MyCanvasDialogLists";
import Button from "@material-ui/core/Button/Button";
import { useStore } from "@lib/hooks/useStore";

interface CanvasListProps extends MyCanvasDialogListsProps, ListProps {
  subHeaderText: string;
  listArray: CanvasModelType[];
  listType: string;
}

const CanvasList: React.FC<CanvasListProps> = observer(
  ({
    open,
    edit,
    listArray,
    subHeaderText,
    selectedCanvas,
    listType,
    listView,
    onSelectClick,
    handleDeleteClick,
    handleEditClick,
    handleSendQuoteClick,
    ...restProps
  }) => {
    const { t } = useTranslation();
    const { updateCanvas } = useCanvasActions();
    const styles = useStyles();
    const store = useStore();

    return (
      <List
        style={{ paddingLeft: 0 }}
        subheader={
          <Typography variant="h4" color="primary" gutterBottom style={{ opacity: open || edit ? 0.5 : "unset" }}>
            {subHeaderText}
          </Typography>
        }
        {...restProps}
      >
        {listArray.map((canvas: CanvasModelType) => {
          const showEdit = edit && selectedCanvas?.listType === listType && selectedCanvas?.id === canvas.id;

          return (
            <ListItem
              key={canvas.id}
              button={!showEdit}
              divider
              onClick={showEdit ? undefined : (): Promise<any> => onSelectClick(canvas)}
              style={{ paddingLeft: 0 }}
              disabled={open || (edit && !showEdit)}
              selected={store.currentCanvas?.id == canvas.id}
              classes={{ container: styles.listHoverToViewButtons }}
            >
              {showEdit ? (
                <EditName
                  name={canvas.name || ""}
                  id={canvas.id}
                  onConfirm={async (values: FormikValues): Promise<any> => {
                    await updateCanvas(values);
                    handleEditClick(undefined);
                  }}
                />
              ) : (
                <ListItemText primary={canvas.name || t("Unnamed canvas")} style={{ paddingLeft: 24 }} />
              )}
              {listView && (
                <ListItemSecondaryAction
                  data-id="secondary-actions"
                  style={{ visibility: showEdit ? "visible" : "hidden" }}
                >
                  <IconButton
                    color="secondary"
                    title={showEdit ? "Save Name" : "Edit Name"}
                    aria-label="edit"
                    disabled={open || (edit && !showEdit)}
                    onClick={(): void => handleEditClick(canvas, listType)}
                    classes={{ disabled: styles.disabledListButton }}
                  >
                    {showEdit ? <CheckCircle fontSize="small" /> : <EditIcon fontSize="small" />}
                  </IconButton>
                  <IconButton
                    color="secondary"
                    aria-label="delete"
                    disabled={open || edit}
                    onClick={handleDeleteClick("left", canvas)}
                    classes={{
                      disabled: showEdit ? undefined : styles.disabledListButton,
                    }}
                  >
                    <DeleteIcon fontSize="small" />
                  </IconButton>
                </ListItemSecondaryAction>
              )}
            </ListItem>
          );
        })}
      </List>
    );
  }
);

export default CanvasList;
