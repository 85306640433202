import React from "react";
import { useTranslation } from "react-i18next";

import { PopperPlacementType } from "@material-ui/core";

import CollectionsList from "./CollectionsList";
import { useStore } from "@lib/hooks/useStore";
import { useStyles } from "styles";
import { CollectionModelType } from "models";

const RECENT = "recent";
const ALL = "all";

export interface MyCollectionsDialogListsProps {
  listView: boolean;
  quoteView: boolean;
  edit: boolean;
  open: boolean;
  selectedCollection: CollectionModelType | null;

  onSelectClick: (collection: CollectionModelType) => Promise<any>;

  handleDeleteClick: (
    newPlacement: PopperPlacementType,
    collection: CollectionModelType
  ) => (event: React.MouseEvent<HTMLButtonElement>) => void;

  handleEditClick: (
    collection?: CollectionModelType,
    listType?: string
  ) => void;

  handleSendQuoteClick: (
    collection?: CollectionModelType,
  ) => void;
}

const MyCollectionsDialogLists: React.FC<MyCollectionsDialogListsProps> = ({
  ...restProps
}) => {
  const store = useStore();
  const styles = useStyles();
  const { t } = useTranslation();

  return (
    <div className={styles.myCollectionsDialogListsContainer}>
      <CollectionsList
        listArray={store.userCollectionList}
        listType={ALL}
        {...restProps}
      />
    </div>
  );
};

export default MyCollectionsDialogLists;
