import { Theme, makeStyles } from "@material-ui/core";

import { createStyles } from "@material-ui/styles";

const LIGHT_GRAY_TEXT = "#2B2B2B";
export const LIGHT_GRAY_BACKGROUND = "#F5F5F5";
const LIGHT_GRAY_BACKGROUND_2 = "#E5E5E5";
const DARK_GRAY_TEXT = "#2B2B2B";
const DARK_HEADER = "#2B2B2B";
const DRAWER_BACKGROUND = "#5D6068";
const SEARCH_BACKGROUND = "#DEDFE3";
const ORANGE_BACKGROUND = "#F26A21";
const GREY_BACKGROUND = "#5d5f68";
export const WHITE = "#ffffff";
const DARK_BACKGROUND = "#383B46";
export const MODAL_BACKGROUND = "#363636";

export const WOLF = "#BF2E1A";
export const SUB_ZERO = "#1E9DF3";
export const COVE = "#C4D82D";
export const BEST = "#65B4DD";
export const SEARCH_BAR_HEIGHT = "82px";

const bebasNeue = { fontFamily: "Bebas Neue", letterSpacing: ".06em" };
const helvatica = {
  fontFamily: '"HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, sans-serif',
  letterSpacing: ".06em",
};
const helvetica = { fontFamily: "Helvetica" };
const robotoCondensed = {
  fontFamily: "Roboto Condensed",
  letterSpacing: ".02em",
};

const flex = { display: "flex" };
const flexColumn = { ...flex, flexDirection: "column" };
const centeredVertically = {
  display: "flex",
  alignItems: "center",
};
const boldUppercase = {
  textTransform: "uppercase",
  fontWeight: "bold",
};
const flex11auto = { flex: "1 1 auto" };

/* eslint-disable @typescript-eslint/no-unused-vars */
const useStyles = makeStyles(
  ({ spacing, palette, transitions, breakpoints, ...theme }: Theme) => {
    /* eslint-enable @typescript-eslint/no-unused-vars */

    const appBarHeight = spacing(75 / 8);
    const searchBarHeight = spacing(75 / 8);
    const padding16TopBottom = {
      paddingTop: spacing(2),
      paddingBottom: spacing(2),
    };
    const padding24TopBottom = {
      paddingTop: spacing(3),
      paddingBottom: spacing(3),
    };

    return createStyles({
      flex,
      flexColumn,
      flex11auto,
      padding16TopBottom,
      padding24TopBottom,
      padding32TopBottom: {
        paddingTop: spacing(4),
        paddingBottom: spacing(4),
      },
      margin32TopBottom: {
        marginTop: spacing(4),
        marginBottom: spacing(4),
      },
      margin24TopBottom: {
        marginTop: spacing(3),
        marginBottom: spacing(3),
      },
      spaceBetween: { ...flex, justifyContent: "space-between" },
      paddingLeftRight32: { paddingLeft: spacing(4), paddingRight: spacing(4) },
      padding0: { padding: 0 },
      padding8: { padding: spacing(1) },
      padding16: { padding: spacing(2) },
      margin8: { margin: spacing(1) },
      colorWhite: { color: palette.common.white },
      colorOrange: { color: palette.secondary.main },
      boldUppercase,
      bold: { fontWeight: "bold" },
      centeredVertically,
      margin0: { margin: 0 },
      fontSize16: { fontSize: spacing(2) },
      positionRelative: { position: "relative" },
      positionAbsolute: { position: "absolute" },
      marginAuto: { margin: "auto" },
      textDecorationNone: { textDecoration: "none" },
      flexWrap: { display: "flex", flexWrap: "wrap" },
      flexDirectionUnset: { flexDirection: "unset" },
      width50Percent: { width: "50%" },
      opacityPoint2: { opacity: 0.2 },
      headerToolbar: {
        ...centeredVertically,
        justifyContent: "space-between",
        height: "inherit",
      },
      appBar: {
        backgroundImage: "url('@lib/logos/bkg.dark-grey.gif')",
        height: appBarHeight,
      },
      tabIndicator: { height: spacing(0.5) },
      dialog: {
        minWidth: spacing(40),
        overflow: "unset",
        width: "-webkit-fill-available",
      },
      dialogContainer: { backgroundColor: MODAL_BACKGROUND },
      dialogActionsContainer: {
        justifyContent: "flex-start",
        paddingLeft: spacing(3),
      },
      dialogActionsContainerBottom: {
        justifyContent: "flex-start",
        padding: 0,
      },
      dialogInnerContainer: {
        position: "relative",
        padding: spacing(8),
      },
      dialogCancelBtn: {
        position: "absolute",
        right: 0,
        top: 0,
        transform: "scale(1) translate(50%, -50%)",
        transformOrigin: "100% 0%",
      },
      detailsPdfBtn: {
        backgroundColor: "transparent",
        border: `1px solid ${LIGHT_GRAY_TEXT}`,
        borderRadius: "5px",
        cursor: "pointer",
        padding: spacing(1),
        textAlign: "center",
        color: "#2B2B2B",
        textDecoration: "none",
        verticalAlign: "middle",
        display: "inline-block",
        width: "100%",
        "&:hover": {
          borderColor: palette.primary.dark,
        },
      },
      dialogFullWidthBtn: {
        height: spacing(10),
      },
      homeContainer: {
        position: "relative",
        top: appBarHeight,
        minHeight: `calc(100vh - ${appBarHeight}px)`,
        display: "flex",
        flexDirection: "column",
      },
      mainContent: { flex: "1 0 auto" },
      wallImg: {
        width: "100%",
        height: "100%",
        objectFit: "fill",
      },
      cardImg: {
        width: "100%",
        height: "100%",
        objectFit: "contain",
      },
      cardImgContainerContainer: {
        position: "relative",
        display: "flex",
        justifyContent: "center",
        backgroundColor: "transparent",
      },
      cardImgContainer: {
        height: spacing(48),
        flex: "1 1 auto",
      },
      kitCardContainer: {
        flexDirection: "column",
        alignItems: "normal",
        padding: spacing(2),
      },
      kitCardHeaderContainer: {
        display: "flex",
        flexDirection: "column",
        height: spacing(12),
        marginBottom: spacing(2),
      },
      kitCardHeader: {
        fontFamily: "Roboto",
        fontSize: "22px",
      },
      kitCardHeaderEllipsis: {
        backgroundColor: "transparent",
        border: `1px solid ${LIGHT_GRAY_TEXT}`,
        borderRadius: "4px",
        cursor: "pointer",
        display: "inline-block",
        width: spacing(3),
        height: spacing(3),
        lineHeight: "10px",
        minWidth: spacing(3),
        padding: "0",
        textAlign: "center",
        textDecoration: "none",
        verticalAlign: "middle",
        "&:hover": {
          borderColor: palette.primary.dark,
        },
      },
      popoverClose: {
        width: spacing(3),
        minWidth: spacing(3),
        height: spacing(3),
        position: "absolute",
        bottom: spacing(1),
        right: spacing(1),
      },
      addKitToCollectionBtn: {
        backgroundColor: LIGHT_GRAY_BACKGROUND_2,
        minWidth: spacing(17),
      },
      kitCardBtnOverrides: {
        fontFamily: "Helvetica",
        fontWeight: "bold",
        textTransform: "none",
      },
      searchBarContainer: {
        position: "relative",
        display: "flex",
        width: "100%",
        marginLeft: "15px",
        marginRight: "15px",
      },
      searchBarArea: {
        height: searchBarHeight,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
        zIndex: 5,
        maxWidth: "450px",
        width: "100%",
      },
      searchBar: {
        borderRadius: spacing(10 / 8),
        padding: spacing(1),
        paddingLeft: spacing(4),
        width: "100%",
        height: spacing(42 / 8),
        backgroundColor: palette.common.white,
        fontSize: spacing(2),
        color: "#5A6172",
        ...robotoCondensed,
      },
      searchBarPlaceholder: {
        paddingLeft: "35px",
        textTransform: "uppercase",
      },
      searchBarButton: {
        backgroundColor: palette.secondary.main,
        position: "absolute",
        left: 0,
        borderRadius: spacing(10 / 8),
        height: spacing(42 / 8),
        width: spacing(42 / 8),
        display: "flex",
        alignItems: "center",
        zIndex: 9,
      },
      searchBarCancel: {
        position: "absolute",
        right: 0,
        zIndex: 9,
        width: spacing(5),
        height: "100%",
      },
      buildContainer: {
        ...flexColumn,
        flexGrow: 1,
        minWidth: 0,
        minHeight: spacing(100),
      },
      searchPage: {
        display: "flex",
        position: "relative",
        width: "100%",
        flex: "1 1 auto",
      },
      searchContainer: {
        position: "sticky",
        top: "70px",
        height: "auto",
        padding: "30px 50px",
        borderBottom: `1px solid ${DRAWER_BACKGROUND}`,
        display: "flex",
        alignItems: "center",
        width: "100%",
        zIndex: "1",
        backgroundColor: "white",
      },
      searchValueText: {
        fontSize: spacing(20 / 8),
        lineHeight: `${spacing(20 / 8)}px`,
      },
      searchResultsText: {
        fontSize: spacing(15 / 8),
        color: DARK_GRAY_TEXT,
      },
      formikTextField: {
        marginTop: spacing(2),
        marginBottom: spacing(2),
        width: "100%",
        "& input": {
          padding: "13px 20px",
          borderRadius: spacing(12),
          border: "1px solid #2B2B2B",
        },
        "& input:focus": {
          border: `2px solid ${ORANGE_BACKGROUND}`,
        },
      },
      FormikRadioField: { marginTop: spacing(2), marginBottom: spacing(2) },
      formikTextFieldErrorMessage: {
        paddingLeft: spacing(4),
        color: palette.primary.main,
      },
      collectionKitListContainer: { width: "25%", padding: spacing(1) },
      collectionKitListItemButtonContainer: {
        marginLeft: spacing(2),
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "flex-end",
      },
      collectionKitListItemButton: {
        top: "75%",
        right: spacing(1),
      },
      basicButton: { marginLeft: spacing(1) },
      currentCollection: { ...flex, marginTop: spacing(2) },
      currentCollectionPage: {
        ...flex,
        justifyContent: "space-between",
        marginBottom: spacing(1),
      },
      help: {
        backgroundColor: SEARCH_BACKGROUND,
        height: spacing(48),
        ...centeredVertically,
        justifyContent: "center",
        textAlign: "center",
      },
      helpText: {
        fontWeight: "bold",
        marginTop: spacing(2),
        marginBottom: spacing(5),
      },
      footer: {
        backgroundColor: MODAL_BACKGROUND,
        height: spacing(16),
        ...centeredVertically,
        justifyContent: "space-between",
        padding: spacing(3),
        paddingLeft: spacing(5),
        paddingRight: spacing(5),
        color: palette.common.white,
        textTransform: "uppercase",
        flexWrap: "wrap",
      },
      footerText: { fontSize: spacing(2) },
      helpFooterContainer: { flexShrink: 0 },
      loginButtonContainer: {
        textAlign: "center",
      },
      contactButtonOverrides: {
        fontSize: "12px",
        lineHeight: "0",
        margin: "0",
        height: "auto",
        padding: "0",
      },
      contactButton: {
        display: "flex",
        margin: "auto",
        justifyContent: "space-between",
      },
      drawerHeader: {
        padding: "16px",
        height: "auto",
        position: "sticky",
        top: "0px",
        background: DRAWER_BACKGROUND,
        borderBottom: "1px solid white",
        zIndex: 10,
      },
      drawerContentList: {
        padding: "0",
        overflowX: "hidden",
        overflowY: "auto",
      },
      drawerContentListMarginBottom: {
        padding: "0",
        overflowX: "hidden",
        overflowY: "auto",
        marginBottom: "50px",
      },
      drawerContact: {
        padding: spacing(20 / 8),
        marginBottom: "0",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "auto",
        position: "sticky",
        bottom: "50px",
        background: DRAWER_BACKGROUND,
        borderTop: "1px solid white",
      },
      innerDrawer: {
        position: "relative",
        background: DRAWER_BACKGROUND,
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
        height: "100vh",
        overflowY: "auto",
      },
      drawerContactButton: {
        backgroundColor: ORANGE_BACKGROUND,
        color: palette.common.white,
        width: spacing(280 / 8),
        fontSize: spacing(20 / 8),
        whiteSpace: "nowrap",
        padding: "10px 8px",
        "&:hover": {
          backgroundColor: palette.common.white,
          color: ORANGE_BACKGROUND,
        },
      },
      removeHoverEffect: {
        backgroundColor: ORANGE_BACKGROUND,
        marginTop: spacing(1),
        paddingLeft: spacing(4),
        paddingRight: spacing(4),
        color: palette.common.white,
        height: spacing(7),
        fontSize: spacing(20 / 8),
        borderRadius: spacing(12),
        display: "flex",
        justifyContent: "space-between",
        "&:hover": {
          backgroundColor: ORANGE_BACKGROUND,
        },
      },
      removeButton: {
        fontSize: spacing(9 / 8),
        height: "unset",
        color: palette.common.white,
        minWidth: "unset",
        position: "relative",
        right: spacing(-4 / 8),
        fontWeight: "bold",
        letterSpacing: ".04em",
      },
      removeButtonIcon: {
        fontSize: "18px",
        marginRight: spacing(4 / 8),
      },
      hide: { display: "none" },
      show: { display: "inherit" },
      drawer: {
        width: "325px",
        flexShrink: 0,
        backgroundColor: DRAWER_BACKGROUND,

        [breakpoints.up("lg")]: {
          width: "350px",
        },
        [breakpoints.up("xl")]: {
          width: "400px",
        },
      },
      drawerOverrides: {
        zIndex: 10,
        backgroundColor: DRAWER_BACKGROUND,
        overflow: "unset",
        color: palette.primary.contrastText,
        border: "none",
        position: "sticky",
        top: "70px",
        height: "auto",
      },
      drawerOpen: {
        transition: transitions.create("width", {
          easing: transitions.easing.easeIn,
          duration: transitions.duration.enteringScreen,
        }),
      },
      drawerClose: {
        transition: transitions.create("width", {
          easing: transitions.easing.easeOut,
          duration: transitions.duration.leavingScreen,
        }),
        backgroundColor: DRAWER_BACKGROUND,
        width: spacing(7) + 1,
        [breakpoints.up("sm")]: {
          width: spacing(96 / 8) + 1,
        },
      },
      drawerToggleButton: {
        position: "absolute",
        borderTopLeftRadius: spacing(3),
        borderBottomLeftRadius: spacing(3),
        backgroundColor: palette.secondary.main,
        height: spacing(34 / 8),
        width: spacing(17 / 8),
        right: spacing(0),
        top: spacing(24 / 8),
        left: "-17px",
        paddingLeft: spacing(2 / 8),
        color: palette.common.white,
        transition: transitions.create("left", {
          easing: transitions.easing.easeIn,
          duration: transitions.duration.enteringScreen,
        }),
        "&:hover": {
          transition: transitions.create("left", {
            easing: transitions.easing.easeIn,
            duration: transitions.duration.enteringScreen,
          }),
          left: "-110px",
        },
        "&::after": {
          content: `'Expand Details'`,
          backgroundColor: palette.secondary.main,
          width: "100px",
          display: "inline-block",
          position: "absolute",
          left: "17px",
          height: "34px",
          lineHeight: "34px",
          fontSize: "10px",
          textAlign: "left",
          fontWeight: "600",
          padding: "0 5px",
          textTransform: "capitalize",
        },
        "&.collapsedTxt::after": {
          content: `'Collapsed Details'`,
        },
        "&.expandedTxt::after": {
          content: `'Expand Details'`,
        },
      },

      drawerListIconContainer: {
        height: spacing(5),
        width: spacing(5),
        borderColor: "#7d8086",
        borderStyle: "solid",
        borderRadius: "50%",
        borderWidth: spacing(2 / 8),
        ...centeredVertically,
        justifyContent: "center",
      },
      drawerListIconButtonDisabled: {
        color: SEARCH_BACKGROUND,
      },
      borderDisabled: { borderColor: SEARCH_BACKGROUND },
      drawerListIconBadge: { top: spacing(0.375), right: spacing(0.375) },
      drawerWrap: {
        ...centeredVertically,
        justifyContent: "center",
        flexDirection: "column",
      },
      drawerListIconBadgeContainer: {
        ...centeredVertically,
        justifyContent: "center",
        height: searchBarHeight - 1,
        paddingTop: "20px",
        paddingBottom: "20px",
      },
      editIconButtonContainer: {
        ...centeredVertically,
        justifyContent: "center",
        height: spacing(30 / 8),
        width: spacing(30 / 8),
        backgroundColor: palette.primary.main,
        marginLeft: spacing(1),
        borderRadius: spacing(5 / 8),
      },
      sideDrawerCollectionName: {
        marginBottom: spacing(2),
        ...centeredVertically,
        width: "100%",
      },
      collectionNameContainer: {
        ...centeredVertically,
        justifyContent: "space-between",
        width: "100%",
        minWidth: 0,
      },
      collectionNameForm: {
        minWidth: 0,
        flex: "1 1 auto",
        borderRadius: "30px",
        border: "1px solid #2B2B2B",
        alignItems: "center",
        display: "flex",
        height: "35px",
        overflow: "hidden",
        "& [class*='makeStyles-formikTextField-']": {
          display: "flex",
          marginLeft: "10px",
        },
        "& p": {
          fontSize: "12px",
        },
        "& input": {
          border: "0px",
          borderRadius: "0",
        },
        "& input:focus": {
          border: "0px",
        },
      },
      collectionKitListItem: {
        height: spacing(96 / 8),
        padding: spacing(1),
        alignItems: "flex-start",
        "&:hover": {
          backgroundColor: DARK_BACKGROUND,
        },
      },
      collectionKitListItemDivider: {
        borderBottom: "1px solid #7d8086",
      },
      collectionKitListItemAvatar: {
        height: spacing(80 / 8),
        width: spacing(80 / 8),
        marginRight: spacing(2),
      },
      collectionKitListItemLogo: {
        position: "relative",
        top: spacing(-5 / 8),
      },
      collectionKitListItemRemoveButton: {
        position: "absolute",
        bottom: spacing(1),
        top: "unset",
        transform: "unset",
      },
      collectionListContainer: {
        maxWidth: "calc(100% - 172px)",
        margin: spacing(11),
        minHeight: spacing(550 / 8),
      },
      collectionCardImgContainer: {
        position: "relative",
        width: "100%",
        paddingBottom: `${(10 / 16) * 100}%`, //// wall aspect ratio.
      },
      collectionCardImg: {
        position: "absolute",
        top: 0,
        width: "100%",
        height: "100%",
        objectFit: "contain",
      },
      collectionCardNameContainer: {
        ...centeredVertically,
        paddingTop: spacing(2),
      },
      collectionCardDateContainer: { ...flex, justifyContent: "space-between" },
      buttonWithRoundedCorners: {
        borderRadius: 100,
        paddingLeft: spacing(3),
        paddingRight: spacing(3),
        paddingTop: "13px",
        paddingBottom: "13px",
        minWidth: "160px",
        display: "flex",
        justifyContent: "space-between",
      },
      buttonFilterReset: { height: "26px" },
      dividerThick: { height: spacing(0.25) },
      dividerWhite: { backgroundColor: palette.common.white },
      canvasCtaMessageBubble: {
        position: "relative",
        top: "0",
        left: "0",
        height: "auto",
        borderRadius: "10px",
        padding: "10%",
        backgroundColor: MODAL_BACKGROUND,
        maxWidth: "70%",
        minHeight: "50%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginLeft: "15%",
        flexDirection: "column",
        "&::after": {
          content: `''`,
          position: "absolute",
          top: "calc(50% - 20px)",
          right: "-20px",
          display: "inline-block",
          width: 0,
          height: 0,
          borderTop: "20px solid transparent",
          borderLeft: `30px solid ${MODAL_BACKGROUND}`,
          borderBottom: "20px solid transparent",
        },
        "& svg": {
          width: "56px",
          height: "56px",
        },
      },
      canvasPreviewWallSaveMessageWrap: {
        position: "relative",
        top: "0",
        left: "0",
        height: "100%",
        width: "100%",
        backgroundColor: "rgba(0,0,0,0.5)",
        maxWidth: "100%",
        minHeight: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        margin: "0",
        flexDirection: "column",
        zIndex: 1,
      },
      canvasPreviewWallSaveMessageText: {
        position: "relative",
        top: "0",
        left: "0",
        height: "auto",
        padding: "4% 5%",
        backgroundColor: "white",
        maxWidth: "50%",
        minHeight: "auto",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginLeft: "0",
        flexDirection: "column",
        textTransform: "capitalize",
      },
      filterCtaMessageBubble: {
        position: "sticky",
        top: "220px",
        left: "0",
        height: "auto",
        borderRadius: "10px",
        padding: "10%",
        backgroundColor: MODAL_BACKGROUND,
        maxWidth: "70%",
        display: "none",
        justifyContent: "center",
        alignItems: "center",
        marginLeft: "15%",
        "&::before": {
          content: `''`,
          position: "absolute",
          top: "calc(50% - 20px)",
          left: "-20px",
          display: "inline-block",
          width: 0,
          height: 0,
          borderTop: "20px solid transparent",
          borderRight: `30px solid ${MODAL_BACKGROUND}`,
          borderBottom: "20px solid transparent",
        },
      },
      canvasCtaMessageText: {
        color: palette.common.white,
        fontSize: "32px",
        textAlign: "center",
        fontWeight: 100,
        ...helvetica,
      },
      canvasButtonPrimary: {
        backgroundColor: DARK_BACKGROUND,
        color: palette.common.white,
        justifyContent: "flex-start",
        padding: "6px 10px",
        fontSize: "22px",
        height: "45px",
      },
      actionIconPrimary: {
        background: ORANGE_BACKGROUND,
        color: palette.common.white,
        borderRadius: "10px",
        marginRight: "20px",
        minWidth: "30px",
        minHeight: "30px",
        padding: "5px",
      },
      actionIconSecondary: {
        display: "flex",
        alignItems: "center",
        background: ORANGE_BACKGROUND,
        color: palette.common.white,
        borderRadius: "5px",
        minWidth: "35px",
        minHeight: "35px",
        padding: "7px",
        marginBottom: "5px",
      },
      filterCtaMessageText: {
        color: palette.common.white,
        fontSize: "32px",
        textAlign: "center",
        ...helvetica,
      },
      dividerCanvas: {
        height: spacing(0.5),
        backgroundColor: palette.secondary.main,
        marginTop: spacing(2),
        marginBottom: spacing(3),
      },
      canvasText: {
        marginRight: spacing(16 / 8),
        fontSize: spacing(16 / 8),
      },
      canvasDescriptionArea: { margin: spacing(48 / 8) },
      canvasDescriptionText: {
        marginBottom: spacing(24 / 8),
        fontSize: spacing(24 / 8),
      },
      myCollectionsButtonContainer: {
        ...centeredVertically,
        justifyContent: "space-between",
        padding: spacing(2),
        height: searchBarHeight,
      },
      stackedBtn: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      },
      myCollectionsDialogListsContainer: { maxHeight: spacing(500 / 8) },
      carouselCardContainer: {
        marginLeft: spacing(3),
        marginRight: spacing(3),
      },
      drawerOpenPadding: {
        padding: spacing(6),
        paddingLeft: spacing(12),
        paddingRight: spacing(12),
      },
      drawerClosed: {
        padding: spacing(4),
        paddingTop: spacing(6),
      },
      collectionKitDisplayContainer: {
        height: spacing(75),
        paddingBottom: spacing(4),
        paddingTop: spacing(2),
      },
      collectionKitDisplayDivider: {
        marginTop: spacing(6),
        marginBottom: spacing(6),
        height: spacing(2 / 8),
      },
      borderLightGrey: { border: `1px solid ${LIGHT_GRAY_BACKGROUND_2}` },
      navButtonContainer: {
        height: searchBarHeight,
        ...centeredVertically,
        width: "100%",
        justifyContent: "space-between",
        paddingLeft: spacing(2),
        paddingRight: spacing(2),
      },
      customInput: {
        backgroundColor: "transparent",
        color: palette.common.white,
      },
      editNameInput: {
        "& input": {
          height: "35px",
          padding: "0px 18px",
        },
      },
      collectionInlineEditNameInput: {
        display: "flex",
        "& input": {
          backgroundColor: "transparent",
          color: palette.common.white,
          padding: "0",
        },
      },
      userPageContainer: {
        ...centeredVertically,
        justifyContent: "center",
        backgroundColor: MODAL_BACKGROUND,
        width: "100%",
        minHeight: "100vh",
        paddingLeft: "20%",
        paddingRight: "20%",
      },
      userPagePaper: {
        width: "100%",
        maxWidth: spacing(100),
        postion: "relative",
        marginTop: "25px",
        marginBottom: "25px",
      },
      linkNoStyle: {
        color: "inherit",
        textDecoration: "none",
        display: "flex",
      },
      loginPageCreateAccount: {
        ...flex,
        justifyContent: "flex-end",
        marginRight: spacing(2),
      },
      previewWall: {
        position: "relative",
        margin: "16px auto",
        backgroundColor: LIGHT_GRAY_BACKGROUND,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        overflow: "hidden",
      },
      stickSidebar: {
        position: "sticky",
        top: "75px",
        zIndex: 1,
        overflowY: "auto",
        height: "89vh",
      },
      wallGrid: {
        backgroundSize: "calc((100% / 16) + .2px) 100%, 100% calc((100% / 10) + .2px)",
        backgroundImage: `linear-gradient(to right, ${LIGHT_GRAY_BACKGROUND_2} 1px, transparent 1px), linear-gradient(to bottom, ${LIGHT_GRAY_BACKGROUND_2} 1px, transparent 1px)`,
        backgroundPosition: "-1px -1px",
      },
      wallItem: {
        position: "absolute",
        cursor: "grab",
        "&:hover": {
          "& button": {
            display: "block",
          },
        },
      },
      wallItemButtonClose: {
        position: "absolute",
        cursor: "pointer",
        top: "-10px",
        right: "-10px",
        backgroundColor: ORANGE_BACKGROUND,
        width: "30px",
        height: "30px",
        borderRadius: "50%",
      },
      wallItemButtonCloseIcon: {
        display: "inline-block",
        width: "15px",
        height: "4px",
        backgroundColor: palette.common.white,
        top: "-3px",
        position: "relative",
      },
      confirmButtonSpinner: {
        position: "absolute",
        color: palette.primary.main,
        left: "50%",
      },
      addToCollectionSpinner: {
        color: palette.primary.main,
        position: "absolute",
        top: spacing(-0.5),
        left: spacing(-0.5),
        zIndex: 1,
      },
      accessoriesMenuButton: {
        width: "100%",
        minHeight: spacing(8),
        justifyContent: "space-between",
        paddingLeft: spacing(3),
        paddingRight: spacing(3),
        fontWeight: "bold",
        fontSize: spacing(20 / 8),
        letterSpacing: ".05em",
        border: `${spacing(2 / 8)}px solid`,
        "&:hover": {
          border: `${spacing(2 / 8)}px solid`,
        },
      },
      accessoriesIconButton: {
        backgroundColor: ORANGE_BACKGROUND,
        color: WHITE,
        "&:hover": {
          color: DARK_GRAY_TEXT,
        },
      },
      optionalButton: {
        color: DARK_GRAY_TEXT,
        textTransform: "none",
        height: "unset",
      },
      optionalButtonIcon: { marginRight: spacing(2 / 8) },
      optionalText: {
        color: palette.common.white,
        fontSize: spacing(12 / 8),
        lineHeight: `${spacing(12 / 8)}px`,
        textTransform: "uppercase",
      },
      listAccessoriesText: {
        marginLeft: spacing(2),
        fontSize: spacing(10 / 8),
        fontWeight: "bold",
      },
      detailedSpecContainer: {
        width: spacing(14),
      },
      detailedSpecLink: {
        ...helvetica,
        fontSize: spacing(12 / 8),
        fontWeight: "bold",
        color: palette.secondary.main,
        textDecoration: "none",
      },

      lightGreyUppercase: { ...boldUppercase, color: LIGHT_GRAY_TEXT },
      sectionHeaderWithDivider: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginTop: spacing(4),
        marginBottom: spacing(4),
      },
      headerDivider: {
        flex: "1 1 auto",
        margin: spacing(2),
        height: spacing(0.25),
      },
      logoNavSectionContainer: {
        margin: `${spacing(1)}px 10%`,
        height: "auto",
      },
      logoNavIconContainer: {
        display: "flex",
        justifyContent: "space-between",
        width: "500px",
        height: "auto",
        "& img": {
          height: "auto",
          width: "110px",
        },
      },
      logoNavDivider: {
        flex: "1 1 auto",
        margin: spacing(2),
        position: "relative",
        top: spacing(2),
        height: spacing(0.25),
      },
      logoNavIconWithText: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "center",
      },
      logoNavIconText: {
        fontWeight: "bold",
        margin: spacing(1),
        fontSize: "10px",
      },
      contentGroupContainer: {
        backgroundColor: palette.primary.main,
        position: "relative",
        height: "40vh",
        width: "100%",
        "@media (max-height: 780px)": {
          height: "38vh",
        },
      },
      perfectKitchenContainer: {
        background: palette.common.white,
        overflow: "hidden",
        position: "relative",
        height: "100%",
      },
      perfectKitchenTextContainer: {
        flex: "0 0 50%",
        padding: "5%",
        position: "absolute",
        fontSize: spacing(11),
      },
      perfectKitchenImageContainer: {
        position: "absolute",
        margin: "auto",
        height: "100%",
        width: "100%",
      },
      perfectKitchenImage: {
        height: "100%",
        width: "100%",
      },
      perfectKitchenVideo: {
        objectFit: "cover",
        height: "100%",
      },
      allCompanyIcons: {
        width: spacing(40),
        color: palette.common.white,
        margin: spacing(4),
      },
      aboutSectionContainer: {
        top: "0",
        left: "0",
        right: "0",
        border: "0",
        margin: "0 auto",
        position: "absolute",
        maxWidth: "820px",
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
        height: "100%",
        justifyContent: "flex-end",
      },
      aboutSectionPrimaryText: {
        color: palette.common.black,
        fontSize: "32px",
        padding: spacing(5, 5),
        textAlign: "center",
        "@media (max-height: 730px)": {
          padding: spacing(2, 2),
        },
      },
      aboutSectionSecondaryText: {
        fontFamily: '"HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, sans-serif',
        fontSize: "16px",
        color: palette.common.black,
        padding: spacing(1, 10),
        textAlign: "center",
        width: "100%",
        [breakpoints.up("md")]: {
          width: "60%",
        },
      },
      aboutButtonContainer: {
        padding: spacing(1, 10, 5),
      },
      easyStepContainer: {
        marginBottom: spacing(8),
      },
      detailedStepSectionContainer: {
        padding: "5%",
      },
      detailedStep: { marginBottom: spacing(0) },
      detailedStepInfo: {
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
        alignItems: "center",
        padding: spacing(2),
      },
      detailedStepTextSection: { marginTop: "12%" },
      detailedBackButton: {
        marginBottom: "10px",
      },
      detailedBackArrowSvg: {
        width: "38px",
        height: "36px",
        marginRight: "10px",
      },
      detailedStepAvatar: {
        ...bebasNeue,
        backgroundColor: MODAL_BACKGROUND,
        height: spacing(13),
        width: spacing(13),
        fontSize: spacing(6),
        marginBottom: spacing(4),
      },
      detailedStepDivider: {
        backgroundColor: palette.secondary.main,
        marginBottom: spacing(4),
        height: spacing(0.5),
      },
      detailedStepSubText: { color: DARK_GRAY_TEXT, marginTop: spacing(6) },
      detailedStepImage: {
        objectFit: "contain",
        width: "100%",
        height: "auto",
        border: "1px solid",
        borderColor: palette.divider,
      },
      tryNowSectionContainer: {
        padding: "5%",
        backgroundColor: "white",
      },
      tryNowDivider: { position: "relative", top: spacing(4) },
      tryNowInfoContainer: { marginTop: "5%" },
      tryNowInfoTextContainer: {
        maxWidth: spacing(500 / 8),
        margin: `${spacing(4)}px auto`,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      },
      tryNowInfoHeader: {
        color: palette.common.black,
        textTransform: "uppercase",
        fontWeight: "bold",
        marginBottom: spacing(3),
      },
      tryNowInfoText: { color: DARK_GRAY_TEXT },
      tryNowInfoButton: {
        height: spacing(8),
        width: spacing(200 / 8),
        marginTop: spacing(5),
        paddingLeft: spacing(4),
        paddingRight: spacing(4),
        fontSize: spacing(20 / 8),
        display: "flex",
        justifyContent: "space-between",
      },
      searchLogoButtons: { color: DARK_HEADER },
      wolf: { color: WOLF },
      subZero: { color: SUB_ZERO },
      cove: { color: COVE },
      best: { color: BEST },
      fontSize13: { fontSize: spacing(13 / 8) },
      listProductName: {
        fontSize: spacing(18 / 8),
        lineHeight: `${spacing(18 / 8)}px`,
        display: "-webkit-box",
        WebkitBoxOrient: "vertical",
        WebkitLineClamp: 3,
        overflow: "hidden",
      },
      optionsRelatedText: {
        fontSize: spacing(42 / 8),
        color: DARK_GRAY_TEXT,
      },
      disabledExportAndSend: {
        "&:disabled": {
          backgroundColor: palette.primary.main,
          color: palette.common.white,
          opacity: 0.2,
        },
      },
      carouselNextButton: {
        border: "none",
        borderRadius: "50%",
        position: "absolute",
        top: "50%",
        left: "101%",
        width: spacing(5),
        height: spacing(5),
        backgroundColor: "transparent",
        ...centeredVertically,
        justifyContent: "center",
        outline: "none",
        "&:hover": {
          backgroundColor: `rgba(0, 0, 0, .08)`,
        },
        "&:disabled": {
          display: "none",
        },
      },
      carouselBackButton: {
        border: "none",
        borderRadius: "50%",
        position: "absolute",
        top: "50%",
        left: "-44px",
        width: spacing(5),
        height: spacing(5),
        backgroundColor: "transparent",
        transform: "rotate(180deg)",
        ...centeredVertically,
        justifyContent: "center",
        outline: "none",
        "&:hover": {
          backgroundColor: `rgba(0, 0, 0, .08)`,
        },
        "&:disabled": {
          display: "none",
        },
      },
      carouselSlidesContainer: {
        marginLeft: spacing(-3),
        height: spacing(76),
        outline: "none",
      },
      carouselSlides: {
        marginLeft: spacing(1),
      },
      carouselDotsContainer: {
        margin: "auto",
        width: "fit-content",
      },
      carouselDots: {
        height: spacing(2),
        borderRadius: "50%",
        margin: spacing(1 / 16),
        borderColor: palette.primary.main,
        backgroundColor: palette.primary.main,
        outline: "none",
        "&:hover": {
          opacity: 0.8,
        },
        "&:disabled": {
          opacity: 0.1,
          cursor: "unset",
        },
      },
      loadingContainer: {
        margin: "auto",
        width: "fit-content",
      },
      refreshButtonContainer: {
        display: "flex",
        justifyContent: "center",
        marginTop: spacing(3),
      },
      accountButton: { fontSize: spacing(12 / 8) },
      loginButton: {
        fontSize: spacing(12 / 8),
        height: spacing(5),
        padding: 0,
      },
      loginIcon: {
        marginLeft: spacing(1),
        color: DARK_HEADER,
        fontSize: spacing(5),
      },
      formConfirmButton: {
        height: spacing(56 / 8),
        minWidth: spacing(25),
        display: "flex",
        justifyContent: "space-between",
        fontSize: spacing(20 / 8),
        paddingLeft: spacing(4),
      },
      formForgotPassButton: {
        color: ORANGE_BACKGROUND,
        fontWeight: "bold",
        backgroundColor: "transparent",
        border: "none",
      },
      showGridSwitch: {
        width: spacing(15),
        marginRight: "8px",
      },
      gridIncrements: {
        color: LIGHT_GRAY_TEXT,
        fontSize: "12px",
        marginTop: "4px",
      },
      showGridSwitchText: {
        fontSize: spacing(2),
        position: "relative",
        top: spacing(2 / 8),
      },
      previewThumbnail: {
        width: spacing(8),
        height: spacing(5),
        border: `1px solid ${LIGHT_GRAY_BACKGROUND_2}`,
      },
      dividerModal: { marginBottom: spacing(4), background: "none" },
      loginPageHeader: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "baseline",
      },
      facetContainer: {
        margin: spacing(3),
        marginRight: spacing(3),
        backgroundColor: LIGHT_GRAY_BACKGROUND_2,
        width: "245px",
        marginTop: "25px",

        [breakpoints.up("lg")]: {
          width: "315px",
        },
        [breakpoints.up("xl")]: {
          width: "315px",
        },
      },
      facetCategoryHeader: {
        fontSize: spacing(3),
        marginBottom: spacing(0),
        textTransform: "uppercase",
        ...bebasNeue,
      },
      facetCheckbox: {
        padding: "4px 0",
        height: "auto",
      },
      facetList: {
        "& ul": {
          listStyle: "none",
          paddingLeft: "0",
        },
        "& li": {
          listStyle: "none",
        },
        "& label": {
          marginRight: 0,
        },
      },
      paginationContainer: {
        display: "flex",
        justifyContent: "center",
        marginTop: spacing(4),
      },
      paginationSelected: {
        textDecoration: "underline",
        backgroundColor: "unset !important",
      },
      paginationArrowButton: {
        borderRadius: "50%",
        height: spacing(5),
        width: spacing(5),
        minWidth: spacing(5),
        margin: spacing(2),
      },
      paginationDisabledButton: {
        backgroundColor: `${palette.primary.main} !important`,
        color: `${palette.common.white} !important`,
      },
      tooltipBackground: {
        backgroundColor: MODAL_BACKGROUND,
      },

      listHoverToViewButtons: {
        "&:hover": {
          "& div[data-id='secondary-actions']": {
            visibility: "visible !important",
          },
        },
      },
      disabledListButton: {
        visibility: "hidden",
      },
      exportDialogHeader: {
        padding: spacing(8),
        paddingBottom: spacing(6),
      },
      exportDialogSecondaryContainer: {
        padding: spacing(2),
        display: "flex",
      },
      exportDialogImageContainer: {
        width: "64%",
        margin: "auto",
      },
      exportDialogImage: {
        objectFit: "contain",
        width: "100%",
      },
      exportDialogButton: {
        height: "47%",
        fontSize: spacing(27 / 8),
        textDecoration: "none",
      },
      exportDialogLinkButton: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        width: "36%",
        paddingLeft: spacing(16 / 8),
      },
      exportDialogSuccess: {
        backgroundColor: palette.secondary.main,
        color: palette.common.white,
        borderRadius: spacing(4 / 8),
        padding: spacing(24 / 8),
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      },
      exportDialogButtonLabel: { flexDirection: "column", width: "90%" },
      exportDialogButtonIcon: {
        height: spacing(4),
        width: spacing(4),
        borderRadius: "50%",
        backgroundColor: palette.secondary.main,
        marginBottom: spacing(1),
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: palette.common.white,
      },
      editNameContainer: {
        width: "100%",
        marginRight: spacing(8),
        marginLeft: spacing(2),
        "& div": {
          marginTop: "0",
          marginBottom: "0",
        },
      },
      deletePopper: {
        backgroundColor: DRAWER_BACKGROUND,
        width: spacing(320 / 8),
        padding: spacing(36 / 8),
      },
      deletePopperText: {
        marginBottom: spacing(24 / 8),
        color: palette.common.white,
      },
    });
  },
  { index: 1 }
);

export default useStyles;
