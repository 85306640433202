import React from "react";
import {
  Typography
} from "@material-ui/core";

import UserPageContainer from "./UserPageContainer";


interface DistributorThankYouPageProps { }

const ErrorPage: React.FC<DistributorThankYouPageProps> = () => {

  return (
    <UserPageContainer>
      <Typography variant="h2" color="primary" style={{
        paddingTop: '50px',
        fontWeight: 'bolder'
      }}>
        Error,
      </Typography>
      <Typography variant="h3" color="primary" style={{
        paddingTop: '25px',
        paddingBottom: '50px',
        lineHeight: '1.4'
      }}>
        Something went wrong please contact ...
      </Typography>
    </UserPageContainer>
  );
};

export default ErrorPage;
