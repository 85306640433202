import React from "react";

import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

interface LoginOutlineIconProps extends SvgIconProps {
  outlineColor?: string;
}

const LoginOutlineIcon: React.FC<LoginOutlineIconProps> = ({outlineColor, ...props}) => {
  return (
    <SvgIcon viewBox="0 0 44 44" titleAccess="User Icon" {...props}>
      <circle cx="22" cy="22" r="21" stroke={outlineColor || "white"} strokeWidth="2" />
      <ellipse
        cx="22.1177"
        cy="16.0014"
        rx="3.44118"
        ry="3.40377"
        fill="white"
      />
      <ellipse
        cx="22.1177"
        cy="25.1653"
        rx="6.88235"
        ry="5.23657"
        fill="white"
      />
    </SvgIcon>
  );
};

export default LoginOutlineIcon;
