import React from "react";
import { useTranslation } from "react-i18next";

import { Typography, Button } from "@material-ui/core";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";

import { useStyles } from "styles";
import ButtonWithRoundedCorners from "@lib/components/ButtonWithRoundedCorners";

const Help: React.FC = () => {
  const styles = useStyles();
  const { t } = useTranslation();

  return (
    <div className={styles.help}>
      <div>
        <Typography variant="h1" color="primary" gutterBottom>
          {t("More questions?")}
        </Typography>
        <Typography
          variant="h4"
          color="primary"
          gutterBottom
          className={styles.helpText}
        >
          {t("Email us below")}
        </Typography>
        <a
          href="mailto:info@Inspiringdesign.com"
          target="_blank"
          rel="noopener noreferrer"
          className={styles.textDecorationNone}
          style={{ textDecoration: "none" }}
        >
          <ButtonWithRoundedCorners
            size="large"
            variant="contained"
            color="primary"
            className={styles.contactButton}
            style={{ border: "none" }}
          >
            {t("Contact Us")}
            <ArrowForwardIcon color="secondary" />
          </ButtonWithRoundedCorners>
        </a>
      </div>
    </div>
  );
};

export default Help;
