/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { IObservableArray } from "mobx"
import { types } from "mobx-state-tree"
import { MSTGQLRef, QueryBuilder, withTypedRefs } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { CanvasModel, CanvasModelType } from "./CanvasModel"
import { CanvasModelSelector } from "./CanvasModel.base"
import { CollectionModel, CollectionModelType } from "./CollectionModel"
import { CollectionModelSelector } from "./CollectionModel.base"
import { RootStoreType } from "./index"


/* The TypeScript type that explicits the refs to other models in order to prevent a circular refs issue */
type Refs = {
  canvass: IObservableArray<CanvasModelType>;
  collections: IObservableArray<CollectionModelType>;
}

/**
 * UserBase
 * auto generated base class for the model UserModel.
 */
export const UserModelBase = withTypedRefs<Refs>()(ModelBase
  .named('User')
  .props({
    __typename: types.optional(types.literal("User"), "User"),
    address: types.union(types.undefined, types.null, types.string),
    affiliatedTradeFirm: types.union(types.undefined, types.null, types.string),
    canvass: types.union(types.undefined, types.null, types.array(MSTGQLRef(types.late((): any => CanvasModel)))),
    city: types.union(types.undefined, types.null, types.string),
    collections: types.union(types.undefined, types.null, types.array(MSTGQLRef(types.late((): any => CollectionModel)))),
    email: types.union(types.undefined, types.string),
    firstName: types.union(types.undefined, types.string),
    fullName: types.union(types.undefined, types.string),
    id: types.identifier,
    lastName: types.union(types.undefined, types.string),
    locationId: types.union(types.undefined, types.null, types.integer),
    projectType: types.union(types.undefined, types.string),
    role: types.union(types.undefined, types.string),
    stateCode: types.union(types.undefined, types.null, types.string),
    token: types.union(types.undefined, types.string),
    zipCode: types.union(types.undefined, types.string),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  })))

export class UserModelSelector extends QueryBuilder {
  get address() { return this.__attr(`address`) }
  get affiliatedTradeFirm() { return this.__attr(`affiliatedTradeFirm`) }
  get city() { return this.__attr(`city`) }
  get email() { return this.__attr(`email`) }
  get firstName() { return this.__attr(`firstName`) }
  get fullName() { return this.__attr(`fullName`) }
  get id() { return this.__attr(`id`) }
  get lastName() { return this.__attr(`lastName`) }
  get locationId() { return this.__attr(`locationId`) }
  get projectType() { return this.__attr(`projectType`) }
  get role() { return this.__attr(`role`) }
  get stateCode() { return this.__attr(`stateCode`) }
  get token() { return this.__attr(`token`) }
  get zipCode() { return this.__attr(`zipCode`) }
  canvass(builder?: string | CanvasModelSelector | ((selector: CanvasModelSelector) => CanvasModelSelector)) { return this.__child(`canvass`, CanvasModelSelector, builder) }
  collections(builder?: string | CollectionModelSelector | ((selector: CollectionModelSelector) => CollectionModelSelector)) { return this.__child(`collections`, CollectionModelSelector, builder) }
}
export function selectFromUser() {
  return new UserModelSelector()
}

export const userModelPrimitives = selectFromUser().address.affiliatedTradeFirm.city.email.firstName.fullName.lastName.locationId.projectType.role.stateCode.token.zipCode
