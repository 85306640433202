import React from "react";
import { FormikValues } from "formik";

import FormikTextField from "@lib/components/FormikTextField";
import CollectionForm from "@lib/components/CollectionForm";
import { useStyles } from "styles";

interface EditName {
  name: string;
  id: string;
  onConfirm: (values: FormikValues) => Promise<any>;
}

const EditName: React.FC<EditName> = ({ name, id, onConfirm }) => {
  const styles = useStyles();
  return (
    <div className={styles.editNameContainer}>
      <CollectionForm onConfirm={onConfirm} initialValues={{ name, id }}>
        {({ setFieldTouched, handleSubmit }): FormikValues => {
          const handleBlur = (): void => {
            setFieldTouched("name");
            handleSubmit();
          };

          const handleEnterPress = (event: React.KeyboardEvent): void => {
            if (event.keyCode === 13) {
              handleBlur();
            }
          };

          return (
            <FormikTextField
              name="name"
              type="text"
              onBlur={handleBlur}
              autoFocus
              onKeyDown={handleEnterPress}
              variant="outlined"
              color="secondary"
              fullWidth
              classes={{ root: styles.editNameInput }}
            />
          );
        }}
      </CollectionForm>
    </div>
  );
};

export default EditName;
