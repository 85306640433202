import React from "react";

import Button, { ButtonProps } from "@material-ui/core/Button";

import { useStyles } from "styles";

const buttonFullWidthDialog: React.FC<ButtonProps> = ({
  children,
  ...props
}) => {
  const styles = useStyles();
  return (
    <Button
      variant="contained"
      color="secondary"
      fullWidth
      classes={{
        root: styles.dialogFullWidthBtn,
      }}
      {...props}
    >
      {children}
    </Button>
  );
};

export default buttonFullWidthDialog;
