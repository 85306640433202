import React from "react";
import { Formik, FormikValues } from "formik";
import { observer } from "mobx-react";

import { UserModelType } from "models";
import { useUserActions } from "@lib/hooks/useUserActions";
import { useYupSchema } from "@lib/hooks/useYupSchema";
import { useHistory } from "react-router";
import { ROOT } from "@lib/utils/routes";
import GeneralDialog from "@lib/components/GeneralDialog";
import { useDialogManager } from "@lib/components/DialogManagerContext";

interface UserFormProps {
  children: (formikProps: FormikValues) => React.ReactNode;
  user?: UserModelType;
  changePassword?: boolean;
}

const UserForm: React.FC<UserFormProps> = observer(({ children, user, changePassword = false }) => {
  const { handleCreateUser, handleUpdateUser } = useUserActions();
  const { newUserSchema, userSchema } = useYupSchema();
  const { openDialog, closeDialog } = useDialogManager();
  const history = useHistory();

  const initialValues = {
    firstName: user?.firstName || "",
    lastName: user?.lastName || "",
    email: user?.email || "",
    projectType: user?.projectType || "",
    affiliatedTradeFirm: user?.affiliatedTradeFirm || "",
    zipCode: user?.zipCode || "",
    password: "",
    passwordConfirmation: "",
  };

  const schema = changePassword ? newUserSchema : userSchema;

  const handleGeneralDialogConfirm = () => {
    closeDialog();
  };

  const handleUpsert = async (
    values: any,
    setSubmitting: (isSubmitting: Boolean) => void,
    setFieldError: (field: String, value: String) => void
  ) => {
    try {
      if (user) {
        await handleUpdateUser(values);
        history.goBack();
      } else {
        let response = await handleCreateUser(values);

        if (response.signUp.distributor) {
          openDialog(
            <GeneralDialog
              title="Welcome,"
              titleVariant="h3"
              contentText="Thanks for signing up. Create your dream kitchen by collecting your favorite items, laying them out on a canvas so you can fully envision all your appliances. When you are ready, request a quote so we can help make your inspiring design a reality."
              onConfirm={handleGeneralDialogConfirm}
              onClose={handleGeneralDialogConfirm}
            />
          );
        } else if (!response.signUp.distributor) {
          openDialog(
            <GeneralDialog
              title="Hi,"
              titleVariant="h3"
              contentText="Your project is outside the Roth Living territory. On your behalf, Roth Living has sent your collection to the local Sub-Zero, Wolf, and Cove distributor. You will be contacted via email for further assistance."
              onConfirm={handleGeneralDialogConfirm}
              onClose={handleGeneralDialogConfirm}
            />
          );
        } else {
          history.goBack();
        }
      }
      setSubmitting(false);
    } catch (error) {
      setSubmitting(false);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={user ? schema : newUserSchema}
      onSubmit={async (values, { setSubmitting, setFieldError }): Promise<any> => {
        handleUpsert(values, setSubmitting, setFieldError);
      }}
    >
      {(formikProps): React.ReactNode => <React.Fragment>{children(formikProps)}</React.Fragment>}
    </Formik>
  );
});

export default UserForm;
