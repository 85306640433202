import React from "react";
import { useTranslation } from "react-i18next";

import { Typography, Grid } from "@material-ui/core";

import { useStyles } from "styles";
import Video from "@lib/video/hero-video.mp4";

const PerfectKitchenSection: React.FC = () => {
  const styles = useStyles();
  const { t } = useTranslation();

  return (
    <div className={styles.perfectKitchenContainer}>
      <Grid container className={styles.perfectKitchenImageContainer}>
        <video className={styles.perfectKitchenVideo} width="100%" height="auto" autoPlay muted loop>
          <source src={Video} type="video/mp4" />
        </video>
      </Grid>
    </div>
  );
};

export default PerfectKitchenSection;
