import React from "react";

import { Typography, Divider } from "@material-ui/core";

import { useStyles } from "styles";

interface SectionHeaderWithDividerProps {
  primaryText: string;
  secondaryText: string;
}

const SectionHeaderWithDivider: React.FC<SectionHeaderWithDividerProps> = ({
  primaryText,
  secondaryText,
}) => {
  const styles = useStyles();

  return (
    <div className={styles.sectionHeaderWithDivider}>
      <Typography variant="h1" color="primary">
        {primaryText}
      </Typography>
      <Divider className={styles.headerDivider} />
      <Typography variant="h4" color="primary" className={styles.boldUppercase}>
        {secondaryText}
      </Typography>
    </div>
  );
};

export default SectionHeaderWithDivider;
