import {
  selectFromCollectionKit,
  selectFromCollection,
  selectFromCanvas,
  selectFromCanvasKit,
  selectFromKit,
  selectFromProduct,
  selectFromUser,
  selectFromKitSearch,
} from "models";

///// PSA - You can't use fragments within fragments because then you return the __typename and id twice. Which can lead to duplicate data.

export const PRODUCT_FRAGMENT = selectFromProduct().description.toString();

export const KIT_FRAGMENT = selectFromKit()
  .name.description.imageUrl.secondaryImageUrl.stackable.width.height.depth.family.showOnWall.featured.pdfUrl.accessoryProducts(
    (product) => product.description
  )
  .toString();

export const KIT_SEARCH_FRAGMENT = selectFromKitSearch()
  .edges((edges) =>
    edges.node((node) =>
      node.name.description.imageUrl.secondaryImageUrl.stackable.width.height.depth.family.showOnWall.featured.pdfUrl.accessoryProducts(
        (product) => product.description
      )
    )
  )
  .pageInfo((pageInfo) => pageInfo.id.items.page.next.totalCount.search)
  .aggregations((agg) =>
    agg.id
      .depth((df) => df.buckets((bucket) => bucket.key.docCount))
      .width((df) => df.buckets((bucket) => bucket.key.docCount))
      .height((df) => df.buckets((bucket) => bucket.key.docCount))
      .family((df) => df.buckets((bucket) => bucket.key.docCount))
      .category((df) => df.buckets((bucket) => bucket.key.docCount))
      .superCategory((df) => df.buckets((bucket) => bucket.key.docCount))
  )
  .toString();

export const COLLECTION_COLLECTION_KIT_FRAGMENT = selectFromCollection()
  .collectionKits((colKit) =>
    colKit.optional
      .collection()
      .group()
      .accessoryProducts()
      .kit((kit) =>
        kit.name.description.imageUrl.secondaryImageUrl.stackable.width.height.depth.family.showOnWall.pdfUrl
          .accessoryKits()
          .relatedKits()
          .optionKits()
      )
  )
  .toString();

export const COLLECTION_CANVAS_KIT_FRAGMENT = selectFromCanvas()
  .name.canvasKits((canKit) => canKit.id.collectionKitId.x.y.scale.canvasId.updatedAt)
  .toString();

export const KITS_OPTIONS_RELATED_FRAGMENT = selectFromKit()
  .optionKits((kit) =>
    kit.name.description.imageUrl.secondaryImageUrl.stackable.width.height.depth.family.showOnWall.pdfUrl.accessoryProducts(
      (product) => product.description
    )
  )
  .relatedKits((kit) =>
    kit.name.description.imageUrl.secondaryImageUrl.stackable.width.height.depth.family.showOnWall.pdfUrl.accessoryProducts(
      (product) => product.description
    )
  )
  .accessoryKits(
    (kit) => kit.name.description.imageUrl.secondaryImageUrl.stackable.width.height.depth.family.showOnWall.pdfUrl
  )
  .toString();

export const USER_FRAGMENT = selectFromUser()
  .fullName.firstName.lastName.email.collections((col) => col.name.createdAt.updatedAt.snapshotUrl.collectionKits())
  .toString();
