/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from "mobx-state-tree"
import { QueryBuilder } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { BucketModel, BucketModelType } from "./BucketModel"
import { BucketModelSelector } from "./BucketModel.base"
import { RootStoreType } from "./index"


/**
 * FilterBase
 * auto generated base class for the model FilterModel.
 */
export const FilterModelBase = ModelBase
  .named('Filter')
  .props({
    __typename: types.optional(types.literal("Filter"), "Filter"),
    buckets: types.union(types.undefined, types.null, types.array(types.late((): any => BucketModel))),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  }))

export class FilterModelSelector extends QueryBuilder {
  buckets(builder?: string | BucketModelSelector | ((selector: BucketModelSelector) => BucketModelSelector)) { return this.__child(`buckets`, BucketModelSelector, builder) }
}
export function selectFromFilter() {
  return new FilterModelSelector()
}

export const filterModelPrimitives = selectFromFilter()
