import React from "react";

import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

const AccountIcon: React.FC<SvgIconProps> = props => {
  return (
    <SvgIcon viewBox="0 0 14 18" titleAccess="Account Icon" {...props}>
      <ellipse
        cx="7.11793"
        cy="3.40377"
        rx="3.44118"
        ry="3.40377"
        fill="white"
      />
      <ellipse
        cx="7.1177"
        cy="12.5676"
        rx="6.88235"
        ry="5.23657"
        fill="white"
      />
    </SvgIcon>
  );
};

export default AccountIcon;
