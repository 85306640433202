import React from "react";

import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

const BestIcon: React.FC<SvgIconProps> = props => {
  return (
    <SvgIcon viewBox="0 0 21 26" titleAccess="Best Icon" {...props}>
      <path d="M3.65528 0C3.71378 0 3.77229 0.028921 3.83079 0.028921C4.00629 0.0578421 4.09403 0.115684 4.15253 0.318131C4.18178 0.462736 4.18179 0.607341 4.18179 0.751947C4.18179 2.94994 4.18179 5.17686 4.18179 7.37486C4.18179 7.49054 4.18179 7.57731 4.18179 7.69299C4.18179 7.72191 4.18179 7.77976 4.21104 7.80868C4.26954 8.06896 4.47428 8.18465 4.73753 8.09789C4.85453 8.06896 4.97153 8.01112 5.08853 7.92436C6.14152 7.25918 7.31152 6.85428 8.56925 6.62291C9.44675 6.47831 10.3242 6.44939 11.2017 6.53615C14.2145 6.79644 16.6129 8.18465 18.4264 10.5562C19.3331 11.713 19.8597 13.0723 20.1229 14.5184C20.2691 15.2992 20.3276 16.109 20.2691 16.8899C20.1522 18.7119 19.5671 20.3604 18.4849 21.8354C17.1687 23.6285 15.4429 24.901 13.2492 25.5373C12.5472 25.7397 11.816 25.8843 11.114 25.9422C10.9677 25.9422 10.8215 25.9711 10.6752 26C10.3535 26 10.0317 26 9.70998 26C9.56374 25.9711 9.44675 25.9422 9.3005 25.9422C8.48151 25.8554 7.69176 25.6819 6.90202 25.3927C4.21104 24.3804 2.22205 22.6162 1.08131 19.9844C0.701065 19.1168 0.467078 18.1913 0.379328 17.2369C0.350079 16.7742 0.320825 16.3115 0.320825 15.8487C0.320825 14.3448 0.320825 12.8409 0.320825 11.337C0.320825 8.09789 0.320825 4.85873 0.320825 1.61958C0.320825 1.47497 0.291565 1.33037 0.291565 1.21468C0.291565 0.983315 0.291565 0.780868 0.291565 0.549499C0.320815 0.491657 0.350068 0.433815 0.350068 0.375973C0.408567 0.202447 0.496327 0.0867631 0.671826 0.057842L0.701068 0.028921C1.66631 6.46435e-09 2.66079 0 3.65528 0ZM10.2365 22.1824C10.5875 22.1535 10.9385 22.1246 11.2602 22.0957C12.5765 21.8932 13.6879 21.3437 14.6239 20.3893C16.0864 18.8854 16.6129 17.0634 16.2034 15.01C15.9402 13.7086 15.2674 12.6096 14.2437 11.7709C13.1615 10.8743 11.9037 10.3537 10.4705 10.3248C9.73924 10.2959 9.03724 10.3826 8.33524 10.614C6.55101 11.2214 5.26402 12.3782 4.53278 14.1424C4.15253 15.0389 4.0648 15.9933 4.18179 16.9477C4.26954 17.6418 4.44504 18.307 4.76679 18.9143C5.70278 20.6496 7.721 22.1824 10.2365 22.1824Z" />
    </SvgIcon>
  );
};

export default BestIcon;
