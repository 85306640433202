/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { IObservableArray } from "mobx"
import { types } from "mobx-state-tree"
import { MSTGQLRef, QueryBuilder, withTypedRefs } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { KitModel, KitModelType } from "./KitModel"
import { ProductModel, ProductModelType } from "./ProductModel"
import { ProductModelSelector } from "./ProductModel.base"
import { RootStoreType } from "./index"


/* The TypeScript type that explicits the refs to other models in order to prevent a circular refs issue */
type Refs = {
  accessoryKits: IObservableArray<KitModelType>;
  accessoryProducts: IObservableArray<ProductModelType>;
  optionKits: IObservableArray<KitModelType>;
  relatedKits: IObservableArray<KitModelType>;
}

/**
 * KitBase
 * auto generated base class for the model KitModel.
 */
export const KitModelBase = withTypedRefs<Refs>()(ModelBase
  .named('Kit')
  .props({
    __typename: types.optional(types.literal("Kit"), "Kit"),
    accessoryDescription: types.union(types.undefined, types.string),
    accessoryKits: types.union(types.undefined, types.null, types.array(MSTGQLRef(types.late((): any => KitModel)))),
    accessoryProducts: types.union(types.undefined, types.null, types.array(MSTGQLRef(types.late((): any => ProductModel)))),
    category: types.union(types.undefined, types.null, types.string),
    depth: types.union(types.undefined, types.null, types.integer),
    description: types.union(types.undefined, types.string),
    family: types.union(types.undefined, types.string),
    featured: types.union(types.undefined, types.boolean),
    fullDescription: types.union(types.undefined, types.string),
    height: types.union(types.undefined, types.null, types.integer),
    id: types.identifier,
    imageUrl: types.union(types.undefined, types.string),
    name: types.union(types.undefined, types.string),
    optionDescription: types.union(types.undefined, types.string),
    optionKits: types.union(types.undefined, types.null, types.array(MSTGQLRef(types.late((): any => KitModel)))),
    pdfUrl: types.union(types.undefined, types.null, types.string),
    relatedKits: types.union(types.undefined, types.null, types.array(MSTGQLRef(types.late((): any => KitModel)))),
    secondaryImageUrl: types.union(types.undefined, types.null, types.string),
    showOnWall: types.union(types.undefined, types.boolean),
    stackable: types.union(types.undefined, types.boolean),
    superCategory: types.union(types.undefined, types.null, types.string),
    width: types.union(types.undefined, types.null, types.integer),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  })))

export class KitModelSelector extends QueryBuilder {
  get accessoryDescription() { return this.__attr(`accessoryDescription`) }
  get category() { return this.__attr(`category`) }
  get depth() { return this.__attr(`depth`) }
  get description() { return this.__attr(`description`) }
  get family() { return this.__attr(`family`) }
  get featured() { return this.__attr(`featured`) }
  get fullDescription() { return this.__attr(`fullDescription`) }
  get height() { return this.__attr(`height`) }
  get id() { return this.__attr(`id`) }
  get imageUrl() { return this.__attr(`imageUrl`) }
  get name() { return this.__attr(`name`) }
  get optionDescription() { return this.__attr(`optionDescription`) }
  get pdfUrl() { return this.__attr(`pdfUrl`) }
  get secondaryImageUrl() { return this.__attr(`secondaryImageUrl`) }
  get showOnWall() { return this.__attr(`showOnWall`) }
  get stackable() { return this.__attr(`stackable`) }
  get superCategory() { return this.__attr(`superCategory`) }
  get width() { return this.__attr(`width`) }
  accessoryKits(builder?: string | KitModelSelector | ((selector: KitModelSelector) => KitModelSelector)) { return this.__child(`accessoryKits`, KitModelSelector, builder) }
  accessoryProducts(builder?: string | ProductModelSelector | ((selector: ProductModelSelector) => ProductModelSelector)) { return this.__child(`accessoryProducts`, ProductModelSelector, builder) }
  optionKits(builder?: string | KitModelSelector | ((selector: KitModelSelector) => KitModelSelector)) { return this.__child(`optionKits`, KitModelSelector, builder) }
  relatedKits(builder?: string | KitModelSelector | ((selector: KitModelSelector) => KitModelSelector)) { return this.__child(`relatedKits`, KitModelSelector, builder) }
}
export function selectFromKit() {
  return new KitModelSelector()
}

export const kitModelPrimitives = selectFromKit().accessoryDescription.category.depth.description.family.featured.fullDescription.height.imageUrl.name.optionDescription.pdfUrl.secondaryImageUrl.showOnWall.stackable.superCategory.width
