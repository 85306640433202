/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from "mobx-state-tree"
import { MSTGQLRef, QueryBuilder, withTypedRefs } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { CollectionKitModel, CollectionKitModelType } from "./CollectionKitModel"
import { CollectionKitModelSelector } from "./CollectionKitModel.base"
import { RootStoreType } from "./index"


/* The TypeScript type that explicits the refs to other models in order to prevent a circular refs issue */
type Refs = {
  collectionKit: CollectionKitModelType;
}

/**
 * DestroyCollectionKitProductPayloadBase
 * auto generated base class for the model DestroyCollectionKitProductPayloadModel.
 *
 * Autogenerated return type of DestroyCollectionKitProduct
 */
export const DestroyCollectionKitProductPayloadModelBase = withTypedRefs<Refs>()(ModelBase
  .named('DestroyCollectionKitProductPayload')
  .props({
    __typename: types.optional(types.literal("DestroyCollectionKitProductPayload"), "DestroyCollectionKitProductPayload"),
    /** A unique identifier for the client performing the mutation. */
    clientMutationId: types.union(types.undefined, types.null, types.string),
    collectionKit: types.union(types.undefined, MSTGQLRef(types.late((): any => CollectionKitModel))),
    result: types.union(types.undefined, types.null, types.boolean),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  })))

export class DestroyCollectionKitProductPayloadModelSelector extends QueryBuilder {
  get clientMutationId() { return this.__attr(`clientMutationId`) }
  get result() { return this.__attr(`result`) }
  collectionKit(builder?: string | CollectionKitModelSelector | ((selector: CollectionKitModelSelector) => CollectionKitModelSelector)) { return this.__child(`collectionKit`, CollectionKitModelSelector, builder) }
}
export function selectFromDestroyCollectionKitProductPayload() {
  return new DestroyCollectionKitProductPayloadModelSelector()
}

export const destroyCollectionKitProductPayloadModelPrimitives = selectFromDestroyCollectionKitProductPayload().clientMutationId.result
