import React from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";

import { Button } from "@material-ui/core";

import { useStyles } from "styles";
import EyeIcon from "@lib/logos/EyeIcon";

interface PreviewWallButtonsProps {
  isDisplayedOnWall: boolean;
}

const PreviewWallButtons: React.FC<PreviewWallButtonsProps> = observer(
  ({ isDisplayedOnWall }) => {
    const styles = useStyles();
    const { t } = useTranslation();

    return (
      <div
        className={styles.collectionKitListItemRemoveButton}
        style={{
          bottom: 4,
          color: "white",
          textAlign: "right",
          minWidth: "max-content",
        }}
      >
        {isDisplayedOnWall ? (
          <Button
            size="small"
            classes={{ root: styles.removeButton }}
            style={{ fontSize: 16, height: 24, display: "none" }}
          >
            <span className={styles.removeButtonIcon}>—</span>
            {t("Hide")}
          </Button>
        ) : (
          <Button
            size="small"
            classes={{ root: styles.removeButton }}
            style={{ fontSize: 16, height: 24 }}
          >
            <EyeIcon color="primary" className={styles.removeButtonIcon} />
            {t("Show")}
          </Button>
        )}
      </div>
    );
  }
);

export default PreviewWallButtons;
