import React from "react";
import { FormikValues } from "formik";
import { useTranslation } from "react-i18next";

import ArrowForwardIcon from "@material-ui/icons/ArrowForward";

import DialogModal from "@lib/components/DialogModal";
import { useDialogManager } from "@lib/components/DialogManagerContext";
import FormikTextField from "@lib/components/FormikTextField";
import ButtonWithRoundedCorners from "@lib/components/ButtonWithRoundedCorners";
import ConfirmButtonSpinner from "@lib/components/ConfirmButtonSpinner";
import LoginForm from "./LoginForm";
import { useStyles } from "styles";

const Inputs: React.FC = () => {
  const { t } = useTranslation();
  return (
    <form>
      <FormikTextField
        variant="outlined"
        name="name"
        label={t("Collection Name")}
        type="text"
      />
      <FormikTextField
        variant="outlined"
        name="email"
        label={t("Email")}
        type="email"
      />
      <FormikTextField
        variant="outlined"
        name="password"
        label={t("Password")}
        type="password"
      />
    </form>
  );
};

interface LoginAndSendDialogProps {
  send?: boolean;
}

const LoginAndSendDialog: React.FC<LoginAndSendDialogProps> = ({
  send = true,
}) => {
  const { closeDialog } = useDialogManager();
  const { t } = useTranslation();
  const styles = useStyles();

  return (
    <LoginForm includeCollectionName={true} send={send}>
      {({ isSubmitting, handleSubmit }: FormikValues): React.ReactNode => (
        <DialogModal
          title={
            send
              ? t("Please login to save and send you your collection details")
              : t("Please login to edit your collection")
          }
          inputComponent={<Inputs />}
          onClose={closeDialog}
          dialogActionButtons={
            <ButtonWithRoundedCorners
              color="secondary"
              variant="contained"
              disabled={isSubmitting}
              size="large"
              endIcon={
                <ArrowForwardIcon
                  color={isSubmitting ? "disabled" : "inherit"}
                />
              }
              onClick={(): void => handleSubmit()}
              className={styles.formConfirmButton}
            >
              {send ? t("Save & Send") : t("Login")}
              {isSubmitting && <ConfirmButtonSpinner />}
            </ButtonWithRoundedCorners>
          }
        />
      )}
    </LoginForm>
  );
};

export default LoginAndSendDialog;
