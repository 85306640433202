export const BASE_WALL_WIDTH = 192; //// 192" === 16'
export const BASE_WALL_HEIGHT = 120; //// 120" === 10'

export const findMaxScale = (): number => {
  const screenHeight = window.screen.availHeight;

  switch (true) {
    case screenHeight >= 1050:
      return 730 / BASE_WALL_HEIGHT;
    case screenHeight >= 900:
      return 585 / BASE_WALL_HEIGHT;
    default:
      return 485 / BASE_WALL_HEIGHT;
  }
};

const calcFullScreen = (): number => {
  const screenHeight = window.screen.availHeight;
  const screenWidth = window.screen.availWidth;
  const maxW = screenHeight * (16 / 10);

  if (maxW > screenWidth) {
    return screenWidth / BASE_WALL_WIDTH;
  }

  return maxW / BASE_WALL_WIDTH;
};

export const calculateWallScale = (width: number, isFullScreen: boolean): number => {
  if (isFullScreen) return calcFullScreen();

  const maxWidth = width - 32; //// min 16 margin on each side
  const maxScale = findMaxScale();
  const initialScale = maxWidth / BASE_WALL_WIDTH;
  const scale = initialScale > maxScale ? maxScale : initialScale;

  return scale;
};

export const calculateImageSize = (imageUrl: string): Partial<HTMLImageElement> => {
  const tempImage = new Image();
  tempImage.src = imageUrl;

  return {
    naturalWidth: tempImage.naturalWidth / 10,
    naturalHeight: tempImage.naturalHeight / 10,
  };
};

export const updateWallItemsLocations = (
  prevScale: number,
  scale: number,
  wallContents: { [key: string]: any }
): { [key: string]: any } => {
  const previewWallContents = { ...wallContents };
  Object.keys(previewWallContents).forEach((key) => {
    previewWallContents[key] = updateWallItemLocation(key, prevScale, scale, previewWallContents[key]);
  });

  return previewWallContents;
};

export const updateWallItemLocation = (key: string, prevScale: number, scale: number, wallItem: any) => {
  const { naturalHeight = 0, naturalWidth = 0 } = calculateImageSize(wallItem.imageUrl || "");
  const itemWidth = naturalWidth * scale;
  const itemHeight = naturalHeight * scale;
  const top = wallItem.top * (scale / prevScale);
  const left = wallItem.left * (scale / prevScale);

  return (wallItem = {
    ...wallItem,
    itemWidth,
    itemHeight,
    top,
    left,
  });
};
