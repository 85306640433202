import React from "react";

import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

const WolfMainLogo: React.FC<SvgIconProps> = props => {
  return (
    <SvgIcon
      viewBox="0 0 139 39"
      titleAccess="Wolf Logo"
      {...props}
    >
      <path d="M135.23 0C138.358 0.65507 138.233 2.94781 138.202 5.20781C138.108 14.7391 138.076 24.3031 138.202 33.8344C138.233 36.9459 136.419 38.4199 133.823 38.4199C90.6957 38.3544 47.5683 38.3871 4.40969 38.3871C1.31352 38.3871 0.0312739 37.077 0.0312739 33.8344C0.0312739 24.1721 0.0625488 14.5098 0 4.88027C0 2.62028 0.437842 0.327535 3.56528 0C47.9749 0 90.8208 0 135.23 0ZM69.1164 36.7167C90.6332 36.7167 112.15 36.7167 133.698 36.7167C136.075 36.7167 136.544 36.2254 136.544 33.7689C136.544 24.1066 136.544 14.4443 136.544 4.78201C136.544 2.16173 136.075 1.70318 133.51 1.70318C124.066 1.70318 114.652 1.70318 105.207 1.70318C71.6809 1.70318 38.186 1.70318 4.65988 1.70318C2.25176 1.70318 1.72009 2.25999 1.72009 4.74926C1.72009 9.76054 1.72009 14.8046 1.72009 19.8159C1.72009 24.6306 1.72009 29.4126 1.72009 34.2274C1.72009 35.4065 2.03284 36.2909 3.15871 36.6184C3.69038 36.7822 4.28459 36.7167 4.8788 36.7167C26.2705 36.7167 47.6934 36.7167 69.1164 36.7167Z" />
      <path d="M63.3932 29.8714C59.8592 29.8714 58.0766 29.5766 55.5121 29.1836C52.3221 28.6595 51.0398 27.1856 50.8522 23.8448C50.6958 20.7004 50.6958 17.5234 50.8522 14.379C51.0086 11.1692 52.2283 9.66252 55.2306 9.13846C60.6411 8.22136 66.1141 8.25412 71.5246 9.17122C73.9014 9.56426 75.4651 11.0709 75.5902 13.6257C75.7466 17.3268 75.7153 21.028 75.5902 24.6964C75.4964 27.2839 73.8701 28.7906 71.5246 29.1181C68.8037 29.5111 67.0523 29.8714 63.3932 29.8714ZM57.0758 19.0628C57.0758 20.4712 56.982 21.9123 57.1071 23.3207C57.2635 25.3187 57.9515 26.1048 59.8592 26.203C62.1735 26.3341 64.5191 26.3341 66.8647 26.1703C68.6161 26.072 69.3666 25.3187 69.4605 23.5173C69.5856 20.5694 69.5856 17.6216 69.4605 14.7066C69.3666 12.9379 68.7411 12.25 66.9898 12.119C64.6129 11.9225 62.2048 11.9225 59.8592 12.0863C57.8577 12.25 57.2634 13.0361 57.1383 15.1324C56.982 16.4425 57.0758 17.7526 57.0758 19.0628Z" />
      <path d="M20.172 24.467C26.5207 22.207 32.5254 22.0432 38.7177 24.467C38.7803 23.5826 38.8428 23.0586 38.8428 22.5673C38.8428 20.0125 38.7803 17.425 38.8741 14.8702C38.9679 11.4966 40.9069 9.33484 44.0344 8.97455C45.1915 8.84353 45.4417 9.0073 45.4417 10.2519C45.473 15.427 45.473 20.602 45.4417 25.7771C45.4104 28.2336 43.7529 29.511 41.4386 28.9214C39.3119 28.3974 37.1853 27.9061 35.0899 27.251C31.337 26.1046 27.6153 26.1374 23.8624 27.2838C21.8295 27.9061 19.7342 28.3646 17.6701 28.8887C15.2932 29.511 13.6357 28.2336 13.6044 25.6461C13.5731 20.5365 13.5731 15.3942 13.6044 10.2847C13.6044 8.94179 13.8233 8.77803 15.0743 8.94179C18.1079 9.33484 19.9844 11.3656 20.1407 14.5754C20.2658 17.1302 20.2033 19.6849 20.2033 22.2725C20.172 22.9275 20.172 23.5171 20.172 24.467Z" />
      <path d="M125.316 9.04012C125.786 12.25 123.815 14.7065 120.719 14.772C117.435 14.8702 114.152 14.772 110.868 14.8375C110.305 14.8375 109.367 15.1323 109.304 15.3943C109.148 16.3769 109.241 17.425 109.367 18.4404C109.398 18.6041 110.18 18.7352 110.618 18.7352C113.651 18.7679 116.653 18.7352 119.687 18.7679C120.156 18.7679 120.625 18.7679 121.063 18.8662C122.001 19.1282 122.533 19.9798 122.377 20.9951C122.22 21.9778 121.689 22.6001 120.719 22.6328C119.155 22.6656 117.592 22.6328 116.028 22.6328C114.152 22.6328 112.275 22.6656 110.399 22.6328C109.46 22.6001 109.085 22.9931 109.116 23.9757C109.148 24.8273 109.148 25.7444 109.023 26.596C108.741 28.2337 107.521 29.2818 105.958 29.3145C104.425 29.3145 103.174 28.2992 102.83 26.6615C102.736 26.203 102.736 25.6789 102.736 25.1876C102.736 21.1589 102.736 17.1302 102.736 13.0688C102.736 10.1865 103.925 8.97461 106.614 8.97461C112.338 8.97461 118.061 8.97461 123.815 8.97461C124.347 8.97461 124.847 9.00736 125.316 9.04012Z" />
      <path d="M81.1882 18.0147C81.1882 15.46 81.1882 12.9052 81.1882 10.3177C81.1882 9.00752 81.4384 8.811 82.6894 9.00752C85.8481 9.46607 87.662 11.5623 87.7246 15.0014C87.7871 17.8837 87.7246 20.766 87.7559 23.6811C87.7559 25.286 87.7871 25.286 89.3508 25.3188C92.1655 25.3188 94.9802 25.3188 97.7949 25.3188C98.264 25.3188 98.7332 25.3188 99.2023 25.417C100.172 25.6463 100.797 26.4979 100.766 27.3822C100.735 28.3648 99.9841 29.1837 98.9208 29.2164C97.5135 29.2819 96.1061 29.2164 94.6988 29.2164C91.415 29.2164 88.1311 29.2164 84.8473 29.2164C82.189 29.2164 81.1882 28.1683 81.1882 25.3515C81.1882 22.9278 81.1882 20.4712 81.1882 18.0147Z" />
      <path d="M30.1485 5.63379C30.7114 7.89378 32.0562 9.46595 33.4636 11.0054C34.1516 11.7914 34.7458 12.7085 35.1836 13.6912C35.903 15.2961 35.5277 16.639 34.2141 17.9491C33.745 16.9665 33.2759 16.0166 32.8381 15.0668C32.6817 15.0996 32.5253 15.0996 32.3689 15.1323C32.2751 15.7219 32.15 16.3114 32.0875 16.901C31.806 19.4885 30.3048 20.7332 27.8342 20.4384C24.6442 20.0781 22.6426 16.7372 23.7059 13.4291C24.1125 12.1845 24.7067 11.0709 26.0202 10.1538C26.2079 10.9399 26.3017 11.5949 26.4894 12.2172C26.7083 12.9706 27.0523 13.7239 27.9905 13.5274C28.8349 13.3636 29.1477 12.5775 29.1164 11.7914C29.0539 10.8743 28.8975 9.95725 28.6786 9.07291C28.3345 7.56625 28.9913 6.51813 30.1485 5.63379Z" />
    </SvgIcon>
  );
};

export default WolfMainLogo;
