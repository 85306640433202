import React from "react";
import { size } from "lodash";

import { Typography } from "@material-ui/core";

import CardCarousel from "@lib/components/CardCarousel";
import { KitModelType } from "models";
import { useStyles } from "styles";
import LoadingContainer from "@lib/components/LoadingContainer";

interface OptionsRelatedDisplayProps {
  kits: KitModelType[];
  text: string;
  loading: boolean;
}

const OptionsRelatedDisplay: React.FC<OptionsRelatedDisplayProps> = ({ kits, text, loading }) => {
  const styles = useStyles();

  return (
    <div className={styles.padding24TopBottom}>
      <Typography variant="button" className={styles.optionsRelatedText} gutterBottom>
        {text}
      </Typography>
      {loading ? (
        <LoadingContainer />
      ) : (
        <React.Fragment>{!!size(kits) && <CardCarousel carouselArray={kits} />}</React.Fragment>
      )}
    </div>
  );
};

export default OptionsRelatedDisplay;
