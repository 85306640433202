import { Instance, flow, destroy, types } from "mobx-state-tree";
import { CollectionModelBase } from "./CollectionModel.base";
import { Query } from "mst-gql";
import { CollectionKitModel } from "./CollectionKitModel";

interface CollectionAttributes {
  id: string;
  name: string;
}

/* The TypeScript type of an instance of CollectionModel */
export interface CollectionModelType extends Instance<typeof CollectionModel.Type> {}

/* A graphql query fragment builders for CollectionModel */
export { selectFromCollection, collectionModelPrimitives, CollectionModelSelector } from "./CollectionModel.base";

/**
 * CollectionModel
 */

export const CollectionModel = CollectionModelBase.props({
  selectedCollectionKit: types.maybe(types.reference(CollectionKitModel)),
}).actions((self) => {
  const createCollection = flow(function* (name = ""): Generator {
    try {
      const input = {
        name,
        userId: self.store.currentUser?.id,
        clientMutationId: "createCollection",
      };

      const response: any = yield self.store.mutateCreateCollection({ input }, (res) =>
        res.result.collection((col) => col.name.createdAt.updatedAt)
      );

      const collection = response.createCollection.collection;

      self.store.setCurrentCollection(collection);
      self.store.addCurrentCollectionToUser();

      return response;
    } catch (error) {
      console.error("Collection Model, createCollection: ", error);
      throw error;
    }
  });

  const updateCollection = ({ id, name }: CollectionAttributes): Query => {
    try {
      const isAssociatedWithUser = !!self.store.currentUser?.collections?.some((col) => col.id === id);
      const user = !isAssociatedWithUser && self.store.currentUser?.id ? { userId: self.store.currentUser?.id } : {};
      const input = { id, name, clientMutationId: "UpdateCollection", ...user };

      return self.store.mutateUpdateCollection(
        { input },
        (response) => response.collection((col) => col.name.updatedAt),
        () => {
          const collection = self.store.collections.get(id);
          self.store.currentUser && self.store.addCurrentCollectionToUser();

          if (collection) {
            collection.name = name;
          }
        }
      );
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const setSelectedCollectionKit = (id: string | undefined): void => {
    const previousKit = self.selectedCollectionKit;

    if (self.store.currentCollectionKits?.length > 0) {
      const kit = self.store.collectionkits.get(id || "");
      self.selectedCollectionKit = kit;
    }
    // remove temp collection kits after use
    if (previousKit && !previousKit?.collection) {
      self.store.removeCollectionKitFromStore(previousKit.id);
    }
  };

  const addKitToCollection = flow(function* addKitToCollection(id: string, collectionName?: string): Generator {
    try {
      if (!self.store.currentCollection) {
        yield createCollection(collectionName);
      }

      const input = {
        kitId: id,
        collectionId: self.store.currentCollection?.id || "",
        clientMutationId: "CreateCollectionKit",
      };

      const response: any = yield self.store.mutateCreateCollectionKit({ input }, (res) =>
        res.collectionKit((colKit) =>
          colKit.updatedAt.optional
            .kit()
            .collection((col) =>
              col.collectionKits((colKits) => {
                colKits.optional
                  .collection()
                  .accessoryProducts()
                  .kit((kit) =>
                    kit.name.description.imageUrl.secondaryImageUrl.stackable.width.height.depth.family.showOnWall.pdfUrl
                      .accessoryKits()
                      .relatedKits()
                      .optionKits()
                  );
              })
            )
            .group()
        )
      );

      // const previousKit = self.selectedCollectionKit;

      // Details view add selected kit to collection
      // if (previousKit && !previousKit?.collection) {
      //   const newId = response.createCollectionKit.collectionKit.id;
      //   const kit = self.store.collectionkits.get(newId || "");

      //   self.selectedCollectionKit = kit;

      //   //remove temp collection kits after use
      //   self.store.removeCollectionKitFromStore(previousKit.id);
      // }

      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  });

  const addPreviewImageToCollection = (blobId: string) => {
    return self.store.mutateAttachSnapshotCollection({ blobId, collectionId: self.id }, (res) =>
      res.collection((col) => col.snapshotUrl.updatedAt)
    );
  };

  const sendCollection = flow(function* (input: any): Generator {
    try {
      const response: any = yield self.store.mutateSendCollection(
        { input },
        (res: any) => res.clientMutationId.result.distributorMatch
      );
      return response;
    } catch (error) {
      console.error("Collection Model, createCollection: ", error);
      throw error;
    }
  });

  return {
    updateCollection,
    createCollection,
    setSelectedCollectionKit,
    addKitToCollection,
    addPreviewImageToCollection,
    sendCollection,
  };
});
