import React, { forwardRef, useEffect, useRef, useState } from "react";
import { useLocation, useParams } from "react-router";
import { ButtonBase, styled } from "@material-ui/core";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { Drawer } from "@material-ui/core";
import clsx from "clsx";
import { useStore } from "@lib/hooks/useStore";
import { useStyles } from "styles";

interface SideDrawerProps {
  open: boolean;
  isNotABuildView: boolean;
  children: any;
}

type Children = React.HTMLProps<HTMLDivElement>;

const SideDrawer: React.FC<SideDrawerProps> = ({ children, open, isNotABuildView }) => {
  const styles = useStyles();
  const store = useStore();
  const location = useLocation();
  const [searchView, setSearchView] = useState(false);
  const innerDrawer = useRef<any>(null);
  const { brand = "all", product_id = 1 } = useParams<any>();

  useEffect(() => {
    setSearchView(brand);
  }, [location, store.isDrawerOpen]);

  const onClick = (): void => {
    store.setIsDrawerOpen(!store.isDrawerOpen);
  };

  const ChildrenWithRef = forwardRef<HTMLDivElement, React.HTMLProps<HTMLDivElement>>((props, ref) => (
    <div id="inner-drawer" ref={ref} className={styles.innerDrawer}>
      {props.children}
    </div>
  ));

  return (
    <Drawer
      variant="permanent"
      anchor="right"
      className={clsx(styles.drawer, {
        [styles.drawerOpen]: open,
        [styles.drawerClose]: !open,
        [styles.hide]: isNotABuildView,
      })}
      classes={{
        paper: clsx(
          {
            [styles.drawerOpen]: open,
            [styles.drawerClose]: !open,
          },
          styles.drawerOverrides
        ),
      }}
    >
      {searchView && (
        <ButtonBase
          onClick={onClick}
          className={`${styles.drawerToggleButton} ${open ? "collapsedTxt" : "expandedTxt"}`}
        >
          {open ? <ChevronRightIcon /> : <ChevronLeftIcon />}
        </ButtonBase>
      )}
      <ChildrenWithRef ref={innerDrawer}>{children}</ChildrenWithRef>
    </Drawer>
  );
};

export default SideDrawer;
