import { useTranslation } from "react-i18next";

import { useNotification } from "@lib/components/NotificationManagerContext";
import { useStore } from "./useStore";
import {
  calculateImageSize,
  calculateWallScale,
  updateWallItemLocation,
  updateWallItemsLocations,
} from "@lib/utils/wallHelpers";

interface UsePreviewWallActions {
  handleAddToWall: (colKitId?: any) => void;
  handleRemoveFromWall: () => void;
}

export const usePreviewWallActions = (collectionKitId?: any): UsePreviewWallActions => {
  const { t } = useTranslation();
  const { openNotification } = useNotification();
  const store = useStore();

  const handleAddToWall = (colKitId: any = ""): void => {
    collectionKitId = colKitId != "" ? colKitId : collectionKitId;

    const colKit = store.collectionkits.get(collectionKitId);
    const kit = { ...colKit.kit };

    const { naturalHeight = 0, naturalWidth = 0 } = calculateImageSize(kit.imageUrl || "");

    const itemWidth = naturalWidth * store.previewWallScale;
    const itemHeight = naturalHeight * store.previewWallScale;
    const newTopValue = store.previewWallHeight - itemHeight;
    // snap not stackable items to floor
    let top = kit.stackable ? 0 : newTopValue;
    let left = 0;

    const maxSize = store.previewWallWidth - itemWidth;

    let isSpaceAvailable = false;

    try {
      for (let i = 0; i <= maxSize; i++) {
        const newItemTop = kit.stackable ? 0 : newTopValue;
        const newItem = {
          left: i,
          top: newItemTop,
          right: i + itemWidth,
          bottom: newItemTop + itemHeight,
        };

        isSpaceAvailable = Object.values(store.previewWallContents).every((item: any) => {
          if (item.delete == false) {
            const kitLeft = item.left;
            const kitRight = kitLeft + item.itemWidth;
            const kitTop = item.top;
            const kitBottom = kitTop + item.itemHeight;

            // If new item occupies the same space return false
            if (
              kitLeft < newItem.right &&
              kitRight > newItem.left &&
              kitTop < newItem.bottom &&
              kitBottom > newItem.top
            ) {
              return false;
            }

            left = newItem.left;
            top = newItem.top;

            return true;
          }
        });

        if (isSpaceAvailable) {
          break;
        }
      }

      //if (!isSpaceAvailable) throw t("Unable to add item");

      const canvasKit = getCanvasKit(collectionKitId);
      const wall = updateWallItemLocation(collectionKitId, canvasKit.scale, store.previewWallScale, {
        ...kit,
        left: canvasKit.x,
        top: canvasKit.y,
      });

      if (colKitId != "") {
        left = wall.left;
        top = wall.top;
      }

      const wallItem = {
        [collectionKitId]: {
          ...kit,
          left,
          top,
          itemWidth,
          itemHeight,
          collectionKitId,
          delete: false,
          canvasKitId: canvasKit.id,
        },
      };

      store.addPreviewWallContent(collectionKitId, wallItem);
    } catch (error: any) {
      console.error(error);
      // openNotification(error);
    }
  };

  const getCanvasKit = (id: any) => {
    let collectionKitId = id;
    let currentCanvasKits = store?.currentCanvas?.canvasKits;

    for (let ii = 0; ii < currentCanvasKits.length; ii++) {
      let canvasKit = currentCanvasKits[ii];
      let canvasCollectionKitId = canvasKit.collectionKitId;

      if (canvasCollectionKitId == collectionKitId) {
        let id = canvasKit.id;
        let x = Number(canvasKit.x);
        let y = Number(canvasKit.y);
        let scale = Number(canvasKit.scale);
        return { scale, x, y, id };
      }
    }

    return { scale: store.previewWallScale, x: 0, y: 0, id: null };
  };

  const handleRemoveFromWall = (): any => {
    store.removePreviewWallContent(collectionKitId);
  };

  return { handleAddToWall, handleRemoveFromWall };
};
