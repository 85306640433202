/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from "mobx-state-tree"
import { QueryBuilder } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { RootStoreType } from "./index"


/**
 * CanvasKitBase
 * auto generated base class for the model CanvasKitModel.
 */
export const CanvasKitModelBase = ModelBase
  .named('CanvasKit')
  .props({
    __typename: types.optional(types.literal("CanvasKit"), "CanvasKit"),
    canvasId: types.union(types.undefined, types.string),
    collectionKitId: types.union(types.undefined, types.string),
    id: types.identifier,
    kitId: types.union(types.undefined, types.string),
    scale: types.union(types.undefined, types.string),
    updatedAt: types.union(types.undefined, types.string),
    x: types.union(types.undefined, types.string),
    y: types.union(types.undefined, types.string),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  }))

export class CanvasKitModelSelector extends QueryBuilder {
  get canvasId() { return this.__attr(`canvasId`) }
  get collectionKitId() { return this.__attr(`collectionKitId`) }
  get id() { return this.__attr(`id`) }
  get kitId() { return this.__attr(`kitId`) }
  get scale() { return this.__attr(`scale`) }
  get updatedAt() { return this.__attr(`updatedAt`) }
  get x() { return this.__attr(`x`) }
  get y() { return this.__attr(`y`) }
}
export function selectFromCanvasKit() {
  return new CanvasKitModelSelector()
}

export const canvasKitModelPrimitives = selectFromCanvasKit().canvasId.collectionKitId.kitId.scale.updatedAt.x.y
