import React from "react";
import { IconButton, IconButtonProps } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useDialogManager } from "./DialogManagerContext";
import MyCollectionsDialog from "components/MyCollections/MyCollectionsDialog";
import { useStyles } from "styles";

const ButtonOpenCollectionsList: React.FC<IconButtonProps> = (props) => {
  const { openDialog } = useDialogManager();
  const styles = useStyles();
  return (
    <IconButton
      size="small"
      onClick={(): void => openDialog(<MyCollectionsDialog listView={true} />)}
      {...props}
    >
      <ExpandMoreIcon className={styles.colorWhite} />
    </IconButton>
  );
};

export default ButtonOpenCollectionsList;
