import React, { ErrorInfo } from "react";
import { Notifier } from "@airbrake/browser";

interface Props {
  children: React.ReactNode;
}

class ErrorBoundary extends React.Component<Props> {
  //// TODO don't hard code ID and key!

  airbrake = new Notifier({
    projectId: 261009,
    projectKey: "1b90572a69f848c14ae8135afc8889aa",
    environment: process.env.NODE_ENV
  });

  componentDidCatch(error: Error, info: ErrorInfo): void {
    if (process.env.NODE_ENV === "production") {
      // Send error to Airbrake
      this.airbrake.notify({
        error: error,
        params: { info }
      });
    }
  }

  render(): React.ReactNode {
    return this.props.children;
  }
}

export default ErrorBoundary;
