import React from "react";
import { useTranslation } from "react-i18next";

import { Typography, Divider } from "@material-ui/core";

import UserPageContainer from "components/User/UserPageContainer";
import { useStyles } from "styles";

const TermsConditions: React.FC = () => {
  const { t } = useTranslation();
  const styles = useStyles();

  return (
    <UserPageContainer>
      <Typography variant="h2" color="primary">
        {t("Terms + Conditions")}
      </Typography>
      <Divider className={styles.dividerModal} />

      <Typography variant="body1" gutterBottom>
        EFFECTIVE DATE: 4/27/2022
      </Typography>

      <Typography variant="body1" gutterBottom>
        Welcome to www.inspiringdesign.com, a website and online service of Roth Corporation (“Roth,” “we,” “our,” or
        “us”). This Terms of Use Agreement (this “Agreement”) is where we explain the terms and conditions by which a
        person visiting www.inspiringdesign.com (the “User,” “you,” or “your”) may use this website, our online
        services, and the software provided on this website or in connection with our services (collectively the
        “Site”). Each of you and Roth shall be referred to individually in this Agreement as a “Party,” and you and Roth
        shall be referred to collectively in this Agreement as the “Parties.”
      </Typography>

      <Typography variant="body1" gutterBottom>
        By accessing or using the Site, you signify that:
      </Typography>

      <Typography variant="body1" gutterBottom>
        (i) you have read, understand, and agree to be bound by, this Agreement;
      </Typography>

      <Typography variant="body1" gutterBottom>
        (ii) you agree to the collection and use of your information by us as set forth in our Roth Corporation Website
        Privacy Policy, available at <a href="http://www.inspiringdeisgn.com">www.inspiringdeisgn.com</a> (the “Privacy
        Policy”), and that you have read, understand, and agree to be bound by, the Privacy Policy; and
      </Typography>

      <Typography variant="body1" gutterBottom>
        (iii) you have read, understand, and agree to any other legal notices or conditions or guidelines posted on the
        Site.
      </Typography>

      <Typography variant="body1" gutterBottom>
        Please note that certain sections of the Site may have additional or different terms of use or privacy policies
        applicable to those sections of the Site. Any such additional or different terms will be posted on the
        applicable section of the Site.
      </Typography>

      <Typography variant="body1" gutterBottom>
        If you do not agree to the terms of this Agreement or the Privacy Policy or to other legal notices or conditions
        or guidelines posted on the Site, please do not use the Site. If you have any questions regarding this
        Agreement, please contact us at <a href="mailTo:info@Inspiringdesign.com">info@Inspiringdesign.com</a>
      </Typography>

      <Typography variant="h3" gutterBottom>
        1. CHANGES TO THIS AGREEMENT OR THE SITE
      </Typography>

      <Typography variant="body1" gutterBottom>
        As Roth may determine in our sole discretion, we reserve the right, at any time and without prior notification
        to you, to:
      </Typography>

      <ul>
        <li>(i) amend or modify the terms and conditions of this Agreement;</li>

        <li>(ii) modify the Site;</li>

        <li>
          (iii) suspend or discontinue operation of the Site, or eliminate portions, elements, aspects, or features of
          the Site; and
        </li>

        <li>(iv) terminate, suspend or deny your access to or use of the Site.</li>
      </ul>

      <Typography variant="body1" gutterBottom>
        Upon any termination, suspension or denial by Roth of your access to or use of the Site, you agree to
        immediately discontinue your use of the Site.
      </Typography>

      <Typography variant="body1" gutterBottom>
        If we amend or modify this Agreement, we will change the Effective Date, above. We may, in our sole discretion
        or when legally required, provide you with email notice regarding such update. Your continued use of the Site
        after any amendment or modification of this Agreement constitutes your acceptance of this Agreement as so
        amended or modified.
      </Typography>

      <Typography variant="body1" gutterBottom>
        Certain terms and conditions of this Agreement may be subject to other terms provided by other distributors of
        similar products offered outside the Roth territory or vendors associated with Roth (individually and
        collectively referred to herein as “Site Vendors”).
      </Typography>

      <Typography variant="h3" gutterBottom>
        2. USE OF OUR SERVICES
      </Typography>

      <Typography variant="body1" gutterBottom>
        Roth provides www.inspiringdesign.com as an online product integrator and information platform that offers an
        opportunity to learn more about luxury appliance design and configuration related to products offered by Roth
        and its Site Vendors (the “Service”).
      </Typography>

      <Typography variant="h3" gutterBottom>
        A. ELIGIBILITY
      </Typography>

      <Typography variant="body1" gutterBottom>
        You must be at least eighteen (18) years of age to set up a User Account or use the Site.
      </Typography>

      <Typography variant="h3" gutterBottom>
        B. ACCOUNT REGISTRATION
      </Typography>

      <Typography variant="body1" gutterBottom>
        When you become a registered user of the Site, you agree to: (i) provide true, accurate, current and complete
        information about yourself, including your current e-mail address and other information, as may be prompted by
        registration forms applicable to the Site; and (ii) maintain and update such information so that it is always
        current in our registration records. Information you provide to us is subject to the Privacy Policy. By becoming
        a registered user of the Site, you agree to accept sole responsibility and liability for all activities that
        occur under and or through the user name that you have provided to us, regardless of whether you have authorized
        such activities. Roth reserves the right to decline registration or to provide you with a lower level of service
        based on our network eligibility requirements, for any or no reason, and we may terminate your registration and
        User Account or otherwise deny you access to the Site in our sole discretion without notice or liability.
      </Typography>

      <Typography variant="h3" gutterBottom>
        C. ACCOUNT INFORMATION
      </Typography>

      <Typography variant="body1" gutterBottom>
        You may not transfer or sell your user account for the Site (your “User Account”) or the associated user name or
        account information to any third party. If you are registering as the representative of a commercial entity, you
        personally guarantee that you have the authority to bind the entity to this Agreement.
      </Typography>

      <Typography variant="h3" gutterBottom>
        D. PASSWORD
      </Typography>

      <Typography variant="body1" gutterBottom>
        You are fully responsible for all activity, liability and damage resulting from your failure to maintain the
        confidentiality of any password associated with your User Account. You agree to immediately notify Roth of any
        unauthorized use of any password, or any other breach of security, associated with your User Account. You also
        agree that Roth shall not be liable for any loss or damage arising from your failure to keep your password
        secure. You agree not to provide your user name and password information in combination to any third party
        without Roth's express written permission.
      </Typography>

      <Typography variant="h3" gutterBottom>
        E. SITE CONTENT
      </Typography>

      <Typography variant="body1" gutterBottom>
        “Site Content” means all content of the Site, and shall include, but shall not be limited to, items such as
        advertisements, audiovisual material, biographical material and information, collective works, compilations,
        data, designs, documents, drawings, files, graphs and charts, illustrations, images, interactive features,
        layout, links, logos, modules, music, performances, personal information, photographs, plans, products,
        promotional literature, references and reference material, scripts, sketches, software, supplier and customer
        information, sounds, text, technology, testimonials, trade dress, trade names, trade/ service/certification
        marks, and videos, including the assembly and arrangement of such items and all derivatives thereof.
      </Typography>

      <Typography variant="body1" gutterBottom>
        Unless otherwise expressly stated on the Site, Roth does not endorse any products, services or other things
        appearing on the Site, and Roth does not warrant, and is not responsible for, the accuracy, timeliness,
        reliability, judgment, usefulness, errors or omissions in, or completeness of, any reviews, opinions, advice,
        statements, or other information that appear on the Site; nor is Roth responsible or liable for your reliance
        thereon, and you understand and agree that it is your independent responsibility to evaluate the same.
      </Typography>

      <Typography variant="h3" gutterBottom>
        F. LIMITED LICENSE TO USE THE SITE
      </Typography>

      <Typography variant="body1" gutterBottom>
        Roth grants you access and use of the Site and the Site Content, on a non-exclusive, revocable (at Roth’s sole
        discretion), limited basis on the condition that your access and use is for your informational and personal use
        only (the “License”). Any permitted download or printing of Site Content is subject to your strict compliance
        with the terms and conditions of this Agreement, and any download shall be for temporary storage only.
      </Typography>

      <Typography variant="h3" gutterBottom>
        G. PROHIBITED USES OF THE SITE
      </Typography>

      <Typography variant="body1" gutterBottom>
        By accessing the Site, you agree not to:
      </Typography>

      <ul>
        <li>
          use, display, mirror or frame the Site, any individual element within the Site, the Roth name, trademark, logo
          or other proprietary information, or the layout or design of any page, without our express written consent;
        </li>

        <li>
          access, tamper with, or use non-public areas of the Site, our computer systems, or the technical delivery
          systems of our providers;
        </li>

        <li>
          attempt to probe, scan, or test the vulnerability of any Roth system or network, or breach any security or
          authentication measures;
        </li>

        <li>
          do anything that will in any way prevent or interfere in any manner with Roth’s full and exclusive enjoyment
          of the Site, Site Content or rights therein or Roth Rights;
        </li>

        <li>
          be a party to any contract or agreement that prevents you from fulfilling all your responsibilities and
          obligations concerning the Roth Rights, use of the Site, Site Content or that impairs or may impair any of
          your responsibilities and obligations concerning such use;
        </li>

        <li>
          avoid, bypass, remove, deactivate, impair, or otherwise circumvent or tamper with, any technological measure
          implemented by Roth or any of our providers or any other third party (including another User) to protect the
          Site, Site Contentor Roth Rights;
        </li>

        <li>
          attempt to access or search the Site or Site Content, or scrape or download Site Content, or otherwise use,
          upload content to, or create new links, reposts, or referrals in the Site through the use of any engine,
          software, tool, agent, device or mechanism (including automated scripts, spiders, robots, crawlers, data
          mining tools or the like) other than the software and/or search agents provided by Roth or other generally
          available third-party web browsers;
        </li>

        <li>
          send, in any way that involves the Site, any unsolicited or unauthorized spam or spam comments on posts,
          advertising messages, promotional materials, email, junk mail, chain letters or other form of solicitation;
        </li>

        <li>
          use any meta tags or other hidden text or metadata utilizing a Roth trademark, logo, URL, or product name
          without Roth’s express written consent;
        </li>

        <li>
          use the Site for any commercial purpose or for the benefit of any third party, or in any manner not permitted
          by the terms and conditions of this Agreement, except as otherwise explicitly permitted by Roth;
        </li>

        <li>
          use any information of Roth or of any user of the Site to forge any TCP/IP packet header or any part of the
          header information in any email or newsgroup posting, or in any way use the Site to send altered, deceptive or
          false source-identifying information;
        </li>

        <li>
          attempt to decipher, decompile, disassemble or reverse engineer any of the software used to provide the Site;
        </li>

        <li>
          interfere with, or attempt to interfere with, the access of any user of the Site, or the operation of the Site
          host or network, including, without limitation, sending a virus, overloading, flooding, spamming, or
          mail-bombing the Site;
        </li>

        <li>
          collect or store any personally identifiable information from the Site of other users of the Site without
          their express permission;
        </li>

        <li>impersonate or misrepresent your affiliation with any person or entity;</li>

        <li>violate any applicable law or regulation; or</li>

        <li>
          encourage or enable any other individual to engage in any of the activities prohibited in this Agreement.
        </li>
      </ul>

      <Typography variant="h3" gutterBottom>
        1. IDEA SUBMISSIONS
      </Typography>

      <Typography variant="body1" gutterBottom>
        Unless stipulated otherwise by the terms and conditions of this Agreement, Roth considers any unsolicited
        suggestions, ideas, proposals or other material submitted to it by users via the Site or otherwise
        (collectively, the "Material") to be non-confidential and non-proprietary, and Roth shall not be liable for the
        disclosure or use of such Material. If, at Roth's request, any user sends Material to improve the site (for
        example, through the Site’s forums or to customer support), Roth will also consider that Material to be
        non-confidential and non-proprietary, and Roth shall not be liable for the disclosure or use of any such
        Material. Any communication by you to Roth is subject to this Agreement. You hereby agree to grant to Roth,
        under all of your rights in the Material, a worldwide, non-exclusive, perpetual, irrevocable, royalty-free,
        fully-paid, sublicensable and transferable right and license to incorporate, use, publish and exploit such
        Material for any purpose whatsoever, commercial or otherwise, including but not limited to incorporating it in
        any API, documentation, or any product or service, without compensation or accounting to you and without further
        recourse by you.
      </Typography>

      <Typography variant="h3" gutterBottom>
        2. THIRD-PARTY LINKS
      </Typography>

      <Typography variant="body1" gutterBottom>
        There may be links at or in the Site to other websites maintained by third persons. Roth does not operate or
        control, in any respect, such linked websites or the content provided through them, or endorse or approve the
        content found there. Roth makes no representations as to the content, quality, suitability, functionality, or
        legality of any such links. Your use of such sites is subject to their policies and terms. You assume sole
        responsibility for your use of such links, and you agree and acknowledge that: (i) Roth is not responsible for
        any content posted on such sites, (ii) Roth is not liable to you for any loss or damage whatsoever that you may
        incur as a result of your contact with or use of such sites, and (iii) you waive any claim that you may have
        against Roth with respect to such sites.
      </Typography>

      <Typography variant="h3" gutterBottom>
        3. PROMOTIONS
      </Typography>

      <Typography variant="body1" gutterBottom>
        In addition to the terms and conditions of this Agreement, any contests, sweepstakes or similar promotions
        (collectively, "Promotions") made available through the Site may be governed by specific rules that are separate
        from these Terms. By participating in any such Promotion, you will be subject to those rules, which may vary
        from the terms and conditions set forth herein. We urge you to review any specific rules applicable to a
        particular Promotion, which will be linked from such Promotion, and to review our Privacy Policy, which, in
        addition to the terms and conditions of this Agreement, governs any information that you submit in connection
        with such activities. To the extent that the terms and conditions of such rules conflict with the terms and
        conditions of this Agreement, the terms and conditions of such rules shall control.
      </Typography>

      <Typography variant="body1" gutterBottom>
        By uploading content for Promotions, you understand that Roth and any third-party administrator of such
        Promotions, or anyone acting on behalf of Roth or its partners, respective licensees, successors and assigns
        will, where permitted by law and without any further notice, review or consent, have the right to print,
        publish, broadcast, distribute, and use, worldwide in any media now known or hereafter known, in perpetuity and
        throughout the World, your entry and related content, including, without limitation, your name, portrait,
        picture, voice, likeness, image, and statements about the Contest, and biographical information concerning you
        such as news, publicity or similar information, for trade, advertising, public relations and promotional
        purposes without any further compensation to you.
      </Typography>

      <Typography variant="body1" gutterBottom>
        You represent and warrant that: (i) any entry or content that you upload for Promotions is your original work
        and does not include the work of any third party without the permission of such third party, and that such
        content will not infringe upon the rights of any third party or entity; and (ii) you have obtained all rights,
        licenses, permissions and consents necessary to submit such entry or content and to grant the other rights
        outlined in the rules.
      </Typography>

      <Typography variant="body1" gutterBottom>
        You are always responsible for all federal, state, and local taxes (including income and withholding taxes) for
        which you are liable in connection with any Promotions, and all other costs associated with prize acceptance and
        use. You waive and release Roth from any and all liability that may result from the acceptance and use of any
        awarded prize.
      </Typography>

      <Typography variant="h3" gutterBottom>
        4. DISCLAIMER
      </Typography>

      <Typography variant="body1" gutterBottom>
        ROTH, INCLUDING ITS DIRECTORS, OFFICERS, EMPLOYEES, PARENTS, PARTNERS, AGENTS, CONSULTANTS, SPONSORS,
        DISTRIBUTION PARTNERS, AFFILIATES, SUBSIDIARIES, SUCCESSORS, ASSIGNS, AND THEIR RELATED COMPANIES,
        (COLLECTIVELY, “ROTH PARTIES”) DISCLAIM AND MAKE NO WARRANTIES WHATSOEVER, EXPRESSED OR IMPLIED, INCLUDING BUT
        NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE, NONINFRINGEMENT,
        NON-MISAPPROPRIATION OF INTELLECTUAL PROPERTY RIGHTS, TRADE SECRETS, OR CONFIDENTIAL INFORMATION OF OTHER
        PERSONS, WARRANTIES FOR SERVICES OR PRODUCTS RECEIVED THROUGH OR ADVERTISED ON THE SITE OR ACCESSED THROUGH THE
        SITE, OR WARRANTIES REGARDING THE ACCURACY OR RELIABILITY OF THE RESULTS THAT MAY BE OBTAINED FROM THE USE OF
        THE SITE, AND ANY CLAIMED WARRANTIES THAT MAY ARISE FROM COURSE OF DEALING, COURSE OF PERFORMANCE OR USAGE OF
        TRADE, OR THAT THE SITE WILL MEET YOUR REQUIREMENTS, OR CONCERNING THE ACCURACY, COMPLETENESS, CURRENCY,
        ERROR-FREE STATUS, RELIABILITY, SECURITY, USEFULNESS, OR TIMELINESS OF SITE CONTENT, OR THAT USE OF THE SITE
        WILL NOT BE INTERRUPTED, OR CONCERNING INFORMATION AND SERVICES PROVIDED THROUGH THE SITE, OR THAT DEFECTS OR
        ERRORS IN SOFTWARE WILL BE CORRECTED, OR THAT THE SITE, THE SITE CONTENT, SOFTWARE OR OTHER MATERIAL ACCESSIBLE
        FROM THE SITE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS. YOU AGREE THAT THE SITE AND SITE CONTENT ARE
        PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS AND “WITH ALL FAULTS,” WITHOUT A WARRANTY OF ANY KIND. THIS
        SECTION SHALL SURVIVE THE TERMINATION OF YOUR USE OF THE SITE AND YOUR RELATIONSHIP WITH ROTH.
      </Typography>

      <Typography variant="h3" gutterBottom>
        5. LIMITATION OF LIABILITY
      </Typography>

      <Typography variant="body1" gutterBottom>
        IN NO EVENT, AND NOTWITHSTANDING THE FAILURE OF PURPOSE OF ANY LIMITED REMEDY OF ANY KIND, SHALL ROTH OR ANY OF
        THE ROTH PARTIES BE LIABLE FOR ANY INCIDENTAL, INDIRECT, SPECIAL, CONSEQUENTIAL OR PUNITIVE DAMAGES (INCLUDING
        WITHOUT LIMITATION FOR LOST PROFITS, LOST INCOME, COSTS OF DELAY, FAILURE OF DELIVERY, BUSINESS INTERRUPTION,
        LOST, DAMAGED OR INADVERTENTLY DISCLOSED DATA OR DOCUMENTATION, LOSS OF USE OF THE SITE OR ANY SOFTWARE, THE
        COST OF SUBSTITUTE SOFTWARE, THE COST OF RECOVERING SOFTWARE OR DATA, OR LIABILITIES TO THIRD PARTIES ARISING
        FROM ANY SOURCE) UNDER ANY CONTRACT, NEGLIGENCE, STRICT LIABILITY, TORT OR OTHER THEORY, ARISING OUT OF,
        RELATING OR ATTRIBUTABLE TO, OR IN ANY WAY CONNECTED WITH THE USE OF OR INABILITY TO USE THE SITE, ADVERTISING
        OF OR OFFERING OF PRODUCTS OR SERVICES THROUGH THE SITE, THE SITE CONTENT YOUR ENGAGING OF OR ASSOCIATION WITH
        ANY THIRD PERSON ADVERTISING OR OFFERING PRODUCTS OR SERVICES THROUGH THE SITE, OR ANY SOFTWARE, EVEN IF
        FOREEABLE OR IF ROTH OR THE ROTH PARTIES HAS/HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. YOUR SOLE
        REMEDY FOR DISSATISFACTION WITH THE SITE OR SITE CONTENT IS TO STOP USING THE SITE AND SITE CONTENT. THIS
        LIMITATION OF LIABILITY APPLIES TO CLAIMED DAMAGES ALLEGED TO HAVE BEEN INCURRED BY REASON OF INFORMATION,
        ADVICE, OR CONTENT POSTED BY A THIRD PERSON, OR CONDUCT OF A THIRD PERSON ON THE SITE, SERVICES OR PRODUCTS
        RECEIVED OR ADVERTISED IN CONNECTION WITH THE SITE, AND LINKS ON THE SITE. DESPITE ANYTHING TO THE CONTRARY IN
        THIS LIMITATION, IN NO EVENT SHALL THE CUMULATIVE LIABILITY OF THE ROTH PARTIES EXCEED THE GREATER OF THE TOTAL
        PAYMENTS, IF ANY, MADE IN CONNECTION WITH THE SITE BY YOU TO ROTH DURING THE CONTINUOUS SIX-MONTH PERIOD
        IMMEDIATELY PRECEDING THE DATE THAT YOU NOTIFY ROTH OF A CLAIM, OR ONE HUNDRED DOLLARS ($100). NOTWITHSTANDING
        THE FOREGOING, (1) THE LIMITATION OF LIABILITY DESCRIBED IN THE PREVIOUS SENTENCE SHALL NOT APPLY TO DAMAGES
        CAUSED BY THE GROSS NEGLIGENCE OR WILLFUL MISCONDUCT OF ANY OF THE ROTH PARTIES; AND (2) BECAUSE SOME STATES DO
        NOT ALLOW THE EXCLUSION OR LIMITATION OF LIABILITY FOR INCIDENTAL, INDIRECT, SPECIAL, CONSEQUENTIAL OR PUNITIVE
        DAMAGES, THE ABOVE LIMITATION MAY NOT APPLY TO YOU. ANY CAUSE OF ACTION ARISING OUT OF, RELATING TO, OR IN ANY
        WAY CONNECTED WITH THE SITE, THE TERMS AND CONDITIONS OF THIS AGREEMENT, OR THE PRIVACY POLICY MUST COMMENCE
        WITHIN ONE (1) YEAR AFTER THE CAUSE OF ACTION ACCRUES; OTHERWISE, SUCH CAUSE OF ACTION SHALL BE PERMANENTLY
        BARRED. THIS SECTION SHALL SURVIVE THE TERMINATION OF YOUR USE OF THE SITE AND YOUR RELATIONSHIP WITH ROTH.
      </Typography>

      <Typography variant="h3" gutterBottom>
        6. INDEMNIFICATION
      </Typography>

      <Typography variant="body1" gutterBottom>
        You hereby agree to indemnify, hold harmless and defend Roth and the Roth Parties (collectively “Indemnified
        Parties”), from any and all claims, demands, actions, causes of action, suits at law or in equity, liabilities,
        costs and expenses (including litigation costs and expert witness and attorney fees), loss, and damages (direct
        or consequential) of every kind or nature whatsoever directly or indirectly, arising out of or attributable to
        your access to or use of the Site or Site Content in any way whatsoever posted to the Site by any third person,
        any actual or alleged breach of any of your aforementioned covenants, representations, and warranties, and any
        breach or violation of this Agreement or the Privacy Policy by you, including your acts and omissions. You agree
        to fully cooperate with the terms of this provision at your expense as reasonably required by an Indemnified
        Party. Each Indemnified Party may, at its election, assume the defense and control of any matter for which it is
        indemnified hereunder. You shall not settle any matter involving an Indemnified Party without the consent of the
        applicable Indemnified Party. This section shall survive the termination of your use of the Site and your
        relationship with Roth.
      </Typography>

      <Typography variant="h3" gutterBottom>
        7. CONSUMER PROTECTION NOTICE
      </Typography>

      <Typography variant="body1" gutterBottom>
        Please note that if you are a consumer, the limitations in this Agreement are intended to be only as broad and
        inclusive as is permitted by the laws of your state of residence.
      </Typography>

      <Typography variant="body1" gutterBottom>
        If you are a New Jersey consumer, the terms of the sections titled “Disclaimer,” “Limitation of Liability,” and
        “Indemnification” do not limit or waive your rights under New Jersey law, and the limitations in this Agreement
        are intended to be only as broad and inclusive as is permitted by the laws of the state of New Jersey.
      </Typography>

      <Typography variant="body1" gutterBottom>
        Subject to the foregoing, we reserve all rights, defenses and permissible limitations under the laws of the
        state of New Jersey and under the laws of your state of residence.
      </Typography>

      <Typography variant="h3" gutterBottom>
        8. EXPORT LAWS
      </Typography>

      <Typography variant="body1" gutterBottom>
        Under no circumstances will any provision of the terms and conditions of this Agreement require you or any third
        party to do anything that violates any export, import, or other applicable law, regulation, or order of the
        United States, or of any state or local government. You agree that you will not download any software related to
        the Site, or ship, transfer, or export or re-export any such software to any country, or use any such software,
        in any manner prohibited by the United States Export Administration Regulations, by regulations administered by
        the Office of Foreign Assets Control of the U.S. Treasury Department, or by any other export laws, restrictions,
        or regulations. This section shall survive the termination of your use of the Site and your relationship with
        Roth.
      </Typography>

      <Typography variant="h3" gutterBottom>
        9. NO CLASS ACTIONS/JURY TRIAL
      </Typography>

      <Typography variant="body1" gutterBottom>
        TO THE EXTENT ALLOWED BY LAW, EACH OF YOU AND ROTH, WAIVE ANY RIGHT TO: (I) PURSUE DISPUTES ON A CLASSWIDE BASIS
        - THAT IS, TO EITHER JOIN A CLAIM WITH THE CLAIM OF ANY OTHER PERSON OR ENTITY, OR ASSERT A CLAIM IN A
        REPRESENTATIVE CAPACITY ON BEHALF OF ANYONE ELSE IN ANY LAWSUIT, ARBITRATION, OR OTHER PROCEEDING, ANYWHERE IN
        THE WORLD; AND (II) TRIAL BY JURY IN ANY LAWSUIT, ARBITRATION, OR OTHER PROCEEDING, ANYWHERE IN THE WORLD. THIS
        SECTION SHALL SURVIVE THE TERMINATION OF YOUR USE OF THE SITE AND YOUR RELATIONSHIP WITH ROTH.
      </Typography>

      <Typography variant="h3" gutterBottom>
        10. EQUITABLE RELIEF
      </Typography>

      <Typography variant="body1" gutterBottom>
        You acknowledge that a violation or attempted violation of any of the terms of this Agreement by you or your
        affiliates, partners, or agents will cause such damage to Roth as will be irreparable, the exact amount of which
        would be impossible to ascertain and for which there will be no adequate remedy at law. Accordingly, you agree
        that Roth shall, in addition to any other remedies available at law or in equity, be entitled to specific
        performance or temporary, preliminary and permanent injunctive relief to enforce the terms and conditions of
        this Agreement without the necessity of proving inadequacy of legal remedies or irreparable harm, or posting
        bond, any such requirements to equitable and injunctive relief being hereby specifically waived. You agree that
        Roth shall be entitled as a matter of right to recover from you any and all costs and expenses sustained or
        incurred by Roth in obtaining any such equitable and injunctive relief, including, without limitation,
        reasonable attorneys’ fees. This section shall survive the termination of your use of the Site and your
        relationship with Roth.
      </Typography>

      <Typography variant="h3" gutterBottom>
        11. DISPUTE RESOLUTION
      </Typography>

      <Typography variant="body1" gutterBottom>
        In the event of any dispute regarding the Site, Site Content, the terms and conditions of this Agreement, or any
        other matter concerning the Site, you and Roth agree to first contact each other and provide a written
        description of the issues and proposed resolution. You may use the contact information provided herein for Roth,
        and Roth may contact you using information you provide and or have provided to Roth. Except for the right of
        Roth to bring suit to obtain equitable relief, any dispute arising under this Agreement shall be resolved
        through a mediation/arbitration approach. The Parties agree to select a mutually agreeable, neutral third person
        to help mediate any dispute that arises under this Agreement. If the Parties are unable to agree on a mediator,
        selection of a mediator shall use the same process described below regarding selection of a single arbitrator.
        If the mediation is unsuccessful, the Parties agree that the dispute shall be decided by binding arbitration
        using a single-member panel selected by the Parties under the rules of the American Arbitration Association
        (“AAA”) in Denver, Colorado, in accordance with the Commercial Arbitration Rules as supplemented by the AAA’s
        Supplementary Procedures for Consumer-Related Disputes. The Parties agree not to pursue arbitration on a
        class-wide basis and that any arbitration will be solely between the Parties (not brought on behalf of or
        together with another person’s claim). If, for any reason, a court or arbitrator holds that this restriction is
        unconscionable or unenforceable, then the agreement to arbitrate does not apply and the dispute must be pursued
        in court. If the Parties are unable to agree on the arbitrator, then each Party shall choose one arbitrator, and
        the arbitrators so chosen shall jointly select a third arbitrator, who shall serve as the sole arbitrator for
        purposes of resolving the dispute. In all events, the arbitrator so selected shall have substantial experience
        in arbitrating commercial disputes, and in the area of law concerning the dispute. Such selection shall be final
        and binding upon the Parties. Each Party shall treat and have the arbitrator treat any information relating to
        or disclosed by any Party in connection with the arbitration as confidential business information. The
        arbitration shall be a confidential proceeding closed to the public. Discovery shall be permitted pursuant to
        the applicable arbitration rules. The length and scope of the arbitration hearing may not exceed three (3) days.
        In deciding any arbitration under this section, the arbitrator shall apply the substantive law of the state of
        Colorado, exclusive of its laws governing conflicts of law. However, matters relating to the enforceability of
        this section, to the procedures to be followed in carrying out any arbitration, and to any award granted under
        this section shall be governed by the Federal Arbitration Act, 9 U.S.C. §§ 1-16. Arbitrable matters shall
        include: (i) matters concerning the scope, construction and enforcement of this section; and (ii) material
        matters that arise under or relate to this Agreement, including the applicability of the laws of the state of
        Colorado to any provision of this Agreement. The arbitrator’s award will consist of a written statement stating
        the disposition of each claim, and will provide a statement of the essential findings and conclusions on which
        the award is based. The arbitrator will be empowered to award money damages only and not punitive damages, and a
        ruling by the arbitrator is subject to appellate review of errors of law. Notwithstanding the foregoing, the
        decision of the arbitrator shall be final and binding on the Parties, and may be entered and enforced in any
        court of competent jurisdiction by either Party. Costs and fees associated with any mediation shall be shared
        equally by the Parties. The prevailing Party, as determined by the arbitrator, in any arbitration proceedings
        shall be awarded reasonable attorney fees, expert witness costs and expenses, and all other costs and expenses
        incurred directly or indirectly in connection with the proceedings, unless the arbitrator shall for good cause
        determine otherwise. This section shall survive the termination of your use of the Site and your relationship
        with Roth.
      </Typography>

      <Typography variant="h3" gutterBottom>
        12. GOVERNING LAW AND VENUE
      </Typography>

      <Typography variant="body1" gutterBottom>
        The Terms, including your rights and obligations and those of Roth e and the Roth Parties, shall be governed and
        construed in accordance with the laws of the State of Colorado without regard to conflicts of law provisions or
        laws. The court and authorities of the State of Colorado and the Federal District Court for Colorado shall have
        sole and exclusive jurisdiction over all controversies, which may arise with respect to the Terms that are not
        subject to the dispute resolution provisions of the Terms, including enforcement of all rights herein. You
        hereby expressly waive any jurisdictional, venue, or inconvenient forum objections which you might be entitled
        by virtue of domicile or otherwise and further agree and consent to be subject to the jurisdiction of such
        courts and any order that may be issued by them in reference to the Terms. This section shall survive the
        termination of your use of the Site and your relationship with Roth.
      </Typography>

      <Typography variant="h3" gutterBottom>
        13. MISCELLANEOUS
      </Typography>

      <Typography variant="body1" gutterBottom>
        This Agreement, together with the documents specifically described herein as containing terms to which you agree
        pursuant to this Agreement, contains the entire understanding of the Parties and supersedes and merges herein
        all prior agreements, representations, courses of conduct and dealing between the Parties concerning the subject
        matter hereof, and undertakings of the Parties, whether oral or written, concerning the subject matter herein
        contained and may not be altered, modified, amended, or waived, in whole or in part, in any way except by Roth.
        Should any provision of this Agreement be void or unenforceable, such provision shall be deemed omitted, and the
        remainder of this Agreement with such provision omitted shall remain in full force and effect. No waiver by Roth
        of a breach of any provision of this Agreement shall be deemed to be a waiver of any preceding or succeeding
        breach of any provision of this Agreement. The terms and conditions of this Agreement shall not be supplemented
        or modified by any course of dealing or usage of trade. You may not transfer or assign any of your, rights,
        responsibilities or obligations hereunder, in whole or in part, without Roth’s prior written consent. Any
        transfer or assignment without such written consent shall be null and void from the beginning and of no force or
        effect. Roth may assign any of its rights or obligations under this Agreement without your consent and without
        notice to you. This section shall survive the termination of your use of the Site and your relationship with
        Roth.
      </Typography>

      <Typography variant="h3" gutterBottom>
        14. COPYRIGHT
      </Typography>

      <Typography variant="body1" gutterBottom>
        We respect the intellectual property rights of others and expect our Users to do the same. It is Roth’s policy,
        in appropriate circumstances and at its discretion, to disable and/or terminate the User Accounts of users who
        repeatedly infringe or are repeatedly charged with infringing the copyrights or other intellectual property
        rights of others.
      </Typography>

      <Typography variant="body1" gutterBottom>
        In accordance with the Digital Millennium Copyright Act (the “DMCA”), if you are a copyright owner, or a person
        authorized to act on behalf of the copyright owner to assert infringement of the copyright, and you believe that
        a work subject to such copyright has been infringed by material appearing on the Site, you may send a written
        notice of claimed infringement to Roth that includes the following information:
      </Typography>

      <ul>
        <li>
          (i) identification of the copyrighted work claimed to have been infringed, or, if multiple copyrighted works
          at the Site are covered by a single notification, a representative list of such works at the Site;
        </li>

        <li>
          (ii) identification of the material that is claimed to be infringing or to be the subject of infringing
          activity and that is to be removed or access to which is to be disabled, and information reasonably sufficient
          to permit Roth to locate the material;
        </li>

        <li>
          (iii) information reasonably sufficient to permit Roth to contact you, such as an address, telephone number,
          and, if available, an electronic mail address at which you may be contacted;
        </li>

        <li>
          (iv) a statement that you have a good-faith belief that the alleged infringing use is not authorized by you as
          the copyright owner, or by your agent, or by law;
        </li>

        <li>
          (v) a statement by you under penalty of perjury that the information in the notice is accurate and that you
          are the copyright owner or are authorized to act on the owner's behalf; and
        </li>

        <li>
          (i) a physical or electronic signature of you, the copyright owner or a person authorized to act on behalf of
          the owner to assert infringement of the copyright and to submit the notice of claimed infringement.
        </li>
      </ul>

      <Typography variant="body1" gutterBottom>
        Address a notice of claimed infringement to Roth’s designated agent:
      </Typography>

      <Typography variant="body1" gutterBottom>
        Roth Corporation <br />
        742 S. Broadway <br />
        Denver, CO 80209
      </Typography>

      <Typography variant="body1" gutterBottom>
        Roth will investigate such notices and take appropriate action under the DMCA. Inquiries that do not follow this
        procedure may not receive a response.
      </Typography>

      <Typography variant="body1" gutterBottom>
        This section shall survive the termination of your use of the Site and your relationship with Roth.
      </Typography>
    </UserPageContainer>
  );
};

export default TermsConditions;
