/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from "mobx-state-tree"
import { MSTGQLRef, QueryBuilder, withTypedRefs } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { CollectionModel, CollectionModelType } from "./CollectionModel"
import { CollectionModelSelector } from "./CollectionModel.base"
import { RootStoreType } from "./index"


/* The TypeScript type that explicits the refs to other models in order to prevent a circular refs issue */
type Refs = {
  collection: CollectionModelType;
}

/**
 * UpdateCollectionPayloadBase
 * auto generated base class for the model UpdateCollectionPayloadModel.
 *
 * Autogenerated return type of UpdateCollection
 */
export const UpdateCollectionPayloadModelBase = withTypedRefs<Refs>()(ModelBase
  .named('UpdateCollectionPayload')
  .props({
    __typename: types.optional(types.literal("UpdateCollectionPayload"), "UpdateCollectionPayload"),
    /** A unique identifier for the client performing the mutation. */
    clientMutationId: types.union(types.undefined, types.null, types.string),
    collection: types.union(types.undefined, types.null, MSTGQLRef(types.late((): any => CollectionModel))),
    result: types.union(types.undefined, types.null, types.boolean),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  })))

export class UpdateCollectionPayloadModelSelector extends QueryBuilder {
  get clientMutationId() { return this.__attr(`clientMutationId`) }
  get result() { return this.__attr(`result`) }
  collection(builder?: string | CollectionModelSelector | ((selector: CollectionModelSelector) => CollectionModelSelector)) { return this.__child(`collection`, CollectionModelSelector, builder) }
}
export function selectFromUpdateCollectionPayload() {
  return new UpdateCollectionPayloadModelSelector()
}

export const updateCollectionPayloadModelPrimitives = selectFromUpdateCollectionPayload().clientMutationId.result
