import React from "react";
import { Formik, FormikValues } from "formik";

import { useYupSchema } from "@lib/hooks/useYupSchema";

export interface CanvasAttributes {
  id: string;
  name: string;
}

interface CanvasFormProps {
  onConfirm: (values: FormikValues) => void;
  children: (formikProps: FormikValues) => React.ReactNode;
  initialValues?: CanvasAttributes;
}

const CanvasForm: React.FC<CanvasFormProps> = ({ children, onConfirm, initialValues = { name: "", id: "" } }) => {
  const { canvasSchema } = useYupSchema();

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      validationSchema={canvasSchema}
      onSubmit={async (values, { setSubmitting }): Promise<any> => {
        await onConfirm(values);
        setSubmitting(false);
      }}
    >
      {(formikProps): React.ReactNode => <React.Fragment>{children(formikProps)}</React.Fragment>}
    </Formik>
  );
};

export default CanvasForm;
