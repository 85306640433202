import { useState } from "react";
import { useHistory, useLocation } from "react-router";
import { useTranslation } from "react-i18next";

import { useQuery } from "models";
import { CreateUserInput, UpdateUserInput } from "models/RootStore.base";
import { useNotification } from "@lib/components/NotificationManagerContext";
import { useCollectionActions } from "./useCollectionActions";
import { ROOT, LOGIN, SEND_RESET_THANK_YOU } from "@lib/utils/routes";
interface UseUserActions {
  handleLogin: (email: string, password: string, redirect?: boolean) => Promise<any>;
  handleLoginAndSend: (email: string, password: string, name: string, send: boolean) => Promise<any>;
  handleSendResetPasswordLink: (email: string) => Promise<any>;
  handleResetUserPassword: (password: string, passwordConfirmation: string, resetPasswordToken: string) => Promise<any>;
  handleCreateUser: (values: any) => Promise<any>;
  handleUpdateUser: (values: any) => Promise<any>;
  handleQueryUser: () => Promise<any>;
  loading: boolean;
}

export const useUserActions = (): UseUserActions => {
  const { store } = useQuery();
  const { openNotification } = useNotification();
  const history = useHistory();
  const { t } = useTranslation();
  const { updateCollection, createCollection } = useCollectionActions();
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const token = new URLSearchParams(location.search).get("token");

  const handleLogin = async (email: string, password: string, redirect = true): Promise<any> => {
    try {
      const response = await store.userStore?.loginUser(email, password);

      if (response?.login == null) {
        openNotification("No Account Found", false);
      } else if (response?.login?.user == null) {
        openNotification("Check Email or Password and Try Again.", false);
      }
    } catch (error) {
      openNotification(t("Invalid Credentials"));
    }
  };

  const handleUpdateUser = async (values: UpdateUserInput): Promise<any> => {
    try {
      await store.currentUser?.updateUser(values);
      openNotification(t("Profile successfully updated"), false);
    } catch (error: any) {
      error.response.errors.forEach((error: any) => {
        openNotification(error.message, false);
      });
    }
  };

  const handleCreateUser = async (values: CreateUserInput): Promise<any> => {
    try {
      const response = await store.userStore?.createUser(values);
      openNotification("Account Created!");

      return response;
    } catch (error: any) {
      let message = "";

      error.response.errors.forEach((error: any) => {
        message += error.message;

        error.fields.email.forEach((val: any) => {
          message += ", Email: " + val;
        });
      });
      openNotification(message);
    }
  };

  const handleQueryUser = async (): Promise<any> => {
    try {
      setLoading(true);
      await store.queryMe(undefined, (res: any) =>
        res.collections((col: any) => col.id.name.snapshotUrl.updatedAt.collectionKits())
      );
    } catch (error) {
      console.error(error);
      // openNotification();
    } finally {
      setLoading(false);
    }
  };

  const handleLoginAndSend = async (email: string, password: string, name: string, send: boolean): Promise<any> => {
    try {
      await handleLogin(email, password, false);

      if (send) {
        await updateCollection({ id: store.currentCollection?.id, name });
        //await sendCollection();
      } else {
        store.currentCollection
          ? await updateCollection({ id: store.currentCollection?.id, name })
          : await createCollection(name);
      }
    } catch (error) {
      console.error("Login and Send:", error);
    }
  };

  const handleSendResetPasswordLink = async (email: string): Promise<any> => {
    try {
      const results = await store.userStore.sendResetPasswordLink(email);

      if (results.sendResetPasswordLink.result) {
        openNotification("Check Your Email for Reset link.", false);
        history.push(SEND_RESET_THANK_YOU);
      } else {
        openNotification("Couldn't find email in DataBase.", false);
      }
    } catch (error: any) {
      error.response.errors.forEach((error: any) => {
        openNotification(error.message);
      });
    }
  };

  const handleResetUserPassword = async (
    password: string,
    passwordConfirmation: string,
    resetPasswordToken: string
  ): Promise<any> => {
    try {
      const results = await store.userStore.resetUserPassword(password, passwordConfirmation, resetPasswordToken);

      if (results.resetUserPassword.result) {
        openNotification("Reset complete, Please Login", false);

        history.push({
          pathname: LOGIN,
          search: "?passreset=success",
        });
      } else {
        openNotification("Token is Invalid. Please Try Again.", false);
      }
    } catch (error: any) {
      error.response.errors.forEach((error: any) => {
        openNotification(error.message);
      });
    }
  };

  return {
    handleLogin,
    handleCreateUser,
    handleUpdateUser,
    handleQueryUser,
    handleLoginAndSend,
    handleSendResetPasswordLink,
    handleResetUserPassword,
    loading,
  };
};
