import { string, object, ObjectSchema } from "yup";
import { useTranslation } from "react-i18next";

interface UseYupSchema {
  collectionSchema: ObjectSchema;
  canvasSchema: ObjectSchema;
  loginSchema: ObjectSchema;
  newUserSchema: ObjectSchema;
  userSchema: ObjectSchema;
  loginWithCollectionNameSchema: ObjectSchema;
  sendResetPasswordLinkSchema: ObjectSchema;
  resetUserPasswordSchema: ObjectSchema;
}

export const useYupSchema = (): UseYupSchema => {
  const { t } = useTranslation();

  const collectionSchema: ObjectSchema = object().shape({
    name: string().required(t("*Required")),
  });

  const canvasSchema: ObjectSchema = object().shape({
    name: string().required(t("*Required")),
  });

  const loginSchema: ObjectSchema = object().shape({
    email: string().email(t("*Invalid Email")).required(t("*Required")),
    password: string().required(t("*Required")),
  });

  const sendResetPasswordLinkSchema: ObjectSchema = object().shape({
    email: string().email(t("*Invalid Email")).required(t("*Required")),
  });

  const resetUserPasswordSchema: ObjectSchema = object().shape({
    password: string().required(t("*Required")).min(6, t("*Password must be at least 6 characters long")),
    passwordConfirmation: string()
      .required(t("*Required"))
      .test("password", t("*Passwords must match"), function (val) {
        return this.parent.password === val;
      }),
  });

  const loginWithCollectionNameSchema: ObjectSchema = object().shape({
    email: string().email(t("*Invalid Email")).required(t("*Required")),
    password: string().required(t("*Required")),
    name: string().required(t("*Required")),
  });

  const userSchema: ObjectSchema = object().shape({
    firstName: string().required(t("*Required")),
    lastName: string(),
    email: string().email(t("*Invalid Email")).required(t("*Required")),
    zipCode: string()
      .required(t("*Required"))
      .matches(/^[0-9]+$/, "Must be only digits"),
    projectType: string().required(t("*Required")),
    affiliatedTradeFirm: string().when("projectType", (val, schema) => {
      if (val === "professional") return string().required(t("*Required"));
      else return string().notRequired();
    }),
  });

  const newUserSchema: ObjectSchema = object().shape({
    firstName: string().required(t("*Required")),
    lastName: string(),
    email: string().email(t("*Invalid Email")).required(t("*Required")),
    password: string().required(t("*Required")).min(6, t("*Password must be at least 6 characters long")),
    passwordConfirmation: string()
      .required(t("*Required"))
      .test("password", t("*Passwords must match"), function (val) {
        return this.parent.password === val;
      }),
    projectType: string().required(t("*Required")),
    affiliatedTradeFirm: string().when("projectType", (val, schema) => {
      if (val === "professional") return string().required(t("*Required"));
      else return string().notRequired();
    }),
    zipCode: string()
      .required(t("*Required"))
      .matches(/^[0-9]+$/, "Must be only digits"),
  });

  return {
    collectionSchema,
    canvasSchema,
    loginSchema,
    newUserSchema,
    userSchema,
    loginWithCollectionNameSchema,
    sendResetPasswordLinkSchema,
    resetUserPasswordSchema,
  };
};
