import { BUILD_ALL } from "@lib/utils/routes";
import { ButtonBase, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { matchPath, Redirect, useHistory, useLocation, useParams } from "react-router";
import BackArrowIcon from "@lib/logos/BackArrowIcon";
import { Divider } from "@material-ui/core";
import KitDetails from "./KitDetails";
import OptionsRelatedContainer from "./OptionsRelatedContainer";
import { useStore } from "@lib/hooks/useStore";
import { useStyles } from "styles";
import { useTranslation } from "react-i18next";
import { useNotification } from "@lib/components/NotificationManagerContext";
import { useQuery } from "@lib/hooks/useQuery";

const DetailsPage = () => {
  const { product_id = null, instance_id = null, brand } = useParams<any>();
  const { t } = useTranslation();
  const history = useHistory();
  const store = useStore();
  const styles = useStyles();
  const details = store.viewKitDetails(product_id);
  const query = useQuery();

  const handleBack = (): void => {
    if (!instance_id) {
      //store.collectionStore.setSelectedCollectionKit(undefined);
    }
    history.goBack();
  };

  useEffect(() => {
    if (instance_id && details?.id == product_id) {
      if (!query.get("action") && query.get("action") != "delete") {
        store.collectionStore?.setSelectedCollectionKit(instance_id);
      }
    }
  }, [instance_id]);

  return details?.id == product_id && !query.get("action") && query.get("action") != "delete" ? (
    <div className={styles.drawerOpenPadding}>
      <ButtonBase className={styles.detailedBackButton} onClick={handleBack}>
        <BackArrowIcon className={styles.detailedBackArrowSvg} />
        <Typography variant="subtitle1" color="secondary" style={{ fontSize: 15, fontWeight: "bold" }}>
          {t("Back")}
        </Typography>
      </ButtonBase>
      <KitDetails />
      <Divider className={styles.collectionKitDisplayDivider} />
      <OptionsRelatedContainer />
    </div>
  ) : (
    <Redirect to={`/build/${brand}`} />
  );
};

export default DetailsPage;
