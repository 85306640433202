import { Button, Fade, Grid, Typography } from "@material-ui/core";
import React, { useRef } from "react";
import { useLocation } from "react-router";
import BackToTop from "@lib/components/BackToTop";
import FilterContainer from "./FilterContainer";
import InfiniteScroll from "react-infinite-scroller";
import KitCard from "@lib/components/KitCard";
import LoadingContainer from "@lib/components/LoadingContainer";
import { observer } from "mobx-react";
import { useKitActions } from "@lib/hooks/useKitActions";
import { useStore } from "@lib/hooks/useStore";
import { useStyles } from "styles";
import { useTranslation } from "react-i18next";

const SearchPage: React.FC = observer(() => {
  const styles = useStyles();
  const store = useStore();
  const location = useLocation();
  const searchTop = useRef<null | HTMLDivElement>(null);
  const pageInfo = store.pageinfos.get("1") || { totalCount: 0, page: 1 };
  const hasMore = !store.loading.kits && !!store.searchResults.size;
  const { queryKits } = useKitActions();
  const { t } = useTranslation();

  React.useEffect(() => {
    queryKits();
    if (searchTop && location.hash.includes("#top")) {
      searchTop?.current?.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "center",
      });
    }
  }, [searchTop, location.hash]);

  const handleClick = (e: any) => {
    if (searchTop) {
      searchTop?.current?.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "center",
      });
    }
  };

  const loadFunc = async () => {
    if (pageInfo.next != null) {
      await queryKits(pageInfo.next);
    }
  };

  return (
    <div className={styles.searchPage}>
      <a id="back-to-top-anchor" ref={searchTop}></a>
      <div
        style={{
          display: "flex",
          backgroundColor: "#E5E5E5",
        }}
      >
        <FilterContainer />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
        }}
      >
        <div className={styles.searchContainer}>
          {store.searchValue && (
            <Typography variant="button" color="primary" className={styles.searchValueText}>
              {store.searchValue} &nbsp;
            </Typography>
          )}
          {!store.loading.kits && (
            <Typography className={styles.searchResultsText}>
              <b>{pageInfo?.totalCount || 0}</b> {t("results found")}
            </Typography>
          )}
          {store.loading.kits && <LoadingContainer />}
        </div>
        {open && (
          <Fade in timeout={200}>
            <div className={styles.filterCtaMessageBubble}>
              <Typography variant="h3" align="center" className={styles.filterCtaMessageText}>
                Try resetting the filters or adjusting your search entry to find products.
              </Typography>
            </div>
          </Fade>
        )}
        <div
          className={styles.drawerClosed}
          style={{
            width: "100%",
            position: "relative",
            minHeight: "100vh",
          }}
        >
          <InfiniteScroll
            pageStart={0}
            loadMore={loadFunc}
            hasMore={hasMore}
            loader={<div className="loader" key={0}></div>}
          >
            <Grid container spacing={store.isDrawerOpen ? 4 : 2}>
              {store.filteredKits?.length > 0 &&
                store.filteredKits?.map((kit: any) => (
                  <Grid item xs={12} md={6} lg={store.isDrawerOpen ? 6 : 4} key={kit.id}>
                    <KitCard {...kit} />
                  </Grid>
                ))}
            </Grid>
          </InfiniteScroll>
          <BackToTop>
            <Button
              style={{
                padding: `10px 15px 8px`,
                borderRadius: `30px`,
              }}
              onClick={handleClick}
              aria-label="scroll back to top"
              variant="contained"
              color="secondary"
              size="small"
            >
              Scroll To Top
            </Button>
          </BackToTop>
        </div>
      </div>
    </div>
  );
});

export default SearchPage;
