import React from "react";

import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

const WolfIcon: React.FC<SvgIconProps> = props => {
  return (
    <SvgIcon viewBox="0 0 22 26" titleAccess="Wolf Icon" {...props}>
      <path d="M11.7934 0C12.7683 3.95766 15.0974 6.71082 17.5348 9.40662C18.7264 10.7832 19.7556 12.3892 20.5139 14.1099C21.7596 16.9204 21.1097 19.2721 18.8348 21.5664C18.0223 19.8457 17.2098 18.1823 16.4515 16.5189C16.1807 16.5763 15.9099 16.5763 15.6391 16.6337C15.4766 17.6661 15.2599 18.6985 15.1516 19.731C14.6641 24.2622 12.0642 26.4418 7.78521 25.9256C2.26043 25.2946 -1.20611 19.4442 0.635486 13.6511C1.33963 11.4715 2.36876 9.52133 4.64366 7.91532C4.96865 9.2919 5.13115 10.4391 5.45613 11.5288C5.83528 12.8481 6.43109 14.1673 8.05603 13.8231C9.51847 13.5364 10.0601 12.1598 10.0059 10.7832C9.89762 9.17719 9.6268 7.57118 9.24765 6.02253C8.65184 3.38409 9.78929 1.54865 11.7934 0Z" />
    </SvgIcon>
  );
};

export default WolfIcon;
