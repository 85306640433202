import { useState } from "react";
import { useTranslation } from "react-i18next";
import { FormikValues } from "formik";

import { useStore } from "@lib/hooks/useStore";
import { useNotification } from "@lib/components/NotificationManagerContext";
import { CanvasModelType } from "models";
import { useCanvasKitActions } from "./useCanvasKitActions";

interface UseCanvasActions {
  createCanvas: (name?: string, collectionId?: string, clientMutationId?: string) => Promise<any>;
  queryCanvass: (collectionId?: string, clientMutationId?: string) => Promise<any>;
  updateCanvas: ({ id, name }: FormikValues) => Promise<any>;
  destroyCanvas: (canvas: CanvasModelType) => Promise<any>;
  loading: boolean;
  success: boolean;
}

export const useCanvasActions = (): UseCanvasActions => {
  const store = useStore();
  const { t } = useTranslation();
  const { openNotification } = useNotification();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const { queryCanvasKits, addCanvasKitsToPreviewWall } = useCanvasKitActions();

  const queryCanvass = async (collectionId = "", clientMutationId = ""): Promise<any> => {
    try {
      await store.canvasStore?.queryCanvass(collectionId, clientMutationId);
      await queryCanvasKits();
      await addCanvasKitsToPreviewWall();
    } catch (error) {
      openNotification(t("Canvas failed to create"));
      console.error(error);
    }
  };

  const createCanvas = async (name = ""): Promise<any> => {
    try {
      await store.canvasStore?.createCanvas(name);
    } catch (error) {
      openNotification(t("Canvas failed to create"));
      console.error(error);
    }
  };

  const updateCanvas = async ({ id, name }: FormikValues): Promise<any> => {
    try {
      await store.canvasStore?.updateCanvas({ id, name });
      openNotification(t("Canvas Updated!"));
    } catch (error) {
      openNotification(t("Canvas failed to update"));
      console.error(error);
    }
  };

  const destroyCanvas = async (canvas: CanvasModelType): Promise<any> => {
    try {
      await store.handleDestroyCanvas(canvas);
      openNotification(t("Canvas successfully removed"));
    } catch (error) {
      openNotification(t("Canvas failed to delete"));
      console.error(error);
    }
  };

  return {
    queryCanvass,
    createCanvas,
    updateCanvas,
    destroyCanvas,
    loading,
    success,
  };
};
