import {
  BUILD_ALL,
  BUILD_BEST,
  BUILD_COVE,
  BUILD_SUBZERO,
  BUILD_WOLF,
  CANVAS,
  DYNAMIC_BUILD_BRAND,
  DYNAMIC_BUILD_BRAND_DETAILS,
  ROOT,
} from "@lib/utils/routes";
import { Redirect, Route, Switch } from "react-router";
import DetailsPage from "./Details/DetailsPage";
import PreviewWallPage from "./PreviewWall/PreviewWallPage";
import React from "react";
import SearchPage from "./Search/SearchPage";
import SideDrawerWrapper from "@lib/components/SideDrawerWrapper";
import SpashPage from "./SpashPage/SpashPage";
import { observer } from "mobx-react";
import { useStyles } from "styles";

const Routing: React.FC = observer(() => {
  const styles = useStyles();

  return (
    <div className={styles.flex}>
      <main className={styles.buildContainer}>
        <Switch>
          <Route exact path={CANVAS} component={PreviewWallPage} />
          <Route exact path={DYNAMIC_BUILD_BRAND_DETAILS} component={DetailsPage} />
          <Route exact path={DYNAMIC_BUILD_BRAND} component={SearchPage} />
          <Route exact path={ROOT} component={SpashPage} />
          <Redirect to={ROOT} />
        </Switch>
      </main>
      <Switch>
        <Route exact path={DYNAMIC_BUILD_BRAND_DETAILS} component={SideDrawerWrapper} />
        <Route exact path={DYNAMIC_BUILD_BRAND} component={SideDrawerWrapper} />
      </Switch>
    </div>
  );
});

export default Routing;
