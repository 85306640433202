/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { IObservableArray } from "mobx"
import { types } from "mobx-state-tree"
import { MSTGQLRef, QueryBuilder, withTypedRefs } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { CollectionKitModel, CollectionKitModelType } from "./CollectionKitModel"
import { CollectionKitModelSelector } from "./CollectionKitModel.base"
import { RootStoreType } from "./index"


/* The TypeScript type that explicits the refs to other models in order to prevent a circular refs issue */
type Refs = {
  collectionKits: IObservableArray<CollectionKitModelType>;
}

/**
 * CollectionBase
 * auto generated base class for the model CollectionModel.
 */
export const CollectionModelBase = withTypedRefs<Refs>()(ModelBase
  .named('Collection')
  .props({
    __typename: types.optional(types.literal("Collection"), "Collection"),
    collectionKits: types.union(types.undefined, types.null, types.array(MSTGQLRef(types.late((): any => CollectionKitModel)))),
    createdAt: types.union(types.undefined, types.string),
    id: types.identifier,
    name: types.union(types.undefined, types.string),
    snapshotUrl: types.union(types.undefined, types.null, types.string),
    updatedAt: types.union(types.undefined, types.string),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  })))

export class CollectionModelSelector extends QueryBuilder {
  get createdAt() { return this.__attr(`createdAt`) }
  get id() { return this.__attr(`id`) }
  get name() { return this.__attr(`name`) }
  get snapshotUrl() { return this.__attr(`snapshotUrl`) }
  get updatedAt() { return this.__attr(`updatedAt`) }
  collectionKits(builder?: string | CollectionKitModelSelector | ((selector: CollectionKitModelSelector) => CollectionKitModelSelector)) { return this.__child(`collectionKits`, CollectionKitModelSelector, builder) }
}
export function selectFromCollection() {
  return new CollectionModelSelector()
}

export const collectionModelPrimitives = selectFromCollection().createdAt.name.snapshotUrl.updatedAt
