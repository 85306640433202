import { createMuiTheme } from "@material-ui/core/styles";
import createSpacing from "@material-ui/core/styles/createSpacing";

export const theme = createMuiTheme({
  palette: {
    primary: {
      light: "#8a8d96",
      main: "#2B2B2B",
      dark: "#33363e",
      contrastText: "#fff",
    },
    secondary: {
      main: "#F26A21",
      contrastText: "#fff",
      light: "#ff9b51",
      dark: "#b83a00",
    },
  },
  typography: {
    fontFamily: ["Roboto"].join(),
    h1: {
      fontFamily: "Roboto",
      fontSize: "50px",
    },
    h2: { fontFamily: "Roboto", fontSize: "45px" },
    h3: {
      fontFamily: "Roboto Condensed",
      fontSize: "28px",
    },
    h4: {
      fontFamily: "Roboto Condensed",
      fontSize: "16px",
      letterSpacing: ".2em",
      textTransform: "uppercase",
    },
    h5: {
      fontFamily: "Roboto Condensed",
      fontSize: "12px",
      letterSpacing: ".2em",
      textTransform: "uppercase",
    },
    h6: {
      fontFamily: "Roboto Condensed",
      fontSize: "11px",
      letterSpacing: ".1em",
      textTransform: "uppercase",
    },
    button: {
      fontFamily: "Bebas Neue",
      fontSize: "27px",
      letterSpacing: ".06em",
      lineHeight: "28px",
    },
    caption: {
      color: "#2B2B2B",
      fontSize: "15px",
    },
    subtitle1: {
      fontFamily: '"HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, sans-serif',
    },
    subtitle2: {
      fontFamily: "Bebas Neue",
    },
  },
  overrides: {
    MuiOutlinedInput: {
      root: {
        borderRadius: "30px",
      },
      input: {
        borderRadius: "30px",
        padding: "15px 20px",
      },
    },
    MuiButton: {
      root: {
        fontFamily: "Bebas Neue",
        fontSize: "16px",
        letterSpacing: ".06em",
        lineHeight: "initial",
        height: "auto",
        borderRadius: "10px",
      },
      textPrimary: {
        fontFamily: "Roboto Condensed",
      },
      textSecondary: {
        fontFamily: "Roboto Condensed",
        color: "#FFF",
        "&:hover": { backgroundColor: "transparent" },
      },
    },
    MuiIconButton: {
      root: {
        padding: "unset",
        height: "42px",
        width: "42px",
      },
    },
    MuiPopover: {
      paper: {
        width: "300px",
        padding: "20px",
      },
    },
    MuiListItem: {
      root: {
        "&$selected": {
          backgroundColor: "rgba(0, 0, 0, 0.2)",
          "&:hover": {
            backgroundColor: "rgba(0, 0, 0, 0.4)",
          },
        },
      },
      button: {
        "&:hover": {
          backgroundColor: "rgba(0, 0, 0, 0.2)",
        },
      },
    },
  },
});
