import { Button, Divider, Grid, Typography } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";

import Backend from "react-dnd-html5-backend";
import { DndProvider } from "react-dnd";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import FullScreen from "react-full-screen";
import { LOGIN } from "@lib/utils/routes";
import MyCanvasDialog from "components/MyCanvas/MyCanvasDialog";
import NewCanvasDialog from "@lib/components/NewCanvasDialog";
import NewCollectionDialog from "@lib/components/NewCollectionDialog";
import PreviewWallContainer from "./PreviewWallContainer";
import PreviewWallNavHeader from "./PreviewWallNavHeader";
import { SizeMe } from "react-sizeme";
import { observer } from "mobx-react";
import { size } from "lodash";
import { useCanvasActions } from "@lib/hooks/useCanvasActions";
import { useCanvasKitActions } from "@lib/hooks/useCanvasKitActions";
import { useCollectionActions } from "@lib/hooks/useCollectionActions";
import { useDialogManager } from "@lib/components/DialogManagerContext";
import { useHistory } from "react-router";
import { useStore } from "@lib/hooks/useStore";
import { useStyles } from "styles";
import { useTranslation } from "react-i18next";
import { FormikValues } from "formik";

const PreviewWallPage: React.FC = observer(() => {
  const styles = useStyles();
  const store = useStore();
  const history = useHistory();
  const { t } = useTranslation();
  const [isFull, setIsFull] = React.useState(false);
  const [showGrid, setShowGrid] = React.useState(false);
  const { openDialog, closeDialog } = useDialogManager();
  const { createCanvas } = useCanvasActions();
  const { updateCollection, createCollection } = useCollectionActions();
  const searchTop = useRef<null | HTMLDivElement>(null);
  const { queryCanvasKits } = useCanvasKitActions();
  const { queryCanvass } = useCanvasActions();

  React.useEffect(() => {
    store.setIsFullScreen(isFull);
  }, [isFull]);

  React.useEffect(() => {
    if (location.hash.includes("#top")) {
      var root = document.getElementById("body");
      setTimeout(
        function () {
          //Start the timer
          root?.scrollIntoView({
            behavior: "smooth",
            block: "start",
            inline: "center",
          });
        }.bind(this),
        500
      );
    }
  }, [location.hash]);

  const handleCreateCanvas = async ({ name }: FormikValues): Promise<any> => {
    await createCanvas(name);
    await queryCanvasKits();
    store.clearWallContent();
    closeDialog();
  };

  const handleCreateColleciton = async ({ name }: FormikValues): Promise<any> => {
    await createCollection(name);
    store.currentCollection?.id && (await queryCanvass(store.currentCollection.id));
    store.clearWallContent();
    closeDialog();
  };

  const handleMyCanvasDialog = () => {
    if (store.currentUser) {
      if (!store.currentCollection) {
        openDialog(<NewCollectionDialog onConfirm={handleCreateColleciton} />);
      } else if (!store.currentCanvas) {
        openDialog(<NewCanvasDialog onConfirm={handleCreateCanvas} />);
      } else {
        openDialog(<MyCanvasDialog listView={true} />);
      }
    } else {
      history.push(LOGIN);
    }
  };

  return (
    <main className={styles.buildContainer}>
      <a id="back-to-top-anchor" ref={searchTop}></a>
      <PreviewWallNavHeader
        setIsFull={setIsFull}
        setShowGrid={setShowGrid}
        showGrid={showGrid}
        disableScreenshot={!size(store.previewWallContents)}
      />
      <Divider className={styles.dividerThick} />
      <div
        style={{
          maxWidth: "80%",
          display: "flex",
          alignItems: "center",
          margin: "10px auto 0",
        }}
      >
        <Typography variant="button" color="primary" align="left" className={styles.canvasText}>
          CLICK & DRAG ON YOUR SELECTED ITEMS TO ARRANGE YOUR SPACE FOR:
        </Typography>
        <Button
          onClick={handleMyCanvasDialog}
          size="small"
          variant="contained"
          color="primary"
          startIcon={<ExpandMoreIcon />}
          style={{
            height: "35px",
            borderRadius: "40px",
            padding: "0 30px",
            backgroundColor: "#383B46",
          }}
        >
          {store.currentCanvas?.name || "Create Canvas"}
        </Button>
      </div>
      <SizeMe>
        {({ size }): React.ReactElement => (
          <FullScreen enabled={isFull} onChange={(isFull): void => setIsFull(isFull)}>
            <DndProvider backend={Backend}>
              <PreviewWallContainer itemsProp={store.currentCollectionKits} showGrid={showGrid} {...size} />
            </DndProvider>
          </FullScreen>
        )}
      </SizeMe>
      <div className={styles.canvasDescriptionArea}>
        <Typography variant="h1" color="primary" style={{ marginBottom: 24 }}>
          {t("Easily communicate your vision")}
        </Typography>
        <Grid container className={styles.easyStepContainer}>
          <Grid item sm={3}>
            <Divider className={styles.dividerCanvas} />
            <Typography variant="h2" color="primary" className={styles.canvasDescriptionText}>
              {t("Place your appliances in the canvas to explore possibilities")}
            </Typography>
          </Grid>
          <Grid item sm={true}>
            <Divider orientation="vertical" className={styles.marginAuto} />
          </Grid>
          <Grid item sm={3}>
            <Divider className={styles.dividerCanvas} />
            <Typography variant="h2" color="primary" className={styles.canvasDescriptionText}>
              {t("In grid view, see your appliance selections in one foot scale")}
            </Typography>
          </Grid>
          <Grid item sm={true}>
            <Divider orientation="vertical" className={styles.marginAuto} />
          </Grid>
          <Grid item sm={3}>
            <Divider className={styles.dividerCanvas} />
            <Typography variant="h2" color="primary" className={styles.canvasDescriptionText}>
              {t("Export your canvas and collection to share your vision")}
            </Typography>
          </Grid>
        </Grid>
      </div>
    </main>
  );
});

export default PreviewWallPage;
