import React from "react";
import clsx from "clsx";

import { Badge, Fade, useTheme } from "@material-ui/core";
import ListItemIcon from "@material-ui/icons/ListAlt";

import { useStyles } from "styles";
import ListIcon from "@lib/logos/ListIcon";

interface SideDrawerListIconProps {
  badgeContent: number;
  disabled: boolean;
}

const SideDrawerListIcon: React.FC<SideDrawerListIconProps> = ({
  badgeContent,
  disabled
}) => {
  const styles = useStyles();
  const { transitions } = useTheme();
  return (
    <Fade in timeout={transitions.duration.standard}>
      <Badge
        badgeContent={badgeContent}
        color="secondary"
        classes={{
          anchorOriginTopRightRectangle: styles.drawerListIconBadge
        }}
      >
        <div
          className={clsx(styles.drawerListIconContainer, {
            [styles.borderDisabled]: disabled
          })}
        >
          <ListIcon className={styles.fontSize16} />
        </div>
      </Badge>
    </Fade>
  );
};

export default SideDrawerListIcon;
