import React from "react";

import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import Button, { ButtonProps } from "@material-ui/core/Button";

import { useStyles } from "styles";

const ButtonWhiteWithRoundedCorners: React.FC<ButtonProps> = ({
  children,
  ...props
}) => {
  const styles = useStyles();
  return (
    <Button
      size="large"
      className={styles.removeHoverEffect}
      {...props}
    >
      {children}
      <ArrowForwardIcon />
    </Button>
  );
};

export default ButtonWhiteWithRoundedCorners;
