import React from "react";

import { Slide, Snackbar, IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { TransitionProps } from "@material-ui/core/transitions";

interface SimpleSnackbarProps {
  message: string;
  onClose?: () => void;
}

const Transition: React.FC<TransitionProps> = props => (
  <Slide {...props} direction="up" />
);

const SimpleSnackbar: React.FC<SimpleSnackbarProps> = ({
  message,
  onClose
}) => {
  const [open, setOpen] = React.useState(true);

  const handleClose = (
    _event: React.SyntheticEvent | React.MouseEvent,
    reason?: string
  ): void => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
    onClose && setTimeout(() => onClose(), 100)
  };

  return (
    <div>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        open={open}
        autoHideDuration={5000}
        onClose={handleClose}
        TransitionComponent={Transition}
        ContentProps={{
          "aria-describedby": "message-id"
        }}
        message={<span id="message-id">{message}</span>}
        action={[
          <IconButton
            key="close"
            aria-label="close"
            color="inherit"
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
        ]}
      />
    </div>
  );
};

export default SimpleSnackbar;
