import React from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { useLocation, useParams } from "react-router";
import { InputBase, ButtonBase } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";
import { useHistory } from "react-router";
import { useStyles } from "styles";
import { useStore } from "@lib/hooks/useStore";
import { useKitActions } from "@lib/hooks/useKitActions";
import { useDebounce } from "@lib/hooks/useDebounce";
import { usePrevious } from "@lib/hooks/usePrevious";
import { BUILD_ALL } from "@lib/utils/routes";

const SearchBar: React.FC = observer(() => {
  const styles = useStyles();
  const { t } = useTranslation();
  const store = useStore();
  const { queryKits } = useKitActions();
  const location = useLocation();
  const { product_id = null, brand } = useParams<any>();
  const isSearchView = brand && !product_id;
  // const open = isSearchView ? store.isDrawerOpen : true;
  const history = useHistory();
  const debouncedValue = useDebounce(store.searchValue, 350);
  const prevSearchValue = usePrevious(debouncedValue);

  React.useEffect(() => {
    queryKits();
    window.scrollTo({ top: 0 });

    // handle redirect when changing search value while in details view
    // after typing is complete.
    if (store.selectedCollectionKit && prevSearchValue !== undefined) {
      store.collectionStore?.setSelectedCollectionKit(undefined);
      store.setSearchTerms({}, true);
    }
  }, [debouncedValue]);

  const handleCancelSearch = (event: React.EventHandler): void => {
    store.setSearchValue("");
  };

  const handleEnterPress = (event: React.KeyboardEvent): void => {
    if (event.keyCode === 13) {
      if (location.pathname != BUILD_ALL) {
        history.push(BUILD_ALL);
      }
      queryKits();
    }
  };

  const handleSearchClick = (event: React.EventHandler): void => {
    if (location.pathname != BUILD_ALL) {
      history.push(BUILD_ALL);
    }
    queryKits();
  };

  const handleChange = (event: any): void => {
    store.clearSeachValue();
    store.setSearchValue(event.target.value);
  };

  return (
    <div id="searchArea" className={styles.searchBarArea}>
      <div className={styles.searchBarContainer}>
        <ButtonBase onClick={handleSearchClick} className={styles.searchBarButton}>
          <SearchIcon className={styles.colorWhite} />
        </ButtonBase>
        <InputBase
          placeholder={t("Search for anything")}
          inputProps={{
            "aria-label": "search for anything",
          }}
          value={store.searchValue}
          onChange={handleChange}
          onKeyDown={handleEnterPress}
          className={styles.searchBar}
          classes={{ input: styles.searchBarPlaceholder }}
        />
        <ButtonBase onClick={handleCancelSearch} className={styles.searchBarCancel}>
          <CloseIcon className={styles.colorOrange} />
        </ButtonBase>
      </div>
    </div>
  );
});

export default SearchBar;
