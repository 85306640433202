import "./lib/ReactotronConfig";
import "./lib/locales/i18n";

import React, { Suspense } from "react";
import { RootStore, StoreContext } from "./models";

import { BrowserRouter } from "react-router-dom";
import ContextProviders from "@lib/components/ContextProviders";
import { CssBaseline } from "@material-ui/core";
import ErrorBoundary from "@lib/components/ErrorBoundry";
import Reactotron from "reactotron-react-js";
import RootLevelRoutes from "components/RootLevelRoutes";
import { ThemeProvider } from "@material-ui/styles";
import { createHttpClient } from "mst-gql";
import { theme } from "./styles/muiTheme";
import { useCategoriesActions } from "@lib/hooks/useCategoriesActions";

Reactotron && Reactotron.clear();

const defaultStore = {
  userStore: "userStore",
  users: { userStore: { id: "userStore" } },
  collectionStore: "defaultCollection",
  collections: {
    defaultCollection: {
      id: "defaultCollection",
      name: "",
      collectionKits: [],
    },
  },
  collectionKitStore: "collectionKitStore",
  collectionKits: { collectionKitStore: { id: "collectionKitStore" } },
  canvasStore: "defaultCanvas",
  canvass: {
    defaultCanvas: {
      id: "defaultCanvas",
      name: "",
      collection_id: "",
      canvasKits: [],
    },
  },
  canvasKitStore: "canvasKitStore",
  canvasKits: { canvasKitStore: { id: "canvasKitStore" } },
};

const gqlHttpClient = createHttpClient("/graphql", {
  headers: {
    "client-name": "Miniature Giant Space Hamster [Boo]",
    "client-version": "1.0.0",
  },
});

const rootStore = RootStore.create(defaultStore, {
  gqlHttpClient,
});

if (process.env.NODE_ENV !== "production") {
  Reactotron.trackMstNode && Reactotron.trackMstNode(rootStore);
}

export const App: React.FC = () => {
  return (
    <ErrorBoundary>
      <StoreContext.Provider value={rootStore}>
        <CssBaseline />
        <Suspense fallback="loading">
          <ThemeProvider theme={theme}>
            <BrowserRouter>
              <ContextProviders>
                <RootLevelRoutes />
              </ContextProviders>
            </BrowserRouter>
          </ThemeProvider>
        </Suspense>
      </StoreContext.Provider>
    </ErrorBoundary>
  );
};
