import {
  Avatar,
  Button,
  Fade,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Slide,
  Typography,
  useTheme,
} from "@material-ui/core";
import { CANVAS, CREATE_ACCOUNT } from "@lib/utils/routes";
import { useHistory, useLocation, useParams } from "react-router";

import CancelIcon from "@material-ui/icons/Cancel";
import ConfirmDialog from "@lib/components/ConfirmDialog";
import { FormikValues } from "formik/dist/types";
import { KitModelType } from "models";
import NewCanvasDialog from "@lib/components/NewCanvasDialog";
import PreviewWallButtons from "components/PreviewWall/PreviewWallButtons";
import React from "react";
import { familyKey } from "@lib/utils/familyKey";
import { observer } from "mobx-react";
import { useCanvasActions } from "@lib/hooks/useCanvasActions";
import { useCanvasKitActions } from "@lib/hooks/useCanvasKitActions";
import { useCollectionKitActions } from "@lib/hooks/useCollectionKitActions";
import { useDialogManager } from "@lib/components/DialogManagerContext";
import { usePreviewWallActions } from "@lib/hooks/usePreviewWallActions";
import { useStore } from "@lib/hooks/useStore";
import { useStyles } from "styles";
import { useTranslation } from "react-i18next";
import { convertToSlug } from "@lib/utils/stringHelpers";
import { useNotification } from "@lib/components/NotificationManagerContext";

interface CollectionKitListItemProps extends KitModelType {
  collectionKitId?: string;
  showButton: boolean;
  optional: boolean;
  numberOfAccessories: number;
}

const CollectionKitListItem: React.FC<Partial<CollectionKitListItemProps>> = observer(
  ({ name, imageUrl, family, collectionKitId = "", showOnWall, showButton, description, numberOfAccessories }) => {
    const styles = useStyles();
    const store = useStore();
    const location = useLocation();
    const history = useHistory();
    const { transitions } = useTheme();
    const { deleteCollectionKit } = useCollectionKitActions();
    const { queryCanvasKits } = useCanvasKitActions();
    const { t } = useTranslation();
    const { handleAddToWall, handleRemoveFromWall } = usePreviewWallActions(collectionKitId);
    const { openDialog, closeDialog } = useDialogManager();
    const { createCanvas, loading } = useCanvasActions();
    const isSelected = !!(store.selectedCollectionKit && store.selectedCollectionKit.id === collectionKitId);
    const isDisplayedOnWall = !(
      !store.previewWallContents[collectionKitId] || store.previewWallContents[collectionKitId].delete == true
    );
    const isPreviewWallView = location.pathname === CANVAS;

    const { brand = "all", product_id = null, instance_id = null } = useParams<any>();

    const handleConfirmDeleteCollectionKit = async () => {
      let response: any = await deleteCollectionKit(collectionKitId);
      store.destroyPreviewWallContent(collectionKitId);

      if (response?.destroyCollectionKit?.result) {
        if (instance_id == collectionKitId) {
          history.push({
            pathname: `/build/${brand}/details/${product_id}`,
            search: "?action=delete",
          });
        }

        closeDialog();
      }
    };

    const handleDeleteCollectionKit = async () => {
      if (store.currentUser && store.currentCanvasKits) {
        openDialog(
          <ConfirmDialog
            title="Are you sure you want to remove this item? It will also be removed from any of you canvases."
            titleVariant="h3"
            onConfirm={handleConfirmDeleteCollectionKit}
            onClose={closeDialog}
          />
        );
      } else {
        let response = await deleteCollectionKit(collectionKitId);
        if (response?.destroyCollectionKit?.result) {
          if (instance_id == collectionKitId) {
            history.push({
              pathname: `/build/${brand}/details/${product_id}`,
              search: "?action=delete",
            });
          }
          closeDialog();
        }
      }
    };

    const handleCreateCanvas = async ({ name }: FormikValues): Promise<any> => {
      // RESET
      await createCanvas(name);
      await queryCanvasKits();
      store.clearWallContent();
      closeDialog();
    };

    const handleItemClick = (): void => {
      if (isPreviewWallView) {
        if (store.currentCanvas) {
          handleAddToWall();
        } else {
          if (store.currentUser) {
            openDialog(<NewCanvasDialog onConfirm={handleCreateCanvas} />);
          } else {
            history.push(CREATE_ACCOUNT);
          }
        }
      } else {
        store.collectionStore?.setSelectedCollectionKit(collectionKitId);
        history.push(
          `/build/${convertToSlug(store.selectedCollectionKit?.kit?.family, "")}/details/${
            store.selectedCollectionKit?.kit?.id
          }/${collectionKitId}`
        );
      }
    };

    return (
      <ListItem
        button
        selected={isPreviewWallView ? false : isSelected}
        role={undefined}
        divider
        disabled={isPreviewWallView && isDisplayedOnWall}
        onClick={handleItemClick}
        className={styles.collectionKitListItem}
        classes={{ divider: styles.collectionKitListItemDivider }}
      >
        <ListItemAvatar>
          {imageUrl ? (
            <Avatar
              alt={name}
              src={imageUrl}
              variant="square"
              classes={{ img: styles.cardImg }}
              className={styles.collectionKitListItemAvatar}
            />
          ) : (
            <div className={styles.collectionKitListItemAvatar} />
          )}
        </ListItemAvatar>

        <ListItemText
          primary={
            <Typography className={styles.listProductName} variant="button" gutterBottom>
              {description}
            </Typography>
          }
          secondary={
            <React.Fragment>
              {name}
              {!!numberOfAccessories && (
                <span className={styles.listAccessoriesText}>{t("Accessory", { count: numberOfAccessories })}</span>
              )}
            </React.Fragment>
          }
          classes={{
            root: styles.margin0,
            secondary: styles.optionalText,
          }}
        />

        <div className={styles.collectionKitListItemButtonContainer}>
          {family && familyKey[family].icon}
          {isPreviewWallView && <PreviewWallButtons isDisplayedOnWall={isDisplayedOnWall} />}
        </div>

        {!isPreviewWallView && (
          <ListItemSecondaryAction className={styles.collectionKitListItemRemoveButton}>
            <Fade in={showButton} timeout={transitions.duration.standard}>
              <Button
                color="primary"
                size="small"
                onClick={handleDeleteCollectionKit}
                classes={{ root: styles.removeButton }}
              >
                <CancelIcon className={styles.removeButtonIcon} />
                {t("Remove")}
              </Button>
            </Fade>
          </ListItemSecondaryAction>
        )}
      </ListItem>
    );
  }
);

export default CollectionKitListItem;
