import React from "react";

import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

const CoveIcon: React.FC<SvgIconProps> = props => {
  return (
    <SvgIcon viewBox="0 0 19 25" titleAccess="Cove Icon" {...props}>
      <path d="M9.72145 0C12.0937 3.09252 14.4133 6.07257 16.6801 9.16509C18.7361 12.0327 19.2105 15.2377 18.2089 18.6676C16.7328 23.4469 12.1991 25.9209 7.19102 24.6839C3.13181 23.6718 0.126957 18.9487 0.812278 14.4505C1.07586 12.8199 1.8139 11.3017 2.39379 9.72737C2.60466 9.16509 3.0791 8.65905 3.50083 8.153C5.5568 5.45408 7.66549 2.75515 9.72145 0Z" />
    </SvgIcon>
  );
};

export default CoveIcon;
