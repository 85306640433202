import React from "react";
import { ErrorMessage, useField } from "formik";

import { TextField, FormHelperText, Input } from "@material-ui/core";
import { OutlinedTextFieldProps } from "@material-ui/core/TextField";
import { startCase } from "lodash";
import { useStyles } from "styles";

interface FormikTextFieldProps extends OutlinedTextFieldProps {
  name: string;
  label?: string;
  labelWidth?: number;
}

const FormikTextField: React.FC<FormikTextFieldProps> = ({
  name,
  labelWidth,
  ...props
}) => {
  const styles = useStyles();
  const [field] = useField(name);

  return (
    <div className={styles.formikTextField}>
      <Input
        disableUnderline={true}
        fullWidth
        {...field}
        {...props}
        placeholder={startCase(name)}
        data-testid={`${name}-textfield`}
        autoComplete="off"
      />

      <ErrorMessage name={name}>
        {(errorMessage): React.ReactNode => (
          <FormHelperText data-testid="textfield-error-text" error>
            {errorMessage}
          </FormHelperText>
        )}
      </ErrorMessage>
    </div>
  );
};

export default FormikTextField;
