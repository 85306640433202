import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useStore } from "@lib/hooks/useStore";
import { useNotification } from "@lib/components/NotificationManagerContext";

interface UseCategoriesActions {
  queryCategories: () => Promise<any>;
  loading: boolean;
  success: boolean;
}

export const useCategoriesActions = (): UseCategoriesActions => {
  const store = useStore();
  const { t } = useTranslation();
  const { openNotification } = useNotification();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const queryCategories = async (): Promise<any> => {
    try {
      let response: any = await store.queryCategories();

      store.setFilterCategories(JSON.parse(response.categories));

      return response
    } catch (error) {
      openNotification(t("Cant get Categories"));
      console.error(error);
    }
  };

  return {
    queryCategories,
    loading,
    success,
  };
};
