import { Instance } from "mobx-state-tree";
import { CanvasKitModelBase } from "./CanvasKitModel.base";
import { Query } from "mst-gql";

/* The TypeScript type of an instance of CanvasKitModel */
export interface CanvasKitModelType
  extends Instance<typeof CanvasKitModel.Type> { }

/* A graphql query fragment builders for CanvasKitModel */
export {
  selectFromCanvasKit,
  canvasKitModelPrimitives,
  CanvasKitModelSelector
} from "./CanvasKitModel.base";

/**
 * CanvasKitModel
 */

export const CanvasKitModel = CanvasKitModelBase.actions(self => {

  const addAccessoryProduct = (id: string): Query => {
    const input = {
      canvasKitId: self.id,
      productId: id,
      clientMutationId: id
    };

    return self.store.mutateCreateCanvasKitProduct(
      { input },
      res => res.canvasKit(colKit => colKit.accessoryProducts()),
      () => {
        const accessory = self.store.products.get(id);

        if (accessory) {
          if (self.accessoryProducts) {
            return self.accessoryProducts.push(accessory);
          }
          self.accessoryProducts = [accessory];
        }
      }
    );
  };


  return { addAccessoryProduct };
});
