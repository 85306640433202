import React from "react";
import { useTranslation } from "react-i18next";

import { Typography, Divider, Grid } from "@material-ui/core";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";

import { useStyles } from "styles";
import ButtonWithRoundedCorners from "@lib/components/ButtonWithRoundedCorners";
import LinkNoStyle from "@lib/components/LinkNoStyle";
import { BUILD_ALL } from "@lib/utils/routes";

const TryNowSection: React.FC = () => {
  const styles = useStyles();
  const { t } = useTranslation();

  return (
    <div className={styles.tryNowSectionContainer}>
      <Grid container spacing={4}>
        <Grid item sm={true}>
          <Divider className={styles.tryNowDivider} />
        </Grid>
        <Grid item>
          <Typography color="primary" variant="h1" gutterBottom>
            {t("Ready to try now?")}
          </Typography>
          <Typography align="center" variant="h4" color="primary" gutterBottom className={styles.boldUppercase}>
            {t("OK, Let’s get going")}
          </Typography>
        </Grid>
        <Grid item sm={true}>
          <Divider className={styles.tryNowDivider} />
        </Grid>
      </Grid>

      <div className={styles.tryNowInfoTextContainer}>
        <Typography align="center" className={styles.tryNowInfoText} gutterBottom>
          {t("Now that we have your attention")}
        </Typography>
        <div style={{ width: "max-content" }}>
          <LinkNoStyle
            to={{
              pathname: BUILD_ALL,
              hash: "#top",
            }}
          >
            <ButtonWithRoundedCorners
              variant="contained"
              color="secondary"
              size="large"
              className={styles.tryNowInfoButton}
            >
              {t("Get Started")}
              <ArrowForwardIcon />
            </ButtonWithRoundedCorners>
          </LinkNoStyle>
        </div>
      </div>
    </div>
  );
};

export default TryNowSection;
