import React from "react";
import { Formik, FormikValues } from "formik";
import { observer } from "mobx-react";

import { useDialogManager } from "@lib/components/DialogManagerContext";
import { useYupSchema } from "@lib/hooks/useYupSchema";
import { useUserActions } from "@lib/hooks/useUserActions";
import { useStore } from "@lib/hooks/useStore";

interface SendResetPasswordLinkFormProps {
  children: (formikProps: FormikValues) => React.ReactNode;
}

const SendResetPasswordLinkForm: React.FC<SendResetPasswordLinkFormProps> = observer(({ children }) => {
  const { closeDialog } = useDialogManager();
  const { sendResetPasswordLinkSchema } = useYupSchema();
  const { handleSendResetPasswordLink } = useUserActions();
  const store = useStore();

  return (
    <Formik
      initialValues={{ email: "" }}
      validationSchema={sendResetPasswordLinkSchema}
      onSubmit={async ({ email }, { setSubmitting }): Promise<any> => {
        handleSendResetPasswordLink(email);
        setSubmitting(false);
        closeDialog();
      }}
    >
      {(formikProps): React.ReactNode => <React.Fragment>{children(formikProps)}</React.Fragment>}
    </Formik>
  );
});

export default SendResetPasswordLinkForm;
