import React from "react";
import Button, { ButtonProps } from "@material-ui/core/Button";
import { useStyles } from "styles";

const ButtonWithRoundedCorners: React.FC<ButtonProps> = ({
  children,
  ...props
}) => {
  const styles = useStyles();
  return (
    <Button {...props} className={`${styles.buttonWithRoundedCorners} ${props.className}`}>
      {children}
    </Button>
  );
};

export default ButtonWithRoundedCorners;
