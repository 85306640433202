import { AppBar, Toolbar } from "@material-ui/core";

import HeaderBackground from "@lib/logos/bkg.dark-grey.gif";
import InspiringDesignRothLivingLogo from "@lib/logos/logo.svg";
import LinkNoStyle from "@lib/components/LinkNoStyle";
import React from "react";
import SearchBar from "./Search/SearchBar";
import UserMenu from "./User/UserMenu";
import { useStyles } from "../styles";
import { ROOT } from "@lib/utils/routes";

const Header: React.FC = () => {
  const classes = useStyles();

  return (
    <AppBar
      color="primary"
      className={classes.appBar}
      elevation={0}
      style={{ backgroundImage: `url(${HeaderBackground})` }}
    >
      <Toolbar className={classes.headerToolbar}>
        <LinkNoStyle to={ROOT}>
          <img src={InspiringDesignRothLivingLogo} style={{ height: 42, width: 370 }} />
        </LinkNoStyle>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "570px",
            alignItems: "center",
          }}
        >
          <SearchBar />
          <UserMenu />
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
