import React from "react";
import { useTranslation } from "react-i18next";
import { PopperPlacementType } from "@material-ui/core";
import CanvasList from "./CanvasList";
import { useStore } from "@lib/hooks/useStore";
import { useStyles } from "styles";
import { CanvasModelType } from "models";

const RECENT = "recent";
const ALL = "all";

export interface MyCanvasDialogListsProps {
  listView: boolean;
  quoteView: boolean;
  edit: boolean;
  open: boolean;
  selectedCanvas: CanvasModelType | null;

  onSelectClick: (canvas: CanvasModelType) => Promise<any>;

  handleDeleteClick: (
    newPlacement: PopperPlacementType,
    canvas: CanvasModelType
  ) => (event: React.MouseEvent<HTMLButtonElement>) => void;

  handleEditClick: (
    canvas?: CanvasModelType,
    listType?: string
  ) => void;

  handleSendQuoteClick: (
    canvas?: CanvasModelType,
  ) => void;
}

const MyCanvasDialogLists: React.FC<MyCanvasDialogListsProps> = ({
  ...restProps
}) => {
  const store = useStore();
  const styles = useStyles();
  const { t } = useTranslation();

  return (
    <div>
      <CanvasList
        listArray={store?.userCanvasList}
        listType={ALL}
        {...restProps}
      />
    </div>
  );
};

export default MyCanvasDialogLists;
