import React from "react";

import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

const EyeIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon viewBox="0 0 15 9" titleAccess="Eye Icon" {...props}>
      <path
        d="M1 4.4686C5.33333 -0.33927 9.66667 0.0305657 14 4.4686C11.1111 8.16702 6.2 10.0901 1 4.4686Z"
        stroke="white"
      />
      <circle cx="7.5" cy="4.5" r="2.5" fill="white" />
    </SvgIcon>
  );
};

export default EyeIcon;
