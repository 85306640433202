import { useContext } from "react";
import { StoreContext, RootStoreType } from "models";

export const useStore = (): RootStoreType => {
  const store: RootStoreType = useContext(StoreContext);

  if (!store) {
    throw new Error("useStore must be used within a StoreContextProvider");
  }

  return store;
};
