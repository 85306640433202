import React from "react";

import { Typography, Divider, Grid, Avatar } from "@material-ui/core";

import { useStyles } from "styles";
import { Step } from "./StepsSection";

interface DetailedStepProps extends Step {
  number: number;
}

const DetailedStep: React.FC<DetailedStepProps> = ({
  number,
  title,
  image,
}) => {
  const styles = useStyles();

  return (
    <Grid item xs={12} sm={4} lg={4} md={4}>
      <div className={styles.detailedStepInfo}>
        <Avatar className={styles.detailedStepAvatar}>{number}</Avatar>
        <div>{image}</div>
        <div className={styles.detailedStepTextSection}>
          <Divider className={styles.detailedStepDivider} />
          <Typography variant="h3" gutterBottom>
            {title}
          </Typography>
        </div>
      </div>
    </Grid>
  );
};

export default DetailedStep;
