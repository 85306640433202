import { Button, CardMedia, List, ListItem, Typography } from "@material-ui/core";
import { useHistory, useParams } from "react-router";
import ButtonAddToCollection from "./ButtonAddToCollection";
import CloseIcon from "@material-ui/icons/Close";
import { KitModelType } from "models";
import Popover from "@material-ui/core/Popover";
import React from "react";
import TextTruncate from "react-text-truncate";
import { familyKey } from "@lib/utils/familyKey";
import { observer } from "mobx-react";
import { useStyles } from "styles";
import { useTranslation } from "react-i18next";
import { convertToSlug } from "@lib/utils/stringHelpers";

interface KitCardProps extends Partial<KitModelType> {
  isOption?: boolean;
  showTooltip: boolean;
}

const KitCard: React.FC<KitCardProps> = ({
  name,
  description,
  imageUrl,
  id = "",
  secondaryImageUrl,
  family,
  isOption = false,
}) => {
  const { brand = "all", product_id = 1 } = useParams<any>();
  const style = useStyles();
  const history = useHistory();
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = (): void => {
    setAnchorEl(null);
  };

  const popOverOpen = Boolean(anchorEl);

  const handleDetailsClick = (): void => {
    let familySlug = convertToSlug(family, "");
    window.scrollTo({ top: 0 });
    history.push(`/build/${familySlug}/details/${id}`);
  };

  const image = isOption ? secondaryImageUrl || imageUrl : imageUrl;

  return (
    <List>
      <ListItem className={style.kitCardContainer}>
        <div className={style.kitCardHeaderContainer}>
          <TextTruncate
            line={2}
            text={description}
            truncateText=""
            textTruncateChild={
              <a
                aria-owns={popOverOpen ? "mouse-over-popover" : undefined}
                aria-haspopup="true"
                onClick={handlePopoverOpen}
                className={style.kitCardHeaderEllipsis}
              >
                ...
              </a>
            }
            style={{
              fontFamily: "Roboto",
              fontSize: "22px",
              textTransform: "lowercase",
            }}
          />
          <Popover
            id="mouse-over-popover"
            open={popOverOpen}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "center",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "center",
              horizontal: "left",
            }}
            onClose={handlePopoverClose}
            disableRestoreFocus
          >
            <Typography variant="h4" color="textPrimary" className={style.fontSize16}>
              {description}
            </Typography>
            <Button
              className={style.popoverClose}
              color="primary"
              variant="outlined"
              size="small"
              onClick={handlePopoverClose}
            >
              <CloseIcon fontSize="small" />
            </Button>
          </Popover>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "baseline",
            }}
          >
            <Typography
              gutterBottom
              variant="body2"
              color="textSecondary"
              component="p"
              noWrap
              className={style.fontSize16}
            >
              {name}
            </Typography>
            {family && familyKey[family]?.icon}
          </div>
        </div>
        <div className={style.cardImgContainerContainer}>
          <div className={style.cardImgContainer}>
            {image && <CardMedia src={image} title={name} component="img" classes={{ root: style.cardImg }} />}
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: 16,
          }}
        >
          <Button
            variant="outlined"
            color="primary"
            size="small"
            onClick={handleDetailsClick}
            classes={{ outlinedPrimary: style.kitCardBtnOverrides }}
          >
            {t("Product details")}
          </Button>
          <ButtonAddToCollection id={id} />
        </div>
      </ListItem>
    </List>
  );
};
export default KitCard;
