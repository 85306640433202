import * as React from "react";
import { Slide, useScrollTrigger } from "@material-ui/core";

const style = {
    position: `sticky`,
    bottom: `25px`,
    zIndex: `99`,
    textAlign: "center"
};

const BackToTop = ({ children }) => {
    const trigger = useScrollTrigger();
    return (
        <Slide in={trigger} direction="up">
            <div style={style}>
                {children}
            </div>
        </Slide>
    );
};

export default BackToTop;
