import { BUILD_ALL, BUILD_COVE, BUILD_SUBZERO, BUILD_WOLF } from "@lib/utils/routes";
import { Divider, Typography } from "@material-ui/core";

import CoveLogo from "@lib/logos/cove.png";
import { Family } from "@lib/utils/familyKey";
import LinkNoStyle from "@lib/components/LinkNoStyle";
import React from "react";
import SubZeroLogo from "@lib/logos/sub-zero.png";
import WolfLogo from "@lib/logos/wolf.png";
import { useStore } from "@lib/hooks/useStore";
import { useStyles } from "styles";
import { useTranslation } from "react-i18next";

const LogoNavSection: React.FC = () => {
  const styles = useStyles();
  const { t } = useTranslation();
  const store = useStore();

  const handleClick = (family: Family): void => {
    store.setSearchTerms({ family: [family] }, true);
    store.handleResetCategories();
  };

  return (
    <div className={styles.logoNavSectionContainer}>
      <div className={styles.logoNavIconContainer}>
        <LinkNoStyle to={BUILD_WOLF} onClick={(): void => handleClick("Wolf")}>
          <div className={styles.logoNavIconWithText}>
            <img src={WolfLogo} />
            <Typography align="center" color="primary" variant="h4" className={styles.logoNavIconText}>
              {t("Cooking")}
            </Typography>
          </div>
        </LinkNoStyle>
        {/* <Divider className={styles.logoNavDivider} /> */}
        <LinkNoStyle to={BUILD_SUBZERO} onClick={(): void => handleClick("Sub Zero")}>
          <div className={styles.logoNavIconWithText}>
            <img
              style={{
                transform: "scale(1.5)",
                marginTop: "5px",
              }}
              src={SubZeroLogo}
            />
            <Typography align="center" color="primary" variant="h4" className={styles.logoNavIconText}>
              {t("Refrigeration")}
            </Typography>
          </div>
        </LinkNoStyle>
        {/* <Divider className={styles.logoNavDivider} /> */}
        <LinkNoStyle to={BUILD_COVE} onClick={(): void => handleClick("Cove")}>
          <div className={styles.logoNavIconWithText}>
            <img src={CoveLogo} />
            <Typography align="center" color="primary" variant="h4" className={styles.logoNavIconText}>
              {t("Dishwashing")}
            </Typography>
          </div>
        </LinkNoStyle>
      </div>
    </div>
  );
};

export default LogoNavSection;
