import { useRef, useEffect, MutableRefObject } from "react";

export const usePrevious = (
  value: any
): MutableRefObject<undefined> | undefined => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });

  return ref.current;
};
