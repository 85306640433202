import { Instance, flow, getEnv } from "mobx-state-tree";
import { UserModelBase } from "./UserModel.base";
import {
  UpdateUserInput,
  CreateUserInput,
  SendResetPasswordLinkInput,
  ResetUserPasswordInput,
} from "./RootStore.base";
import { values } from "mobx";

/* The TypeScript type of an instance of UserModel */
export interface UserModelType extends Instance<typeof UserModel.Type> {}

/* A graphql query fragment builders for UserModel */
export {
  selectFromUser,
  userModelPrimitives,
  UserModelSelector,
} from "./UserModel.base";

/**
 * UserModel
 */
export const UserModel = UserModelBase.actions((self) => {
  const updateUser = flow(function* (input: UpdateUserInput): Generator {
    try {
      const response = yield self.store.mutateUpdateUser(
        { input: { ...input, clientMutationId: "UpdateUser" } },
        (res) =>
          res.result.clientMutationId.user(
            (user) =>
              user.address.affiliatedTradeFirm.city.email.firstName.lastName
                .projectType.stateCode.zipCode
          ),
        () => {
          self.address = input.address;
          self.affiliatedTradeFirm = input.affiliatedTradeFirm;
          self.city = input.city;
          self.email = input.email;
          self.firstName = input.firstName;
          self.lastName = input.lastName;
          self.projectType = input.projectType;
          self.stateCode = input.stateCode;
          self.zipCode = input.zipCode;
        }
      );

      self.store.setUserLocalStorage();

      return response;
    } catch (error) {
      console.error("Update User", error);
      throw error;
    }
  });

  const loginUser = flow(function* loginUser(
    email: string,
    password: string
  ): Generator {
    try {
      const response: any = yield self.store.mutateLogin(
        {
          input: {
            email: email,
            password: password,
            clientMutationId: "LoginUser",
          },
        },
        (res) =>
          res.clientMutationId.result.user((user) =>
            user.address.affiliatedTradeFirm.city.email.firstName.lastName.fullName.zipCode.projectType.stateCode.token.role.locationId.collections(
              (col) => col.name.createdAt.updatedAt.snapshotUrl.collectionKits()
            )
          ),
        () => {
          self.email = email;
        }
      );

      if (response?.login?.user) {
        self.store.setCurrentUser(response.login.user);
        self.store.setUserToken();
        self.store.setUserLocalStorage();

        const collectionLS =
          localStorage.getItem("dssTempCollection") &&
          JSON.parse(localStorage.getItem("dssTempCollection") || "");

        if (collectionLS?.id) {
          self.store
            .updateCollectionLocalStorage(collectionLS?.id, collectionLS?.name)
            .then((r: any) => {
              self.store
                .queryCollectionLocalStorage(collectionLS.id)
                .then((result: any) => {
                  console.log(result);
                });
            });
        } else {
          let collections = values(self.store.currentUser.collections);
          if (collections.length > 0) {
            let collection = collections[0];
            self.store.setCurrentCollection(collection);
            self.store.handleQueryCollectionKits(collection.id);
          }
        }
      }

      return response;
    } catch (error) {
      console.error("Login:", error);
      throw error;
    }
  });

  const createUser = flow(function* (input: CreateUserInput): Generator {
    try {
      const response = yield self.store.mutateSignUp(
        { input: { ...input, clientMutationId: "SignUp" } },
        (res: any) =>
          res.result.clientMutationId.distributor.user(
            (user: any) =>
              user.address.affiliatedTradeFirm.city.email.firstName.lastName
                .projectType.stateCode.zipCode.result
          ),
        () => {
          self.address = input.address;
          self.affiliatedTradeFirm = input.affiliatedTradeFirm;
          self.city = input.city;
          self.firstName = input.firstName;
          self.lastName = input.lastName;
          self.email = input.email;
          self.projectType = input.projectType;
          self.stateCode = input.stateCode;
          self.zipCode = input.zipCode;
        }
      );

      loginUser(input.email, input.password);

      return response;
    } catch (error) {
      throw error;
    }
  });

  const sendResetPasswordLink = flow(function* (
    email: SendResetPasswordLinkInput
  ): Generator {
    try {
      const response = yield self.store.mutateSendResetPasswordLink(
        { input: { email: email, clientMutationId: "SendResetPasswordLink" } },
        (res) => res.result.clientMutationId.user((user) => user.email),
        () => {
          self.email = email;
        }
      );

      return response;
    } catch (error) {
      console.error("Reset Password User:", error);
      throw error;
    }
  });

  const resetUserPassword = flow(function* (
    password: string,
    passwordConfirmation: string,
    resetPasswordToken: string
  ): Generator {
    try {
      const response = yield self.store.mutateResetUserPassword(
        {
          input: {
            password: password,
            passwordConfirmation: passwordConfirmation,
            resetPasswordToken: resetPasswordToken,
            clientMutationId: "ResetUserPassword",
          },
        },
        (res) =>
          res.result.clientMutationId.user(
            (user) => user.password,
            (user) => user.passwordConfirmation,
            (user) => user.resetPasswordToken
          ),
        () => {
          self.password = password;
          self.passwordConfirmation = passwordConfirmation;
          self.resetPasswordToken = resetPasswordToken;
        }
      );

      return response;
    } catch (error) {
      console.error("Reset Password User:", error);

      throw error;
    }
  });

  return {
    updateUser,
    loginUser,
    createUser,
    sendResetPasswordLink,
    resetUserPassword,
  };
});
