/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { IObservableArray } from "mobx"
import { types } from "mobx-state-tree"
import { MSTGQLRef, QueryBuilder, withTypedRefs } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { CanvasKitModel, CanvasKitModelType } from "./CanvasKitModel"
import { CanvasKitModelSelector } from "./CanvasKitModel.base"
import { RootStoreType } from "./index"


/* The TypeScript type that explicits the refs to other models in order to prevent a circular refs issue */
type Refs = {
  canvasKits: IObservableArray<CanvasKitModelType>;
}

/**
 * CanvasBase
 * auto generated base class for the model CanvasModel.
 */
export const CanvasModelBase = withTypedRefs<Refs>()(ModelBase
  .named('Canvas')
  .props({
    __typename: types.optional(types.literal("Canvas"), "Canvas"),
    canvasKits: types.union(types.undefined, types.null, types.array(MSTGQLRef(types.late((): any => CanvasKitModel)))),
    collectionId: types.union(types.undefined, types.string),
    createdAt: types.union(types.undefined, types.string),
    id: types.identifier,
    name: types.union(types.undefined, types.string),
    updatedAt: types.union(types.undefined, types.string),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  })))

export class CanvasModelSelector extends QueryBuilder {
  get collectionId() { return this.__attr(`collectionId`) }
  get createdAt() { return this.__attr(`createdAt`) }
  get id() { return this.__attr(`id`) }
  get name() { return this.__attr(`name`) }
  get updatedAt() { return this.__attr(`updatedAt`) }
  canvasKits(builder?: string | CanvasKitModelSelector | ((selector: CanvasKitModelSelector) => CanvasKitModelSelector)) { return this.__child(`canvasKits`, CanvasKitModelSelector, builder) }
}
export function selectFromCanvas() {
  return new CanvasModelSelector()
}

export const canvasModelPrimitives = selectFromCanvas().collectionId.createdAt.name.updatedAt
