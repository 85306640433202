import React from "react";

import { CircularProgress } from "@material-ui/core";

import { useStyles } from "styles";

const LoadingContainer: React.FC = () => {
  const styles = useStyles();
  return (
    <div className={styles.loadingContainer}>
      <CircularProgress color="primary" disableShrink />
    </div>
  );
};

export default LoadingContainer;
