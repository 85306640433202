import { Button, Typography } from "@material-ui/core";

import ArrowDownward from "@material-ui/icons/ArrowDownward";
import React from "react";

interface ScrollToMoreProps {
  onClick?: any;
}

const defaultProps: ScrollToMoreProps = {};

const ScrollToMore = ({ onClick }: ScrollToMoreProps) => {
  return (
    <React.Fragment>
      <Typography color="primary" style={{ fontSize: 16, fontWeight: "800", marginBottom: "5px" }}>
        See how it works
      </Typography>
      <Button
        onClick={onClick}
        variant="contained"
        color="secondary"
        aria-label="scroll to see more"
        style={{ borderRadius: "50%", width: "50px", height: "50px", padding: "0", minWidth: "unset" }}
      >
        <ArrowDownward />
      </Button>
    </React.Fragment>
  );
};

ScrollToMore.defaultProps = defaultProps;

export default ScrollToMore;
