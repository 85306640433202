import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { useStore } from "@lib/hooks/useStore";
import { useNotification } from "@lib/components/NotificationManagerContext";

interface UseCollectionKitActions {
  createCollectionKit: (id: string, collectionName?: string) => Promise<any>;
  deleteCollectionKit: (id: string) => Promise<any>;
  addAccessoryProductToCollectionKit: (productId: string) => Promise<any>;
  removeAccessoryProductFromCollectionKit: (productId: string) => Promise<any>;
  addCollectionKitToCollection: (kitId: string) => Promise<any>;
  toggleOptional: () => Promise<any>;
  queryCollectionKits: () => Promise<any>;
  loading: boolean;
  success: boolean;
}

export const useCollectionKitActions = (): UseCollectionKitActions => {
  const store = useStore();
  const { t } = useTranslation();
  const { openNotification } = useNotification();

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  let timer: number;

  const handleSuccess = (): void => {
    setSuccess(true);
    timer = window.setTimeout(() => setSuccess(false), 3000);
    openNotification(t("Item Saved To Collection"));
  };

  useEffect(() => {
    return (): void => {
      clearTimeout(timer);
    };
  }, []);

  const createCollectionKit = async (id: string, collectionName?: string): Promise<any> => {
    try {
      setLoading(true);
      const response = await store.collectionStore?.addKitToCollection(id, collectionName);
      handleSuccess();
      return response;
    } catch (error) {
      openNotification(t("Server Error Failed to add item"));
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const deleteCollectionKit = async (id: string): Promise<any> => {
    try {
      const response = await store.handleDestroyCollectionKit(id);
      handleSuccess();
      return response;
    } catch (error) {
      console.error(error);
      openNotification();
    }
  };

  const addAccessoryProductToCollectionKit = async (productId: string): Promise<any> => {
    try {
      const response = await store.selectedCollectionKit?.addAccessoryProduct(productId);
      handleSuccess();
      return response;
    } catch (error) {
      console.error(error);
    }
  };

  const addCollectionKitToCollection = async (kitId: string): Promise<any> => {
    try {
      setLoading(true);
      const response = await store.collectionStore?.addKitToCollection(kitId);
      //store.collectionStore?.setSelectedCollectionKit(response.createCollectionKit?.collectionKit?.id);

      handleSuccess();
      return response;
    } catch (error) {
      // openNotification();
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const removeAccessoryProductFromCollectionKit = async (productId: string): Promise<any> => {
    try {
      const response = await store.selectedCollectionKit?.removeAccessoryProduct(productId);
      handleSuccess();
    } catch (error) {
      console.error(error);
    }
  };

  const toggleOptional = async (): Promise<any> => {
    try {
      const response = await store.selectedCollectionKit?.toggleOptional();
      return response;
    } catch (error) {
      // openNotification();
      console.error(error);
    }
  };

  const queryCollectionKits = async (): Promise<any> => {
    try {
      setLoading(true);
      const response = await store.handleQueryCollectionKits();
      return response;
    } catch (error: any) {
      // openNotification();
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return {
    createCollectionKit,
    deleteCollectionKit,
    addAccessoryProductToCollectionKit,
    removeAccessoryProductFromCollectionKit,
    addCollectionKitToCollection,
    toggleOptional,
    queryCollectionKits,
    loading,
    success,
  };
};
