import { Instance } from "mobx-state-tree";
import { CollectionKitModelBase } from "./CollectionKitModel.base";
import { Query } from "mst-gql";

/* The TypeScript type of an instance of CollectionKitModel */
export interface CollectionKitModelType extends Instance<typeof CollectionKitModel.Type> {}

/* A graphql query fragment builders for CollectionKitModel */
export {
  selectFromCollectionKit,
  collectionKitModelPrimitives,
  CollectionKitModelSelector,
} from "./CollectionKitModel.base";

/**
 * CollectionKitModel
 */
export const CollectionKitModel = CollectionKitModelBase.actions((self) => {
  const addAccessoryProduct = (id: string): Query => {
    const input = {
      collectionKitId: self.id,
      productId: id,
      clientMutationId: id,
    };

    return self.store.mutateCreateCollectionKitProduct(
      { input },
      (res) => res.collectionKit((colKit) => colKit.accessoryProducts()),
      () => {
        const accessory = self.store.products.get(id);

        if (accessory) {
          if (self.accessoryProducts) {
            return self.accessoryProducts.push(accessory);
          }

          self.accessoryProducts = [accessory];
        }
      }
    );
  };

  const removeAccessoryProduct = (id: string): Query => {
    const input = {
      collectionKitId: self.id,
      productId: id,
      clientMutationId: id,
    };

    return self.store.mutateDestroyCollectionKitProduct(
      { input },
      (res) => res.collectionKit((colKit) => colKit.accessoryProducts()),
      () => {
        self.accessoryProducts = self.accessoryProducts.filter((product) => product.id !== id);
      }
    );
  };

  const toggleOptional = () => {
    const id = self.store.selectedCollectionKit?.id;
    const optional = !self.store.selectedCollectionKit?.optional;

    const input = { id, optional, clientMutationId: "ToggleOptional" };

    return self.store.mutateUpdateCollectionKit(
      { input },
      (res) => res.collectionKit((colKit) => colKit.optional),
      () => {
        if (self.store.selectedCollectionKit) {
          self.store.selectedCollectionKit.optional = optional;
        }
      }
    );
  };

  return { removeAccessoryProduct, addAccessoryProduct, toggleOptional };
});
