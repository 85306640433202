import React, { useEffect } from "react";
import { useDrag } from "react-dnd";
import { useStyles } from "styles";
import { ItemTypes } from "./PreviewWallContainer";
import { usePreviewWallActions } from "@lib/hooks/usePreviewWallActions";
import { useStore } from "@lib/hooks/useStore";
import { Slide } from "@material-ui/core";
export interface ItemProps {
  left: number;
  top: number;
  stackable: boolean;
  featured: boolean;
  itemHeight: number;
  itemWidth: number;
  collectionKitId: string;
  imageUrl: string;
}

const PreviewWallItem: React.FC<ItemProps> = ({
  left,
  top,
  itemHeight,
  itemWidth,
  stackable,
  featured,
  imageUrl,
  collectionKitId,
}) => {
  const styles = useStyles();
  const [{ isDragging }, drag] = useDrag({
    item: {
      left,
      top,
      itemHeight,
      itemWidth,
      stackable,
      featured,
      collectionKitId,
      type: ItemTypes.ITEM,
    },
    options: { dropEffect: "copy" },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const { handleRemoveFromWall } = usePreviewWallActions(collectionKitId);
  const [checked, setChecked] = React.useState(true);
  const direction: string = !stackable ? "up" : "down";

  useEffect(() => {}, []);

  const handleRemoveItem = (): void => {
    setChecked(false);
  };

  const onExit = (): void => {
    handleRemoveFromWall();
  };

  return (
    <div
      ref={drag}
      className={styles.wallItem}
      style={{
        left,
        top,
        height: itemHeight,
        width: itemWidth,
      }}
    >
      <button onClick={handleRemoveItem} className={styles.wallItemButtonClose}>
        <span className={styles.wallItemButtonCloseIcon}></span>
      </button>
      <Slide direction={direction} in={checked} onExited={onExit} mountOnEnter unmountOnExit>
        <img src={imageUrl} className={styles.wallImg} />
      </Slide>
    </div>
  );
};

export default PreviewWallItem;
