import {
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Icon,
  Typography,
} from "@material-ui/core";
import { useHistory, useLocation } from "react-router";

import { BUILD_ALL } from "@lib/utils/routes";
import React from "react";
import _ from "lodash";
import checkboxFillDashSvg from "@lib/icons/check-box-fill-dash-icon.svg";
import checkboxFillSvg from "@lib/icons/check-box-fill-icon.svg";
import clsx from "clsx";
import { observer } from "mobx-react";
import { useCategoriesActions } from "@lib/hooks/useCategoriesActions";
import { useKitActions } from "@lib/hooks/useKitActions";
import { useStore } from "@lib/hooks/useStore";
import { useStyles } from "styles";
import { useTranslation } from "react-i18next";

const Facets: React.FC = observer(() => {
  const styles = useStyles();
  const store = useStore();
  const { t } = useTranslation();
  const { queryKits } = useKitActions();
  const { queryCategories } = useCategoriesActions();

  const [showCategoriesHeader, setShowCategoriesHeader] = React.useState(false);
  const [cbChecked, setCbChecked] = React.useState([]);
  const [queryCategoriesRan, setQueryCategoriesRan] = React.useState(false);
  const [cbWidthFiltered, setCbWidthFiltered] = React.useState([]);
  const history = useHistory();
  const location = useLocation();

  const activeRadioMap: any = {
    "/build": "All Brands",
    "/build/cove": "Cove",
    "/build/wolf": "Wolf",
    "/build/subzero": "Sub Zero",
    "/build/best": "Best",
  };

  React.useEffect(() => {
    if (location?.pathname) {
      if (location?.pathname == BUILD_ALL) {
        setShowCategoriesHeader(false);
      } else {
        setShowCategoriesHeader(true);
      }

      const unlisten = history.listen((location) => {
        if (activeRadioMap[location.pathname] != undefined) {
          let temp: any = [];
          if (location?.pathname == BUILD_ALL) {
            setShowCategoriesHeader(false);
          } else {
            setShowCategoriesHeader(true);
          }
          setCbChecked(temp);
          setCbWidthFiltered(temp);
        }
      });

      return unlisten;
    }
  }, []);

  React.useEffect(() => {
    store.setLoading("facets", false);
  }, [store.searchFacets]);

  React.useEffect(() => {
    (async function () {
      await queryCategories();
      setQueryCategoriesRan(true);
    })();
  }, [setQueryCategoriesRan]);

  const CheckboxFillIcon = () => (
    <Icon>
      <img
        style={{
          maxWidth: "100%",
          position: "relative",
          top: "-4px",
        }}
        src={checkboxFillSvg}
      />
    </Icon>
  );

  const CheckboxFillDashIcon = () => (
    <Icon>
      <img
        style={{
          maxWidth: "100%",
          position: "relative",
          top: "-4px",
        }}
        src={checkboxFillDashSvg}
      />
    </Icon>
  );

  const handleChange = (target: any, parent?: any) => (event: any) => {
    let temp = cbChecked;

    target.seleted = !target.seleted;

    if (target.seleted == false && target?.items) {
      target.items.map((item: any, index: number) => {
        item.seleted = false;
      });
    }

    if (parent?.seleted == true && parent?.items) {
      let bools = parent.items.filter((item: any) => item.seleted);
      if (bools.length == parent?.items.length || bools.length == 0) {
        parent.indeterminate = false;
      } else {
        parent.indeterminate = true;
      }
    }

    if (target.seleted) {
      let values: any = [...temp, ...target.width];
      temp = values;
    } else if (!target.seleted) {
      for (let index = 0; index < target.width.length; index++) {
        const element: any = target.width[index];
        const values: any = _.filter(temp, (item: any) => {
          return item.label != element.label && item.parent != element.parent;
        });

        temp = values;
      }
    }

    store.setLoading("facets", true);
    store.updateSearchTerms(target.type, target.label);
    queryKits();
  };

  const handleChangeWh = (target: any) => (): void => {
    target.seleted = !target.seleted;
    store.setLoading("facets", true);
    store.updateSearchTerms(target.type, target.label);
    queryKits();
  };

  return (
    <div>
      <Divider className={clsx(styles.dividerThick, styles.margin32TopBottom)} />

      <FormControl component="fieldset" style={{ width: "100%" }}>
        {showCategoriesHeader && (
          <React.Fragment>
            <FormLabel component="legend">
              <Typography variant="h6" className={styles.facetCategoryHeader}>
                {t("Categories")}
              </Typography>
            </FormLabel>
            <FormGroup>
              {queryCategoriesRan &&
                store.currentFamily &&
                store?.currentFamilyFiltersList?.map((parent: any, index: number) => {
                  return (
                    <div key={index} className={styles.facetList}>
                      <ul style={{ margin: "0px" }}>
                        <li className={parent.label == "" ? styles.hide : ""}>
                          <FormControlLabel
                            key={parent.id}
                            label={parent.label}
                            classes={{ label: styles.fontSize13 }}
                            control={
                              <Checkbox
                                className={styles.facetCheckbox}
                                onClick={handleChange(parent)}
                                checkedIcon={<CheckboxFillIcon />}
                                checked={parent.seleted}
                                value={parent.label}
                                color="secondary"
                                indeterminateIcon={<CheckboxFillDashIcon />}
                                indeterminate={parent.seleted && parent.indeterminate}
                              />
                            }
                          />
                        </li>
                        {parent.width.map((width: any, i: number) => {
                          let other = !parent.seleted;
                          return (
                            <ul
                              key={`${index}_${i}`}
                              className={other ? styles.hide : ""}
                              style={{ marginLeft: "20px" }}
                            >
                              {width != null && (
                                <React.Fragment key={`width_${index}_${i}`}>
                                  <li className={!other && i == 0 ? styles.show : styles.hide}>Size</li>
                                  <li>
                                    <FormControlLabel
                                      key={width.id}
                                      label={width.label}
                                      classes={{ label: styles.fontSize13 }}
                                      control={
                                        <Checkbox
                                          className={styles.facetCheckbox}
                                          onClick={handleChangeWh(width)}
                                          checked={width.seleted}
                                          value={width.label}
                                          color="secondary"
                                          checkedIcon={<CheckboxFillIcon />}
                                        />
                                      }
                                    />
                                    &Prime;
                                  </li>
                                </React.Fragment>
                              )}
                            </ul>
                          );
                        })}
                        {parent.items.map((child: any, i: number) => {
                          let other = !parent.seleted;
                          return (
                            <ul
                              key={`${index}_${i}`}
                              className={other ? styles.hide : ""}
                              style={{ marginLeft: "20px" }}
                            >
                              {child != null && (
                                <li>
                                  <FormControlLabel
                                    key={child.id}
                                    label={child.label}
                                    classes={{ label: styles.fontSize13 }}
                                    control={
                                      <Checkbox
                                        className={styles.facetCheckbox}
                                        onClick={handleChange(child, parent)}
                                        checked={child.seleted}
                                        value={child.label}
                                        color="secondary"
                                        checkedIcon={<CheckboxFillIcon />}
                                      />
                                    }
                                  />
                                </li>
                              )}
                              <ul style={{ marginLeft: "20px" }}>
                                {child?.width?.map((width: any, i: number) => {
                                  let childSelected = !child.seleted;
                                  return (
                                    <React.Fragment key={`width_${index}_${i}`}>
                                      <li className={!childSelected && i == 0 ? styles.show : styles.hide}>Size</li>
                                      <li className={childSelected ? styles.hide : ""}>
                                        <FormControlLabel
                                          key={i}
                                          label={width.label}
                                          classes={{ label: styles.fontSize13 }}
                                          control={
                                            <Checkbox
                                              className={styles.facetCheckbox}
                                              onChange={handleChangeWh(width)}
                                              checked={width.seleted}
                                              value={width.label}
                                              color="secondary"
                                              checkedIcon={<CheckboxFillIcon />}
                                            />
                                          }
                                        />
                                        &Prime;
                                      </li>
                                    </React.Fragment>
                                  );
                                })}
                              </ul>
                            </ul>
                          );
                        })}
                      </ul>
                    </div>
                  );
                })}
            </FormGroup>
          </React.Fragment>
        )}
      </FormControl>
    </div>
  );
});

export default Facets;
