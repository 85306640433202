import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { useStore } from "@lib/hooks/useStore";
import { useNotification } from "@lib/components/NotificationManagerContext";
import { usePreviewWallActions } from "./usePreviewWallActions";
import { produce } from "immer";
interface useCanvasKitActions {
  createCanvasKit: (
    canvasId: string,
    collectionKitId: string,
    kitId: string,
    x: string,
    y: string,
    scale: string
  ) => Promise<any>;
  updateCanvasKit: (id: string, x: string, y: string, scale: string) => Promise<any>;
  createCanvasKits: (input: any[]) => Promise<any>;
  destroyCanvasKit: (id: string) => Promise<any>;
  queryCanvasKits: () => Promise<any>;
  destroyCanvasKits: () => Promise<any>;
  addCanvasKitsToPreviewWall: () => Promise<any>;
  loading: boolean;
  success: boolean;
}

export const useCanvasKitActions = (): useCanvasKitActions => {
  const store = useStore();
  const { t } = useTranslation();
  const { openNotification } = useNotification();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const { handleAddToWall } = usePreviewWallActions();

  let timer: number;

  const handleSuccess = (): void => {
    setSuccess(true);
    timer = window.setTimeout(() => setSuccess(false), 3000);
  };

  useEffect(() => {
    return (): void => {
      clearTimeout(timer);
    };
  }, []);

  const createCanvasKit = async (
    canvasId: string,
    collectionKitId: string,
    kitId: string,
    x: string,
    y: string,
    scale: string
  ): Promise<any> => {
    try {
      setLoading(true);
      await store.canvasStore?.createCanvasKit(canvasId, collectionKitId, kitId, x, y, scale);
      store.openPrevWallPopover({
        text: "Canvas Saved",
        time: 2000,
      });
      openNotification(t("Canvas Saved"));
      handleSuccess();
    } catch (error) {
      openNotification(t("Server Error: Failed to Save Canvas"));
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const updateCanvasKit = async (id: string, x: string, y: string, scale: string): Promise<any> => {
    try {
      setLoading(true);
      const response = await store.canvasStore?.updateCanvasKit(id, x, y, scale);
      store.openPrevWallPopover({
        text: "Canvas Updated",
        time: 2000,
      });
      openNotification(t("Canvas Updated"));
      handleSuccess();
      return response;
    } catch (error) {
      openNotification(t("Server Error: Failed to Update Canvas"));
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const createCanvasKits = async (input: any[]): Promise<any> => {
    try {
      setLoading(true);
      await store.createCanvasKits(input);
      handleSuccess();
    } catch (error) {
      openNotification(t("Server Error Failed to save items"));
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const destroyCanvasKit = async (id: string): Promise<any> => {
    try {
      await store.canvasStore.destroyCanvasKit(id);
    } catch (error) {
      // openNotification();
      console.error(error);
    }
  };

  const queryCanvasKits = async (): Promise<any> => {
    try {
      await store.queryCanvasKits();
      addCanvasKitsToPreviewWall();
    } catch (error) {
      // openNotification();
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  // THIS IS NOT FUNCTIONAL, ITS USING `store.currentCanvas?.canvasKits` BUT DO TO TIME I HAD TO USE
  // `store.previewWallContents` AS MAIN ARRAY. WILL REFACTOR WHEN HAVE MORE TIME!
  const destroyCanvasKits = async (): Promise<any> => {
    try {
      store.currentCanvas?.canvasKits?.forEach(async (value: any) => {
        if (value?.delete == true) {
          await destroyCanvasKit(value.id);
        }
      });
      openNotification(t("Canvas Item Deleted"));
    } catch (error) {
      // openNotification();
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const addCanvasKitsToPreviewWall = async (): Promise<any> => {
    let currentCollectionKits = store?.currentCollection?.collectionKits;
    let currentCanvasKits = store?.currentCanvas?.canvasKits;

    store.clearWallContent();

    if (currentCollectionKits && currentCanvasKits) {
      currentCanvasKits.forEach((item: any) => {
        handleAddToWall(item.collectionKitId);
      });
    }
  };

  return {
    createCanvasKit,
    updateCanvasKit,
    createCanvasKits,
    destroyCanvasKit,
    destroyCanvasKits,
    queryCanvasKits,
    addCanvasKitsToPreviewWall,
    loading,
    success,
  };
};
