import React from "react";

import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

const CoveMainLogo: React.FC<SvgIconProps> = props => {
  return (
    <SvgIcon viewBox="0 0 144 39" titleAccess="Cove Logo" {...props}>
      <path d="M14.214 37.9613C6.14524 36.0616 -0.35983 28.168 0.0154625 18.2437C0.359481 9.49851 6.86455 1.70318 15.3086 0.29478C16.372 0.131012 17.4666 0 18.5612 0C54.1202 0 89.6479 0 125.207 0C135.527 0 143.596 8.22113 143.846 18.997C144.065 27.8405 137.779 36.1599 129.491 37.9941C128.272 38.2888 124.894 38.4526 124.894 38.4526C89.3038 38.4526 53.7136 38.4526 18.1233 38.4526C16.3094 38.4199 14.214 37.9613 14.214 37.9613ZM71.7902 36.6839C89.7104 36.6839 107.631 36.6839 125.551 36.6839C132.994 36.6839 139.624 31.3124 141.563 23.779C144.378 12.7084 136.403 1.73593 125.426 1.73593C103.065 1.70318 80.7347 1.73593 58.3735 1.73593C45.0193 1.73593 31.6964 1.70318 18.3423 1.73593C9.55415 1.76869 2.20467 8.97445 1.64173 18.0799C1.01624 28.1025 8.70974 36.6839 18.3423 36.6839C36.1687 36.6839 53.9638 36.6839 71.7902 36.6839Z" />
      <path d="M56.3095 32.426C53.4322 32.6553 50.4925 31.8692 48.0218 29.5437C45.8013 27.4147 44.5816 24.7944 44.4878 21.6501C44.4252 20.2744 44.4565 18.9315 44.4878 17.5559C44.5503 11.7258 48.7724 6.87825 54.3705 6.51796C56.5284 6.38695 58.7802 6.35419 60.8755 6.81274C65.6605 7.89361 68.8505 12.1188 69.1007 17.2284C69.1945 18.9643 69.2258 20.733 68.9756 22.4362C68.1625 28.4956 63.9092 32.3605 58.0921 32.426C57.623 32.426 57.1539 32.426 56.3095 32.426ZM64.8474 19.4556C64.8474 18.8988 64.8474 18.3747 64.8474 17.8179C64.7536 13.7237 61.9702 10.8742 58.0609 10.8414C57.1226 10.8414 56.1844 10.8414 55.2462 10.8414C53.2446 10.8087 51.7122 11.7913 50.2735 13.1669C48.0531 15.2631 48.1156 23.6808 50.3361 25.777C52.1813 27.5457 54.2454 27.9388 56.6222 28.1025C61.4698 28.4301 65.3478 25.4822 64.8474 19.4556Z" />
      <path d="M127.333 14.641C126.52 14.641 125.957 14.6083 125.394 14.641C123.893 14.8048 122.673 14.5755 121.673 13.0361C120.641 11.4639 118.889 10.8089 117.013 10.8089C115.918 10.8089 114.824 10.7761 113.729 10.8089C110.508 10.8744 108.006 13.5929 107.974 17.2286C110.758 17.2286 113.541 17.2286 116.387 17.2286C116.387 18.7352 116.387 20.0781 116.387 21.552C113.573 21.552 110.82 21.552 108.1 21.552C107.756 24.1068 109.413 26.8581 111.915 27.6114C114.354 28.332 116.888 28.4303 119.265 27.4476C120.328 27.0218 121.297 26.1375 122.079 25.2204C122.705 24.4998 123.205 24.1068 124.143 24.1723C125.113 24.2706 126.114 24.205 127.271 24.205C126.02 28.2992 123.58 30.9523 119.765 31.9676C116.856 32.7537 113.854 32.7537 110.946 31.9676C107.255 30.9195 104.784 28.2992 103.815 24.4343C102.971 21.1262 102.971 17.7199 103.815 14.4118C104.941 9.82627 108.537 6.71468 113.072 6.48541C115.387 6.38715 117.795 6.35439 120.046 6.94395C123.737 7.8938 126.082 10.6123 127.333 14.641Z" />
      <path d="M40.1718 23.2548C39.859 25.6785 38.7957 27.5127 37.3258 29.0849C35.3868 31.1484 33.0099 32.2948 30.2265 32.393C28.4752 32.4258 26.6612 32.5568 24.9412 32.262C19.6871 31.4104 16.028 27.0214 15.8403 21.2568C15.7778 19.0623 15.8403 16.7368 16.3407 14.6079C17.4666 9.85861 21.3133 6.68151 25.9419 6.45223C28.1624 6.35397 30.4454 6.38673 32.6034 6.87804C36.4814 7.79513 39.515 11.3653 40.1405 15.4267C38.8582 15.4267 37.576 15.4595 36.325 15.3939C36.0748 15.3939 35.7621 14.9681 35.6057 14.6734C34.1984 11.9548 32.0092 10.7102 29.0694 10.8085C28.225 10.8412 27.4118 10.8085 26.5674 10.8085C22.9709 10.874 20.1562 13.789 20.0936 17.5557C20.0624 18.6365 20.0936 19.7502 20.0936 20.831C20.1249 24.6632 22.3454 27.4472 25.9107 27.9058C27.3806 28.1023 28.9443 28.0695 30.4142 27.9058C32.7597 27.6765 34.4798 26.3336 35.5432 24.1391C35.8872 23.4513 36.2312 23.1565 36.9818 23.222C37.9826 23.3203 39.0146 23.2548 40.1718 23.2548Z" />
      <path d="M70.6016 6.71453C72.1653 6.71453 73.5726 6.71453 75.0738 6.71453C76.2309 9.85886 77.3881 13.0687 78.5452 16.2785C79.6399 19.2919 80.7032 22.3052 81.7978 25.2858C82.6422 27.6113 83.2677 28.0371 85.6445 28.0371C88.8345 28.0371 89.1785 27.8078 90.3357 24.6634C92.3685 19.1281 94.3701 13.56 96.3404 7.99191C96.7157 6.9438 97.1848 6.518 98.2481 6.61626C99.1551 6.71452 100.093 6.64902 101.219 6.64902C101.094 7.20583 101 7.59887 100.875 7.99191C98.6234 14.2806 96.3716 20.5365 94.1199 26.7924C92.7438 30.6246 90.6797 32.1312 86.8017 32.1312C86.1762 32.1312 85.5507 32.1312 84.9252 32.1312C81.4225 32.1312 79.2333 30.6246 77.9823 27.1855C75.9495 21.6501 73.9792 16.082 71.9776 10.5139C71.5085 9.30206 71.102 8.09018 70.6016 6.71453Z" />
      <path d="M85.9263 4.06177C87.3337 5.86321 88.7097 7.59914 90.0545 9.40058C91.2742 11.071 91.5557 12.938 90.9615 14.9359C90.0858 17.72 87.3962 19.1611 84.4252 18.4406C82.017 17.851 80.2344 15.0997 80.641 12.4794C80.7973 11.5296 81.2352 10.6452 81.5792 9.72812C81.7043 9.40058 81.9857 9.10581 82.2359 8.81102C83.4556 7.23886 84.7066 5.66669 85.9263 4.06177Z" />
    </SvgIcon>
  );
};

export default CoveMainLogo;
